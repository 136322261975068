import { IconButton, Stack } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import { useEffect } from 'react';

interface NumberPickerProps {
  value?: number;
  minValue?: number;
  maxValue: number;
  onChange: (value: number) => void;
  decrementDisabled?: boolean;
  incrementDisabled?: boolean;
}

const NumberPicker = ({
  value = 0,
  minValue = 0,
  maxValue,
  onChange,
  decrementDisabled,
  incrementDisabled,
}: NumberPickerProps) => {
  useEffect(() => {
    onChange(value);
  }, [value]);

  function decrement() {
    const newValue = value === minValue ? 0 : value - 1;
    onChange(newValue);
  }

  function increment() {
    const newValue = value < minValue ? minValue : value + 1;
    onChange(newValue);
  }

  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      alignItems='center'
      className='p-1 shrink-0'
      sx={{ minWidth: '6.25rem' }}
    >
      <IconButton
        size='small'
        className='rounded-lg bg-[#F4F4F4]'
        sx={{ color: '#000' }}
        disabled={!value || decrementDisabled}
        onClick={decrement}
      >
        <Remove />
      </IconButton>

      <div className='px-4 font-bold flex min-w-[45px]'>{value}</div>

      <IconButton
        size='small'
        className='rounded-lg bg-[#F4F4F4]'
        sx={{ color: '#000' }}
        disabled={value === maxValue || incrementDisabled}
        onClick={increment}
      >
        <Add />
      </IconButton>
    </Stack>
  );
};

export default NumberPicker;
