import { EventArtistData, EventArtist } from '@/Models/eventArtists';
import { internalApi } from './internalApi';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { TAG_TYPE } from '@/Enums/tag-types';
import { getHideSuccessMessageHeader } from '@/Utils/http-client';

export const eventArtistsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventArtists: builder.query<EventArtistData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/artists`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
    }),
    getEventArtist: builder.query<EventArtist, { eventId: string; artistId: string }>({
      query: ({ eventId, artistId }) => ({
        url: `/events/${eventId}/artists/${artistId}`,
        method: 'GET',
      }),
    }),
    createEventArtist: builder.mutation<EventArtist, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/artists`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ARTISTS],
    }),
    updateEventArtist: builder.mutation<
      EventArtist,
      { eventId: string; artistId: string; body: any }
    >({
      query: ({ eventId, artistId, body }) => ({
        url: `/events/${eventId}/artists/${artistId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ARTISTS],
    }),
    deleteEventArtist: builder.mutation<any, { eventId: string; artistId: string }>({
      query: ({ eventId, artistId }) => ({
        url: `/events/${eventId}/artists/${artistId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.ARTISTS],
    }),
    generateEventArtistId: builder.mutation<{ id: string }, void>({
      query: () => ({
        url: `/artists/generate-id`,
        method: 'POST',
        headers: getHideSuccessMessageHeader(),
      }),
    }),
  }),
});

export const {
  useGetEventArtistsQuery,
  useGetEventArtistQuery,
  useCreateEventArtistMutation,
  useUpdateEventArtistMutation,
  useDeleteEventArtistMutation,
  useGenerateEventArtistIdMutation,
} = eventArtistsApi;
