import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
} from '@mui/material';
import { formatPrice, currencySymbol } from '@/Utils/currency';

const TicketList = ({
  event,
  tickets,
  selectedTickets,
  setSelectedTickets,
  orderTicketsDetails,
}: any) => {
  const handleTicketClick = (ticket: any) => {
    if (selectedTickets.includes(ticket)) {
      setSelectedTickets(selectedTickets.filter((tck: any) => tck !== ticket));
    } else {
      setSelectedTickets([...selectedTickets, ticket]);
    }
  };

  const filteredTickets = tickets.filter((t: any) => {
    const ticketPrice = parseFloat(t.price);
    return orderTicketsDetails.some((d: any) => parseFloat(d.price) === ticketPrice);
  });

  return filteredTickets.length > 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredTickets.map((ticket: any) => (
          <TableRow key={ticket.id}>
            <TableCell padding='checkbox'>
              <Checkbox
                checked={selectedTickets.includes(ticket)}
                onChange={() => handleTicketClick(ticket)}
                disabled={ticket.sold_out}
              />
            </TableCell>
            <TableCell>{ticket.title}</TableCell>
            {!ticket.sold_out ? (
              <TableCell style={{ verticalAlign: 'middle' }}>
                {formatPrice(ticket.price, event.currency)}
              </TableCell>
            ) : (
              <TableCell style={{ verticalAlign: 'middle', color: 'red' }}>Sold out</TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <>
      {' '}
      <Typography>No tickets found with the same price and currency</Typography>
    </>
  );
};

export default TicketList;
