import { FilterData } from '@/Models/filtering';
import { cleanObject } from './general';

export function getFilterQueryParams(
  { search, pageData, sortData, filters }: FilterData,
  headers: Record<string, string | number> = {},
): Record<string, string | number> {
  return {
    ...headers,
    ...(filters || {}),
    ...(search ? { search } : {}),
    ...(pageData ? { page: pageData.page + 1, pageSize: pageData.pageSize } : {}),
    ...(sortData ? { sortProperty: sortData.propertyName, sortOrder: sortData.order } : {}),
  };
}

export function getFilterQueryParamsFromObject(
  randomObject: Record<string, any>,
): Record<string, string | number> {
  return Object.entries(cleanObject(randomObject)).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: typeof value === 'number' ? value : String(value),
    };
  }, {});
}
