import { Card, CardContent, IconButton, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface StatsCardProps {
  title: string;
  value: string;
  Icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      })
    | null;
  className?: string;
}

const StatsCard = ({ title, value, Icon, className }: StatsCardProps) => {
  return (
    <Card sx={{ minWidth: 275 }} className={`relative  ${className}`}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {title}
        </Typography>
        <Typography variant='h4' component='div'>
          {value}
        </Typography>
        <IconButton disabled className='top-2 right-2' style={{ position: 'absolute' }}>
          {Icon && <Icon color='primary' sx={{ fontSize: 32 }} />}
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
