import { Stack, Typography } from '@mui/material';
import SvgEditor from './SvgEditor';
import EditingPane from './EditingPane';
import { useContext, useEffect, useState } from 'react';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import SeatSectionLabelingForm from './SeatSectionLabelingForm';
import { SeatSectionLabelForm } from './seat-section-labeling-form';
import { editorTools } from '@/Constants/venue-tools';
import { useSelector } from 'react-redux';
import { GlobalStore } from '@/store';
import { ColorGenerator, getItemId } from '@festtix/venue-editor';

interface SectionIdentifierEditorContainerProps {
  svgContent: string;
}

const colorGenerator = new ColorGenerator();

function SectionIdentifierEditorContainer({ svgContent }: SectionIdentifierEditorContainerProps) {
  const { venueEditorService, sectionIdentifierService, editorEventService } =
    useContext(VenueEditorContext);
  const [selectedSectionId, setSelectedSectionId] = useState<string>('');
  const { currentTool } = useSelector((state: GlobalStore) => state.venueEditor);

  function initialize() {
    venueEditorService.initialize('venue-svg-container-canvas', svgContent);
    venueEditorService.removeAllSeats();

    const sections = venueEditorService.getAllSections();
    sectionIdentifierService.generateLabelsForSections(sections);
    venueEditorService.fillLabelsForSections(sectionIdentifierService.getSectionData());
    venueEditorService.makeSectionsVisibleAtAllTimes();

    const sectionPalette = colorGenerator.generateColors(sections.length);
    sectionIdentifierService.assignColorsToSections(sections, sectionPalette);
    editorEventService.sectionsStatusChanged$.next(sections);
    editorEventService.sectionsVisibilityChanged$.next(sections);
  }

  useEffect(() => {
    const sub = venueEditorService.getSelectedSectionId$().subscribe((selectedSectionId) => {
      setSelectedSectionId(selectedSectionId);
    });

    return () => sub.unsubscribe();
  }, []);

  function onSeatSectionLabelFormSubmit(data: SeatSectionLabelForm) {
    const selectedSection = venueEditorService.getSelectedSectionAsPaperItem();

    if (!selectedSection) {
      return;
    }

    venueEditorService.addLabelToSelectedSection(data.sectionLabel);
    sectionIdentifierService.fillSectionIdentificationData(getItemId(selectedSection), data);
  }

  function getToolHelperText() {
    const tool = editorTools.find((tool) => tool.type === currentTool);

    return tool?.helperText ?? '';
  }

  return (
    <Stack direction='row' className='w-full h-[calc(100vh-186px)]'>
      <div className='w-[70%]'>
        <SvgEditor initialize={initialize} />
      </div>
      <div className='w-[30%] border-solid border-l-2'>
        <EditingPane allowedTools={['singleSectionSelector']}>
          {!!selectedSectionId ? (
            <Stack>
              <div className='mb-4'>
                <SeatSectionLabelingForm onSubmit={onSeatSectionLabelFormSubmit} />
              </div>
            </Stack>
          ) : (
            <Stack className='h-full' alignItems='center' justifyContent='center'>
              <Typography variant='body1'>{getToolHelperText()}</Typography>
            </Stack>
          )}
        </EditingPane>
      </div>
    </Stack>
  );
}

export default SectionIdentifierEditorContainer;
