import { TAG_TYPE } from '@/Enums/tag-types';
import { TransactionData } from '@/Models/transactions';
import { internalApi } from './internalApi';
import { CheckoutForm } from '@/Components/Custom/Checkout/form-data';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';

export interface CreateTransactionPayload {
  tickets: Record<string, number>;
  vouchers?: string[];
}

export interface CheckoutFormData {
  orderData: CheckoutForm & {
    transactionId: string;
  };
}

export const transactionsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    createTransaction: builder.mutation<any, { eventId: string; body: CreateTransactionPayload }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/transactions`,
        method: 'POST',
        body,
      }),
    }),

    cancelTransaction: builder.mutation<any, { eventId: string; transactionId: string }>({
      query: ({ eventId, transactionId }) => ({
        url: `/public/events/${eventId}/transactions/${transactionId}/cancel`,
        method: 'PUT',
      }),
    }),

    getTransactionData: builder.query<
      any,
      {
        eventId: string;
        transactionId: string;
        withReservedTickets: boolean | null;
        withReservedSeats: boolean | null;
      }
    >({
      query: ({
        eventId,
        transactionId,
        withReservedTickets = false,
        withReservedSeats = false,
      }) => ({
        url: `/public/events/${eventId}/transactions/${transactionId}`,
        method: 'GET',
        params: {
          withReservedTickets,
          withReservedSeats,
        },
      }),
    }),

    captureOrder: builder.mutation<any, { body: CheckoutForm; transactionId: string | undefined }>({
      query: ({ body, transactionId }) => ({
        url: `/events/support/orders/capture/${transactionId}`,
        method: 'PUT',
        body,
      }),
    }),

    getTicketData: builder.query<any, { ticketId: string }>({
      query: ({ ticketId }) => ({
        url: `/events/support/ticket/${ticketId}`,
        method: 'GET',
      }),
    }),

    getTransactions: builder.query<any, { filterData: FilterData }>({
      query: ({ filterData }) => ({
        url: `/transactions`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.TRANSACTIONS],
    }),

    getTransactionsStats: builder.query<any, any>({
      query: () => ({
        url: `/transactions-stats`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.TRANSACTIONS],
    }),

    getAppliedVoucher: builder.query<any, { eventId: string; transactionId: string }>({
      query: ({ eventId, transactionId }) => ({
        url: `/public/events/${eventId}/transactions/${transactionId}/applied-voucher`,
        method: 'GET',
      }),
    }),

    applyVoucher: builder.mutation<
      any,
      { eventId: string; transactionId: string; voucher: string }
    >({
      query: ({ eventId, transactionId, voucher }) => ({
        url: `/public/events/${eventId}/transactions/${transactionId}/apply-voucher`,
        method: 'POST',
        body: {
          voucher,
        },
      }),
    }),

    removeVoucher: builder.mutation<void, { transactionId: string }>({
      query: ({ transactionId }) => ({
        url: `/public/transactions/${transactionId}/remove-voucher`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateTransactionMutation,
  useCancelTransactionMutation,
  useGetTransactionDataQuery,
  useCaptureOrderMutation,
  useGetTicketDataQuery,
  useGetTransactionsQuery,
  useGetTransactionsStatsQuery,
  useGetAppliedVoucherQuery,
  useApplyVoucherMutation,
  useRemoveVoucherMutation,
} = transactionsApi;
