import { SeatData, SectionData } from '@/Models/seating';
import { Ticket } from '@/Models/tickets';
import {
  ColorGenerator,
  SeatIdentificationData,
  SectionIdentificationData,
  getItemId,
  isSeatAvailable,
} from '@festtix/venue-editor';
import { groupBy } from './general';

export function adaptSectionData(data: SectionData): {
  [sectionId: string]: SectionIdentificationData;
} {
  return data.reduce((acc, item) => {
    acc[item.id] = { ...item };
    return acc;
  }, {} as { [sectionId: string]: SectionIdentificationData });
}

export function adaptSeatData(data: SeatData): { [seatId: string]: SeatIdentificationData } {
  return data.reduce((acc, item) => {
    acc[item.id] = { ...item };
    return acc;
  }, {} as { [seatId: string]: SeatIdentificationData });
}

export function extractSeatLabellingData(data: { [seatId: string]: SeatIdentificationData }): {
  [seatId: string]: SeatIdentificationData;
} {
  return Object.keys(data).reduce((acc, seatId) => {
    acc[seatId] = {
      section_id: data[seatId].section_id,
      group_label: data[seatId].group_label,
      seat_number: data[seatId].seat_number,
    };

    return acc;
  }, {} as { [seatId: string]: SeatIdentificationData });
}

export function extractSeatCategorizationData(data: { [seatId: string]: SeatIdentificationData }): {
  [seatId: string]: SeatIdentificationData;
} {
  return Object.keys(data).reduce((acc, seatId) => {
    acc[seatId] = {
      ticket_id: data[seatId].ticket_id,
      invitation_type_id: data[seatId].invitation_type_id,
    };

    return acc;
  }, {} as { [seatId: string]: SeatIdentificationData });
}

export function buildSeatDescription(
  sectionLabel?: string,
  group_label?: string,
  seat_number?: number,
): string {
  const seatDescParts = [];
  if (sectionLabel) {
    seatDescParts.push(`Section: ${sectionLabel}`);
  }

  if (group_label) {
    seatDescParts.push(`Group label: ${group_label}`);
  }

  if (seat_number) {
    seatDescParts.push(`Seat no.: ${seat_number}`);
  }

  return seatDescParts.join(', ');
}

export function getSectionColorsBasedOnAvailability(
  seats: paper.Item[],
  seatData: { [seatId: string]: SeatIdentificationData },
  colorGenerator: ColorGenerator,
) {
  const seatsGroupedBySection = groupBy(
    seats,
    (seat) => seatData[getItemId(seat)]?.section_id || '',
  );

  const sectionAvailabilityData = Object.keys(seatsGroupedBySection).reduce((acc, sectionId) => {
    const sectionSeats = seatsGroupedBySection[sectionId];

    if (sectionSeats.length === 0) return acc; // Skip sections with no seats

    const availableSeatsCount = sectionSeats.filter((seat) => isSeatAvailable(seat)).length;
    const availableSeatsPercentage = Math.floor((availableSeatsCount / sectionSeats.length) * 100);

    acc[sectionId] = availableSeatsPercentage;
    return acc;
  }, {} as { [sectionId: string]: number });

  const intensities = Object.values(sectionAvailabilityData);
  if (intensities.length === 0) return {}; // Return an empty object if no sections are available

  const colorsByIntensity = colorGenerator.generateColorsBasedOnIntensity('#ff8833', intensities);

  const colorsBySectionId = Object.keys(sectionAvailabilityData).reduce((acc, sectionId) => {
    const intensity = sectionAvailabilityData[sectionId];
    const color = colorsByIntensity[intensity];

    if (typeof color === 'string') {
      acc[sectionId] = color;
    } else {
      console.error(`No color found for intensity ${intensity} in section ${sectionId}`);
    }

    return acc;
  }, {} as { [sectionId: string]: string });

  return colorsBySectionId;
}

export function buildSeatDataInformation(
  seat: paper.Item,
  tickets: Ticket[],
  seatsData: { [seatId: string]: SeatIdentificationData },
  sectionData: { [sectionId: string]: SectionIdentificationData },
): {
  ticket: Ticket | undefined;
  sectionLabel: string;
  groupLabel: string | undefined;
  seatNumber: number | undefined;
} | null {
  const seatId = getItemId(seat);
  const seatData = seatsData[seatId];
  const ticketId = seatData?.ticket_id;

  if (!ticketId) {
    return null;
  }

  const sectionId = seatData.section_id;

  return {
    ticket: tickets.find((ticket) => ticket.id === ticketId),
    sectionLabel: sectionData[sectionId!].label,
    groupLabel: seatData.group_label,
    seatNumber: seatData.seat_number,
  };
}
