import { hideLoader, showLoader } from '@/Slices/loaderSlice';
import { getHeaders } from '@/Utils/http';
import { useDispatch } from 'react-redux';

// TODO: fix in the future so that every interceptor included in redux internal API is used
// here too. Make sure to extract code for reusability
const useAdvancedFetch = () => {
  const dispatch = useDispatch();

  return async (url: string, fetchOptions: RequestInit = {}) => {
    const headers = await getHeaders(new Headers(), url);
    fetchOptions.headers = headers;

    dispatch(showLoader());

    return fetch(url, fetchOptions)
      .then((response) => {
        dispatch(hideLoader());

        return response;
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch(hideLoader());
        }, 200);

        throw error;
      });
  };
};

export default useAdvancedFetch;
