import { PropsWithChildren, useEffect } from 'react';
import TopNavigation from '@/Components/Templates/TopNavigation/TopNavigation';
import Sidebar from '@/Components/Organisms/Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import { GlobalStore } from '@/store';
import { useSelector } from 'react-redux';
import { LayoutMode } from '@/Models/layout';

interface Props {
  mode: LayoutMode;
}

// TODO: make header title a global component so that everyone renders it
// the same way
const MainLayout = ({ mode }: Props) => {
  const { header } = useSelector((state: GlobalStore) => state.layout);

  return (
    <div>
      <div className=' h-screen body flex grow'>
        <div className='z-50'>
          <Sidebar mode={mode} />
        </div>
        <div
          id='main-container'
          style={{ scrollBehavior: 'smooth' }}
          className='overflow-auto h-full flex flex-col w-full stylish-scrollbar'
        >
          <TopNavigation header={header} />

          <main className='h-full'>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
