import {
  adminNavItems,
  sidebarNavItems,
  eventNavItems,
  donationsItem,
  servicesItem,
  invitationItem,
  promocodesItem,
  venueItem,
  parkingSpacesItem,
  ticketCodes,
  posItem,
  supportOrders,
} from '../../../Constants/sidebar-nav-items';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  SwipeableDrawer,
  Button,
} from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getOrganization as getOrginaztionKey } from '@/Utils/auth';
import { useGetOrganizationQuery } from '@/Api/organizationsSlice';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PermissionsGate from '@/HOC/PermissionsGate';
import { useGetEventQuery } from '@/Api/eventsSlice';
import React, { useEffect, useState } from 'react';
import { LayoutMode } from '@/Models/layout';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useResponsive } from '@/Hooks/useResponsive';
import { SideBarItem } from '@/Constants/sidebar-nav-items';
import { EventsSidebar } from '@/Components/Organisms/Sidebar/SidebarSection';
import SidebarMenuList from '@/Components/Organisms/Sidebar/SidebarMenuList';
interface SidebarProps {
  mode: LayoutMode;
}

const cacheTime = 60 * 10 * 1000;

export default function Sidebar({ mode }: SidebarProps) {
  const { pathname } = useLocation();
  const { eventId } = useParams();
  const [localEventNavItems, setLocalEventNavItems] = useState(eventNavItems);

  const orgId = getOrginaztionKey();
  const { data: organization } = useGetOrganizationQuery(orgId!, {
    refetchOnMountOrArgChange: false,
    pollingInterval: cacheTime,
  });

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: false,
    skip: !eventId,
  });

  useEffect(() => {
    if (!event) {
      setLocalEventNavItems([...eventNavItems]);
      return;
    }

    const extraItems = [];
    if (event.invitations_enabled) {
      extraItems.push(invitationItem);
    }

    if (event.passcodes_enabled || event.vouchers_enabled) {
      extraItems.push(promocodesItem);
    }

    if (event?.visible){
      extraItems.push(supportOrders);
    }

    if (event?.donations_enabled) {
      extraItems.push(donationsItem);
    }

    if (event?.services_enabled) {
      extraItems.push(servicesItem);
    }

    if (event.parking_spaces_enabled) {
      extraItems.push(parkingSpacesItem);
    }

    if (event?.online_event) {
      extraItems.push(ticketCodes);
    }

    if (event?.seating_enabled) {
      extraItems.push(venueItem);
    }

    setLocalEventNavItems([...eventNavItems, ...extraItems]);
  }, [event]);

  const navItems = mode === LayoutMode.ADMIN ? adminNavItems : sidebarNavItems;
  const [isOpen, setIsOpen] = useState(false);

  const combinedNavItems = [...navItems];
  const regularNavItems = combinedNavItems.filter((item) => item !== posItem);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
      return;
    }

    setIsOpen(open);
  };

  const { isMobile } = useResponsive();
  return (
    <>
      {isMobile ? (
        <>
          <div className=' absolute top-3 left-[-0.3rem] z-50'>
            <Button size='small' onClick={toggleDrawer(true)}>
              <MenuIcon style={{ color: 'black' }} />
            </Button>
          </div>
          <SwipeableDrawer
            anchor='left'
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <div
              className={`pt-16 z-20 min-w-[16rem] w-1/6 max-w-2xl bg-gray-00 flex flex-col bg-gray-800`}
              style={{
                boxShadow: '4px 0px 6px -1px rgb(0 0 0 / 10%), 2px 0px 4px -2px rgb(0 0 0 / 10%)',
                height: '100vh',
              }}
              aria-label='Sidebar'
            >
              <div className='flex pl-5'>
                <div className='absolute top-6 left-[12rem] z-50'>
                  <Button size='small' onClick={toggleDrawer(false)}>
                    <CloseIcon style={{ color: 'white' }} />
                  </Button>
                </div>
                <div className='flex-shrink-0 flex items-center text-white'>
                  <Link onClick={toggleDrawer(false)} to='/events'>
                    <img
                      style={{ filter: 'drop-shadow(1px 0px 2px black)' }}
                      src='/images/festtix-logo.svg'
                      alt='Festtix Logo'
                    />
                  </Link>
                </div>
              </div>

              {mode === LayoutMode.EVENT ? (
                <EventsSidebar
                  pathname={pathname}
                  items={localEventNavItems}
                  toggleDrawer={toggleDrawer}
                />
              ) : (
                <SidebarMenuList
                  pathname={pathname}
                  items={regularNavItems}
                  toggleDrawer={toggleDrawer}
                  isMobile={true}
                />
              )}

              {organization && (
                <Stack
                  direction='row'
                  alignItems='center'
                  className='mt-auto mb-4 ml-4 pr-2'
                  spacing={2}
                >
                  <Avatar
                    alt={organization.name}
                    src={organization.logo}
                    variant={organization.logo ? 'square' : 'circular'}
                  >
                    {organization.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography variant='body1' color='white' noWrap>
                    {organization.name}
                  </Typography>
                </Stack>
              )}
            </div>
          </SwipeableDrawer>
        </>
      ) : (
        <aside
          className={`pt-16 z-20 min-w-[16rem] w-1/6 max-w-2xl flex flex-col bg-gray-800`}
          style={{
            boxShadow: '4px 0px 6px -1px rgb(0 0 0 / 10%), 2px 0px 4px -2px rgb(0 0 0 / 10%)',
            height: '100vh',
          }}
          aria-label='Sidebar'
        >
          <div className='flex pl-5'>
            <div className='flex-shrink-0 flex items-center text-white'>
              <Link to='/events'>
                <img
                  style={{ filter: 'drop-shadow(1px 0px 2px black)' }}
                  src='/images/festtix-logo.svg'
                  alt='Festtix Logo'
                />
              </Link>
            </div>
          </div>

          {mode === LayoutMode.EVENT ? (
            <EventsSidebar
              pathname={pathname}
              items={localEventNavItems}
              toggleDrawer={toggleDrawer}
            />
          ) : (
            <SidebarMenuList
              pathname={pathname}
              items={regularNavItems}
              isMobile={false}
              toggleDrawer={toggleDrawer}
            />
          )}

          {organization && mode !== LayoutMode.EVENT ? (
            <div>
              <Divider sx={{ color: 'inherit' }} className='mt-auto' />
              <Stack
                direction='row'
                alignItems='center'
                className='mt-auto mb-8 ml-4 pr-2'
                spacing={2}
              >
                <Avatar
                  alt={organization.name}
                  src={organization.logo}
                  variant={organization.logo ? 'square' : 'circular'}
                >
                  {organization.name.charAt(0).toUpperCase()}
                </Avatar>
                <Typography variant='body1' color='white' noWrap>
                  {organization.name}
                </Typography>
              </Stack>
            </div>
          ) : (
            organization && (
              <Stack
                direction='row'
                alignItems='center'
                className='mt-auto mb-5 ml-4 pr-2'
                spacing={2}
              >
                <Avatar
                  alt={organization.name}
                  src={organization.logo}
                  variant={organization.logo ? 'square' : 'circular'}
                >
                  {organization.name.charAt(0).toUpperCase()}
                </Avatar>
                <Typography variant='body1' color='white' noWrap>
                  {organization.name}
                </Typography>
              </Stack>
            )
          )}
        </aside>
      )}
    </>
  );
}
