import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ServiceForm, serviceFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { FileContext, ResourceType } from '@/Enums/files';
import {
  useCreateServiceMutation,
  useGetServiceQuery,
  useUpdateServiceMutation,
} from '@/Api/servicesSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { PreviewData } from '@/Models/preview';
import useLoading from '@/Hooks/useLoading';
import { cleanObject } from '@/Utils/general';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
const fileKeys = ['image'] as const;
type FileKey = typeof fileKeys[number];

const CreateOrEditService = () => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();
  const [fileQueue, setFileQueue] = useState<{ key: FileKey; file: File }[]>([]);

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [createService] = useCreateServiceMutation();
  const [updateService] = useUpdateServiceMutation();

  const { data: service } = useGetServiceQuery(serviceId!, {
    refetchOnMountOrArgChange: false,
    skip: !serviceId,
  });

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<ServiceForm>({
    defaultValues: {
      name: '',
      description: '',
      image: '',
    },
    resolver: zodResolver(serviceFormSchema),
  });

  useEffect(() => {
    if (!service) {
      return;
    }

    reset({
      ...cleanObject(service),
    });
  }, [service]);

  function getTitle() {
    return `${!!serviceId ? 'Edit' : 'Create'} Service Cause`;
  }

  async function onSubmit() {
    let serviceData = { ...getValues() };

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles());
      serviceData = { ...serviceData, ...processedFiles };
    }

    if (!validateImages(serviceData)) {
      return;
    }

    const promise = !!serviceId
      ? updateService({ serviceId, body: serviceData })
      : createService(serviceData);

    withLoading(promise.unwrap())
      .then(() => {
        navigate('/services');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof ServiceForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  const imagePreviews: { [key in FileKey]?: PreviewData } = useMemo(() => {
    return {
      image: service && { type: 'image', url: service.image },
    };
  }, [service]);

  function addFileInQueue(key: FileKey, file: File) {
    clearErrors(key);
    setValue(key, file);
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }
  async function processFiles(): Promise<Pick<ServiceForm, FileKey>> {
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.SERVICES,
      })),
    );

    const eventServicesData: Pick<ServiceForm, FileKey> = { image: '' };
    signedUrlPayloads.forEach((payload, i) => {
      eventServicesData[fileData[i].key] = payload.fields.key;
      setValue(fileData[i].key, payload.fields.key);
    });
    setFileQueue([]);

    return eventServicesData;
  }

  function validateImages(data: ServiceForm): boolean {
    const notUploadedImages = fileKeys.filter((key) => !data[key]);

    notUploadedImages.forEach((key) => {
      setError(key, {
        type: 'manual',
        message: 'Please upload an image first',
      });
    });

    return !notUploadedImages.length;
  }

  const { isMobile } = useResponsive();

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the cause
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Name'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='description'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Description'
                          variant='outlined'
                          fullWidth
                          multiline
                          rows={4}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <Controller
                        name='image'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <FileUpload
                            label='Image'
                            allowedFileTypes={allowedFileTypes}
                            maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                            onSelect={(file) => addFileInQueue('image', file)}
                            preview
                            previewData={imagePreviews.image}
                            helperText='Recommended size: 100x100px'
                            onError={(message) =>
                              setError('image', {
                                message,
                                type: CustomZodErrorType.INPUT_FILE,
                              })
                            }
                          />
                        )}
                      />

                      {!!errors.image && errors.image.type !== CustomZodErrorType.INPUT_FILE && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>
                            Please upload an image first
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEditService;
