import {
  useDeleteVenueMutation,
  useGetEventVenueQuery,
  useGetSeatingStatsQuery,
  useStoreEventVenueMutation,
} from '@/Api/seatingSlice';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import EmptyContent from '../Info/EmptyContent';
import {
  BookmarkBorder,
  Category,
  Delete,
  Download,
  EventSeat,
  FlightClass,
  MoreVert,
  Stadium,
  SystemUpdateAlt,
  Upload,
} from '@mui/icons-material';
import { useRef, useState } from 'react';
import { getFormDataFromObject } from '@/Utils/general';
import useLoading from '@/Hooks/useLoading';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { Link } from 'react-router-dom';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import StatsCard, { StatsCardProps } from '@/Components/Molecules/Cards/StatsCard';
import { useResponsive } from '@/Hooks/useResponsive';
import { AttachMoney, Equalizer, Functions, Sync } from '@mui/icons-material';
import environment from '@/environments/environment';
import { downloadFile } from '@/Utils/files';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

const cardDetails: {
  [key: string]: Omit<StatsCardProps, 'value'>;
} = {
  totalSeats: {
    title: 'Total Seats',
    Icon: Functions,
  },
  purchasedSeats: {
    title: 'Purchased Seats',
    Icon: AttachMoney,
  },
  reservedSeats: {
    title: 'Reserved Seats',
    Icon: FlightClass,
  },
  availableSeats: {
    title: 'Available Seats',
    Icon: EventSeat,
  },
};

function EventVenue() {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [venueDeletionDialogOpen, setVenueDeletionDialogOpen] = useState(false);
  const { eventId } = useParams<{ eventId: string }>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, withLoading] = useLoading();
  const advancedFetch = useAdvancedFetch();

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  const {
    data: eventVenueData,
    isLoading,
    isFetching,
  } = useGetEventVenueQuery(eventId!, { skip: !eventId });
  const [storeEventVenueMutation] = useStoreEventVenueMutation();
  const [deleteVenue] = useDeleteVenueMutation();

  const eventVenueExists = !!eventVenueData;

  const { data: stats } = useGetSeatingStatsQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  function onFileUploadButtonClick() {
    fileInputRef.current?.click();
  }

  function onFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files?.length) {
      return;
    }

    const file = event.target.files[0];

    deleteFile();

    const body = getFormDataFromObject({ file });

    withLoading(
      storeEventVenueMutation({
        eventId: eventId!,
        body,
      }),
    );
  }

  function deleteFile() {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  function onDialogClose(value: boolean) {
    setVenueDeletionDialogOpen(false);
    if (value) {
      deleteVenue(eventVenueData!.id);
    }
  }

  if (isLoading) {
    return (
      <Stack justifyContent='center' alignItems='center' className='w-full h-full'>
        <CircularProgress />
      </Stack>
    );
  }

  if (!eventVenueExists) {
    return (
      <>
        <input
          ref={fileInputRef}
          type='file'
          className='hidden'
          accept='.svg'
          onChange={onFileUpload}
        />
        <EmptyContent
          icon={<Stadium sx={{ color: '#cccccc', fontSize: '8rem' }} />}
          primaryText='No venue found'
          secondaryText='Please create a venue for this event'
          button={
            <LoadingButton loading={loading || isFetching}>
              <Button
                variant='contained'
                disabled={loading || isFetching}
                onClick={onFileUploadButtonClick}
              >
                Upload venue
              </Button>
            </LoadingButton>
          }
        />
      </>
    );
  }

  function onClickDownloadAssets() {
    advancedFetch(`${environment.baseUrl}/venue-assets?download=1`, {
      method: 'GET',
    }).then((response) => {
      handleBlobReponse(response);
    });
  }

  function onClickDownloadVenue(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    if (eventVenueData) {
      downloadFile(eventVenueData.svg, 'venue.svg');
    }
  }

  return (
    <>
      <Stack className='w-full h-full p-4' spacing={2}>
        <Grid container spacing={2}>
          {Object.entries(stats || []).map(([key, value]: [string, any], index) => {
            return (
              <Grid item xs={12} md={3} key={key}>
                <StatsCard
                  title={cardDetails[key].title}
                  value={stats[key]}
                  Icon={cardDetails[key].Icon}
                />
              </Grid>
            );
          })}
        </Grid>

        <Stack className='rounded-md overflow-hidden shadow-lg h-[calc(100%-16px-200px)]'>
          <Stack
            className='px-4 h-12 shadow-md bg-[#6c7193]'
            direction='row'
            justifyContent='space-between'
          >
            <Typography variant='h6' className='p-2' color='white'>
              Venue preview
            </Typography>

            <PermissionsGate scope={SCOPES.CreateEvent}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchor(event.currentTarget);
                }}
                sx={{ color: 'white' }}
              >
                <MoreVert />
              </IconButton>
            </PermissionsGate>
          </Stack>

          <Stack
            alignItems='center'
            justifyContent='center'
            className='bg-white h-[calc(100%-48px)] relative'
          >
            <img src={eventVenueData.svg} alt='Venue preview' className='h-full absolute' />
          </Stack>
        </Stack>
      </Stack>

      {anchor && (
        <Menu
          anchorEl={anchor}
          open={!!anchor}
          onClose={() => {
            setAnchor(null);
          }}
        >
          {/* TODO SVG: implement later */}
          <MenuItem disabled={true}>
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText>Upload venue</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              onClickDownloadAssets();
              setAnchor(null);
            }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText>Download Assets</ListItemText>
          </MenuItem>

          <Link to={`${eventVenueData.id}/editor`}>
            <MenuItem>
              <ListItemIcon>
                <BookmarkBorder />
              </ListItemIcon>
              <ListItemText>Label seats</ListItemText>
            </MenuItem>
          </Link>

          <Link to={`${eventVenueData.id}/configurator`}>
            <MenuItem>
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText>Categorize seats</ListItemText>
            </MenuItem>
          </Link>

          <MenuItem
            onClick={(e) => {
              onClickDownloadVenue(e);
              setAnchor(null);
            }}
          >
            <ListItemIcon>
              <SystemUpdateAlt />
            </ListItemIcon>
            <ListItemText>Export venue</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setVenueDeletionDialogOpen(true);
              setAnchor(null);
            }}
          >
            <ListItemIcon>
              <Delete color='error' />
            </ListItemIcon>
            <ListItemText>Delete venue</ListItemText>
          </MenuItem>
        </Menu>
      )}
      <DeleteDialog open={venueDeletionDialogOpen} onDialogClose={onDialogClose} />
    </>
  );
}

export default EventVenue;
