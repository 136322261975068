import { Event } from '@/Models/events';
import { DateFormats, formatDate } from './dates';

export function getEventDate(event?: Event): string {
  if (!event) {
    return 'Event date';
  }

  return `${formatDate(event.starts_at, DateFormats.LONG_DATE, event.timezone)} to ${formatDate(
    event.ends_at,
    DateFormats.LONG_DATE,
  )}`;
}
