import { ClearOrganizationAction } from '@/Constants/actions';
import { ADMIN_ORGANIZATION_ID, REGISTERED_ORGANIZATION_ID } from '@/Constants/auth';
import { UserRole } from '@/Enums/roles';
import useEffectOnce from '@/Hooks/useEffectOnce';
import authService from '@/Services/AuthService';
import { getOrganization } from '@/Utils/auth';
import { CircularProgress } from '@mui/material';
import { User } from 'oidc-client-ts';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function isRegisteredOrganization(org: string) {
  return org === REGISTERED_ORGANIZATION_ID;
}

function isNonAdminUserInAdminOrganization(org: string, user: User) {
  return org === ADMIN_ORGANIZATION_ID && !user.profile.organizations[org].includes(UserRole.ADMIN);
}

function userDoesNotBelongToOrganization(org: string, user: User) {
  return !user.profile.organizations[org];
}

const OidcCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffectOnce(() => {
    authService.handleCallback().then((user) => {
      const currentOrg = getOrganization();

      if (!!user && !!currentOrg) {
        if (
          isRegisteredOrganization(currentOrg) ||
          userDoesNotBelongToOrganization(currentOrg, user) ||
          isNonAdminUserInAdminOrganization(currentOrg, user)
        ) {
          dispatch(ClearOrganizationAction());
        }
      }

      navigate('/');
    });
  });

  return (
    <div className='h-screen w-screen flex items-center justify-center'>
      <CircularProgress />
    </div>
  );
};

export default OidcCallback;
