import { zodResolver } from '@hookform/resolvers/zod';
import { SeatLabelForm, seatLabelFormSchema } from './seat-labeling-form-helpers';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ArrowBack, ArrowDownward, ArrowForward, ArrowUpwardOutlined } from '@mui/icons-material';

interface SeatLabelingFormProps {
  onSubmit: (data: SeatLabelForm) => void;
}

function SeatLabelingForm({ onSubmit }: SeatLabelingFormProps) {
  const { control, handleSubmit, setValue } = useForm<SeatLabelForm>({
    defaultValues: {
      groupLabel: 'A',
      startFrom: 1,
      direction: 'ltr',
    },
    resolver: zodResolver(seatLabelFormSchema),
  });

  return (
    <form name='seatLabelForm' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='groupLabel'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  required
                  label='Group Label'
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='startFrom'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  required
                  type='number'
                  label='Start From'
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ min: 0 }}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='direction'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Stack>
                  <Typography variant='body2'>Direction*</Typography>

                  <ToggleButtonGroup
                    {...field}
                    onChange={(_, value) => {
                      if (!value) return;

                      setValue('direction', value);
                    }}
                    exclusive
                  >
                    <ToggleButton value='ltr'>
                      <ArrowForward />
                    </ToggleButton>

                    <ToggleButton value='rtl'>
                      <ArrowBack />
                    </ToggleButton>

                    <ToggleButton value='ttb'>
                      <ArrowDownward />
                    </ToggleButton>

                    <ToggleButton value='btt'>
                      <ArrowUpwardOutlined />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              )}
            />
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent='flex-end' className='mt-4'>
          <Button type='submit' variant='contained'>
            Apply
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default SeatLabelingForm;
