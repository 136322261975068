import { DialogProps } from '@/Models/dialogs';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatPrice } from '@/Utils/currency';
import { useGetEventQuery } from '@/Api/eventsSlice';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { useGetServicePurchasesQuery } from '@/Api/servicesSlice';
import { handleBlobReponse } from '@/Utils/blob';
import environment from '@/environments/environment';

interface ServiceProps extends DialogProps<any> {
  service_id: string | null;
}

const ServicePurchases = ({ open, onDialogClose, service_id }: ServiceProps) => {
  const { eventId } = useParams();
  const advancedFetch = useAdvancedFetch();

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const pageSize = 10;
  interface Event {
    id: string;
  }

  const { data: purchases } = useGetServicePurchasesQuery(
    {
      eventId: eventId!,
      filterData: { search: search, pageData: { page, pageSize } },
      uuid: service_id!,
    },
    { refetchOnMountOrArgChange: true },
  );

  console.log(purchases);

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  function onPageChange(page: number) {
    setPage(page);
  }

  async function onTicketClick(event: Event, referenceNo: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${event.id}/orders/${referenceNo}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response);
    });
  }

  const columns: GridColDef[] = [
    {
      field: 'order.first_name',
      headerName: 'First name',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return <span>{row.order.first_name}</span>;
      },
    },
    {
      field: 'order.last_name',
      headerName: 'Last name',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return <span>{row.order.last_name}</span>;
      },
    },
    {
      field: 'order.referenceNo',
      headerName: 'Order Reference No.',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketClick(event!, row.order.referenceNo);
            }}
          >
            {row.order.referenceNo}
          </span>
        );
      },
    },
    {
      field: 'service.name',
      headerName: 'Service Name',
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{row.service.name}</span>;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: (params) => {
        return <span>{formatPrice(params.row.amount, params.row.event.currency)}</span>;
      },
    },
  ];

  return (
    <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle>Service Purchases</DialogTitle>
      <DialogContent>
        <div className='h-[50vh]'>
          {purchases?.data && purchases.data.length > 0 && (
            <DataGrid
              columns={columns}
              rows={purchases.data}
              pageSize={pageSize}
              paginationMode='server'
              onPageChange={onPageChange}
              rowsPerPageOptions={[pageSize]}
              rowCount={purchases.total}
              components={{ Toolbar: GridToolbar }}
              filterMode='server'
              onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 1000 },
                },
              }}
              getRowId={(row) => row.order.referenceNo}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ServicePurchases;
