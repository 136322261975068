import { InvitationType } from '@/Models/tickets';
import List from '@mui/material/List';
import AllocatedInvitationType from '../../Atoms/AllocatedInvitationType/AllocatedInvitationType';

export interface InvitationTypesProps {
  invitations: InvitationType[];
  simple?: boolean;
}

const InvitationTypes = ({ invitations, simple }: InvitationTypesProps) => {
  return (
    <List sx={{ padding: 0, marginTop: simple ? 0 : 4 }}>
      {invitations.map((invitation) => (
        <AllocatedInvitationType invitation={invitation} simple={simple ? simple : false} />
      ))}
    </List>
  );
};

export default InvitationTypes;
