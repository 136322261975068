import {
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from '@/Api/organizationsSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { ClearOrganizationAction } from '@/Constants/actions';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { FileContext, ResourceType } from '@/Enums/files';
import useForm from '@/Hooks/useForm';
import useLoading from '@/Hooks/useLoading';
import { PreviewData } from '@/Models/preview';
import authService from '@/Services/AuthService';
import { setTitle } from '@/Slices/layoutSlice';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { HTTP_CODE } from '@/Utils/http-client';
import { Button, FormControl, FormHelperText, Grid, Paper, Stack, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const allowedImageTypes = ['image/png', 'image/jpeg'];

const CreateOrEditOrganization = () => {
  const [loading, withLoading] = useLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { organizationId } = useParams();
  const { data: organization } = useGetOrganizationQuery(organizationId!, {
    refetchOnMountOrArgChange: !!organizationId,
    skip: !organizationId,
  });
  const [updateOrganization] = useUpdateOrganizationMutation();
  const [createOrganization] = useCreateOrganizationMutation();

  useEffect(() => {
    dispatch(setTitle(`${organizationId ? 'Edit' : 'Create'} Organization}`));
  }, []);

  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    business_no: '',
    vat_no: '',
    address: '',
    city: '',
    zip_code: '',
    country: '',
    contact: '',
  });

  const { data, setData, errors, setError, clearErrors, hasErrors } = useForm({
    name: '',
    description: '',
    logo: '',
    company_details: companyDetails,
  });
  const [fileQueue, setFileQueue] = useState<{ key: keyof typeof data; file: File }[]>([]);

  const logoPreview: PreviewData | undefined = useMemo(() => {
    return organization ? { type: 'image', url: organization.logo } : undefined;
  }, [organization]);

  useEffect(() => {
    if (organization) {
      setData({ ...data, ...organization });
    }
  }, [organization]);

  useEffect(() => {
    if (organization?.settings?.company_details) {
      setCompanyDetails(JSON.parse(organization.settings.company_details));
    }
  }, [organization]);

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors();
    setData(fieldName, value);
  }

  function addFileInQueue(key: keyof typeof data, file: File) {
    clearErrors(key);
    setData(key, '');
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }

  async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    if (hasErrors) {
      return;
    }

    let orgData = { ...data, company_details: JSON.stringify(companyDetails) };

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles());
      orgData = { ...orgData, ...processedFiles };
    }

    if (!validatePictures(orgData)) {
      return;
    }

    let promise: Promise<any>;
    if (organization) {
      promise = updateOrganization({ organizationId: organization.id, body: orgData }).unwrap();
    } else {
      promise = createOrganization(orgData).unwrap();
    }

    withLoading(promise)
      .then(() => {
        return dispatch(ClearOrganizationAction());
      })
      .then(() => {
        return authService.renewToken();
      })
      .then(() => {
        navigate('/choose-organization');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors);
        }
      });
  }

  async function processFiles() {
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.ORGANIZATIONS,
      })),
    );

    const ticketData: { [key: string]: any } = {};
    signedUrlPayloads.forEach((p, i) => {
      ticketData[fileData[i].key] = p.fields.key;
      setData(fileData[i].key, p.fields.key);
    });

    setFileQueue([]);

    return ticketData;
  }

  function validatePictures(data: any) {
    if (!data['logo']) {
      setError('logo', 'Please upload an image first!');
      return false;
    }

    return true;
  }

  function updateCompanyDetails(field: keyof typeof companyDetails, value: string) {
    setCompanyDetails({
      ...companyDetails,
      [field]: value,
    });
  }

  return (
    <form name='createOrgForm' onSubmit={handleSubmit}>
      <Paper className='p-8 w-[30rem]'>
        <Stack spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id='name'
                name='name'
                label='Name'
                error={!!errors.name}
                helperText={errors.name?.[0]}
                fullWidth
                value={data.name}
                onChange={(e) => updateData('name', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='description'
                name='description'
                label='Description'
                error={!!errors.description}
                helperText={errors.description?.[0]}
                multiline
                rows={3}
                fullWidth
                value={data.description}
                onChange={(e) => updateData('description', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='name'
                name='name'
                label='Company Name'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.name}
                onChange={(e) => updateCompanyDetails('name', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='business_no'
                name='business_no'
                label='Business No'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.business_no}
                onChange={(e) => updateCompanyDetails('business_no', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='vat_no'
                name='vat_no'
                label='VAT No'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.vat_no}
                onChange={(e) => updateCompanyDetails('vat_no', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='address'
                name='address'
                label='Address'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.address}
                onChange={(e) => updateCompanyDetails('address', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='city'
                name='city'
                label='City'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.city}
                onChange={(e) => updateCompanyDetails('city', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='zip_code'
                name='zip_code'
                label='Zip Code'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.zip_code}
                onChange={(e) => updateCompanyDetails('zip_code', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='country'
                name='country'
                label='Country'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.country}
                onChange={(e) => updateCompanyDetails('country', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='contact'
                name='contact'
                label='Contact'
                error={!!errors.company_details}
                helperText={errors.company_details?.[0]}
                fullWidth
                value={companyDetails.contact}
                onChange={(e) => updateCompanyDetails('contact', e?.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack>
                <FileUpload
                  label='Logo'
                  allowedFileTypes={allowedImageTypes}
                  maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                  onSelect={(file) => addFileInQueue('logo', file)}
                  preview
                  previewData={!!organization ? logoPreview : undefined}
                  previewProps={{ style: { width: '10rem', objectFit: 'contain' } }}
                  helperText='Recommended size: 100x100px'
                />

                {errors['logo'] && (
                  <FormControl error>
                    <FormHelperText className='!ml-0'>{errors['logo']}</FormHelperText>
                  </FormControl>
                )}
              </Stack>
            </Grid>
          </Grid>

          <LoadingButton loading={loading}>
            <Button className='w-full' variant='contained' disabled={loading} type='submit'>
              {!!organizationId ? 'Update' : 'Create'}
            </Button>
          </LoadingButton>
        </Stack>
      </Paper>
    </form>
  );
};

export default CreateOrEditOrganization;
