import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { Service, ServiceData } from '@/Models/services';
import { Event } from '@/Models/events';
import { formatPrice } from '@/Utils/currency';
import { EditOutlined, MoreVert, PaidOutlined } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ServicePurchases from '../EventServices/ServicePurchases';

interface ServicesTableProps {
  services: ServiceData;
  columns: string[];
  pageSize: number;
  onPageChange: (page: number) => void;
  event?: Event;
}

const ServicesTable = ({
  services,
  columns,
  pageSize,
  onPageChange,
  event,
}: ServicesTableProps) => {
  const [shownColumns, setShownColumns] = useState<GridColDef<Service>[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    service: Service;
  }>(null);
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [selectedService, setSelectedServiceId] = useState<string | null>(null);

  const showActionMenu = useCallback(
    (service: Service, event: React.MouseEvent<HTMLElement>) => {
      setMenuContext({ anchor: event.currentTarget, service });
    },
    [setMenuContext],
  );

  const allColumns: GridColDef<Service>[] = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
      { field: 'description', headerName: 'Description', sortable: false, flex: 2 },
      {
        field: 'totalQuantity',
        headerName: 'Total Quantity',
        sortable: false,
        renderCell: ({ row }) => <BeautifulChip color='info'>{row.totalQuantity}</BeautifulChip>,
        flex: 1,
      },
      {
        field: 'totalAmount',
        headerName: 'Total Amount',
        sortable: false,
        renderCell: ({ row }) => (
          <BeautifulChip color='success'>
            {event && formatPrice(row.totalAmount, event.currency)}
          </BeautifulChip>
        ),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => (
          <IconButton onClick={(service) => showActionMenu(row, service)}>
            <MoreVert />
          </IconButton>
        ),
        sortable: false,
      },
    ];
  }, [showActionMenu]);

  useEffect(() => {
    setShownColumns(allColumns.filter((column) => columns.includes(column.field)));
  }, [columns, allColumns]);

  function dispatchPageChange(page: number) {
    onPageChange(page);
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const handleServiceDialogOpen = (parkignSpacesId: string) => {
    setSelectedServiceId(parkignSpacesId);
    setServiceDialogOpen(true);
  };

  return (
    <>
      <DataGrid
        rows={services.data}
        columns={shownColumns}
        rowHeight={80}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        disableSelectionOnClick
        rowCount={services.total}
        paginationMode='server'
        onPageChange={dispatchPageChange}
        hideFooterSelectedRowCount={true}
        isRowSelectable={() => false}
      />

      {menuContext && (
        <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
          <Link to={`${menuContext.service.id}/edit`}>
            <MenuItem
              onClick={() => {
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              handleServiceDialogOpen(menuContext.service.id);
            }}
          >
            <ListItemIcon>
              <PaidOutlined />
            </ListItemIcon>
            <ListItemText>Purchases</ListItemText>
          </MenuItem>
        </Menu>
      )}

      {serviceDialogOpen && selectedService && (
        <ServicePurchases
          open={serviceDialogOpen}
          onDialogClose={() => setServiceDialogOpen(false)}
          service_id={selectedService}
        />
      )}
    </>
  );
};

export default ServicesTable;
