import { useGetMeQuery } from '@/Api/usersSlice';
import authService from '@/Services/AuthService';
import { useEffect, useState } from 'react';

const userDataStaleTime = 60 * 10 * 1000;

// TODO: do we need this anymore, the user info is stored in KeyCloak
export function useMe() {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffect(() => {
    authService.isAuthenticated().then((isAuth) => setIsAuth(isAuth));
  }, []);

  const data = useGetMeQuery(undefined, {
    refetchOnMountOrArgChange: false,
    pollingInterval: userDataStaleTime,
    skip: !isAuth,
  });

  return data;
}
