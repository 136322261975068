import { useState, useEffect, useRef } from 'react';
import { Typography, Stack, Box, Pagination, Button } from '@mui/material';
import { useGetPublicEventsQuery } from '@/Api/eventsSlice';
import { useDrop } from 'react-dnd';
import { Event } from '@/Models/events';
import { FeaturedArticles } from '@/Models/featuredArticles';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useGetArticlesQuery } from '@/Api/articlesSlice';
import { Article } from '@/Models/articles';
import {
  ArticleDragCard,
  FeaturedArticleDragCard,
} from '@/Components/Molecules/Cards/ArticleDragCard';
import {
  useGetFeaturedArticlesQuery,
  usePostFeaturedArticlesMutation,
} from '@/Api/featuredArticlesSlice';

const usePrevious = (value: FeaturedArticles[] | undefined) => {
  const ref = useRef<FeaturedArticles[] | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

interface DropData {
  index: number;
}

interface FeaturedArticleData {
  article_id: string;
  article: Article;
  order: number;
}

const FeaturedArticle = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Featured Article'));
    dispatch(
      setHeader({
        title: 'Featured Article',
      }),
    );
  }, []);

  const { data: events } = useGetPublicEventsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );
  const { data: articles } = useGetArticlesQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  console.log(articles);

  const [postFeaturedArticles] = usePostFeaturedArticlesMutation();
  const { data: existingFeaturedArticles } = useGetFeaturedArticlesQuery({});

  const prevExistingFeaturedArticles = usePrevious(existingFeaturedArticles);

  const [allArticles, setAllArticles] = useState<any>(undefined);
  const [featuredArticles, setFeaturedArticles] = useState<FeaturedArticles[]>([]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setAllArticles(articles?.data);
    const featuredArticlesHaveChanged =
      prevExistingFeaturedArticles === undefined ||
      existingFeaturedArticles?.length !== prevExistingFeaturedArticles?.length ||
      existingFeaturedArticles?.some((article: any, index: any) => {
        const prevArticle = prevExistingFeaturedArticles[index];
        return article.article_id !== prevArticle.article_id || article.order !== prevArticle.order;
      });

    if (featuredArticlesHaveChanged) {
      setFeaturedArticles(
        existingFeaturedArticles?.map((article: any) => ({
          article_id: article.article_id,
          article: article.article,
          order: article.order,
        })),
      );
    }
  }, [articles, existingFeaturedArticles, prevExistingFeaturedArticles, featuredArticles]);

  const addArticleToFeatured = (article: Article, destinationIndex: number) => {
    const articleToAdd = allArticles.find((a: Article) => a.id === article.id);
    if (articleToAdd) {
      if (featuredArticles?.find((featuredArticle) => featuredArticle.article_id === article.id))
        return;

      const newFeaturedArticle: FeaturedArticleData = {
        article_id: article.id,
        article: articleToAdd,
        order: destinationIndex,
      };

      setFeaturedArticles((prev: FeaturedArticles[]) => {
        const copy = [...prev, newFeaturedArticle];
        copy.sort((a, b) => a.order - b.order);
        return copy.map((featuredArticle, i) => ({ ...featuredArticle, order: i + 1 }));
      });
    }
  };

  const removeFromFeatured = (featuredArticle: FeaturedArticles) => {
    setFeaturedArticles((prev: FeaturedArticles[]) =>
      prev.filter((a: FeaturedArticles) => a.article_id !== featuredArticle.article_id),
    );
  };

  const dropRef = useRef<HTMLDivElement>(null) as any;

  const [, drop] = useDrop(
    () => ({
      accept: 'article',
      drop: (item: Article, monitor) => {
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) {
          return;
        }

        const dropBoxBounds = dropRef.current.getBoundingClientRect();

        const index = Math.round((clientOffset.y - dropBoxBounds.top) / 100);
        addArticleToFeatured(item, index);
      },
    }),
    [allArticles],
  );

  return (
    <div className='py-8'>
      <div className='max-w-7xl sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between'>
          <Typography variant='h5'>Articles</Typography>
          <div className='w-[45%] flex items-center justify-start'>
            <Typography variant='h5'>Featured Articles</Typography>
          </div>
        </div>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }} className='py-4'>
          {allArticles && (
            <Stack
              className='py-4 w-[45%] items-center bg-[rgba(256,256,256,05)] rounded-md'
              spacing={2}
              sx={{ boxShadow: 2 }}
            >
              {allArticles.map((article: Article, index: number) => (
                <ArticleDragCard
                  key={article.id}
                  article={article}
                  index={index}
                  disabled={
                    !!featuredArticles?.find(
                      (featuredArticle) => featuredArticle.article_id === article.id,
                    )
                  }
                />
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  page={articles?.current_page}
                  count={articles?.last_page}
                  showFirstButton
                  showLastButton
                  onChange={(_, page) => onPageChange(page - 1)}
                />
              </Box>
            </Stack>
          )}

          <div ref={drop} className='w-[45%] flex flex-col items-center bg-white rounded-lg '>
            {featuredArticles && (
              <div className='w-full' ref={dropRef}>
                {featuredArticles?.map((featuredArticle) => {
                  return (
                    <FeaturedArticleDragCard
                      key={featuredArticle.article_id}
                      featuredArticle={featuredArticle}
                      removeFromFeatured={removeFromFeatured}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Stack>
      </div>
      <div className='max-w-7xl sm:px-6 lg:px-8 flex justify-end'>
        <Button
          variant='contained'
          onClick={() => {
            postFeaturedArticles(featuredArticles)
              .unwrap()
              .then((response) => {})
              .catch((error) => {});
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default FeaturedArticle;
