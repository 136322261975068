import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import InviteForm from './InviteForm';
import InviteCSV from './InviteCSV';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import {
  AddOutlined,
  CancelOutlined,
  MoreVert,
  SendOutlined,
  UploadFile,
  Link as MuiLink,
  EventSeatOutlined,
} from '@mui/icons-material';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import {
  useCancelInvitationStatusMutation,
  useResendInvitationMutation,
  useGetGuestsQuery,
  useDownloadZipLinkMutation,
} from '@/Api/guestsSlice';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import InvitationTypesTable from '../Tickets/InvitationTypesTable';
import InvitationTypes from '@/Components/Molecules/InvitationTypes/InvitationTypes';
import { useMe } from '@/Hooks/useQueryFns';
import { useGetInvitationTypesQuery } from '@/Api/invitationsSlice';
import { UserRole } from '@/Enums/roles';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import { useResponsive } from '@/Hooks/useResponsive';
import environment from '@/environments/environment';
import { showSnackbar } from '@/Slices/snackbarSlice';
import { Guest } from '@/Models/guests';
import FolderZip from '@mui/icons-material/FolderZip';

import { HTTP_CODE } from '@/Utils/http-client';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import GroupedGuests from './GroupedGuests';
import ReserveSeats from './ReserveSeats';

interface DialogData {
  title: string;
  content: (...args: any) => JSX.Element;
  startIcon: JSX.Element;
}

const dialogTypes: DialogData[] = [
  {
    title: 'Add invitations',
    content: InviteForm,
    startIcon: <AddOutlined />,
  },
  {
    title: 'Import from CSV',
    content: InviteCSV,
    startIcon: <UploadFile />,
  },
];

const dialogTypesSeating: DialogData[] = [
  {
    title: 'Reserve Seats',
    content: ReserveSeats,
    startIcon: <EventSeatOutlined />,
  },
];

const Guests = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const { data: user } = useMe();
  const [search, setSearch] = useState('');
  const { data: invitations } = useGetInvitationTypesQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const [downloadZipLink] = useDownloadZipLinkMutation();

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    guest: Guest;
  }>(null);

  const [invitationGuestDialogOpen, setInvitationGuestDialogOpen] = useState(false);
  const [selectedGuestId, setSelectedGuestId] = useState<string | null>(null);

  const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);
  const [resendInvitationDialogOpen, setResendInvitationDialogOpen] = useState(false);
  const [cancelInvitationDialogOpen, setCancelInvitatiionDialogOpen] = useState(false);
  const [sendInvitation, { isLoading: loading }] = useResendInvitationMutation();
  const [cancelInvitaionStatus, { isLoading: loadingCancel }] = useCancelInvitationStatusMutation();

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<DialogData | null>(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState<number>(20);

  const { data: guestData, refetch } = useGetGuestsQuery(
    {
      eventId: eventId!,
      filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  function openDialog(dialogData: DialogData) {
    setOpen(true);
    setDialogContent(dialogData);
  }

  function onDialogClose(value: boolean) {
    setOpen(value);
    setDialogContent(null);
  }

  const resendInvitation = () => {
    if (selectedGuest) {
      sendInvitation({ eventId: eventId!, guestId: selectedGuest.uuid })
        .unwrap()
        .then(() => {
          setResendInvitationDialogOpen(false);
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const confirmCancelStatus = () => {
    if (selectedGuest) {
      cancelInvitaionStatus({ eventId: eventId!, guestId: selectedGuest.uuid })
        .unwrap()
        .then(() => {
          setCancelInvitatiionDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  function showActionMenu(guest: Guest, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, guest });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const handleInvitationGuestDialogOpen = (guestId: string) => {
    setSelectedGuestId(guestId);
    setInvitationGuestDialogOpen(true);
  };

  const columns: GridColDef[] = [
    { field: 'first_name', headerName: 'First name', sortable: false },
    { field: 'last_name', headerName: 'Last name', sortable: false },
    { field: 'email', headerName: 'Email', flex: 1, sortable: false },
    { field: 'notes', headerName: 'Notes', sortable: false, width: 200 },
    {
      field: 'invitationType',
      headerName: 'Invitation Ticket',
      valueGetter: ({ row }) => row.invitation_type.title,
      flex: 1,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      flex: 1,
      sortable: false,
    },
    { field: 'quantity', headerName: 'Quantity', sortable: false },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.InviteGuest}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenu(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  const downloadZIP = (guest: any) => {
    downloadZipLink({ eventId: event?.id!!, body: guest })
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            message:
              "The ZIP generation has started. We will notify you with email when it's ready and you can download it there",
            severity: 'info',
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';
  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4'>
          <div className='h-full'>
            <div className='mx-auto h-full flex flex-col'>
              <PermissionsGate scope={SCOPES.InviteGuest}>
                <div className='w-1/2'>
                  <Typography variant='h6'>Your invitations:</Typography>
                  {!!invitations && <InvitationTypes invitations={invitations} simple />}
                </div>
                <div className=' flex justify-end mb-4'>
                  <Stack className='w-full lg:justify-end' direction={direction} spacing={2}>
                    {(event?.seating_enabled ? dialogTypesSeating : dialogTypes).map((dt) => (
                      <Button
                        key={dt.title}
                        variant='contained'
                        onClick={() => openDialog(dt)}
                        startIcon={dt.startIcon}
                      >
                        {dt.title}
                      </Button>
                    ))}
                  </Stack>
                </div>
              </PermissionsGate>

              <div className='h-[411px] sm:rounded-lg grow'>
                {!!guestData?.data && (
                  <DataGrid
                    rows={guestData.data.map((guest, index) => ({ ...guest, id: index }))}
                    columns={columns}
                    paginationMode='server'
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={guestData.total}
                    onRowClick={(params) => handleInvitationGuestDialogOpen(params.row.uuid)}
                    components={{ Toolbar: GridToolbar }}
                    filterMode='server'
                    onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 1000 },
                      },
                    }}
                  />
                )}
              </div>

              <InvitationTypesTable event={event} />
            </div>
          </div>

          {invitationGuestDialogOpen && selectedGuestId && (
            <GroupedGuests
              open={invitationGuestDialogOpen}
              onDialogClose={() => setInvitationGuestDialogOpen(false)}
              uuid={selectedGuestId}
            />
          )}

          {dialogContent &&
            open &&
            React.createElement(dialogContent.content, {
              open,
              onDialogClose,
              event,
            })}

          {menuContext && (
            <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
              <MenuItem
                onClick={() => {
                  setResendInvitationDialogOpen(true);
                  setSelectedGuest(menuContext.guest);
                  hideActionMenu();
                }}
              >
                <ListItemIcon>
                  <SendOutlined color='primary' />
                </ListItemIcon>
                <ListItemText>Resend Email</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setCancelInvitatiionDialogOpen(true);
                  setSelectedGuest(menuContext.guest);
                  hideActionMenu();
                }}
              >
                <ListItemIcon>
                  <CancelOutlined color='error' />
                </ListItemIcon>
                <ListItemText>Cancel Invitation</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setSelectedGuest(menuContext.guest);
                  downloadZIP(menuContext.guest);
                  hideActionMenu();
                }}
              >
                <ListItemIcon>
                  <FolderZip />
                </ListItemIcon>
                <ListItemText>Download to ZIP</ListItemText>
              </MenuItem>
            </Menu>
          )}

          {resendInvitationDialogOpen && selectedGuest ? (
            <Dialog
              open={resendInvitationDialogOpen}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                Are you sure you want to resend email?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Please make sure if the email is correct before sending the invitation to the
                  Guest's email
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setResendInvitationDialogOpen(false)}>Cancel</Button>
                <LoadingButton loading={loading}>
                  <Button onClick={resendInvitation} disabled={loading} color='success'>
                    Send
                  </Button>
                </LoadingButton>
              </DialogActions>
            </Dialog>
          ) : (
            ''
          )}
          {cancelInvitationDialogOpen && selectedGuest ? (
            <Dialog
              open={cancelInvitationDialogOpen}
              // onDialogClose={(value) => onResendDialogClose(value)}
              aria-labelledby='cancel-dialog-title'
              aria-describedby='cancel-dialog-description'
            >
              <DialogTitle id='cancel-dialog-title'>
                Are you sure you want to change the status to 'CANCEL'?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='cancel-dialog-description'></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setCancelInvitatiionDialogOpen(false)} color='success'>
                  Close
                </Button>
                <LoadingButton loading={loadingCancel}>
                  <Button onClick={confirmCancelStatus} disabled={loadingCancel} color='error'>
                    Confirm Cancel
                  </Button>
                </LoadingButton>
              </DialogActions>
            </Dialog>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default Guests;
