import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';

export const ticketCodesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    createTicketCodes: builder.mutation<any, { eventId: string; data: any }>({
      query: ({ eventId, data }) => ({
        url: `/events/${eventId}/ticket-codes/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_ONLINE],
    }),
    importTicketCodes: builder.mutation<any, { eventId: string; ticketId: string; data: any }>({
      query: ({ eventId, ticketId, data }) => ({
        url: `/events/${eventId}/ticket-codes/${ticketId}/import`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_ONLINE],
    }),
  }),
});

export const { useCreateTicketCodesMutation, useImportTicketCodesMutation } = ticketCodesApi;
