import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PermissionsGate from '@/HOC/PermissionsGate';
import TransactionsStats from '@/Components/Templates/TransactionsStats/TransactionsStats';
import TransactionsList from '@/Pages/Transactions/TransactionsList';
import { SCOPES } from '@/Constants/permission-map';

const AdminDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Dashboard'));
    dispatch(
      setHeader({
        title: 'Dashboard',
      }),
    );
  }, []);

  return (
    <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
      <div className='h-full'>
        <div className='mx-auto grid gap-4 pb-8'>
          <TransactionsStats />
        </div>
        <div className='mx-auto grid gap-4 pb-8'>
          <TransactionsList />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
