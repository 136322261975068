import { z } from 'zod';

export const articleFormSchema = z.object({
  title: z.string().min(1),
  slug: z.string().min(1),
  author: z.string().min(1),
  content: z.string().min(1),
  desktop_image: z.union([z.string().min(1), z.instanceof(File)]),
  mobile_image: z.union([z.string().min(1), z.instanceof(File)]),
  published: z.boolean(),
  meta_description: z.string(),
  tag: z.array(z.string()),
  id: z.string(),
});

export type ArticleForm = z.infer<typeof articleFormSchema>;
