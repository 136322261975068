import { Event } from '@/Models/events';
import EventOverviewStats from '@/Components/Organisms/EventOverviewStats/EventOverviewStats';
import EventStats from '@/Components/Templates/EventStats/EventStats';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';

export interface EventDashboardProps {
  event: Event;
}

const EventDashboard = ({ event }: EventDashboardProps) => {
  return (
    <div className='h-full'>
      <div className='mx-auto grid gap-4 pb-8'>
        <PermissionsGate scope={SCOPES.ViewEventReport}>
          <EventOverviewStats event={event} />
          <EventStats event={event} />
        </PermissionsGate>
      </div>
    </div>
  );
};

export default EventDashboard;
