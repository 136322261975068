import { Avatar } from '@mui/material';
import { useMemo } from 'react';
import { red, deepPurple, pink, blue } from '@mui/material/colors';
import { User } from '@/Models/users';

interface UserAvatarProps {
  user?: User;
}

const avatarPalette = [red[500], deepPurple[500], pink[500], blue[500]];

const UserAvatar = ({ user }: UserAvatarProps) => {
  const avatarColor = useMemo(() => {
    if (!user) {
      return avatarPalette[0];
    }

    return avatarPalette[user.name.charCodeAt(0) % avatarPalette.length];
  }, [user]);

  return (
    <Avatar sx={{ bgcolor: avatarColor }}>{user ? user.name.charAt(0).toUpperCase() : 'U'}</Avatar>
  );
};

export default UserAvatar;
