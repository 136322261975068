import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import {
  useCancelOrderMutation,
  useGetAllOrdersQuery,
  useRefundOrderMutation,
  useSendOrderTicketsMutation,
} from '@/Api/ordersSlice';
import { useGetEventsQuery } from '@/Api/eventsSlice';
import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Order } from '@/Models/orders';
import environment from '@/environments/environment';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import { formatDate } from '@/Utils/dates';
import { currencySymbol, formatPrice } from '@/Utils/currency';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { useResponsive } from '@/Hooks/useResponsive';
import ExportIcon from '@mui/icons-material/SaveAlt';
import TransactionDetails from '../Orders/TransactionDetails';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import {
  CancelOutlined,
  CheckCircleOutline,
  Download,
  EditOutlined,
  MoreVert,
  PeopleOutlined,
  ReplayOutlined,
  SendOutlined,
  SyncAltOutlined,
} from '@mui/icons-material';
import { Attendee } from '@/Models/attendees';
import Edit from '../Orders/Edit';
import EditAttendee from '../Attendees/EditAttendee';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useCheckInAttendeeMutation, useResetCheckInAttendeeMutation } from '@/Api/attendeesSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { showSnackbar } from '@/Slices/snackbarSlice';
import EditTransferOrder from '../Orders/EditTransferOrder';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

const AllOrders = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const [pageSize, setPageSize] = useState<number>(20);
  const [displayedOrders, setDisplayedOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const [eventsPage] = useState(0);
  const [eventsPageSize] = useState(Number.MAX_SAFE_INTEGER);
  const [transactionDialogOpen, setTransactionDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogOpenAttendees, setEditDialogOpenAttendees] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | undefined>(undefined);
  const [checkInDialogOpen, setCheckInDialogOpen] = useState(false);
  const [resetCheckInDialogOpen, setResetCheckInDialogOpen] = useState(false);
  const [attendeesDialogOpen, setAttendeesDialogOpen] = useState(false);
  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [resendDialogOpen, setResendDialogOpen] = useState(false);
  const [editTransferOrderDialogOpen, setEditTransferOrderDialogOpen] = useState(false);
  const [refundOrderDialogOpen, setRefundOrderDialogOpen] = useState(false);
  const [refundAttendees, setRefundAttendees] = useState<string[]>([]);

  const advancedFetch = useAdvancedFetch();

  const [refundOrder, { isLoading: loadingRefund }] = useRefundOrderMutation();
  const [cancelOrder, { isLoading: loadingCancel }] = useCancelOrderMutation();
  const [sendTickets, { isLoading: loading }] = useSendOrderTicketsMutation();
  const [resetCheckIn, { isLoading: loadingResetCheckIn }] = useResetCheckInAttendeeMutation();
  const [checkIn, { isLoading: loadingCheckIn }] = useCheckInAttendeeMutation();

  interface Event {
    id: string;
  }

  useEffect(() => {
    dispatch(setTitle('All Orders'));
    dispatch(
      setHeader({
        title: 'All Orders',
      }),
    );
  }, []);

  const { data: events } = useGetEventsQuery(
    { pageData: { page: eventsPage, pageSize: eventsPageSize } },
    { refetchOnMountOrArgChange: true },
  );

  const selectedEventsSlug = selectedEvents.length > 0 ? selectedEvents.join(',') : undefined;

  const { data: orders, refetch } = useGetAllOrdersQuery(
    {
      event_id: selectedEventsSlug,
      filterData: { search: search, pageData: { page, pageSize } },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    order: Order;
  }>(null);

  const [menuContextDialog, setMenuContextDialog] = useState<null | {
    anchor: HTMLElement;
    attendee: Attendee;
  }>(null);

  const handleEventChange = (events: React.ChangeEvent<{}>, values: any[]) => {
    const selectedValues = values.map((value) => value.id);
    setSelectedEvents(selectedValues);
  };

  function onPageChange(page: number) {
    setPage(page);
  }

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  const handleExport = () => {
    const url = new URL(`${environment.baseUrl}/export-orders`);
    if (selectedEvents.length > 0) {
      url.searchParams.append('event_ids', selectedEvents.join(','));
    }

    advancedFetch(url.toString(), {
      method: 'GET',
    }).then((res) => {
      handleBlobReponse(res, {
        handleMethod: 'download',
        fileName: 'orders.csv',
      });
    });
  };

  function onReferenceClick(event: string, referenceNo: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${event}/orders/${referenceNo}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((res) => {
      handleBlobReponse(res);
    });
  }

  function onTransactionDialogClose(value: boolean) {
    setTransactionDialogOpen(false);

    if (!value) {
      return;
    }
  }

  function onTicketAttendeeClick(event: string, barcode: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${selectedOrder?.event_id}/attendees/${barcode}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((res) => {
      handleBlobReponse(res);
    });
  }

  function onDownloadClick(event: string, referenceNo: string | undefined) {
    advancedFetch(
      `${environment.baseUrl}/events/${event}/orders/${referenceNo}/invoice?download=1`,
      {
        method: 'GET',
      },
    ).then((res) => {
      handleBlobReponse(res);
    });
  }

  function showActionMenu(order: Order, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, order });
  }

  function showActionMenuDIalog(attendee: Attendee, event: React.MouseEvent<HTMLElement>) {
    setMenuContextDialog({ anchor: event.currentTarget, attendee });
  }

  function hideActionMenuDialog() {
    setMenuContextDialog(null);
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  function onEditDialogClose(value: boolean) {
    setEditDialogOpen(false);

    if (!value) {
      return;
    }
  }

  function onEditDialogCloseDialog(value: boolean) {
    setEditDialogOpenAttendees(false);
    refetch();
    if (!value) {
      return;
    }
  }

  const handleAttendeeUpdate = (updatedAttendee: Attendee) => {
    setSelectedOrder((prevSelectedOrder: any) => {
      if (prevSelectedOrder) {
        const updatedAttendees = prevSelectedOrder.attendees.map((attendee: any) =>
          attendee.id === updatedAttendee.id ? updatedAttendee : attendee,
        );
        return { ...prevSelectedOrder, attendees: updatedAttendees };
      }
      return prevSelectedOrder;
    });
  };

  const confirmCheckIn = () => {
    if (selectedAttendee) {
      checkIn({
        eventId: selectedOrder?.event_id!,
        attendeeId: selectedAttendee.id,
      })
        .unwrap()
        .then(() => {
          setCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const confirmResetCheckIn = () => {
    if (selectedAttendee) {
      resetCheckIn({
        eventId: selectedOrder?.event_id!,
        attendeeId: selectedAttendee.id,
      })
        .unwrap()
        .then(() => {
          setResetCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const resendTickets = () => {
    if (selectedOrder) {
      sendTickets({ eventId: selectedOrder?.event_id!, orderId: selectedOrder.id })
        .unwrap()
        .then(() => {
          setResendDialogOpen(false);
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const confirmCancelOrder = () => {
    if (selectedOrder) {
      cancelOrder({ eventId: selectedOrder?.event_id!, orderId: selectedOrder.id })
        .unwrap()
        .then(() => {
          setCancelOrderDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const confirmRefundOrder = () => {
    if (selectedOrder) {
      refundOrder({
        eventId: selectedOrder?.event_id!,
        orderId: selectedOrder.id,
        attendeeIds: refundAttendees,
      })
        .unwrap()
        .then(() => {
          setRefundOrderDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  function onEditDTransferOrderialogClose(value: boolean) {
    setEditTransferOrderDialogOpen(false);

    if (!value) {
      return;
    }
  }

  useEffect(() => {
    if (orders) {
      if (selectedEvents.length > 0) {
        const newOrders = orders.data.filter((order) => selectedEvents.includes(order.event_id));
        setDisplayedOrders(newOrders);
      } else {
        setDisplayedOrders(orders.data);
      }
    }
  }, [selectedEvents, orders, search]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'first_name', headerName: 'First name', width: 130 },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email', width: 230 },
    {
      field: 'referenceNo',
      headerName: 'Reference No.',
      width: 200,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onReferenceClick(params.row.event_id, params.row.referenceNo);
            }}
          >
            {params.row.referenceNo}
          </span>
        );
      },
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction ID',
      width: 300,
      renderCell: ({ row }) => (
        <span
          className='underline text-blue-600 cursor-pointer'
          onClick={(event) => {
            event.stopPropagation();
            setSelectedEvent(row.event_id || null);
            setSelectedOrder(row);
            setTransactionDialogOpen(true);
          }}
        >
          {row.transaction_id}
        </span>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 90,
      renderCell: (params) => {
        return formatPrice(params.row.amount, params.row.event.currency);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      width: 120,
      sortable: false,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 220,
      valueFormatter: (cell) => formatDate(cell.value),
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellClassName: 'sticky-actions-column',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageOrder}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenu(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  const columnsAttendees: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'First name',
      width: 100,
      sortable: false,
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      width: 100,
      sortable: false,
    },
    { field: 'email', headerName: 'Email', width: 230, sortable: false },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 130,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketAttendeeClick(params.row.event_id, params.row.barcode);
            }}
          >
            {params.row.barcode}
          </span>
        );
      },
    },
    {
      field: 'arrived',
      headerName: 'Arrived',
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <div
              className='w-2 h-2 rounded-full'
              style={{
                background: row.arrived ? 'rgb(50 225 121)' : 'rgba(0, 0, 0, 0.47)',
              }}
            ></div>
            <span>{row.arrived ? 'Yes' : 'No'}</span>
          </Stack>
        );
      },
      sortable: false,
    },
    {
      field: 'arrived_at',
      width: 220,
      headerName: 'Arrived at',
      renderCell: ({ row }) => (
        <EmptyCell condition={row.arrived}>
          <div>{formatDate(row.arrived_at)}</div>
        </EmptyCell>
      ),
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageOrder}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenuDIalog(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  return (
    <div className=' max-w-7xl mx-auto w-full h-full p-4'>
      <div className='h-full'>
        <div className='mx-auto h-full flex flex-col'>
          <Stack direction={direction} className='lg:items-center justify-end py-3 w-full'>
            <div className='pb-3 lg:w-1/2'>
              <Autocomplete
                multiple
                value={selectedEvents.map((eventId) =>
                  events?.data?.find((event) => event.id === eventId),
                )}
                onChange={handleEventChange}
                options={events?.data || []}
                getOptionLabel={(event) => event?.title || ''}
                renderInput={(params) => (
                  <TextField {...params} label='Select Events' placeholder='Select Events' />
                )}
              />
            </div>

            <div className='pb-3 lg:pl-5'>
              <Button
                onClick={handleExport}
                className='w-full'
                variant='contained'
                color='primary'
                startIcon={<ExportIcon />}
              >
                Export
              </Button>
            </div>
          </Stack>

          <div className='h-96 sm:rounded-lg grow'>
            {orders ? (
              <DataGrid
                rows={orders.data}
                columns={columns}
                paginationMode='server'
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                onPageChange={onPageChange}
                rowCount={orders.total}
                components={{ Toolbar: GridToolbar }}
                filterMode='server'
                onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 1000 },
                  },
                }}
              />
            ) : (
              ''
            )}

            {selectedEvent && transactionDialogOpen && selectedOrder ? (
              <TransactionDetails
                event={selectedEvent as any}
                order={selectedOrder}
                open={transactionDialogOpen}
                onDialogClose={(value) => onTransactionDialogClose(value)}
              />
            ) : (
              ''
            )}

            {menuContext && (
              <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
                <MenuItem
                  onClick={() => {
                    setEditDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <EditOutlined />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setAttendeesDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <PeopleOutlined />
                  </ListItemIcon>
                  <ListItemText>Attendees</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setResendDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <SendOutlined />
                  </ListItemIcon>
                  <ListItemText>Resend Tickets</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setCancelOrderDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <CancelOutlined color='error' />
                  </ListItemIcon>
                  <ListItemText>Cancel Order</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setRefundOrderDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <ReplayOutlined color='error' />
                  </ListItemIcon>
                  <ListItemText>Refund</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={(event) => {
                    const selectDown = menuContext.order;
                    hideActionMenu();
                    onDownloadClick(selectDown.event_id, selectDown?.referenceNo);
                  }}
                >
                  <ListItemIcon>
                    <Download />
                  </ListItemIcon>
                  <ListItemText>Invoice</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setEditTransferOrderDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                  disabled={menuContext.order?.event?.seating_enabled}
                >
                  <ListItemIcon>
                    <SyncAltOutlined />
                  </ListItemIcon>
                  <ListItemText>Transfer Order</ListItemText>
                </MenuItem>
              </Menu>
            )}

            {editDialogOpen && selectedOrder ? (
              <Edit
                eventId={selectedOrder.event_id}
                order={selectedOrder}
                open={editDialogOpen}
                onDialogClose={(value) => onEditDialogClose(value)}
              />
            ) : (
              ''
            )}

            {attendeesDialogOpen && selectedOrder ? (
              <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={attendeesDialogOpen}
                aria-labelledby='attendees-dialog-title'
                aria-describedby='attendees-dialog-description'
                onClose={() => setAttendeesDialogOpen(false)}
              >
                <DialogTitle>Order attendees</DialogTitle>
                <DialogContent style={{ width: '100%', height: '900px' }}>
                  {selectedOrder.attendees ? (
                    <>
                      <DataGrid rows={selectedOrder.attendees} columns={columnsAttendees} />

                      {editDialogOpenAttendees && selectedAttendee ? (
                        <EditAttendee
                          eventId={selectedOrder.event_id}
                          attendee={selectedAttendee}
                          open={editDialogOpenAttendees}
                          onDialogClose={(value) => onEditDialogCloseDialog(value)}
                          onAttendeeUpdate={handleAttendeeUpdate}
                        />
                      ) : (
                        ''
                      )}

                      {checkInDialogOpen && selectedAttendee ? (
                        <Dialog
                          open={checkInDialogOpen}
                          aria-labelledby='check-in-dialog-title'
                          aria-describedby='check-in-dialog-description'
                        >
                          <DialogTitle id='check-in-dialog-title'>
                            Are you sure you want to check-in this attendee?
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id='check-in-dialog-description'>
                              Keep in mind that this action could affect the on-site check-in
                              process. Please be sure that you want to make this action.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setCheckInDialogOpen(false)} color='success'>
                              Close
                            </Button>
                            <LoadingButton loading={loadingCheckIn}>
                              <Button
                                onClick={confirmCheckIn}
                                disabled={loadingCheckIn}
                                color='error'
                              >
                                Confirm Check-in
                              </Button>
                            </LoadingButton>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ''
                      )}

                      {resetCheckInDialogOpen && selectedAttendee ? (
                        <Dialog
                          open={resetCheckInDialogOpen}
                          aria-labelledby='reset-check-in-dialog-title'
                          aria-describedby='reset-check-in-dialog-description'
                        >
                          <DialogTitle id='reset-check-in-dialog-title'>
                            Are you sure you want to reset check-in for this attendee?
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id='reset-check-in-dialog-description'>
                              Keep in mind that this action could affect the on-site check-in
                              process. Please be sure that you want to make this action.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => setResetCheckInDialogOpen(false)}
                              color='success'
                            >
                              Close
                            </Button>
                            <LoadingButton loading={loadingCheckIn}>
                              <Button
                                onClick={confirmResetCheckIn}
                                disabled={loadingResetCheckIn}
                                color='error'
                              >
                                Confirm Reset Check-in
                              </Button>
                            </LoadingButton>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ''
                      )}

                      {menuContextDialog && (
                        <Menu
                          anchorEl={menuContextDialog?.anchor}
                          open={!!menuContextDialog}
                          onClose={hideActionMenuDialog}
                        >
                          <MenuItem
                            onClick={() => {
                              setEditDialogOpenAttendees(true);
                              setSelectedAttendee(menuContextDialog.attendee);
                              hideActionMenuDialog();
                            }}
                          >
                            <ListItemIcon>
                              <EditOutlined />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              setCheckInDialogOpen(true);
                              setSelectedAttendee(menuContextDialog.attendee);
                              hideActionMenuDialog();
                            }}
                          >
                            <ListItemIcon>
                              <CheckCircleOutline />
                            </ListItemIcon>
                            <ListItemText>Check-in</ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              setResetCheckInDialogOpen(true);
                              setSelectedAttendee(menuContextDialog.attendee);
                              hideActionMenuDialog();
                            }}
                          >
                            <ListItemIcon>
                              <ReplayOutlined color='error' />
                            </ListItemIcon>
                            <ListItemText>Reset check-in</ListItemText>
                          </MenuItem>
                        </Menu>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttendeesDialogOpen(false)} color='info'>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {resendDialogOpen && selectedOrder ? (
              <Dialog
                open={resendDialogOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  Are you sure you want to resend these tickets?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Please make sure if the email is correct before sending the tickets to the
                    Order's email
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setResendDialogOpen(false)}>Cancel</Button>
                  <LoadingButton loading={loading}>
                    <Button onClick={resendTickets} disabled={loading}>
                      Send
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {cancelOrderDialogOpen && selectedOrder ? (
              <Dialog
                open={cancelOrderDialogOpen}
                aria-labelledby='cancel-dialog-title'
                aria-describedby='cancel-dialog-description'
              >
                <DialogTitle id='cancel-dialog-title'>
                  Are you sure you want to cancel this order?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='cancel-dialog-description'>
                    Keep in mind that this action has no easy way to be undone. Please be sure that
                    you want to cancel this order.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setCancelOrderDialogOpen(false)} color='success'>
                    Close
                  </Button>
                  <LoadingButton loading={loadingCancel}>
                    <Button onClick={confirmCancelOrder} disabled={loadingCancel} color='error'>
                      Confirm Cancel
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {refundOrderDialogOpen && selectedOrder ? (
              <Dialog
                open={refundOrderDialogOpen}
                aria-labelledby='refund-dialog-title'
                aria-describedby='refund-dialog-description'
              >
                <DialogTitle id='refund-dialog-title'>
                  Which attendees would you like to refund?
                </DialogTitle>
                <DialogContent>
                  <List>
                    {selectedOrder.attendees?.map((attendee: Attendee) => (
                      <ListItem key={attendee.id}>
                        <ListItemText
                          primary={`${attendee.first_name} ${attendee.last_name}`}
                          secondary={`Price: ${formatPrice(
                            attendee.paid_price,
                            selectedOrder?.event?.currency,
                          )}`}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge='end'
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (checked) {
                                setRefundAttendees((prev) => [...prev, attendee.id]);
                              } else {
                                setRefundAttendees((prev) => prev.filter((a) => a !== attendee.id));
                              }
                            }}
                            checked={refundAttendees.includes(attendee.id)}
                            disabled={attendee.status === 'refunded'}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setRefundOrderDialogOpen(false)} color='success'>
                    Close
                  </Button>
                  <LoadingButton loading={loadingRefund}>
                    <Button
                      onClick={confirmRefundOrder}
                      disabled={loadingRefund || refundAttendees.length === 0}
                      color='error'
                    >
                      Confirm Refund
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {editTransferOrderDialogOpen && selectedOrder ? (
              <EditTransferOrder
                eventId={selectedOrder.event_id}
                order={selectedOrder}
                open={editTransferOrderDialogOpen}
                onDialogClose={(value) => onEditDTransferOrderialogClose(value)}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
