import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { TrackingLinkForm, trackingLinkFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect } from 'react';
import {
  useCreateTrackingLinkMutation,
  useGetTrackingLinkQuery,
  useUpdateTrackingLinkMutation,
} from '@/Api/insightsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import useLoading from '@/Hooks/useLoading';
import { cleanObject } from '@/Utils/general';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { useGetEventsQuery } from '@/Api/eventsSlice';
import { Event } from '@/Models/events';
import { showSnackbar } from '@/Slices/snackbarSlice';
import environment from '@/environments/environment';
import { FileCopyOutlined } from '@mui/icons-material';

const CreateOrEditTrackingLink = () => {
  const { trackingLinkId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<TrackingLinkForm>({
    defaultValues: {
      name: '',
      event_id: '',
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      url: '',
    },
    resolver: zodResolver(trackingLinkFormSchema),
  });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [createTrackingLink] = useCreateTrackingLinkMutation();
  const [updateTrackingLink] = useUpdateTrackingLinkMutation();

  const { data: trackingLink } = useGetTrackingLinkQuery(trackingLinkId!, {
    refetchOnMountOrArgChange: true,
    skip: !trackingLinkId,
  });

  const { data: events, isLoading } = useGetEventsQuery(
    { pageData: { page: 0, pageSize: 1000 } },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (!trackingLink) {
      return;
    }

    reset({
      ...cleanObject(trackingLink),
    });
  }, [trackingLink]);

  function getTitle() {
    return `${!!trackingLinkId ? 'Edit' : 'Create'} Tracking Link`;
  }

  async function onSubmit() {
    let trackingLinkData = { ...getValues() };

    const promise = !!trackingLinkId
      ? updateTrackingLink({ trackingLinkId, body: trackingLinkData })
      : createTrackingLink(trackingLinkData);

    withLoading(promise.unwrap())
      .then(() => {
        navigate('/insights');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof TrackingLinkForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  const selectedEventId = watch('event_id');
  const selectedEvent = events?.data.find((event: Event) => event.id === selectedEventId);
  const selectedEventSlug = selectedEvent ? selectedEvent.slug : '';

  useEffect(() => {
    if (selectedEventSlug || watch('utm_source') || watch('utm_medium') || watch('utm_campaign')) {
      setValue('url', generateURL());
    }
  }, [selectedEventSlug, watch('utm_source'), watch('utm_medium'), watch('utm_campaign')]);

  function generateURL() {
    const { utm_source, utm_medium, utm_campaign } = getValues();
    return `${environment.ticketingUrl}/events/${selectedEventSlug}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
  }

  const isFieldDisabled = trackingLink && trackingLink.purchase_count > 0;

  const { isMobile } = useResponsive();

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the tracking link
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Name'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='body1'>Select the Event</Typography>
                    <Controller
                      name='event_id'
                      control={control}
                      render={({ field, fieldState: { error } }) =>
                        isLoading ? (
                          <CircularProgress />
                        ) : error ? (
                          <Typography variant='body1'>Failed to load events</Typography>
                        ) : (
                          <Select
                            {...field}
                            required
                            label='Select Event'
                            variant='outlined'
                            disabled={isFieldDisabled}
                            fullWidth
                            error={!!error}
                          >
                            {events &&
                              events.data.map((event: Event) => (
                                <MenuItem key={event.id} value={event.id}>
                                  {event.title}
                                </MenuItem>
                              ))}
                          </Select>
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='utm_source'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='UTM Source'
                          variant='outlined'
                          disabled={isFieldDisabled}
                          fullWidth
                          error={!!error}
                          helperText={
                            error?.message ||
                            'Identify the source, like facebook, newsletter, or google'
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='utm_medium'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='UTM Medium'
                          variant='outlined'
                          disabled={isFieldDisabled}
                          fullWidth
                          error={!!error}
                          helperText={
                            error?.message || 'Identify the medium, like email, social, or banner'
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='utm_campaign'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='UTM Campaign'
                          variant='outlined'
                          disabled={isFieldDisabled}
                          fullWidth
                          error={!!error}
                          helperText={
                            error?.message ||
                            'Identify the campaign, like spring_sale, black_friday, or new_product_launch.'
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='url'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='URL'
                          variant='outlined'
                          disabled={isFieldDisabled}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                          className='bg-gray-100'
                          sx={{ '& .MuiInputBase-input': { color: 'gray' } }}
                          onFocus={() => {
                            if (
                              selectedEventSlug ||
                              watch('utm_source') ||
                              watch('utm_medium') ||
                              watch('utm_campaign')
                            ) {
                              setValue('url', generateURL());
                            }
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the click event from reaching the TextField
                                    navigator.clipboard.writeText(field.value || '').then(
                                      () => {
                                        dispatch(
                                          showSnackbar({
                                            message:
                                              'The tracking link has been copied to your clipboard',
                                            severity: 'success',
                                          }),
                                        );
                                      },
                                      (error) => {
                                        console.log(error);
                                        dispatch(
                                          showSnackbar({
                                            message: 'Failed to copy to clipboard',
                                            severity: 'error',
                                          }),
                                        );
                                      },
                                    );
                                  }}
                                  sx={{ borderRadius: '0' }}
                                >
                                  <FileCopyOutlined className='mr-1' />
                                  <Typography variant='body1'>Copy</Typography>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEditTrackingLink;
