import { useGetVouchersQuery } from '@/Api/promocodesSlice';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import { DialogProps } from '@/Models/dialogs';
import { Voucher, VoucherGroup } from '@/Models/promocodes';
import { isEmpty } from '@/Utils/general';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';

interface VouchersProps {
  voucherGroup: VoucherGroup;
}

const Vouchers = ({ open, onDialogClose, voucherGroup }: DialogProps<VouchersProps>) => {
  const [page, setPage] = useState(0);

  const pageSize = 10;

  const { data: vouchersData } = useGetVouchersQuery(
    {
      voucherGroupId: voucherGroup.id,
      filterData: { pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  const columns: GridColDef<Voucher>[] = [
    { field: 'code', headerName: 'Code', sortable: false, flex: 1 },
    {
      field: 'used',
      headerName: 'Used',
      renderCell: ({ row: { used } }) => <EmptyCell condition={!isEmpty(used)}>{used}</EmptyCell>,
      sortable: false,
      flex: 1,
    },
  ];

  function onPageChange(page: number) {
    setPage(page);
  }

  return (
    <Dialog fullWidth={true} maxWidth='md' open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle>Voucher codes</DialogTitle>

      <DialogContent>
        <div className='h-[50vh]'>
          {vouchersData && (
            <DataGrid
              rows={vouchersData.data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[pageSize]}
              rowCount={vouchersData.total}
              paginationMode='server'
              onPageChange={onPageChange}
              hideFooterSelectedRowCount={true}
              isRowSelectable={() => false}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Vouchers;
