import React, { useState } from 'react';
import { Guest } from '@/Models/guests';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import { useGetTransactionsQuery } from '@/Api/transactionsSlice';
import { useDispatch } from 'react-redux';
import { useResponsive } from '@/Hooks/useResponsive';
import { formatPrice } from '@/Utils/currency';

const TransactionsList = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [checkboxSelection, setCheckboxSelection] = React.useState(true);
  const [pageSize, setPageSize] = React.useState<number>(20);

  const [search, setSearch] = useState('');

  const { data: transactionData, refetch } = useGetTransactionsQuery(
    {
      filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    guest: Guest;
  }>(null);

  function onPageChange(page: number) {
    setPage(page);
  }

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  const columns: GridColDef[] = [
    { field: 'transaction_id', headerName: 'Transaction Id', sortable: false, width: 300 },
    {
      field: 'payment_gateway_transaction_id',
      headerName: 'Payment Gateway Transaction Id',
      sortable: false,
      width: 300,
    },
    {
      field: 'payment_gateway',
      headerName: 'Payment Gateway',
      valueGetter: ({ row }) => row.payment_gateway,
      width: 150,
      sortable: false,
    },
    {
      field: 'event',
      headerName: 'Event Title',
      valueGetter: ({ row }) => row.event.title,
      width: 200,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      width: 150,
      sortable: false,
    },
    {
      field: 'booking_fee',
      headerName: 'Booking Fee',
      type: 'number',
      width: 100,
      renderCell: (params) => {
        const { event, booking_fee } = params.row;
        return formatPrice(booking_fee, event?.currency);
      },
    },
    { field: 'first_name', headerName: 'First name', sortable: false },
    {
      field: 'last_name',
      headerName: 'Last name',
      width: 100,
      sortable: false,
    },
    { field: 'email', headerName: 'Email', width: 200, sortable: false },
  ];

  const { isMobile } = useResponsive();

  return (
    <>
      <div style={{ height: '500px', paddingRight: isMobile ? '0px' : '100px' }}>
        {transactionData ? (
          <DataGrid
            rows={transactionData.data.map((transaction: any, index: any) => ({
              ...transaction,
              id: index,
            }))}
            columns={columns}
            paginationMode='server'
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onPageChange={onPageChange}
            rowCount={transactionData.total}
            checkboxSelection={checkboxSelection}
            components={{ Toolbar: GridToolbar }}
            filterMode='server'
            onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 1000 },
              },
            }}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default TransactionsList;
