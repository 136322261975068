import { Paper, Stack, Typography } from '@mui/material';

export interface LegendProps {}

const Legend = ({}: LegendProps) => {
  return (
    <Paper
      elevation={2}
      className='absolute bottom-4 right-4 p-4 flex flex-col items-baseline gap-2'
    >
      <Stack direction='row' spacing={2} alignItems='center'>
        <div className='w-4 h-4 rounded-full bg-[#ff8833]'></div>
        <Typography variant='body2'>Available</Typography>
      </Stack>
      <Stack direction='row' spacing={2} alignItems='center'>
        <div className='w-4 h-4 rounded-full bg-[#cccccc]'></div>
        <Typography variant='body2'>Unavailable</Typography>
      </Stack>
    </Paper>
  );
};

export default Legend;
