import React from 'react';
import { Event } from '@/Models/events';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TicketTypes from '@/Components/Molecules/TicketTypes/TicketTypes';
import InvitationTypes from '@/Components/Molecules/InvitationTypes/InvitationTypes';
import TicketTypesAllocated from '@/Components/Molecules/TicketTypesAllocated/TicketTypesAllocated';
import { useGetTicketsQuery } from '@/Api/ticketsSlice';
import { useGetInvitationTypesQuery } from '@/Api/invitationsSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useResponsive } from '@/Hooks/useResponsive';

export interface TicketTypesReportTabsProps {
  event: Event;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: index == 1 ? 4 : 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TicketTypesReportTabs = ({ event }: TicketTypesReportTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: tickets } = useGetTicketsQuery(
    { eventId: event.id },
    { refetchOnMountOrArgChange: true },
  );
  const { data: invitations } = useGetInvitationTypesQuery(event.id, {
    refetchOnMountOrArgChange: true,
  });

  const loading = (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
  const { isMobile } = useResponsive();
  return (
    <Box
      sx={{
        
        backgroundColor: '#fff',
        p: 2,
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          {...(isMobile && { variant: 'scrollable', scrollButtons: 'auto' })}
          indicatorColor={isMobile ? 'primary' : undefined}
          textColor={isMobile ? 'primary' : undefined}
        >
          <Tab label='Ticket Allocation' {...a11yProps(0)} />
          <Tab label='Compare Tickets' {...a11yProps(1)} />
          <Tab label='Invitations' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {!tickets ? loading : <TicketTypesAllocated tickets={tickets} event={event} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!tickets ? loading : <TicketTypes tickets={tickets} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!invitations ? loading : <InvitationTypes invitations={invitations} />}
      </TabPanel>
    </Box>
  );
};

export default TicketTypesReportTabs;
