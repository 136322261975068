import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyContent from './EmptyContent';
import { HomeOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setTitle } from '@/Slices/layoutSlice';

const Page404 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Not Found'));
  }, []);

  return (
    <div className='h-screen w-screen'>
      <EmptyContent
        icon={
          <Typography variant='h1' fontWeight='bold'>
            404
          </Typography>
        }
        primaryText='Page Not Found'
        secondaryText="The page you're looking for does not exist"
        button={
          <Link to='/dashboard'>
            <Button variant='contained' startIcon={<HomeOutlined />}>
              Go back Home
            </Button>
          </Link>
        }
      />
    </div>
  );
};

export default Page404;
