import { Stack, Typography } from '@mui/material';
import SvgEditor from './SvgEditor';
import EditingPane from './EditingPane';
import { useContext, useEffect, useState } from 'react';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import SeatCategorizationForm from './SeatCategorizationForm';
import { ColorGenerator } from '@festtix/venue-editor';
import { SeatCategoryForm } from './seat-categorization-form-helpers';
import { editorTools } from '@/Constants/venue-tools';
import { useSelector } from 'react-redux';
import { GlobalStore } from '@/store';
import { Ticket } from '@/Models/tickets';

interface SeatCategorizationEditorContainerProps {
  svgContent: string;
  tickets: Ticket[] | undefined;
}

function SeatCategorizationEditorContainer({
  svgContent,
  tickets,
}: SeatCategorizationEditorContainerProps) {
  const {
    venueEditorService,
    seatIdentifierService,
    sectionIdentifierService,
    editorEventService,
  } = useContext(VenueEditorContext);
  const [selectedSeatIds, setSelectedSeatIds] = useState<string[]>([]);
  const { currentTool } = useSelector((state: GlobalStore) => state.venueEditor);

  function initialize() {
    venueEditorService.initialize('venue-svg-container-canvas', svgContent);
    venueEditorService.removeUnidentifiedSeatItems();
    venueEditorService.removeUnlabelledSeatItems(seatIdentifierService.getSeatData());

    const allSections = venueEditorService.getAllSections();

    const colorGenerator = new ColorGenerator();
    const sectionPalette = colorGenerator.generateColors(allSections.length);
    sectionIdentifierService.assignColorsToSections(allSections, sectionPalette);

    venueEditorService.fillLabelsForSections(sectionIdentifierService.getSectionData());

    editorEventService.seatsStatusChanged$.next(venueEditorService.getAllSeats());
    editorEventService.sectionsStatusChanged$.next(allSections);
    editorEventService.sectionsVisibilityChanged$.next(allSections);
  }

  useEffect(() => {
    const sub = venueEditorService.getSelectedElementsIds$().subscribe((selectedSeatIds) => {
      setSelectedSeatIds(selectedSeatIds);
    });

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (!tickets) return;

    const colorGenerator = new ColorGenerator();
    const colors = colorGenerator.generateColors(tickets.length);
    seatIdentifierService.assignColorsToTickets(
      tickets.map((ticket) => ticket.id),
      colors,
    );

    editorEventService.seatsStatusChanged$.next(venueEditorService.getAllSeats());
  }, [tickets]);

  function onSeatCategorizationFormSubmit(data: SeatCategoryForm) {
    const selectedSeats = venueEditorService.getSelectedSeats();
    seatIdentifierService.fillSeatCategorizationData(selectedSeats, data);
    venueEditorService.clearSelection();
  }

  function getToolHelperText() {
    const tool = editorTools.find((tool) => tool.type === currentTool);

    return tool?.helperText ?? '';
  }

  return (
    <Stack direction='row' className='w-full h-[calc(100vh-122px)]'>
      <div className='w-[70%]'>
        <SvgEditor initialize={initialize} />
      </div>
      <div className='w-[30%] border-solid border-l-2'>
        <EditingPane
          allowedTools={['singleSeatSelector', 'rectangularSeatSelector', 'polygonalSeatSelector']}
        >
          {!!selectedSeatIds.length ? (
            <Stack>
              <div className='mb-4'>
                <SeatCategorizationForm
                  tickets={tickets}
                  onSubmit={onSeatCategorizationFormSubmit}
                />
              </div>
            </Stack>
          ) : (
            <Stack className='h-full' alignItems='center' justifyContent='center'>
              <Typography variant='body1'>{getToolHelperText()}</Typography>
            </Stack>
          )}
        </EditingPane>
      </div>
    </Stack>
  );
}

export default SeatCategorizationEditorContainer;
