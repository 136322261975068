import React from 'react';
import { Event } from '@/Models/events';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TransactionOverview from '@/Components/Molecules/TransactionOverview/TransactionOverview';
import { useGetTransactionsStatsQuery } from '@/Api/transactionsSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useResponsive } from '@/Hooks/useResponsive';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TransactionTab = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: stats } = useGetTransactionsStatsQuery({ refetchOnMountOrArgChange: true });

  const loading = (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
  const { isMobile } = useResponsive();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#fff',
        p: 2,
        height: '100%',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className='scrollable auto-scroll-buttons primary-indicator-color lg:primary-text-color'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            {...(isMobile && {
              variant: 'scrollable',
              scrollButtons: 'auto',
              indicatorColor: 'primary',
              textColor: 'primary',
            })}
          >
            <Tab label='Transaction Overview' {...a11yProps(0)} />
          </Tabs>
        </div>
      </Box>
      {!stats ? (
        loading
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <TransactionOverview transactions={stats} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default TransactionTab;
