import { DataKey, LocalDataMap } from '@/Models/local-storage';

export abstract class FSTXLocalStorage {
  private static prefix = 'FSTX-';

  private static buildKey(key: DataKey): string {
    return `${FSTXLocalStorage.prefix}${key}`;
  }

  public static setItem<T extends keyof LocalDataMap>(key: DataKey, value: LocalDataMap[T]): void {
    const lsKey = FSTXLocalStorage.buildKey(key);
    localStorage.setItem(lsKey, JSON.stringify(value));
  }

  // TODO: add validator as optional parameter, that throws an error
  public static getItem<T extends keyof LocalDataMap>(key: T): LocalDataMap[T] | null {
    const lsKey = FSTXLocalStorage.buildKey(key);
    const item = localStorage.getItem(lsKey);

    if (!item) {
      return null;
    }

    return JSON.parse(item) as LocalDataMap[T];
  }

  public static removeItem(key: DataKey): void {
    const lsKey = FSTXLocalStorage.buildKey(key);
    localStorage.removeItem(lsKey);
  }
}
