import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { ScanGroup, ScanGroupData } from '@/Models/scanGroups';

export const scanGroupsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getScanGroups: builder.query<ScanGroupData, FilterData<'upcoming'>>({
      query: (filterData) => ({
        url: `/scan-groups`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
    getScanGroup: builder.query<ScanGroup, string>({
      query: (scanGroupId) => ({
        url: `/scan-groups/${scanGroupId}`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
    createScanGroup: builder.mutation<ScanGroup, any>({
      query: (body) => ({
        url: `/scan-groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
    updateScanGroup: builder.mutation<ScanGroup, { scanGroupId: String; body: any }>({
      query: ({ scanGroupId, body }) => ({
        url: `/scan-groups/${scanGroupId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
    deleteScanGroup: builder.mutation<any, string>({
      query: (scanGroupId) => ({
        url: `/scan-groups/${scanGroupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
    getScanGroupStats: builder.query<any, { scanGroupId: string }>({
      query: ({ scanGroupId }) => ({
        url: `/scan-groups/${scanGroupId}/stats`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
    getScanGroupStatsOverview: builder.query<any, { scanGroupId: string }>({
      query: ({ scanGroupId }) => ({
        url: `/scan-groups/${scanGroupId}/stats-overview`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.SCAN_GROUPS],
    }),
  }),
});

export const {
  useGetScanGroupsQuery,
  useGetScanGroupQuery,
  useCreateScanGroupMutation,
  useUpdateScanGroupMutation,
  useDeleteScanGroupMutation,
  useGetScanGroupStatsQuery,
  useGetScanGroupStatsOverviewQuery,
} = scanGroupsApi;
