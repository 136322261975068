import { QueryResult } from '@/Models/redux';
import { Method } from 'axios';

export enum HTTP_CODE {
  OK = 200,
  NO_CONTENT = 204,

  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  CSRF_MISMATCH = 419,
  UNPROCESSABLE_ENTITY = 422,

  INTERNAL_SERVER_ERROR = 500,
}

export function getSuccessMessageBasedOnMethod(response: Method | string): string {
  switch (response.toUpperCase()) {
    case 'POST':
      return 'Item was saved successfully!';
    case 'PUT':
      return 'Item was updated successfully!';
    case 'DELETE':
      return 'Item was deleted successfully';
    default:
      return 'Action was successful!';
  }
}

// TODO front: type this and add other messages
export function getErrorMessageBasedOnStatus(response: QueryResult): string {
  switch (response.meta!.response!.status) {
    case HTTP_CODE.FORBIDDEN:
    case HTTP_CODE.NOT_FOUND:
    case HTTP_CODE.INTERNAL_SERVER_ERROR:
      return (response.error?.data as any)?.['message'];
    case HTTP_CODE.BAD_REQUEST:
      return (response.error?.data as any)?.['message'];
    case HTTP_CODE.UNPROCESSABLE_ENTITY:
      return 'There are some errors on your form! Please fix them before proceeding.';
    default:
      return 'Something went wrong, please try again later.';
  }
}

export enum FSTX_HEADER {
  HIDE_MESSAGES = 'fstx-hide-messages',
  HIDE_SUCCESS_MESSAGES = 'fstx-hide-success-message',
  HIDE_ERROR_MESSAGES = 'fstx-hide-error-message',
  TENANT_ID = 'fstx-tenant-id',
}

export function getHidesMessagesHeader() {
  return {
    [FSTX_HEADER.HIDE_MESSAGES]: true,
  };
}

export function getHideSuccessMessageHeader() {
  return {
    [FSTX_HEADER.HIDE_SUCCESS_MESSAGES]: true,
  };
}

export function getHideErrorMessageHeader() {
  return {
    [FSTX_HEADER.HIDE_ERROR_MESSAGES]: true,
  };
}
