import {
  useCreatePasscodeGroupMutation,
  useGetPasscodeGroupQuery,
  useUpdatePasscodeGroupMutation,
} from '@/Api/promocodesSlice';
import CardSelection, { CardItem } from '@/Components/Organisms/CardSelection/CardSelection';
// import { EVENT_TAB } from '@/Pages/Events/Event/constants';
import { getFormDataFromObject } from '@/Utils/general';
import { HTTP_CODE } from '@/Utils/http-client';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { add, max } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { codeGenerationMethods, GenerationMethod } from '../helpers';
import {
  codeGenerationFormKeys,
  passcodeGenerationMethods,
  PasscodesForm,
  getSchema,
} from './passcode-helpers';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_CSV_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import TemplateDownload from '@/Components/Molecules/TemplateDownload/TemplateDownload';
import environment from '@/environments/environment';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo } from 'react';
import { RowError } from '@/Models/csv';
import { humanizeArray } from '@/Utils/string';
import useLoading from '@/Hooks/useLoading';
import { useDispatch } from 'react-redux';
import { showPageRefreshSnackbar, showSnackbar } from '@/Slices/snackbarSlice';
import { useGetTicketsQuery } from '@/Api/ticketsSlice';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { formatTimeZoneShort } from '@/Utils/dates';

const allowedFileTypes = ['text/csv'];

const CreatePasscodes = () => {
  const { eventId, passcodeGroupId } = useParams();
  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const [isLoading, withLoading] = useLoading();
  const dispatch = useDispatch();

  const isEditing = !!passcodeGroupId;

  const [createPasscodeGroup] = useCreatePasscodeGroupMutation();
  const [updatePasscodeGroup] = useUpdatePasscodeGroupMutation();

  const { data: passcodeGroup, isFetching: isFetchingGroup } = useGetPasscodeGroupQuery(
    {
      eventId: eventId!,
      passcodeGroupId: passcodeGroupId!,
    },
    {
      skip: !passcodeGroupId,
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: tickets, isFetching: isFetchingTickets } = useGetTicketsQuery(
    { eventId: eventId!, locked: true },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, [event]);

  const navigate = useNavigate();

  const passcodesFormSchema = useMemo(() => {
    return getSchema(passcodeGroup);
  }, [passcodeGroup]);

  const {
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
    setError,
    clearErrors,
    unregister,
    handleSubmit,
  } = useForm<PasscodesForm>({
    defaultValues: {
      name: '',
      expires_at: add(new Date(), { hours: 48 }),
      allocated_tickets: 1,
      unlocked_tickets: [],
      ...(isEditing ? {} : { generation_method: GenerationMethod.GENERATE }),
    },
    resolver: zodResolver(passcodesFormSchema),
  });

  useEffect(() => {
    if (!!passcodeGroup) {
      const transformedPasscodeGroup = {
        ...passcodeGroup,
        unlocked_tickets: passcodeGroup.unlocked_tickets.map((t) => t.id),
      };
      const transformedData = passcodesFormSchema.safeParse(transformedPasscodeGroup);

      if (transformedData.success) {
        reset(transformedData.data);
      } else {
        dispatch(showPageRefreshSnackbar());
      }
    }
  }, [passcodeGroup]);

  function getExpiresAtMinDate() {
    if (isEditing && !!passcodeGroup) {
      return max([add(new Date(), { hours: 1 }), new Date(passcodeGroup.expires_at)]);
    }

    return add(new Date(), { hours: 1 });
  }

  function onMethodChange(item: CardItem | undefined) {
    const currMethod = getValues('generation_method');
    if (currMethod) {
      codeGenerationFormKeys[currMethod]!.forEach((fk) => {
        unregister(fk, { keepDefaultValue: true });
      });
    }

    setValue('generation_method', item?.label as GenerationMethod, {
      shouldValidate: true,
    });
  }

  function onFileUpload(file: File) {
    setValue('csv_file', file);
    clearErrors('csv_file');
  }

  const onSubmit: SubmitHandler<PasscodesForm> = async (formData) => {
    let data: any =
      selectedMethod === GenerationMethod.IMPORT_CSV ? getFormDataFromObject(formData) : formData;
    const promise = isEditing
      ? updatePasscodeGroup({ eventId: eventId!, passcodeGroupId: passcodeGroup!.id, data })
      : createPasscodeGroup({ eventId: eventId!, data });

    withLoading(promise.unwrap())
      .then(() => {
        navigate(`/events/${eventId}/promocodes`);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          if (!!errors[CustomZodErrorType.CSV_ERRORS]) {
            const invalidCodes = humanizeArray<RowError<'code'>>(
              errors[CustomZodErrorType.CSV_ERRORS],
              (el) => el.rowData.code,
            );

            setError('csv_file', {
              message: `Some of the codes in the file are invalid: ${invalidCodes}. Or the file is greater than 1mb`,
              type: CustomZodErrorType.CSV_ERRORS,
            });
          } else {
            Object.keys(errors).forEach((key) => {
              setError(key as keyof typeof formData, {
                message: errors[key]?.[0],
                type: 'validate',
              });
            });
          }
        }
      });
  };

  function getTitle() {
    return isEditing ? 'Edit Passcode Group' : 'Create Passcodes';
  }

  const selectedMethod = getValues('generation_method');

  const selectedCardItem = useMemo(() => {
    return codeGenerationMethods.find((cgm) => cgm.label === selectedMethod);
  }, [selectedMethod]);
  const { isMobile } = useResponsive();
  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form name='createForm' onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack spacing={4} divider={<Divider />}>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 ml-2 flex-shrink-0 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>General</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Provide essential information about the passcodes
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        name='name'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            required
                            id='name'
                            label='Name'
                            error={!!error}
                            helperText={error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name='expires_at'
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => {
                          const currentDate = new Date();
                          const expiresAtDate = new Date(field.value);

                          const isExpired = expiresAtDate < currentDate;

                          return (
                            <DesktopDateTimePicker
                              {...field}
                              label='Expires at'
                              inputFormat='MM/dd/yyyy HH:mm'
                              minDateTime={getExpiresAtMinDate()}
                              disabled={isExpired}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  id='expires_at'
                                  label={`Expires at (${formatTimeZoneShort(event?.timezone)})`}
                                  error={!!error}
                                  helperText={
                                    error?.message ||
                                    (isEditing
                                      ? 'You can only extend the already set expiry date'
                                      : 'The minimum expiry date is one hour from now')
                                  }
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name='allocated_tickets'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            id='allocated_tickets'
                            label='Max no. of tickets'
                            type='number'
                            disabled={isEditing}
                            error={!!error}
                            helperText={
                              error?.message ||
                              'This denotes the maximum number of tickets the user can buy using this passcode'
                            }
                            inputProps={{ min: 1 }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name='unlocked_tickets'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            select
                            SelectProps={{
                              multiple: true,
                              renderValue: (selectedTicketIds) => {
                                if (!tickets) {
                                  return '';
                                }

                                return (selectedTicketIds as string[])
                                  .map((ticketId) => tickets.find((t) => t.id == ticketId)?.title)
                                  .join(', ');
                              },
                            }}
                            id='tickets'
                            label='Tickets to unlock'
                            error={!!error}
                            helperText={
                              error?.message || 'Passcode can only be applied to locked tickets'
                            }
                            fullWidth
                          >
                            {isFetchingTickets
                              ? []
                              : tickets?.map(({ id, title }) => (
                                  <MenuItem key={id} value={`${id}`}>
                                    <Checkbox checked={field.value.includes(`${id}`)} />
                                    <ListItemText primary={title} />
                                  </MenuItem>
                                ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>

              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 ml-2 flex-shrink-0 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>Code Generation</Typography>

                  <Typography variant='body2' color='text.secondary'>
                    Configure in what way will the passcodes be generated.
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4, flex: 1 }}>
                  <Grid container spacing={3}>
                    {isEditing && (
                      <Grid item xs={12}>
                        <Alert severity='info'>
                          The previously generated codes will not be replaced, instead new ones will
                          be generated
                        </Alert>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <CardSelection
                        items={passcodeGenerationMethods}
                        onSelectionChange={onMethodChange}
                        value={selectedCardItem}
                      />
                    </Grid>

                    {!!selectedMethod && (
                      <Grid item xs={12}>
                        {selectedMethod === GenerationMethod.IMPORT_CSV && (
                          <Alert severity='info' className='mb-4'>
                            The code should consist only of uppercase letters (A-Z), numbers (0-9)
                            and hyphens. Because users will have to write this code, it is preferred
                            not to make it that long.
                          </Alert>
                        )}

                        {selectedMethod === GenerationMethod.GENERATE && (
                          <Controller
                            name='codeNo'
                            defaultValue={5}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                id='codeNo'
                                label='Number of voucher codes'
                                type='number'
                                error={!!error}
                                helperText={error?.message}
                                inputProps={{ min: 1 }}
                                fullWidth
                              />
                            )}
                          />
                        )}

                        {selectedMethod === GenerationMethod.IMPORT_CSV && (
                          <Stack>
                            <Controller
                              name='csv_file'
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <FileUpload
                                  label='Attachment'
                                  allowedFileTypes={allowedFileTypes}
                                  maxFileSize={MAX_CSV_FILE_SIZE_IN_BYTES}
                                  onSelect={onFileUpload}
                                  onError={(message) =>
                                    setError('csv_file', {
                                      message,
                                      type: CustomZodErrorType.INPUT_FILE,
                                    })
                                  }
                                />
                              )}
                            />

                            {!!errors.csv_file &&
                              errors.csv_file.type !== CustomZodErrorType.INPUT_FILE && (
                                <FormControl error>
                                  <FormHelperText className='!ml-0'>
                                    {errors.csv_file.message}
                                  </FormHelperText>
                                </FormControl>
                              )}

                            <TemplateDownload
                              link={environment.baseUrl + '/downloads/code-template.csv'}
                            />
                          </Stack>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Stack>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={isLoading}>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={isLoading || (isEditing && isFetchingGroup)}
                  color='success'
                >
                  {isEditing ? 'Save' : 'Create'}
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreatePasscodes;
