import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Seat, SeatData, SectionData, Venue } from '@/Models/seating';

export const seatingApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    storeEventVenue: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/venue`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_VENUE],
    }),
    getEventVenue: builder.query<Venue, string>({
      query: (eventId: string) => ({
        url: `/events/${eventId}/venue`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.EVENT_VENUE],
    }),
    getSectionData: builder.query<SectionData, string>({
      query: (venueId) => ({
        url: `/venues/${venueId}/section-data`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.VENUE_SECTION_DATA],
    }),
    // where fields is an array of strings that are the keys of the Seat model
    getSeatData: builder.query<SeatData, { venueId: string; fields?: (keyof Seat)[] }>({
      query: ({ venueId, fields }) => {
        const params: any = {};

        if (!!fields) {
          params['fields'] = fields.join(',');
        }

        return {
          url: `/venues/${venueId}/seat-data`,
          method: 'GET',
          params,
        };
      },
      providesTags: [TAG_TYPE.VENUE_SECTION_DATA],
    }),
    storeEventVenueData: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/venue-data`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.VENUE_SECTION_DATA, TAG_TYPE.VENUE_SEAT_DATA],
    }),
    storeSeatCategorizationData: builder.mutation<any, { venueId: string; body: any }>({
      query: ({ venueId, body }) => ({
        url: `/venues/${venueId}/seat-categorization`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.VENUE_SEAT_DATA],
    }),
    deleteVenue: builder.mutation<any, string>({
      query: (venueId) => ({
        url: `/venues/${venueId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TAG_TYPE.EVENT_VENUE,
        TAG_TYPE.VENUE_SECTION_DATA,
        TAG_TYPE.VENUE_SEAT_DATA,
      ],
    }),
    getAvailableSeats: builder.query<
      { [seatId: string]: boolean },
      { eventId: string; isOrder?: boolean | null }
    >({
      query: ({ eventId, isOrder = false }) => ({
        url: `/events/${eventId}/venue-available-seats`,
        method: 'GET',
        params: {
          isOrder,
        },
      }),
    }),
    getSectionDataFromEvent: builder.query<any, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/venue-section-data`,
        method: 'GET',
      }),
    }),
    getSeatDataFromEvent: builder.query<
      any,
      { eventId: string; seatIds?: string[]; withSectionData?: boolean }
    >({
      query: ({ eventId, seatIds, withSectionData = false }) => {
        const params = {
          ...(seatIds && { seatIds: seatIds.join(',') }),
          withSectionData,
        };

        return {
          url: `/events/${eventId}/venue-seat-data`,
          method: 'GET',
          params,
        };
      },
    }),
    addSeats: builder.mutation<any, { eventId: string; transactionId: string; seat_ids: string[] }>(
      {
        query: ({ eventId, transactionId, seat_ids }) => ({
          url: `/public/events/${eventId}/transactions/${transactionId}/add-seats`,
          method: 'POST',
          body: {
            seat_ids,
          },
        }),
        invalidatesTags: [TAG_TYPE.VENUE_SEAT_DATA],
      },
    ),
    getSeatingStats: builder.query<any, string>({
      query: (eventId: string) => ({
        url: `/events/${eventId}/venue-stats`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.VENUE_SEAT_DATA],
    }),
  }),
});

export const {
  useStoreEventVenueMutation,
  useGetEventVenueQuery,
  useLazyGetEventVenueQuery,
  useStoreEventVenueDataMutation,
  useGetSectionDataQuery,
  useLazyGetSectionDataQuery,
  useGetSeatDataQuery,
  useLazyGetSeatDataQuery,
  useStoreSeatCategorizationDataMutation,
  useDeleteVenueMutation,
  useGetAvailableSeatsQuery,
  useLazyGetAvailableSeatsQuery,
  useGetSectionDataFromEventQuery,
  useLazyGetSectionDataFromEventQuery,
  useGetSeatDataFromEventQuery,
  useLazyGetSeatDataFromEventQuery,
  useAddSeatsMutation,
  useGetSeatingStatsQuery,
} = seatingApi;
