// @ts-nocheck
// TODO: this file was imported from inertia; replace it with react-hook-form library later
import isEqual from 'lodash/isEqual';
import { useState } from 'react';

type setDataByObject<TForm> = (data: TForm) => void;
type setDataByMethod<TForm> = (data: (previousData: TForm) => TForm) => void;
type setDataByKeyValuePair<TForm> = <K extends keyof TForm>(key: K, value: TForm[K]) => void;

export interface InertiaFormProps<TForm = Record<string, any>> {
  data: TForm;
  isDirty: boolean;
  errors: Record<keyof TForm, string>;
  hasErrors: boolean;
  processing: boolean;
  wasSuccessful: boolean;
  recentlySuccessful: boolean;
  setData: setDataByObject<TForm> & setDataByMethod<TForm> & setDataByKeyValuePair<TForm>;
  transform: (callback: (data: TForm) => TForm) => void;
  setDefaults(): void;
  setDefaults(field: keyof TForm, value: string): void;
  setDefaults(fields: Record<keyof TForm, string>): void;
  reset: (...fields: (keyof TForm)[]) => void;
  clearErrors: (...fields: (keyof TForm)[]) => void;
  setError(field: keyof TForm, value: string): void;
  setError(errors: Record<keyof TForm, string>): void;
}

export default function useForm<TForm = Record<string, any>>(
  initialValues?: TForm,
): InertiaFormProps<TForm> {
  const [defaults, setDefaults] = useState(initialValues || {});
  const [data, setData] = useState(defaults);
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);
  const [processing] = useState(false);
  const [progress] = useState(null);
  const [wasSuccessful] = useState(false);
  const [recentlySuccessful] = useState(false);
  let transform = (data: any) => data;

  return {
    data,
    setData(key, value) {
      if (typeof key === 'string') {
        setData({ ...data, [key]: value });
      } else if (typeof key === 'function') {
        setData((data: any) => key(data));
      } else {
        setData(key);
      }
    },
    isDirty: !isEqual(data, defaults),
    errors,
    hasErrors,
    processing,
    progress,
    wasSuccessful,
    recentlySuccessful,
    transform(callback: any) {
      transform = callback;
    },
    setDefaults(key: any, value: any) {
      if (typeof key === 'undefined') {
        setDefaults(() => data);
      } else {
        setDefaults((defaults: any) => ({
          ...defaults,
          ...(value ? { [key]: value } : key),
        }));
      }
    },
    reset(...fields: any) {
      if (fields.length === 0) {
        setData(defaults);
      } else {
        setData(
          Object.keys(defaults)
            .filter((key) => fields.includes(key))
            .reduce(
              (carry, key) => {
                carry[key] = defaults[key];
                return carry;
              },
              { ...data },
            ),
        );
      }
    },
    setError(key: any, value: any) {
      setErrors((errors) => {
        const newErrors = {
          ...errors,
          ...(value ? { [key]: value } : key),
        };
        setHasErrors(Object.keys(newErrors).length > 0);
        return newErrors;
      });
    },
    clearErrors(...fields: any) {
      setErrors((errors: any) => {
        const newErrors = Object.keys(errors).reduce(
          (carry, field) => ({
            ...carry,
            ...(fields.length > 0 && !fields.includes(field) ? { [field]: errors[field] } : {}),
          }),
          {},
        );
        setHasErrors(Object.keys(newErrors).length > 0);
        return newErrors;
      });
    },
  };
}
