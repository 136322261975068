import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarData } from '../Models/snackbar';

const initialState: SnackbarData = {
  open: false,
  message: '',
  severity: 'info',
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    hideSnackbar: (state) => ({
      ...state,
      open: false,
    }),
    showSnackbar: (state, { payload }: PayloadAction<Partial<Omit<SnackbarData, 'open'>>>) => ({
      ...state,
      open: true,
      ...payload,
    }),
    showPageRefreshSnackbar: (state) => ({
      ...state,
      open: true,
      message: 'An error has occurred! Please refresh the page and try again!',
      severity: 'error',
    }),
  },
});

export const { hideSnackbar, showSnackbar, showPageRefreshSnackbar } = snackbarSlice.actions;

export const snackbarReducer = snackbarSlice.reducer;
