export function capitalizeString(str: string) {
  if (!str) {
    return '';
  }

  if (str.includes('_')) {
    const words = str.split('_');
    const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return formattedWords.join(' ');
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export function humanizeArray<T>(
  invalidCodesData: T[],
  valueAccessor: (el: T) => string,
  maxInvalidErrorCodesShown = 10,
): string {
  if (!invalidCodesData || !!invalidCodesData.length) {
    return '';
  } else if (invalidCodesData.length === 1) {
    return valueAccessor(invalidCodesData[0]);
  } else if (invalidCodesData.length < maxInvalidErrorCodesShown) {
    return (
      invalidCodesData
        .slice(0, -1)
        .map((el) => valueAccessor(el))
        .join(', ') +
      ' and ' +
      valueAccessor(invalidCodesData[invalidCodesData.length - 1])
    );
  } else {
    return (
      invalidCodesData
        .slice(0, maxInvalidErrorCodesShown)
        .map((el) => valueAccessor)
        .join(', ') + ' and more'
    );
  }
}
