import { GenerateSignedPostUrlPayload, SignedPutUrlPayload } from '@/Models/files';
import { getHideSuccessMessageHeader } from '@/Utils/http-client';
import { internalApi } from './internalApi';
import { thirdPartyApi } from './thirdPartyApi';

export const filesInternalApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    generateSignedUrl: builder.mutation<
      SignedPutUrlPayload[],
      { payload: GenerateSignedPostUrlPayload[] }
    >({
      query: ({ payload }) => ({
        url: `/generate-url`,
        method: 'POST',
        body: { data: payload },
        headers: getHideSuccessMessageHeader(),
      }),
    }),
  }),
});

export const filesExternalApi = thirdPartyApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, { url: string; formData: FormData }>({
      query: ({ url, formData }) => ({
        url,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const {
  endpoints: { generateSignedUrl },
} = filesInternalApi;
export const {
  endpoints: { uploadFile },
} = filesExternalApi;
