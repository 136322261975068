import React from 'react';
import BaseDialog from './BaseDialog';
import { Button } from '@mui/material';
import { ConfirmationDialogProps } from './dialogProps';

export default function DeleteDialog({
  open,
  onDialogClose,
  query,
  cancelButtonLabel,
  confirmButtonLabel,
}: ConfirmationDialogProps) {
  return (
    <BaseDialog
      title={query ?? 'Are you sure you want to delete this item?'}
      open={open}
      onClose={onDialogClose}
      content="You can't undo this action"
    >
      <Button onClick={() => onDialogClose(false)}>{cancelButtonLabel ?? 'Cancel'}</Button>

      <Button variant='contained' color='error' onClick={() => onDialogClose(true)}>
        {confirmButtonLabel ?? 'Delete'}
      </Button>
    </BaseDialog>
  );
}
