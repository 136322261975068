import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  TextFieldProps,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  Checkbox,
  Stack,
} from '@mui/material';
import { Order } from '@/Models/orders';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { formatDate } from '@/Utils/dates';
import Edit from './Edit';
import EditTransferOrder from './EditTransferOrder';
import {
  useCancelOrderMutation,
  useGetOrdersQuery,
  useRefundOrderMutation,
} from '@/Api/ordersSlice';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import ExportIcon from '@mui/icons-material/SaveAlt';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import environment from '@/environments/environment';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import {
  SendOutlined,
  EditOutlined,
  MoreVert,
  ReplayOutlined,
  PeopleOutlined,
  CancelOutlined,
  Download,
  SyncAltOutlined,
  CheckCircleOutline,
} from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HTTP_CODE } from '@/Utils/http-client';
import { useSendOrderTicketsMutation } from '@/Api/ordersSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import TransactionDetails from './TransactionDetails';
import useForm from '@/Hooks/useForm';
import { add, addDays, endOfDay, isWithinInterval, startOfDay, subDays } from 'date-fns';
import { DesktopDatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { formatPrice } from '@/Utils/currency';
import { Attendee } from '@/Models/attendees';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import EditAttendee from '@/Pages/Attendees/EditAttendee';
import { useCheckInAttendeeMutation, useResetCheckInAttendeeMutation } from '@/Api/attendeesSlice';
import { showSnackbar } from '@/Slices/snackbarSlice';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { getHeaders } from '@/Utils/http';
import { handleBlobReponse } from '@/Utils/blob';
import { request } from 'http';

const EventOrders = () => {
  const { eventId } = useParams();

  const dispatch = useDispatch();
  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  const [page, setPage] = useState(0);
  const [checkboxSelection, setCheckboxSelection] = useState(true);
  const [pageSize, setPageSize] = useState<number>(20);
  const [search, setSearch] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editTransferOrderDialogOpen, setEditTransferOrderDialogOpen] = useState(false);
  const [resendDialogOpen, setResendDialogOpen] = useState(false);
  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [refundOrderDialogOpen, setRefundOrderDialogOpen] = useState(false);
  const [transactionDialogOpen, setTransactionDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [refundAttendees, setRefundAttendees] = useState<string[]>([]);
  const [attendeesDialogOpen, setAttendeesDialogOpen] = useState(false);

  const [editDialogOpenAttendees, setEditDialogOpenAttendees] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | undefined>(undefined);
  const [checkInDialogOpen, setCheckInDialogOpen] = useState(false);
  const [resetCheckInDialogOpen, setResetCheckInDialogOpen] = useState(false);

  const advancedFetch = useAdvancedFetch();

  const { data, setData, errors, clearErrors } = useForm<{
    starts_at: Date | null;
    ends_at: Date | null;
    [key: string]: any;
  }>({
    starts_at: startOfDay(add(new Date(), { days: 1 })),
    ends_at: endOfDay(add(new Date(), { days: 1 })),
  });

  function getCustomizedDayComponent(
    day: Date,
    _: Date[],
    DayComponentProps: PickersDayProps<Date>,
  ) {
    const startDate = event ? subDays(new Date(event.starts_at), 30) : null;
    const endDate = event ? new Date(event.ends_at) : null;
    const isEventDay = event
      ? isWithinInterval(day, {
          start: startDate!,
          end: endDate!,
        })
      : true;

    return (
      <Badge key={day.toString()} color='success' invisible={!isEventDay} variant='dot'>
        <PickersDay {...DayComponentProps} />
      </Badge>
    );
  }

  function updateData(fieldName: string, value: any) {
    clearErrors(fieldName);
    setData((prevData) => ({ ...prevData, [fieldName]: value }));
  }
  interface Event {
    id: string;
  }

  const handleExport = (event: React.MouseEvent<HTMLButtonElement>, eventObj: Event) => {
    event.preventDefault();
    const fromDate = data.starts_at?.toISOString();
    const toDate = data.ends_at?.toISOString();

    advancedFetch(
      `${environment.baseUrl}/events/${eventObj.id}/orders/exportMonthly?from=${fromDate}&to=${toDate}`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response, {
        handleMethod: 'download',
        fileName: `orders-${eventObj.id}.csv`,
      });
    });
  };
  const { data: orderData, refetch } = useGetOrdersQuery(
    {
      eventId: eventId!,
      filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    order: Order;
  }>(null);

  const [menuContextDialog, setMenuContextDialog] = useState<null | {
    anchor: HTMLElement;
    attendee: Attendee;
  }>(null);

  function onPageChange(page: number) {
    setPage(page);
  }

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };
  function showActionMenu(order: Order, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, order });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  function showActionMenuDIalog(attendee: Attendee, event: React.MouseEvent<HTMLElement>) {
    setMenuContextDialog({ anchor: event.currentTarget, attendee });
  }

  function hideActionMenuDialog() {
    setMenuContextDialog(null);
  }

  function onEditDialogClose(value: boolean) {
    setEditDialogOpen(false);

    if (!value) {
      return;
    }
  }

  function onEditDialogCloseDialog(value: boolean) {
    setEditDialogOpenAttendees(false);
    refetch();
    if (!value) {
      return;
    }
  }

  function onEditDTransferOrderialogClose(value: boolean) {
    setEditTransferOrderDialogOpen(false);

    if (!value) {
      return;
    }
  }

  function onTransactionDialogClose(value: boolean) {
    setTransactionDialogOpen(false);

    if (!value) {
      return;
    }
  }

  const [sendTickets, { isLoading: loading }] = useSendOrderTicketsMutation();

  const resendTickets = () => {
    if (selectedOrder) {
      sendTickets({ eventId: eventId!, orderId: selectedOrder.id })
        .unwrap()
        .then(() => {
          setResendDialogOpen(false);
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const [cancelOrder, { isLoading: loadingCancel }] = useCancelOrderMutation();

  const confirmCancelOrder = () => {
    if (selectedOrder) {
      cancelOrder({ eventId: eventId!, orderId: selectedOrder.id })
        .unwrap()
        .then(() => {
          setCancelOrderDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const [refundOrder, { isLoading: loadingRefund }] = useRefundOrderMutation();

  const confirmRefundOrder = () => {
    if (selectedOrder) {
      refundOrder({ eventId: eventId!, orderId: selectedOrder.id, attendeeIds: refundAttendees })
        .unwrap()
        .then(() => {
          setRefundOrderDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'first_name', headerName: 'First name', width: 130 },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email', width: 230 },
    {
      field: 'referenceNo',
      headerName: 'Reference No.',
      width: 200,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketClick(event!, params.value);
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction ID',
      width: 300,
      renderCell: ({ row }) => (
        <span
          className='underline text-blue-600 cursor-pointer'
          onClick={(event) => {
            event.stopPropagation();
            setSelectedOrder(row);
            setTransactionDialogOpen(true);
          }}
        >
          {row.transaction_id}
        </span>
      ),
    },
    {
      field: 'discounted',
      headerName: 'Amount',
      type: 'number',
      width: 90,
      renderCell: (params) => {
        return `${event && formatPrice(params.value, event.currency)}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      width: 120,
      sortable: false,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 220,
      valueFormatter: (cell) => formatDate(cell.value),
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellClassName: 'sticky-actions-column',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageOrder}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenu(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  async function onExportClick(event: Event) {
    advancedFetch(`${environment.baseUrl}/events/${event.id}/orders/export?download=1`, {
      method: 'GET',
    }).then((response) => {
      handleBlobReponse(response, {
        handleMethod: 'download',
        fileName: `orders-${event.id}.csv`,
      });
    });
  }

  async function onTicketClick(event: Event, referenceNo: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${event.id}/orders/${referenceNo}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response);
    });
  }

  function onTicketAttendeeClick(event: Event, barcode: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${event.id}/attendees/${barcode}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response);
    });
  }

  function onDownloadClick(event: Event, referenceNo: string | undefined) {
    advancedFetch(
      `${environment.baseUrl}/events/${event.id}/orders/${referenceNo}/invoice?download=1`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response);
    });
  }

  const [checkIn, { isLoading: loadingCheckIn }] = useCheckInAttendeeMutation();
  const confirmCheckIn = () => {
    if (selectedAttendee) {
      checkIn({
        eventId: eventId!,
        attendeeId: selectedAttendee.id,
      })
        .unwrap()
        .then(() => {
          setCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const [resetCheckIn, { isLoading: loadingResetCheckIn }] = useResetCheckInAttendeeMutation();
  const confirmResetCheckIn = () => {
    if (selectedAttendee) {
      resetCheckIn({
        eventId: eventId!,
        attendeeId: selectedAttendee.id,
      })
        .unwrap()
        .then(() => {
          setResetCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const columnsAttendees: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'First name',
      width: 100,
      sortable: false,
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      width: 100,
      sortable: false,
    },
    { field: 'email', headerName: 'Email', width: 230, sortable: false },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 130,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketAttendeeClick(event!, params.value);
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'arrived',
      headerName: 'Arrived',
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <div
              className='w-2 h-2 rounded-full'
              style={{
                background: row.arrived ? 'rgb(50 225 121)' : 'rgba(0, 0, 0, 0.47)',
              }}
            ></div>
            <span>{row.arrived ? 'Yes' : 'No'}</span>
          </Stack>
        );
      },
      sortable: false,
    },
    {
      field: 'arrived_at',
      width: 220,
      headerName: 'Arrived at',
      renderCell: ({ row }) => (
        <EmptyCell condition={row.arrived}>
          <div>{formatDate(row.arrived_at)}</div>
        </EmptyCell>
      ),
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageOrder}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenuDIalog(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  const handleAttendeeUpdate = (updatedAttendee: Attendee) => {
    setSelectedOrder((prevSelectedOrder: any) => {
      if (prevSelectedOrder) {
        const updatedAttendees = prevSelectedOrder.attendees.map((attendee: any) =>
          attendee.id === updatedAttendee.id ? updatedAttendee : attendee,
        );
        return { ...prevSelectedOrder, attendees: updatedAttendees };
      }
      return prevSelectedOrder;
    });
  };

  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <div className='h-full'>
            <div className='mx-auto h-full flex flex-col'>
              <div className='mb-4'>
                <Typography>Options:</Typography>
                <div className='text-xs flex flex-col justify-between lg:flex lg:flex-row '>
                  <Button
                    onClick={() => setCheckboxSelection(!checkboxSelection)}
                    startIcon={<CompareArrowsIcon />}
                  >
                    Toggle checkbox selection
                  </Button>
                  <div className='lg:flex'>
                    <div className=' flex mt-4 flex-col gap-4 h-full lg:flex lg:flex-row lg:mr-7 lg:mt-2 '>
                      <Grid item>
                        <DesktopDatePicker
                          label='Starts at'
                          inputFormat='MM/dd/yyyy'
                          value={data.starts_at}
                          onChange={(value) => updateData('starts_at', value)}
                          renderDay={getCustomizedDayComponent}
                          renderInput={function (
                            props: TextFieldProps,
                          ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                            return (
                              <TextField
                                {...props}
                                required
                                id='startsAt'
                                name='startsAt'
                                label='Starts At'
                                error={!!errors.starts_at}
                                helperText={errors.starts_at?.[0]}
                                fullWidth
                              />
                            );
                          }}
                          minDate={addDays(new Date(), -30)}
                          maxDate={new Date()}
                        />
                      </Grid>
                      <Grid item>
                        <DesktopDatePicker
                          label='Ends at'
                          inputFormat='MM/dd/yyyy'
                          value={data.ends_at}
                          onChange={(value) => updateData('ends_at', value)}
                          renderDay={getCustomizedDayComponent}
                          renderInput={function (
                            props: TextFieldProps,
                          ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                            return (
                              <TextField
                                {...props}
                                required
                                id='endsAt'
                                name='endsAt'
                                label='Ends At'
                                error={!!errors.ends_at}
                                helperText={errors.ends_at?.[0]}
                                fullWidth
                              />
                            );
                          }}
                          minDate={data.starts_at ? new Date(data.starts_at) : undefined}
                          maxDate={new Date()}
                        />
                      </Grid>
                      <Grid className='lg:mt-2' minWidth={110}>
                        <Button
                          className='w-full'
                          variant='contained'
                          color='primary'
                          onClick={(event) => {
                            if (eventId) {
                              handleExport(event, { id: eventId });
                            }
                          }}
                          startIcon={<ExportIcon />}
                        >
                          Export By Dates
                        </Button>
                      </Grid>
                    </div>
                    <Grid marginTop={2}>
                      <Button
                        className='w-full'
                        onClick={() => {
                          onExportClick(event);
                        }}
                        variant='contained'
                        startIcon={<ExportIcon />}
                      >
                        Export All
                      </Button>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className='sm:rounded-lg grow'>
                {orderData ? (
                  <DataGrid
                    autoHeight
                    rows={orderData.data}
                    columns={columns}
                    paginationMode='server'
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    onPageChange={onPageChange}
                    rowCount={orderData.total}
                    checkboxSelection={checkboxSelection}
                    components={{ Toolbar: GridToolbar }}
                    filterMode='server'
                    onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          debounceMs: 1000,
                        },
                      },
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            {editDialogOpen && selectedOrder ? (
              <Edit
                eventId={event.id}
                order={selectedOrder}
                open={editDialogOpen}
                onDialogClose={(value) => onEditDialogClose(value)}
              />
            ) : (
              ''
            )}

            {transactionDialogOpen && selectedOrder ? (
              <TransactionDetails
                event={event}
                order={selectedOrder}
                open={transactionDialogOpen}
                onDialogClose={(value) => onTransactionDialogClose(value)}
              />
            ) : (
              ''
            )}

            {resendDialogOpen && selectedOrder ? (
              <Dialog
                open={resendDialogOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  Are you sure you want to resend these tickets?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Please make sure if the email is correct before sending the tickets to the
                    Order's email
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setResendDialogOpen(false)}>Cancel</Button>
                  <LoadingButton loading={loading}>
                    <Button onClick={resendTickets} disabled={loading}>
                      Send
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {cancelOrderDialogOpen && selectedOrder ? (
              <Dialog
                open={cancelOrderDialogOpen}
                // onDialogClose={(value) => onResendDialogClose(value)}
                aria-labelledby='cancel-dialog-title'
                aria-describedby='cancel-dialog-description'
              >
                <DialogTitle id='cancel-dialog-title'>
                  Are you sure you want to cancel this order?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='cancel-dialog-description'>
                    Keep in mind that this action has no easy way to be undone. Please be sure that
                    you want to cancel this order.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setCancelOrderDialogOpen(false)} color='success'>
                    Close
                  </Button>
                  <LoadingButton loading={loadingCancel}>
                    <Button onClick={confirmCancelOrder} disabled={loadingCancel} color='error'>
                      Confirm Cancel
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {refundOrderDialogOpen && selectedOrder ? (
              <Dialog
                open={refundOrderDialogOpen}
                aria-labelledby='refund-dialog-title'
                aria-describedby='refund-dialog-description'
              >
                <DialogTitle id='refund-dialog-title'>
                  Which attendees would you like to refund?
                </DialogTitle>
                <DialogContent>
                  <List>
                    {selectedOrder.attendees?.map((attendee: Attendee) => (
                      <ListItem key={attendee.id}>
                        <ListItemText
                          primary={`${attendee.first_name} ${attendee.last_name}`}
                          secondary={`Price: ${formatPrice(attendee.paid_price, event.currency)}`}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge='end'
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (checked) {
                                setRefundAttendees((prev) => [...prev, attendee.id]);
                              } else {
                                setRefundAttendees((prev) => prev.filter((a) => a !== attendee.id));
                              }
                            }}
                            checked={refundAttendees.includes(attendee.id)}
                            disabled={attendee.status === 'refunded'}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setRefundOrderDialogOpen(false)} color='success'>
                    Close
                  </Button>
                  <LoadingButton loading={loadingRefund}>
                    <Button
                      onClick={confirmRefundOrder}
                      disabled={loadingRefund || refundAttendees.length === 0}
                      color='error'
                    >
                      Confirm Refund
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {editTransferOrderDialogOpen && selectedOrder ? (
              <EditTransferOrder
                eventId={event.id}
                order={selectedOrder}
                open={editTransferOrderDialogOpen}
                onDialogClose={(value) => onEditDTransferOrderialogClose(value)}
              />
            ) : (
              ''
            )}

            {attendeesDialogOpen && selectedOrder ? (
              <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={attendeesDialogOpen}
                aria-labelledby='attendees-dialog-title'
                aria-describedby='attendees-dialog-description'
                onClose={() => setAttendeesDialogOpen(false)}
              >
                <DialogTitle>Order attendees</DialogTitle>
                <DialogContent style={{ width: '100%', height: '900px' }}>
                  {selectedOrder.attendees ? (
                    <>
                      <DataGrid rows={selectedOrder.attendees} columns={columnsAttendees} />

                      {editDialogOpenAttendees && selectedAttendee ? (
                        <EditAttendee
                          eventId={event.id}
                          attendee={selectedAttendee}
                          open={editDialogOpenAttendees}
                          onDialogClose={(value) => onEditDialogCloseDialog(value)}
                          onAttendeeUpdate={handleAttendeeUpdate}
                        />
                      ) : (
                        ''
                      )}

                      {checkInDialogOpen && selectedAttendee ? (
                        <Dialog
                          open={checkInDialogOpen}
                          aria-labelledby='check-in-dialog-title'
                          aria-describedby='check-in-dialog-description'
                        >
                          <DialogTitle id='check-in-dialog-title'>
                            Are you sure you want to check-in this attendee?
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id='check-in-dialog-description'>
                              Keep in mind that this action could affect the on-site check-in
                              process. Please be sure that you want to make this action.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setCheckInDialogOpen(false)} color='success'>
                              Close
                            </Button>
                            <LoadingButton loading={loadingCheckIn}>
                              <Button
                                onClick={confirmCheckIn}
                                disabled={loadingCheckIn}
                                color='error'
                              >
                                Confirm Check-in
                              </Button>
                            </LoadingButton>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ''
                      )}

                      {resetCheckInDialogOpen && selectedAttendee ? (
                        <Dialog
                          open={resetCheckInDialogOpen}
                          aria-labelledby='reset-check-in-dialog-title'
                          aria-describedby='reset-check-in-dialog-description'
                        >
                          <DialogTitle id='reset-check-in-dialog-title'>
                            Are you sure you want to reset check-in for this attendee?
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id='reset-check-in-dialog-description'>
                              Keep in mind that this action could affect the on-site check-in
                              process. Please be sure that you want to make this action.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => setResetCheckInDialogOpen(false)}
                              color='success'
                            >
                              Close
                            </Button>
                            <LoadingButton loading={loadingCheckIn}>
                              <Button
                                onClick={confirmResetCheckIn}
                                disabled={loadingResetCheckIn}
                                color='error'
                              >
                                Confirm Reset Check-in
                              </Button>
                            </LoadingButton>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ''
                      )}

                      {menuContextDialog && (
                        <Menu
                          anchorEl={menuContextDialog?.anchor}
                          open={!!menuContextDialog}
                          onClose={hideActionMenuDialog}
                        >
                          <MenuItem
                            onClick={() => {
                              setEditDialogOpenAttendees(true);
                              setSelectedAttendee(menuContextDialog.attendee);
                              hideActionMenuDialog();
                            }}
                          >
                            <ListItemIcon>
                              <EditOutlined />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              setCheckInDialogOpen(true);
                              setSelectedAttendee(menuContextDialog.attendee);
                              hideActionMenuDialog();
                            }}
                          >
                            <ListItemIcon>
                              <CheckCircleOutline />
                            </ListItemIcon>
                            <ListItemText>Check-in</ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              setResetCheckInDialogOpen(true);
                              setSelectedAttendee(menuContextDialog.attendee);
                              hideActionMenuDialog();
                            }}
                          >
                            <ListItemIcon>
                              <ReplayOutlined color='error' />
                            </ListItemIcon>
                            <ListItemText>Reset check-in</ListItemText>
                          </MenuItem>
                        </Menu>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttendeesDialogOpen(false)} color='info'>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {menuContext && (
              <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
                <MenuItem
                  onClick={() => {
                    setEditDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <EditOutlined />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setAttendeesDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <PeopleOutlined />
                  </ListItemIcon>
                  <ListItemText>Attendees</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setResendDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <SendOutlined />
                  </ListItemIcon>
                  <ListItemText>Resend Tickets</ListItemText>
                </MenuItem>

                <PermissionsGate scope={SCOPES.ManageOrganization}>
                  <MenuItem
                    onClick={() => {
                      setCancelOrderDialogOpen(true);
                      setSelectedOrder(menuContext.order);
                      hideActionMenu();
                    }}
                  >
                    <ListItemIcon>
                      <CancelOutlined color='error' />
                    </ListItemIcon>
                    <ListItemText>Cancel Order</ListItemText>
                  </MenuItem>
                </PermissionsGate>

                <MenuItem
                  onClick={() => {
                    setRefundOrderDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <ReplayOutlined color='error' />
                  </ListItemIcon>
                  <ListItemText>Refund</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    const selectDown = menuContext.order;
                    hideActionMenu();
                    onDownloadClick(event!, selectDown?.referenceNo);
                  }}
                >
                  <ListItemIcon>
                    <Download />
                  </ListItemIcon>
                  <ListItemText>Invoice</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setEditTransferOrderDialogOpen(true);
                    setSelectedOrder(menuContext.order);
                    hideActionMenu();
                  }}
                  disabled={menuContext.order?.event?.seating_enabled}
                >
                  <ListItemIcon>
                    <SyncAltOutlined />
                  </ListItemIcon>
                  <ListItemText>Transfer Order</ListItemText>
                </MenuItem>
              </Menu>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EventOrders;
