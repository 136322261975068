import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Article, ArticleData } from '@/Models/articles';

export const articlesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<ArticleData, FilterData<'upcoming'>>({
      query: (filterData) => ({
        url: `/articles`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getArticle: builder.query<Article, string>({
      query: (articleId) => ({
        url: `/articles/${articleId}`,
        method: 'GET',
      }),
    }),
    createArticle: builder.mutation<Article, any>({
      query: (body) => ({
        url: `/articles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    updateArticle: builder.mutation<Article, { articleId: String; body: any }>({
      query: ({ articleId, body }) => ({
        url: `/articles/${articleId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    deleteArticle: builder.mutation<any, string>({
      query: (articleId) => ({
        url: `/articles/${articleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  useCreateArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
} = articlesApi;
