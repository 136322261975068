import { TransactionExtraData } from './transactions';

export enum DataKey {
  TRANSACTION = 'transaction',
  TENANT_ID = 'tenant-id',
  TRANSACTION_EXTRA = 'transaction-extra',
}

export interface LocalTransactionData {
  transactionId: string;
  eventId: string;
  expiresAt: string;
  totalPrice: number;
  tickets: Record<string, number>;
}

export type LocalDataMap = {
  [DataKey.TRANSACTION]: LocalTransactionData;
  [DataKey.TENANT_ID]: string;
  [DataKey.TRANSACTION_EXTRA]: TransactionExtraData;
};
