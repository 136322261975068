import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Pagination,
} from '@mui/material';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { useDeleteEventFaqMutation, useGetEventFaqsQuery } from '@/Api/eventFaqsSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EventFaq } from '@/Models/eventFaqs';

const EventFaqs = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [faqToDelete, setFaqToDelete] = useState<{ eventId: string; eventFaqId: string } | null>(
    null,
  );

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Event FAQ'));
    dispatch(
      setHeader({
        title: 'Event FAQ',
      }),
    );
  }, []);

  const { data: faqs, refetch } = useGetEventFaqsQuery(
    {
      eventId: eventId!,
      filterData: { pageData: { page, pageSize } },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !eventId,
    },
  );

  const [deleteEventFaq] = useDeleteEventFaqMutation();

  const onDialogClose = (value: boolean) => {
    setDialogOpen(false);
    if (value && faqToDelete) {
      deleteEventFaq(faqToDelete);
    }
    refetch();
  };

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    faq: EventFaq;
  }>(null);

  const onEditClick = (
    $faq: React.MouseEvent<HTMLDivElement, MouseEvent>,
    eventId: string,
    eventFaqId: string,
  ) => {
    $faq.preventDefault();
    navigate(`/events/${eventId}/faqs/${eventFaqId}/edit`);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  function showActionMenu(faq: EventFaq, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, faq });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <PermissionsGate className='' scope={SCOPES.ManageOrganization}>
              <Link to={`/events/${eventId}/faqs/create`}>
                <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                  Create FAQ's
                </Button>
              </Link>
            </PermissionsGate>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {faqs && faqs.data && faqs.data.length > 0 && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={4} paddingBottom={4}>
                  {faqs.data.map((faq) => (
                    <Accordion key={faq.id} sx={{ boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          backgroundColor: 'rgba(0,0,0,0.02)',
                          borderBottom: '1px solid rgba(0,0,0,0.08)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant='h6' fontWeight='bold' sx={{ flexGrow: 1 }}>
                          {faq.question}
                        </Typography>

                        <Box className='flex flex-row justify-end'>
                          <PermissionsGate scope={SCOPES.ManageOrganization}>
                            <IconButton
                              component='div'
                              onClick={($faq) => onEditClick($faq, eventId as string, faq.id)}
                              sx={{ '&:hover': { backgroundColor: 'rgba(0,0,255,0.1)' } }}
                            >
                              <EditOutlined color='primary' />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                console.log('Clicked FAQ ID:', faq.id);
                                setDialogOpen(true);
                                setFaqToDelete({ eventId: eventId!, eventFaqId: faq.id });
                                showActionMenu(faq, event);
                                hideActionMenu();
                              }}
                              sx={{ '&:hover': { backgroundColor: 'rgba(255,0,0,0.1)' } }}
                            >
                              <DeleteOutline color='error' />
                            </IconButton>
                            <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />
                          </PermissionsGate>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant='body1'>{faq.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={faqs.current_page}
                    count={faqs.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>

      <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />
    </>
  );
};

export default EventFaqs;
