import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import { HTTP_CODE } from '@/Utils/http-client';
import useLoading from '@/Hooks/useLoading';
import { cleanObject } from '@/Utils/general';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { ParkingSpacesForm, parkingSpacesFormSchema } from './form-data';
import {
  useCreateParkingSpaceMutation,
  useGetParkingSpaceQuery,
  useUpdateParkingSpaceMutation,
} from '@/Api/parkingSpacesSlice';
import { ChromePicker } from 'react-color';
import CircleIcon from '@mui/icons-material/Circle';
import { useGetEventQuery } from '@/Api/eventsSlice';
import Dinero from 'dinero.js';
import ReactQuill from 'react-quill';
import { PreviewData } from '@/Models/preview';
import { ResourceType, FileContext } from '@/Enums/files';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { ArticleForm } from '../Articles/form-data';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
const fileKeys = ['image'] as const;
type FileKey = typeof fileKeys[number];

const CreateOrEditParkingSpaces = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { parkingSpacesId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();
  const [fileQueue, setFileQueue] = useState<{ key: FileKey; file: File }[]>([]);

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [createParkingSpace] = useCreateParkingSpaceMutation();
  const [updateParkingSpace] = useUpdateParkingSpaceMutation();

  const [anchorPrimary, setAnchorPrimary] = useState<HTMLButtonElement | null>(null);
  const handleClickPrimary = (event: any) => {
    setAnchorPrimary(event.currentTarget);
  };
  const handleClosePrimary = () => {
    setAnchorPrimary(null);
  };
  const openPrimary = Boolean(anchorPrimary);
  const idPrimary = openPrimary ? 'primary-popover' : undefined;

  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickColorPicker = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
    setAnchorEl(null);
  };

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const { data: parkingSpace } = useGetParkingSpaceQuery(
    {
      eventId: eventId!,
      parkingSpacesId: parkingSpacesId!,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !parkingSpacesId,
    },
  );

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<ParkingSpacesForm>({
    defaultValues: {
      event_id: eventId || '',
      name: '',
      description: '',
      price: 10,
      allocated: 1,
      image: '',
    },
    resolver: zodResolver(parkingSpacesFormSchema),
  });

  useEffect(() => {
    if (!parkingSpace) {
      return;
    }
    reset({
      ...cleanObject(parkingSpace),
    });
  }, [parkingSpace]);

  useEffect(() => {
    if (!!parkingSpace && !!event) {
      setValue('price', Dinero({ amount: parkingSpace.price, currency: event.currency }).toUnit());
    }
  }, [parkingSpace, event]);

  const imagePreviews: {
    image?: PreviewData;
  } = useMemo(() => {
    return {
      image: parkingSpace && { type: 'image', url: parkingSpace.image },
    };
  }, [parkingSpace]);

  function getTitle() {
    return `${!!parkingSpacesId ? 'Edit' : 'Create'}  Parking Spaces `;
  }

  function addFileInQueue(key: FileKey, file: File) {
    clearErrors(key);
    setValue(key, file);
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }

  async function processFiles(): Promise<Pick<ParkingSpacesForm, FileKey>> {
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.PARKINGSPACES,
        eventId: eventId!,
      })),
    );

    const parkingSpaceData: Pick<ParkingSpacesForm, FileKey> = { image: '' };
    signedUrlPayloads.forEach((payload, i) => {
      parkingSpaceData[fileData[i].key] = payload.fields.key;
      setValue(fileData[i].key, payload.fields.key);
    });
    setFileQueue([]);

    return parkingSpaceData;
  }

  function validateImages(data: ParkingSpacesForm): boolean {
    const notUploadedImages = fileKeys.filter((key) => !data[key]);

    notUploadedImages.forEach((key) => {
      setError(key, {
        type: 'manual',
        message: 'Please upload an image first',
      });
    });

    return !notUploadedImages.length;
  }

  async function onSubmit() {
    let parkingSpacesData = { ...getValues() };

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles());
      parkingSpacesData = { ...parkingSpacesData, ...processedFiles };
    }

    if (!validateImages(parkingSpacesData)) {
      return;
    }

    if (!eventId) {
      return;
    }

    const promise = !!parkingSpacesId
      ? updateParkingSpace({ eventId, parkingSpacesId, body: parkingSpacesData })
      : createParkingSpace({ eventId, body: parkingSpacesData });

    withLoading(promise.unwrap())
      .then(() => {
        navigate(`/events/${eventId}/parking-spaces`);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};
        }
      });
  }

  const { isMobile } = useResponsive();

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the cause
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Name'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='description'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <ReactQuill
                          {...field}
                          value={field.value || ''}
                          onChange={field.onChange}
                          theme='snow'
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='price'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          type='number'
                          {...field}
                          required
                          label='Price'
                          variant='outlined'
                          fullWidth
                          inputProps={{ min: 0, step: 0.0001 }}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='allocated'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          type='number'
                          {...field}
                          required
                          label='Allocated'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                          inputProps={{ min: 0 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Image*'
                        allowedFileTypes={allowedFileTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('image', file)}
                        preview
                        previewData={imagePreviews.image}
                        helperText='Recommended size: 1280x720px'
                      />
                      {errors['image'] && (
                        <FormHelperText className='!ml-0'>
                          Please upload an image first
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEditParkingSpaces;
