import React from 'react';
import { Event } from '@/Models/events';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrivalsOverview from '@/Components/Molecules/ArrivalsOverview/ArrivalsOverview';
import ArrivalsDaily from '@/Components/Molecules/ArrivalsDaily/ArrivalsDaily';
import ArrivalsHourly from '@/Components/Molecules/ArrivalsHourly/ArrivalsHourly';
import { useGetAttendeesStatsQuery } from '@/Api/attendeesSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useResponsive } from '@/Hooks/useResponsive';
import { useGetScanGroupStatsQuery } from '@/Api/scanGroupsSlice';
import { ScanGroup } from '@/Models/scanGroups';
import ArrivalsScanGroupTabs from '../ArrivalsScanGroupTabs/ArrivalsScanGroupTabs';
import { Grid } from '@mui/material';

export interface ArrivalsScanGroupProps {
  scanGroup: ScanGroup;
}

const ArrivalsScanGroup = ({ scanGroup }: ArrivalsScanGroupProps) => {
  const { data: stats } = useGetScanGroupStatsQuery(
    {
      scanGroupId: scanGroup.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <Grid container={true} spacing={2} marginTop={0}>
      <Grid item xs={12} md={6} height={450}>
        <ArrivalsScanGroupTabs stats={stats} model="attendees" />
      </Grid>
      <Grid item xs={12} md={6} height={450}>
        <ArrivalsScanGroupTabs stats={stats} model="guests" />
      </Grid>
    </Grid>
  );
};

export default ArrivalsScanGroup;
