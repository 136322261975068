import {
  EditorEventService,
  VenueEditorService,
  SectionIdentifierService,
  SeatIdentifierService,
  StylingService,
} from '@festtix/venue-editor';
import React, { PropsWithChildren, useMemo } from 'react';

export interface VenueEditorContextData {
  editorEventService: EditorEventService;
  venueEditorService: VenueEditorService;
  sectionIdentifierService: SectionIdentifierService;
  seatIdentifierService: SeatIdentifierService;
}

const editorEventService = new EditorEventService();
const venueEditorService = new VenueEditorService(editorEventService);
const sectionIdentifierService = new SectionIdentifierService();
const seatIdentifierService = new SeatIdentifierService();

export const VenueEditorContext = React.createContext<VenueEditorContextData>({
  editorEventService,
  venueEditorService,
  sectionIdentifierService,
  seatIdentifierService,
});

type VenueEditorContextProviderProps = Partial<VenueEditorContextData>;

const VenueEditorContextProvider = ({
  children,
  editorEventService,
  venueEditorService,
  sectionIdentifierService,
  seatIdentifierService,
}: PropsWithChildren<VenueEditorContextProviderProps>) => {
  const services = useMemo(() => {
    editorEventService ??= new EditorEventService();
    venueEditorService ??= new VenueEditorService(editorEventService);
    sectionIdentifierService ??= new SectionIdentifierService();
    seatIdentifierService ??= new SeatIdentifierService();

    // TODO SVG: fix this; it looks like it is not used anywhere, but it initializes some important listeners
    const stylingService = new StylingService(
      venueEditorService,
      seatIdentifierService,
      sectionIdentifierService,
      editorEventService,
    );

    return {
      editorEventService,
      venueEditorService,
      sectionIdentifierService,
      seatIdentifierService,
    };
  }, []);

  return (
    <VenueEditorContext.Provider
      value={{
        ...services,
      }}
    >
      {children}
    </VenueEditorContext.Provider>
  );
};

export default VenueEditorContextProvider;
