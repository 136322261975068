import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  Box,
  CardActionArea,
} from '@mui/material';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDeleteScanGroupMutation, useGetScanGroupsQuery } from '@/Api/scanGroupsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';

const Index = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [scanGroupToDelete, setScanGroupToDelete] = useState<string>();

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Scan Groups'));
    dispatch(
      setHeader({
        title: 'Scan Groups',
      }),
    );
  }, [dispatch]);

  const { data: scanGroups } = useGetScanGroupsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );
  const [deleteScanGroup] = useDeleteScanGroupMutation();

  const onDialogClose = (value: boolean) => {
    setOpen(false);
    if (value && scanGroupToDelete) {
      deleteScanGroup(scanGroupToDelete);
    }
    setScanGroupToDelete(undefined);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditClick = (
    $scanGroup: React.MouseEvent<HTMLDivElement, MouseEvent>,
    scanGroupId: string,
  ) => {
    $scanGroup.preventDefault();
    navigate(`/scan-groups/${scanGroupId}/edit`);
  };

  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <PermissionsGate className='' scope={SCOPES.CreateEvent}>
              <Link to={`/scan-groups/create`}>
                <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                  Create Scan Group
                </Button>
              </Link>
            </PermissionsGate>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {scanGroups && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {scanGroups.data.map((scanGroup) => (
                    <Link to={`/scan-groups/${scanGroup.id}`} key={scanGroup.id}>
                      <Card sx={{ display: 'flex', overflow: 'auto' }} key={scanGroup.id}>
                        <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
                          <Stack width='100%' divider={<Divider />}>
                            <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                              <Stack flex={1} maxWidth='600px'>
                                <Typography variant='h6' noWrap>
                                  {scanGroup.name}
                                </Typography>
                              </Stack>

                              <Box className='flex flex-row justify-center !ml-auto'>
                                <PermissionsGate scope={SCOPES.UpdateEvent}>
                                  <IconButton
                                    sx={{ margin: 'auto 0' }}
                                    component='div'
                                    onClick={($scanGroup) => onEditClick($scanGroup, scanGroup.id)}
                                  >
                                    <EditOutlined />
                                  </IconButton>
                                </PermissionsGate>

                                <PermissionsGate scope={SCOPES.DeleteEvent}>
                                  <IconButton
                                    sx={{ margin: 'auto 0' }}
                                    component='div'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpen(true);
                                      setScanGroupToDelete(scanGroup.id);
                                    }}
                                    color='error'
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </PermissionsGate>
                              </Box>
                            </Stack>
                          </Stack>
                        </CardActionArea>
                      </Card>
                    </Link>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={scanGroups.current_page}
                    count={scanGroups.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>

      <DeleteDialog open={open} onDialogClose={onDialogClose} />
    </>
  );
};

export default Index;
