import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import CardSelection, { CardItem } from '@/Components/Organisms/CardSelection/CardSelection';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { add, max } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_CSV_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { codeGenerationFormKeys, getSchema, VouchersForm } from './voucher-helpers';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import { cleanObject, getFormDataFromObject } from '@/Utils/general';
import { useNavigate, useParams } from 'react-router-dom';
import { HTTP_CODE } from '@/Utils/http-client';
import TemplateDownload from '@/Components/Molecules/TemplateDownload/TemplateDownload';
import environment from '@/environments/environment';
import {
  useCreateVoucherGroupMutation,
  useGetVoucherGroupQuery,
  useUpdateVoucherGroupMutation,
} from '@/Api/promocodesSlice';
import { codeGenerationMethods, GenerationMethod } from '../helpers';
// import { EVENT_TAB } from '@/Pages/Events/Event/constants';
import { RowError } from '@/Models/csv';
import { humanizeArray } from '@/Utils/string';
import { useGetTicketsQuery } from '@/Api/ticketsSlice';
import useLoading from '@/Hooks/useLoading';
import { useDispatch } from 'react-redux';
import { showPageRefreshSnackbar, showSnackbar } from '@/Slices/snackbarSlice';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { formatTimeZoneShort } from '@/Utils/dates';

const allowedFileTypes = ['text/csv'];

const CreateVouchers = () => {
  const { eventId, voucherGroupId } = useParams();
  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const [isLoading, withLoading] = useLoading();
  const dispatch = useDispatch();

  const isEditing = !!voucherGroupId;

  const [createVoucherGroup] = useCreateVoucherGroupMutation();
  const [updateVoucherGroup] = useUpdateVoucherGroupMutation();

  const { data: voucherGroup, isFetching: isFetchingGroup } = useGetVoucherGroupQuery(
    {
      eventId: eventId!,
      voucherGroupId: voucherGroupId!,
    },
    {
      skip: !voucherGroupId,
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: tickets, isFetching: isFetchingTickets } = useGetTicketsQuery(
    {
      eventId: eventId!,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, [event]);

  const navigate = useNavigate();

  const [formFieldsVisibilityMap, setFormFieldsVisibilityMap] = useState<{
    [key in 'expires_at' | 'allocated']: boolean;
  }>({ expires_at: false, allocated: false });

  const vouchersFormSchema = useMemo(() => {
    return getSchema(voucherGroup);
  }, [voucherGroup]);

  const {
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    unregister,
    handleSubmit,
  } = useForm<VouchersForm>({
    defaultValues: {
      name: 'Test' + Math.floor(Math.random() * 1000), // TODO: remove this
      discount_percentage: 5,
      generation_method: undefined,
      tickets_eligible_for_voucher_discount: [],
      ...(isEditing ? {} : { generation_method: GenerationMethod.GENERATE }),
    },
    resolver: zodResolver(vouchersFormSchema),
  });

  useEffect(() => {
    if (!!voucherGroup) {
      const transformedVoucherGroup = {
        ...cleanObject(voucherGroup),
        tickets_eligible_for_voucher_discount:
          voucherGroup.tickets_eligible_for_voucher_discount.map((t) => t.id),
      };
      const transformedData = vouchersFormSchema.safeParse(transformedVoucherGroup);

      if (transformedData.success) {
        reset(transformedData.data);
        const hiddenFields = ['expires_at', 'allocated'] as const;
        hiddenFields
          .filter((field) => !!transformedData.data[field])
          .forEach((field) => {
            toggleField(field);
          });
      } else {
        dispatch(showPageRefreshSnackbar());
      }
    }
  }, [voucherGroup]);

  function getExpiresAtMinDate() {
    if (isEditing && !!voucherGroup) {
      return max([add(new Date(), { hours: 1 }), new Date(voucherGroup.expires_at || '')]);
    }

    return add(new Date(), { hours: 1 });
  }

  function toggleField(fieldKey: 'expires_at' | 'allocated') {
    setFormFieldsVisibilityMap((prevState) => {
      const currVal = formFieldsVisibilityMap[fieldKey];
      if (currVal) {
        unregister(fieldKey, { keepDefaultValue: true });
      }

      return {
        ...prevState,
        [fieldKey]: !currVal,
      };
    });
  }

  function onMethodChange(item: CardItem | undefined) {
    const currMethod = getValues('generation_method');
    if (currMethod) {
      codeGenerationFormKeys[currMethod].forEach((fk) => {
        unregister(fk, { keepDefaultValue: true });
      });
    }

    setValue('generation_method', item?.label as GenerationMethod, {
      shouldValidate: true,
    });
  }

  function onFileUpload(file: File) {
    setValue('csv_file', file);
    clearErrors('csv_file');
  }

  const onSubmit: SubmitHandler<VouchersForm> = async (formData) => {
    let data: any =
      selectedMethod === GenerationMethod.IMPORT_CSV ? getFormDataFromObject(formData) : formData;

    const promise = isEditing
      ? updateVoucherGroup({ eventId: eventId!, voucherGroupId: voucherGroup!.id, data })
      : createVoucherGroup({ eventId: eventId!, data });

    withLoading(promise.unwrap())
      .then(() => {
        navigate(`/events/${eventId}/promocodes`);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          if (!!errors[CustomZodErrorType.CSV_ERRORS]) {
            const invalidCodes = humanizeArray<RowError<'code'>>(
              errors[CustomZodErrorType.CSV_ERRORS],
              (el) => el.rowData.code,
            );

            setError('csv_file', {
              message: `Some of the codes in the file are invalid: ${invalidCodes}.`,
              type: CustomZodErrorType.CSV_ERRORS,
            });
          } else {
            Object.keys(errors).forEach((key) => {
              setError(key as keyof typeof formData, {
                message: errors[key]?.[0],
                type: 'validate',
              });
            });
          }
        }
      });
  };

  function getTitle() {
    return isEditing ? 'Edit Voucher Group' : 'Create Vouchers';
  }

  const selectedMethod = getValues('generation_method');

  const selectedCardItem = useMemo(() => {
    return codeGenerationMethods.find((cgm) => cgm.label === selectedMethod);
  }, [selectedMethod]);
  const { isMobile } = useResponsive();
  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form name='createForm' onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack spacing={4} divider={<Divider />}>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 flex-shrink-0 ml-2 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>General</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Provide essential information about the vouchers
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        name='name'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            required
                            id='name'
                            label='Name'
                            error={!!error}
                            helperText={error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name='discount_percentage'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            required
                            {...field}
                            id='discount_percentage'
                            label='Discount Percentage'
                            type='number'
                            error={!!error}
                            helperText={error?.message}
                            inputProps={{ min: 1, max: 100 }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name='tickets_eligible_for_voucher_discount'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            select
                            SelectProps={{
                              multiple: true,
                              renderValue: (selectedTicketIds) => {
                                if (!tickets) {
                                  return '';
                                }

                                return (selectedTicketIds as string[])
                                  .map((ticketId) => tickets.find((t) => t.id == ticketId)?.title)
                                  .join(', ');
                              },
                            }}
                            id='tickets'
                            label='Tickets eligible for voucher discounts'
                            error={!!error}
                            helperText={error?.message}
                            fullWidth
                          >
                            {isFetchingTickets
                              ? []
                              : tickets?.map(({ id, title }) => (
                                  <MenuItem key={id} value={`${id}`}>
                                    <Checkbox checked={field.value.includes(`${id}`)} />
                                    <ListItemText primary={title} />
                                  </MenuItem>
                                ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {formFieldsVisibilityMap['expires_at'] ? (
                        <Stack spacing={1} alignItems='flex-start'>
                          <Controller
                            name='expires_at'
                            defaultValue={add(new Date(), { hours: 48 })}
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState: { error } }) => (
                              <DesktopDateTimePicker
                                {...field}
                                label='Expires at'
                                inputFormat='MM/dd/yyyy HH:mm'
                                minDateTime={getExpiresAtMinDate()}
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    id='expires_at'
                                    label={`Expires at (${formatTimeZoneShort(event?.timezone)})`}
                                    error={!!error}
                                    helperText={
                                      error?.message ||
                                      (isEditing
                                        ? 'You can only extend the already set expiry date'
                                        : 'The minimum expiry date is one hour from now')
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          />
                          <Button startIcon={<Remove />} onClick={() => toggleField('expires_at')}>
                            Remove Expiry Date
                          </Button>
                        </Stack>
                      ) : (
                        <Button startIcon={<Add />} onClick={() => toggleField('expires_at')}>
                          Add Expiry Date
                        </Button>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {formFieldsVisibilityMap['allocated'] ? (
                        <Stack spacing={1} alignItems='flex-start'>
                          <Controller
                            name='allocated'
                            defaultValue={5}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                id='allocated'
                                label='Max no. of usages'
                                type='number'
                                error={!!error}
                                helperText={error?.message}
                                inputProps={{ min: 1 }}
                                fullWidth
                              />
                            )}
                          />

                          <Button startIcon={<Remove />} onClick={() => toggleField('allocated')}>
                            Remove max no. of usages
                          </Button>
                        </Stack>
                      ) : (
                        <Button startIcon={<Add />} onClick={() => toggleField('allocated')}>
                          Add max no. of usages
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>

              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 flex-shrink-0 ml-2 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>Code Generation</Typography>

                  <Typography variant='body2' color='text.secondary'>
                    Configure in what way will the voucher codes be generated.
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4, flex: 1 }}>
                  <Grid container spacing={3}>
                    {isEditing && (
                      <Grid item xs={12}>
                        <Alert severity='info'>
                          The previously generated codes will not be replaced, instead new ones will
                          be generated
                        </Alert>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <CardSelection
                        items={codeGenerationMethods}
                        onSelectionChange={onMethodChange}
                        value={selectedCardItem}
                      />
                    </Grid>

                    {!!selectedMethod && (
                      <Grid item xs={12}>
                        {[GenerationMethod.IMPORT_CSV, GenerationMethod.INPUT].includes(
                          selectedMethod,
                        ) && (
                          <Alert severity='info' className='mb-4'>
                            The code should consist only of uppercase letters (A-Z), numbers (0-9)
                            and hyphens. Because users will have to write this code, it is preferred
                            not to make it that long.
                          </Alert>
                        )}

                        {selectedMethod === GenerationMethod.GENERATE && (
                          <Controller
                            name='codeNo'
                            defaultValue={5}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                id='codeNo'
                                label='Number of voucher codes'
                                type='number'
                                error={!!error}
                                helperText={error?.message}
                                inputProps={{ min: 1 }}
                                fullWidth
                              />
                            )}
                          />
                        )}

                        {selectedMethod === GenerationMethod.IMPORT_CSV && (
                          <Stack>
                            <Controller
                              name='csv_file'
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <FileUpload
                                  label='Attachment'
                                  allowedFileTypes={allowedFileTypes}
                                  maxFileSize={MAX_CSV_FILE_SIZE_IN_BYTES}
                                  onSelect={onFileUpload}
                                  onError={(message) =>
                                    setError('csv_file', {
                                      message,
                                      type: CustomZodErrorType.INPUT_FILE,
                                    })
                                  }
                                />
                              )}
                            />

                            {!!errors.csv_file &&
                              errors.csv_file.type !== CustomZodErrorType.INPUT_FILE && (
                                <FormControl error>
                                  <FormHelperText className='!ml-0'>
                                    {errors.csv_file.message}
                                  </FormHelperText>
                                </FormControl>
                              )}

                            <TemplateDownload
                              link={environment.baseUrl + '/downloads/code-template.csv'}
                            />
                          </Stack>
                        )}

                        {selectedMethod === GenerationMethod.INPUT && (
                          <Controller
                            name='code'
                            defaultValue='SAMPLE-CODE-000'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                id='code'
                                onChange={(val) => {
                                  field.onChange(val);
                                  trigger('code');
                                }}
                                label='Code'
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Stack>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={isLoading}>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={isLoading || (isEditing && isFetchingGroup)}
                  color='success'
                >
                  {isEditing ? 'Save' : 'Create'}
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateVouchers;
