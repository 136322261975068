import { Card, CardActionArea, Stack, Typography, Divider, Button } from '@mui/material';
import { useDrag } from 'react-dnd';
import { Article } from '@/Models/articles';
import { FeaturedArticles } from '@/Models/featuredArticles';

export const ArticleDragCard = ({
  article,
  disabled,
  index,
}: {
  article: Article;
  disabled: boolean;
  index: number;
}) => {
  const [, drag] = useDrag(() => ({
    type: 'article',
    item: { id: article.id, title: article.title, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Card
      ref={drag}
      className='flex w-[90%]'
      style={
        disabled
          ? {
              pointerEvents: 'none',
              opacity: 0.5,
            }
          : {}
      }
    >
      <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
        <Stack divider={<Divider />} spacing={4} className='p-4'>
          <Typography variant='h6'>{article.title}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export const FeaturedArticleDragCard = ({
  featuredArticle,
  removeFromFeatured,
}: {
  featuredArticle: FeaturedArticles;
  removeFromFeatured: any;
}) => {
  return (
    <Card className='flex w-[90%] mt-3 ml-6'>
      <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
        <Stack
          className='p-4 w-full'
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>{featuredArticle.article.title}</Typography>
          <Button className='m-0' onClick={() => removeFromFeatured(featuredArticle)}>
            remove
          </Button>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
