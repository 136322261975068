import { UserRole } from '@/Enums/roles';

export const roleExplain: { [key in UserRole]?: string } = {
  [UserRole.EDITOR]:
    'Editor users have the ability to edit events, create tickets, promocodes, vouchers, orders, attendees, send invitations, and view reports.',
  [UserRole.SUPPORT]:
    'Support users have the ability to view events, tickets, promocodes, vouchers, orders, attendees, invitations, and reports.',
  [UserRole.ANALYST]: 'Analyst users have the ability to view events and analyze reports.',
  [UserRole.AFFILIATE]:
    'Affiliate users have the ability to sell tickets in the name of the organizer',
  [UserRole.SPONSOR]:
    'Sponsor users have the ability send out invitations allocated by the organizer',
  [UserRole.SCAN]:
    'Scan users have the ability scan tickets on-site for the events created by this organization',
};
