import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Box,
  CardActionArea,
} from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import {
  useDeleteEventArtistMutation,
  useGetEventArtistsQuery,
} from '@/Api/eventArtistsSlice';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { EventArtist } from '@/Models/eventArtists';

const Index = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [artistToDelete, setEventArtistToDelete] = useState<string>();

  const dispatch = useDispatch();
  const { eventId } = useParams();
  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Event Artists'));
    dispatch(
      setHeader({
        title: 'Event Artists',
      }),
    );
  }, []);

  const { data: artists, refetch } = useGetEventArtistsQuery(
    {
      eventId: eventId!,
      filterData: { pageData: { page, pageSize } },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !eventId,
    },
  );
  const [deleteEventArtist] = useDeleteEventArtistMutation();

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    artist: EventArtist;
  }>(null);

  const onDialogClose = (value: boolean) => {
    setDialogOpen(false);
    if (value && artistToDelete) {
      deleteEventArtist({
        eventId: eventId!,
        artistId: artistToDelete!,
      });
    }
    refetch();
    setEventArtistToDelete(undefined);
  };
  function showActionMenu(artist: EventArtist, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, artist });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }
  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditClick = (
    $artist: React.MouseEvent<HTMLDivElement, MouseEvent>,
    artistId: string,
  ) => {
    $artist.preventDefault();
    navigate(`/events/${eventId}/artists/${artistId}/edit`);
  };

  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <PermissionsGate className='' scope={SCOPES.ManageOrder}>
              <Link to={`/events/${eventId}/artists/create`}>
                <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                  Add Artist for Event
                </Button>
              </Link>
            </PermissionsGate>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {artists && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {artists.data.map((artist) => (
                    <Link
                      key={artist.id}
                      to={`/events/${eventId}/artists`}
                      className='hover:scale-[1.005] transition-all'
                    >
                      <Card sx={{ display: 'flex', overflow: 'auto' }}>
                        <CardActionArea sx={{ display: 'flex' }}>
                          <Stack width='100%' divider={<Divider />}>
                            <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                              <CardMedia
                                component='img'
                                sx={{
                                  width: 120,
                                  height: 120,
                                  borderRadius: 1,
                                  objectFit: 'contain',
                                }}
                                image={artist.image}
                              />

                              <Stack flex={1} maxWidth='600px'>
                                <Typography variant='h4' noWrap>
                                  {artist.artist && artist.artist.name}
                                </Typography>
                              </Stack>

                              <Stack >
                                <Typography variant='h6'>Information</Typography>
                                <Typography variant='caption' color='text.primary' noWrap>
                                  {artist.headliner ? 'Headliner' : ''}
                                </Typography>
                                <Typography variant='caption' color='text.secondary' noWrap>
                                  Day: {artist.day}
                                </Typography>
                              </Stack>

                              <Box className='flex flex-row justify-center !ml-auto'>
                                <PermissionsGate scope={SCOPES.ManageOrder}>
                                  <IconButton
                                    onClick={(event) => {
                                      setDialogOpen(true);
                                      setEventArtistToDelete(artist?.id);
                                      showActionMenu(artist, event);
                                      setEventArtistToDelete(artist?.id);
                                      hideActionMenu();
                                    }}
                                  >
                                    <DeleteOutline color='error' />
                                  </IconButton>
                                  <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />

                                  <IconButton
                                    sx={{ margin: 'auto 0' }}
                                    component='div'
                                    onClick={($artist) =>
                                      onEditClick($artist, artist.id)
                                    }
                                  >
                                    <EditOutlined />
                                  </IconButton>
                                </PermissionsGate>
                              </Box>
                            </Stack>
                          </Stack>
                        </CardActionArea>
                      </Card>
                    </Link>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={artists.current_page}
                    count={artists.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
