export enum LayoutMode {
  ADMIN = 'admin',
  CLIENT = 'client',
  EVENT = 'event',
}

export interface LayoutState {
  title: string;
  header: {
    title: string;
    subtitle?: string;
  };
}
