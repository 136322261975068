import React, { useState } from 'react';
import { Event } from '@/Models/events';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useGetTransactionsQuery } from '@/Api/transactionsSlice';
import { Order } from '@/Models/orders';
import { relativeDate } from '@/Utils/dates';
import environment from '@/environments/environment';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';

const RecentTransactions = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState('');

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
    boxShadow: 'none',
  }));

  const { data: transactionData } = useGetTransactionsQuery(
    {
      filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <Item>
      Recent Transactions
      <List sx={{ bgcolor: 'background.paper', height: '90%', overflowY: 'scroll' }}>
        {transactionData?.data?.map((transaction: any) => (
          <React.Fragment key={transaction.referenceNo}>
            <ListItem alignItems='flex-start' className='relative'>
              <ListItemText
                primary={transaction.first_name + ' ' + transaction.last_name}
                secondary={
                  <React.Fragment>
                    <Typography variant='subtitle2'>{transaction.email}</Typography>
                    <div className='my-2'>
                      <span className='text-blue-400 cursor-pointer'>
                        {transaction.event.title}
                      </span>
                    </div>
                    <div className='gap-2 lg:mb-2 lg:flex lg:gap-2'>
                      <BeautifulChip color={getStatusColor(transaction.status)}>
                        {humanizeAttendeeStatus(transaction.status)}
                      </BeautifulChip>
                    </div>
                    <div>
                      <Typography variant='subtitle2' className='text-right right-4 bottom-6'>
                        {relativeDate(transaction.created_at)}
                      </Typography>
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider component='li' />
          </React.Fragment>
        ))}
      </List>
    </Item>
  );
};

export default RecentTransactions;
