import { QueryResult } from '@/Models/redux';
import { showSnackbar } from '@/Slices/snackbarSlice';
import { FSTX_HEADER, getErrorMessageBasedOnStatus } from '@/Utils/http-client';
import { RTKInterceptor } from './types';

const errorSnackbarInterceptor: RTKInterceptor = async (next, args, api, extraOptions) => {
  const result = await next(args, api, extraOptions);

  if (!result.error) {
    return result;
  }

  if (shouldHideErrorMessage(result)) {
    return result;
  }

  if (!!result.meta?.response?.status) {
    const errorMessage = getErrorMessageBasedOnStatus(result);
    api.dispatch(
      showSnackbar({
        message: errorMessage,
        severity: 'error',
      }),
    );
  }

  return result;
};

function shouldHideErrorMessage(exception: QueryResult) {
  return (
    exception.meta?.response?.headers.get(FSTX_HEADER.HIDE_ERROR_MESSAGES) ||
    exception.meta?.response?.headers.get(FSTX_HEADER.HIDE_MESSAGES)
  );
}

export default errorSnackbarInterceptor;
