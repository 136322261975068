import { TAG_TYPE } from '@/Enums/tag-types';
import { FilterData } from '@/Models/filtering';
import {
  PasscodeGroup,
  PasscodeGroupData,
  VoucherGroup,
  VoucherGroupData,
} from '@/Models/promocodes';
import { getFilterQueryParams } from '@/Utils/filters';
import { internalApi } from './internalApi';

export const promocodesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getPasscodeGroups: builder.query<
      PasscodeGroupData,
      { eventId: string; filterData: FilterData }
    >({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/passcode-groups`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.PASSCODE_GROUPS],
    }),
    getPasscodeGroup: builder.query<PasscodeGroup, { eventId: string; passcodeGroupId: string }>({
      query: ({ eventId, passcodeGroupId }) => ({
        url: `/events/${eventId}/passcode-groups/${passcodeGroupId}`,
        method: 'GET',
      }),
      providesTags: (_, __, { passcodeGroupId }) => [
        { type: TAG_TYPE.PASSCODE_GROUPS, id: passcodeGroupId },
      ],
    }),
    getPasscodes: builder.query<any, { passcodeGroupId: string; filterData: FilterData }>({
      query: ({ passcodeGroupId, filterData }) => ({
        url: `/passcodes`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData, { passcodeGroupId }) },
      }),
      providesTags: [TAG_TYPE.PASSCODES],
    }),
    createPasscodeGroup: builder.mutation<any, { eventId: string; data: any }>({
      query: ({ eventId, data }) => ({
        url: `/events/${eventId}/passcode-groups`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TAG_TYPE.PASSCODE_GROUPS],
    }),
    updatePasscodeGroup: builder.mutation<
      any,
      { eventId: string; passcodeGroupId: string; data: any }
    >({
      query: ({ eventId, passcodeGroupId, data }) => ({
        url: `/events/${eventId}/passcode-groups/${passcodeGroupId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TAG_TYPE.PASSCODE_GROUPS],
    }),
    linkWithPasscodeGroup: builder.mutation<any, { eventId: string; passcodeGroupId: string }>({
      query: ({ eventId, passcodeGroupId }) => ({
        url: `/events/${eventId}/passcode-groups/link`,
        method: 'PUT',
        body: {
          passcode_group_id: passcodeGroupId,
        },
      }),
      invalidatesTags: [TAG_TYPE.PASSCODE_GROUPS],
    }),
    unlinkPasscodeGroup: builder.mutation<any, { eventId: string; passcodeGroupId: string }>({
      query: ({ eventId, passcodeGroupId }) => ({
        url: `/events/${eventId}/passcode-groups/unlink`,
        method: 'PUT',
        body: {
          passcode_group_id: passcodeGroupId,
        },
      }),
      invalidatesTags: [TAG_TYPE.PASSCODE_GROUPS],
    }),
    getVoucherGroups: builder.query<VoucherGroupData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/voucher-groups`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.VOUCHER_GROUPS],
    }),
    getVoucherGroup: builder.query<VoucherGroup, { eventId: string; voucherGroupId: string }>({
      query: ({ eventId, voucherGroupId }) => ({
        url: `/events/${eventId}/voucher-groups/${voucherGroupId}`,
        method: 'GET',
      }),
      providesTags: (_, __, { voucherGroupId }) => [
        { type: TAG_TYPE.VOUCHER_GROUPS, id: voucherGroupId },
      ],
    }),
    getVouchers: builder.query<any, { voucherGroupId: string; filterData: FilterData }>({
      query: ({ voucherGroupId, filterData }) => ({
        url: `vouchers`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData, { voucherGroupId }) },
      }),
      providesTags: [TAG_TYPE.VOUCHERS],
    }),
    createVoucherGroup: builder.mutation<any, { eventId: string; data: any }>({
      query: ({ eventId, data }) => ({
        url: `/events/${eventId}/voucher-groups`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TAG_TYPE.VOUCHER_GROUPS],
    }),
    updateVoucherGroup: builder.mutation<
      any,
      { eventId: string; voucherGroupId: string; data: any }
    >({
      query: ({ eventId, voucherGroupId, data }) => ({
        url: `/events/${eventId}/voucher-groups/${voucherGroupId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TAG_TYPE.VOUCHER_GROUPS],
    }),
    updateRevokeStatus: builder.mutation<
      any,
      { eventId: string; voucherGroupId: string; revoke: boolean }
    >({
      query: ({ eventId, voucherGroupId, revoke }) => ({
        url: `/events/${eventId}/voucher-groups/${voucherGroupId}/updateStatus`,
        method: 'PUT',
        body: {
          revoke,
        },
      }),
      invalidatesTags: [TAG_TYPE.VOUCHER_GROUPS],
    }),
  }),
});

export const {
  useGetPasscodeGroupsQuery,
  useGetPasscodeGroupQuery,
  useGetPasscodesQuery,
  useCreatePasscodeGroupMutation,
  useUpdatePasscodeGroupMutation,
  useLinkWithPasscodeGroupMutation,
  useUnlinkPasscodeGroupMutation,
  useGetVoucherGroupsQuery,
  useGetVoucherGroupQuery,
  useGetVouchersQuery,
  useCreateVoucherGroupMutation,
  useUpdateVoucherGroupMutation,
  useUpdateRevokeStatusMutation,
} = promocodesApi;
