import { User, UserManager } from 'oidc-client-ts';
import environment from '@/environments/environment';
import getPromiseDeduplicator from '@/Utils/promise';
import { isTokenNearExpiration } from '@/Utils/auth';

class AuthService {
  private userManager: UserManager;
  private deduplicateRenewPromise = getPromiseDeduplicator<User | null>();

  constructor() {
    this.userManager = new UserManager(environment.oidcConfig);
  }

  async login(): Promise<void> {
    return await this.userManager.signinRedirect();
  }

  async logout() {
    return await this.userManager.signoutRedirect();
  }

  async handleCallback() {
    return await this.userManager.signinRedirectCallback();
  }

  async renewToken() {
    return await this.userManager.signinSilent();
  }

  async getUser() {
    return await this.userManager.getUser();
  }

  async getToken(): Promise<string | null> {
    const user = await this.getUser();
    if (!user) {
      return null;
    }

    if (isTokenNearExpiration(user)) {
      await this.deduplicateRenewPromise(() => this.renewToken());
      return this.getToken();
    }

    return user.access_token;
  }

  async isAuthenticated(): Promise<boolean> {
    const user = await this.getUser();

    return !!user && !user.expired;
  }
}

const authService = new AuthService();

export default authService;
