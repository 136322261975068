import { useContext, useEffect, useState } from 'react';
import useEffectOnce from '@/Hooks/useEffectOnce';
import { useLazyGetTicketsQuery } from '@/Api/ticketsSlice';
import {
  useLazyGetAvailableSeatsQuery,
  useLazyGetEventVenueQuery,
  useLazyGetSeatDataFromEventQuery,
  useLazyGetSectionDataFromEventQuery,
} from '@/Api/seatingSlice';
import { useParams } from 'react-router-dom';
import { Ticket } from '@/Models/tickets';
import { useLazyGetEventQuery } from '@/Api/eventsSlice';
import VenueViewer from './VenueViewer';
import { Event } from '@/Models/events';
import { adaptSeatData, adaptSectionData } from '@/Utils/seating';
import VenueEditorContextProvider, { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { Subscription } from 'rxjs';

interface SeatSelectionProps {
  selectedSeatIds: string[];
  setSelectedSeatIds: (ids: string[]) => void;
}

function SeatSelection({ selectedSeatIds, setSelectedSeatIds }: SeatSelectionProps) {
  const [svgContent, setSvgContent] = useState<string>('');
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [event, setEvent] = useState<Event>();
  const { eventId } = useParams<{ eventId: string; venueId: string }>();
  const [availableSeats, setAvailableSeats] = useState<{ [seatId: string]: boolean }>({});
  const { venueEditorService, sectionIdentifierService, seatIdentifierService } =
    useContext(VenueEditorContext);

  const [getTicketsRequest] = useLazyGetTicketsQuery();
  const [getEventVenueDataRequest] = useLazyGetEventVenueQuery();
  const [getEventData] = useLazyGetEventQuery();
  const [getAvailableSeats] = useLazyGetAvailableSeatsQuery();
  const [getVenueSectionData] = useLazyGetSectionDataFromEventQuery();
  const [getVenueSeatData] = useLazyGetSeatDataFromEventQuery();

  useEffectOnce(() => {
    Promise.all([
      getTicketsRequest({ eventId: eventId!, includeInvitationTypes: true }),
      getEventVenueDataRequest(eventId!),
      getEventData(eventId!),
      getAvailableSeats({ eventId: eventId! }),
      getVenueSectionData(eventId!),
      getVenueSeatData({ eventId: eventId! }),
    ]).then(
      ([
        { data: tickets },
        { data: eventVenueData },
        { data: event },
        { data: availableSeats },
        { data: venueSectionData },
        { data: venueSeatData },
      ]) => {
        if (
          !tickets ||
          !eventVenueData ||
          !event ||
          !availableSeats ||
          !venueSectionData ||
          !venueSeatData
        )
          return;

        setEvent(event);
        setTickets(tickets);
        setAvailableSeats(availableSeats);

        sectionIdentifierService.loadInitialSectionData(adaptSectionData(venueSectionData));
        seatIdentifierService.loadInitialSeatData(adaptSeatData(venueSeatData));

        fetch(eventVenueData.svg)
          .then((res) => res.text())
          .then((text) => {
            setSvgContent(text);
          });
      },
    );
  });

  useEffect(() => {
    const subscriptions = new Subscription();

    subscriptions.add(
      venueEditorService.getSelectedElementsIds$().subscribe((ids) => {
        setSelectedSeatIds(ids);
      }),
    );

    return () => {
      subscriptions.unsubscribe();
    };
  }, []);

  if (svgContent) {
    return (
      <VenueViewer
        svgContent={svgContent}
        event={event!}
        tickets={tickets}
        availableSeats={availableSeats}
      />
    );
  }

  return <></>;
}

function VenueWrapper({ selectedSeatIds, setSelectedSeatIds }: SeatSelectionProps) {
  return (
    <VenueEditorContextProvider>
      <SeatSelection selectedSeatIds={selectedSeatIds} setSelectedSeatIds={setSelectedSeatIds} />
    </VenueEditorContextProvider>
  );
}

export default VenueWrapper;
