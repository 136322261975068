import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Order, OrderData } from '@/Models/orders';

export const ordersApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<OrderData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/orders`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.ORDER],
    }),
    updateOrder: builder.mutation<Order, { eventId: string; orderId: string; body: any }>({
      query: ({ eventId, orderId, body }) => ({
        url: `/events/${eventId}/orders/${orderId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ORDER],
    }),
    updateTransferOrder: builder.mutation<Order, { eventId: string; orderId: string; body: any }>({
      query: ({ eventId, orderId, body }) => ({
        url: `/events/${eventId}/orders/${orderId}/transferOrder`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ORDER],
    }),
    getOrderAttendees: builder.query<any, { eventId: string; orderId: string }>({
      query: ({ eventId, orderId }) => ({
        url: `/events/${eventId}/orders/${orderId}/attendees`,
        method: 'GET',
      }),
    }),
    sendOrderTickets: builder.mutation<any, { eventId: string; orderId: string }>({
      query: ({ eventId, orderId }) => ({
        url: `/events/${eventId}/orders/${orderId}/resend`,
        method: 'POST',
      }),
    }),
    getOrdersMonthly: builder.query<any, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/orders/monthly`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.ORDER],
    }),
    getOrdersStatus: builder.query<any, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/orders/status`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.ORDER],
    }),
    getTransactionDetails: builder.query<any, { eventId: string; transactionId: string }>({
      query: ({ eventId, transactionId }) => ({
        url: `/events/${eventId}/transactions/${transactionId}`,
        method: 'GET',
      }),
    }),
    cancelOrder: builder.mutation<any, { eventId: string; orderId: string }>({
      query: ({ eventId, orderId }) => ({
        url: `/events/${eventId}/orders/${orderId}/cancel`,
        method: 'POST',
      }),
    }),
    getOrderTicketsDetails: builder.query<any, { eventId: string; order: any }>({
      query: ({ eventId, order }) => ({
        url: `/events/${eventId}/${order}/ticketsDetails`,
        method: 'GET',
      }),
    }),
    refundOrder: builder.mutation<any, { eventId: string; orderId: string; attendeeIds: string[] }>(
      {
        query: ({ eventId, orderId, attendeeIds }) => {
          return {
            url: `/events/${eventId}/orders/${orderId}/refund`,
            method: 'POST',
            body: { attendeeIds },
          };
        },
      },
    ),
    getOrderDetails: builder.query<any, { eventId: string; orderId: string }>({
      query: ({ eventId, orderId }) => ({
        url: `/events/${eventId}/orders/${orderId}/details`,
        method: 'GET',
      }),
    }),
    getAllOrders: builder.query<OrderData, { event_id?: string | null; filterData: FilterData }>({
      query: ({ event_id, filterData }) => ({
        url: '/all-orders',
        method: 'GET',
        params: { event_ids: event_id, ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.ORDER],
    }),
  }),
});

export const {
  useGetOrderAttendeesQuery,
  useUpdateOrderMutation,
  useUpdateTransferOrderMutation,
  useSendOrderTicketsMutation,
  useGetOrdersQuery,
  useGetOrdersMonthlyQuery,
  useGetOrdersStatusQuery,
  useGetTransactionDetailsQuery,
  useCancelOrderMutation,
  useRefundOrderMutation,
  useGetOrderTicketsDetailsQuery,
  useGetOrderDetailsQuery,
  useGetAllOrdersQuery,
} = ordersApi;
