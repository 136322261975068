import React from 'react';
import {
  Stack,
  TextField,
  Grid,
  Paper,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const PaymentGateways = ({ control }:any) => {
  return (
    <Stack>
      <Stack direction='row' spacing={4}>
        <Paper elevation={2} sx={{ p: 4, width: '63.5%', marginLeft: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='informations_step.payment_type.pan'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    required
                    {...field}
                    id='informations_step.payment_type.pan'
                    label='PAN'
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='informations_step.payment_type.brand'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    required
                    {...field}
                    id='informations_step.payment_type.brand'
                    label='Brand'
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='informations_step.payment_type.order_id'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    required
                    {...field}
                    id='informations_step.payment_type.order_id'
                    label='Order Id'
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='informations_step.payment_type.order_status'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    required
                    {...field}
                    id='informations_step.payment_type.order_status'
                    label='Order Status'
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='informations_step.payment_type.approval_code'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    required
                    {...field}
                    id='informations_step.payment_type.approval_code'
                    label='Approval Code'
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='informations_step.payment_type.trans_datetime'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    required
                    {...field}
                    id='informations_step.payment_type.trans_datetime'
                    label='TranDateTime'
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
        <br />
      </Stack>
    </Stack>
  );
};

export default PaymentGateways;
