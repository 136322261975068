import { TAG_TYPE } from '@/Enums/tag-types';
import { DonationCause, DonationCauseData } from '@/Models/donations';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { internalApi } from './internalApi';

export const donationsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getDonationCauses: builder.query<DonationCauseData, FilterData>({
      query: (filterData) => ({
        url: '/donation-causes',
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
    getArchivedDonationCauses: builder.query<DonationCauseData, FilterData>({
      query: (filterData) => ({
        url: '/donation-causes/archived',
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
    getEventDonationCauses: builder.query<DonationCauseData, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/donation-causes`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.EVENT_DONATION_CAUSES],
    }),
    getEventArchivedDonationCauses: builder.query<DonationCauseData, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/donation-causes/archived`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.EVENT_DONATION_CAUSES],
    }),
    getDonationCause: builder.query<DonationCause, string>({
      query: (donationCauseId) => ({
        url: `/donation-causes/${donationCauseId}`,
        method: 'GET',
      }),
    }),
    createDonationCause: builder.mutation({
      query: (body) => ({
        url: '/donation-causes',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
    updateDonationCause: builder.mutation<any, { donationCauseId: string; body: any }>({
      query: ({ donationCauseId, body }) => ({
        url: `/donation-causes/${donationCauseId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
    syncEventDonationCauses: builder.mutation<
      any,
      { eventId: string; body: { donation_causes_ids: string[] } }
    >({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/donation-causes`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_DONATION_CAUSES],
    }),
    deleteDonationCause: builder.mutation<any, string>({
      query: (donationCauseId) => ({
        url: `/donation-causes/${donationCauseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
    getDonationStats: builder.query<any, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/donation-stats`,
        method: 'GET',
      }),
    }),
    archiveDonationCause: builder.mutation<any, string>({
      query: (donationCauseId) => ({
        url: `/donation-causes/${donationCauseId}/archive`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
    unArchiveDonationCause: builder.mutation<any, string>({
      query: (donationCauseId) => ({
        url: `/donation-causes/${donationCauseId}/unarchive`,
        method: 'PUT',
      }),
      invalidatesTags: [TAG_TYPE.ALL_DONATION_CAUSES],
    }),
  }),
});

export const {
  useGetDonationCausesQuery,
  useGetArchivedDonationCausesQuery,
  useGetEventDonationCausesQuery,
  useGetEventArchivedDonationCausesQuery,
  useGetDonationCauseQuery,
  useCreateDonationCauseMutation,
  useSyncEventDonationCausesMutation,
  useDeleteDonationCauseMutation,
  useUpdateDonationCauseMutation,
  useGetDonationStatsQuery,
  useArchiveDonationCauseMutation,
  useUnArchiveDonationCauseMutation,
} = donationsApi;
