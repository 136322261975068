import { useGetUserPermissionsQuery } from '@/Api/permissionsSlice';
import { useMemo } from 'react';
import RouteGuard from './RouteGuard';

const hasPermission = ({ permissions, scope }: any) => {
  if (!permissions) {
    return false;
  }

  return permissions[scope] || false;
};

export default function PermissionsGate({ children, scope, page }: any) {
  const { data: permissions, isFetching } = useGetUserPermissionsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const permissionGranted = useMemo(() => {
    return hasPermission({ permissions, scope });
  }, [permissions, scope]);

  if (isFetching) {
    return <></>;
  }

  if (page) {
    return <RouteGuard isAllowed={permissionGranted} redirectPath='403' />;
  } else {
    if (!permissionGranted) {
      return <></>;
    }
    return <>{children}</>;
  }
}
