import { useEffect, useState } from 'react';
import { Stack, Typography, Paper, Button } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const SuccessIndex = () => {
  const [buyerEmail, setBuyerEmail] = useState('') as any;
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/events/${eventId}/support-order`);
  };

  useEffect(() => {
    setBuyerEmail(localStorage.getItem('buyer-email'));
  }, []);

  return (
    <Stack className='flex justify-center items-center gap-4 mt-16'>
      <Paper className='p-16 px-12 items-center'>
        <Stack className='gap-4 flex justify-center items-center'>
          <CheckCircleOutline sx={{ fontSize: 40 }} color='success' />
          <Typography variant='h5' textAlign='center'>
            Order created successfully
          </Typography>
          {buyerEmail !== undefined ? (
            <Typography variant='body1' textAlign='center'>
              The tickets have been sent to this email: {buyerEmail}
            </Typography>
          ) : (
            <></>
          )}
          <Button onClick={handleClick} sx={{ marginTop: 2 }} variant='outlined'>
            Go to Support Order
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SuccessIndex;
