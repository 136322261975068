import React from 'react';
import { Event } from '@/Models/events';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrivalsOverview from '@/Components/Molecules/ArrivalsOverview/ArrivalsOverview';
import ArrivalsDaily from '@/Components/Molecules/ArrivalsDaily/ArrivalsDaily';
import ArrivalsHourly from '@/Components/Molecules/ArrivalsHourly/ArrivalsHourly';
import { useGetAttendeesStatsQuery } from '@/Api/attendeesSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useResponsive } from '@/Hooks/useResponsive';

export interface SalesReportTabsProps {
  event: Event;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SalesReportTabs = ({ event }: SalesReportTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: stats } = useGetAttendeesStatsQuery(
    {
      eventId: event.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const loading = (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
  const { isMobile } = useResponsive();

  return (
    <Box sx={{ width: '100%', backgroundColor: '#fff', p: 2, height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className='scrollable auto-scroll-buttons primary-indicator-color lg:primary-text-color'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            variant='scrollable'
            {...(isMobile && {
              variant: 'scrollable',
              scrollButtons: 'auto',
              indicatorColor: 'primary',
              textColor: 'primary',
            })}
          >
            <Tab label='Arrivals Overview' {...a11yProps(0)} />
            <Tab label='Arrivals Daily' {...a11yProps(1)} />
            <Tab label='Arrivals Hourly' {...a11yProps(2)} />
            <Tab label='Guest Arrivals' {...a11yProps(3)} />
            <Tab label='Guests Daily' {...a11yProps(4)} />
            <Tab label='Guests Hourly' {...a11yProps(5)} />
          </Tabs>
        </div>
      </Box>
      {!stats ? (
        loading
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <ArrivalsOverview arrivals={stats.attendeesArrival} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ArrivalsDaily arrivals={stats.attendeesDaily} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ArrivalsHourly arrivals={stats.attendeesHourly} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ArrivalsOverview arrivals={stats.guestArrivals} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ArrivalsDaily arrivals={stats.guestsDaily} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ArrivalsHourly arrivals={stats.guestsHourly} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default SalesReportTabs;
