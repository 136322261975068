import { editorTools } from '@/Constants/venue-tools';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { setCurrentTool } from '@/Slices/venueEditorSlice';
import { GlobalStore } from '@/store';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolType } from '@festtix/venue-editor';

interface ToolbarProps {
  tools: ToolType[];
}

function Toolbar({ tools }: ToolbarProps) {
  const { editorEventService } = useContext(VenueEditorContext);
  const dispatch = useDispatch();
  const { currentTool } = useSelector((state: GlobalStore) => state.venueEditor);

  function toggleTool(type: ToolType): void {
    const newTool = type === currentTool ? 'navigate' : type;
    dispatch(setCurrentTool(newTool));
    editorEventService.toolChanged$.next(newTool);
  }

  const allowedTools = useMemo(() => {
    return editorTools.filter((tool) => tools.includes(tool.type));
  }, [tools]);

  function handleKeyDown(event: KeyboardEvent) {
    const tool = allowedTools.find((t) => t.keyboardKey === event.key);
    if (tool) {
      toggleTool(tool.type);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [allowedTools]);

  return (
    <Stack alignItems='center'>
      <Stack direction='row' className='h-12' columnGap='10px'>
        {allowedTools.map((tool) => (
          <Tooltip key={tool.type} title={tool.tooltip}>
            <IconButton
              sx={{
                margin: 'auto',
                backgroundColor: currentTool === tool.type ? 'primary.main' : 'transparent',
                color: currentTool === tool.type ? 'white' : 'primary.main',
              }}
              onClick={() => toggleTool(tool.type)}
            >
              {tool.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
}

export default Toolbar;
