import React, { useState, useEffect} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import { TicketDragCard } from '@/Components/Molecules/Cards/TicketDragCard';
import { Ticket } from '@/Models/tickets';
import { useReOrderTicketsMutation } from '@/Api/ticketsSlice';
import { useParams } from 'react-router-dom';

const ReOrder = ({ tickets, open, onDialogClose }: DialogProps<any>) => {
  const { eventId } = useParams();
  const [orderedTickets, setOrderedTickets] = useState<Ticket[]>(tickets);

  const onTicketDrop = (item: Ticket, index: number) => {
    const ticketToOrder = tickets.find((e: Ticket) => e.id === item.id);
    if (ticketToOrder) {
      const updatedTickets = [...orderedTickets];
      const ticketIndex = updatedTickets.findIndex((t) => t.id === ticketToOrder.id);
      if (ticketIndex !== -1) {
        updatedTickets.splice(ticketIndex, 1);
      }
      updatedTickets.splice(index, 0, ticketToOrder);
      setOrderedTickets(updatedTickets);
    }
  };
  
  const [reOrderTickets] = useReOrderTicketsMutation();

  const reorderTickets = (orderedTickets: any) => {
      reOrderTickets({ eventId, body: { tickets: orderedTickets }  })
          .unwrap()
          .then(() => {
            onDialogClose(true);
          })
          .catch((error) => {
            console.log('error ', error);
          });
  };

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle sx={{ background: '#263a51', color: 'white', fontSize: '15px' }}>REORDER TICKETS</DialogTitle>

      <DialogContent className='overflow-auto'>
        <div className='w-100 py-2'>
          <Grid container spacing={3}>
            {orderedTickets.map((ticket: any, index: number) => (
              <Grid item key={ticket.id} xs={12}>
                <div className='mt-4 lg:mt-0'>
                  <TicketDragCard
                    ticket={ticket}
                    tickets={tickets}
                    onTicketDrop={onTicketDrop}
                    index={index}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onDialogClose(false)}>Cancel</Button>

        <Button variant='contained' color='success' onClick={() => reorderTickets(orderedTickets)}>
          ReOrder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReOrder;
