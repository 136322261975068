import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  Box,
  CardActionArea,
} from '@mui/material';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { useDeleteArtistMutation, useGetArtistsQuery } from '@/Api/artistsSlice';
import { Artist } from '@/Models/artists';

const Index = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [artistToDelete, setArtistToDelete] = useState<string>();

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Artists'));
    dispatch(
      setHeader({
        title: 'Artists',
      }),
    );
  }, []);

  const { data: artists, refetch } = useGetArtistsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );
  const [deleteArtist] = useDeleteArtistMutation();

  const onDialogClose = (value: boolean) => {
    setDialogOpen(false);
    if (value && artistToDelete) {
      deleteArtist(artistToDelete);
    }
    refetch();
    setArtistToDelete(undefined);
  };
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    artist: Artist;
  }>(null);

  const onEditClick = ($artist: React.MouseEvent<HTMLDivElement, MouseEvent>, artistId: string) => {
    $artist.preventDefault();
    navigate(`/artists/${artistId}/edit`);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };
  function showActionMenu(artist: Artist, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, artist });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';
  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <Link to={`/artists/create`}>
              <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                Create Artist
              </Button>
            </Link>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {artists && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {artists.data.map((artist) => (
                    <Link
                      key={artist.id}
                      to={`/artists`}
                      className='hover:scale-[1.005] transition-all'
                    >
                      <Card sx={{ display: 'flex', overflow: 'auto' }}>
                        <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
                          <Stack width='100%' height='100%' divider={<Divider />}>
                            <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                              <Stack flex={1} maxWidth='600px'>
                                <Typography variant='h4' noWrap>
                                  {artist.name}
                                </Typography>
                              </Stack>

                              <Stack maxWidth='280px'>
                                <Typography variant='caption' color='text.secondary' noWrap>
                                  {artist.description
                                    ? artist.description.replace(/<\/?[^>]+(>|$)/g, '')
                                    : ''}
                                </Typography>

                                <Typography variant='caption' color='text.secondary'>
                                  {artist.spotify_url}
                                </Typography>
                              </Stack>

                              <Box className='flex flex-row justify-center !ml-auto'>
                                <IconButton
                                  onClick={(event) => {
                                    setDialogOpen(true);
                                    setArtistToDelete(artist.id);
                                    showActionMenu(artist, event);
                                    setArtistToDelete(artist?.id);
                                    hideActionMenu();
                                  }}
                                >
                                  <DeleteOutline color='error' />
                                </IconButton>
                                <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />

                                <IconButton
                                  sx={{ margin: 'auto 0' }}
                                  component='div'
                                  onClick={($artist) => onEditClick($artist, artist.id)}
                                >
                                  <EditOutlined />
                                </IconButton>
                              </Box>
                            </Stack>
                          </Stack>
                        </CardActionArea>
                      </Card>
                    </Link>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={artists.current_page}
                    count={artists.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>

      <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />
    </>
  );
};

export default Index;
