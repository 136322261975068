import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { getHideErrorMessageHeader, getHideSuccessMessageHeader } from '@/Utils/http-client';
import { GuestData, Guest } from '@/Models/guests';

export const guestsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getGuestsFromEvent: builder.query<GuestData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/guests`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.GUESTS],
    }),
    getGuests: builder.query<GuestData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/invites`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.GUESTS],
    }),
    generateManyInvitations: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/invites/generateMultiple`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.GUESTS],
    }),
    generateInvitationsFromCSVFile: builder.mutation<any, { eventId: string; csvFile: FormData }>({
      query: ({ eventId, csvFile }) => ({
        url: `/events/${eventId}/invites/import`,
        method: 'POST',
        body: csvFile,
      }),
      invalidatesTags: [TAG_TYPE.GUESTS],
    }),
    updateGuest: builder.mutation<
      Guest,
      {
        eventId: string;
        guestId: string;
        body: any;
      }
    >({
      query: ({ eventId, guestId, body }) => ({
        url: `/events/${eventId}/guests/${guestId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.GUESTS],
    }),
    downloadZipLink: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/invites/download`,
        method: 'POST',
        body,
      }),
    }),
    downloadSelectedZipLink: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/invites/zip-donwload-selected`,
        method: 'POST',
        body,
      }),
    }),
    resendInvitation: builder.mutation<any, { eventId: string; guestId: string }>({
      query: ({ eventId, guestId }) => ({
        url: `/events/${eventId}/guests/${guestId}/resend`,
        method: 'POST',
      }),
    }),
    cancelInvitationStatus: builder.mutation<any, { eventId: string; guestId: string }>({
      query: ({ eventId, guestId }) => ({
        url: `/events/${eventId}/guests/${guestId}/cancel`,
        method: 'POST',
      }),
    }),
    checkInGuest: builder.mutation<
      Guest,
      {
        eventId: string;
        guestId: string;
      }
    >({
      query: ({ eventId, guestId }) => ({
        url: `/events/${eventId}/guests/${guestId}/check-in`,
        method: 'PUT',
        headers: getHideErrorMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.GUESTS],
    }),
    resetCheckInGuest: builder.mutation<
      Guest,
      {
        eventId: string;
        guestId: string;
      }
    >({
      query: ({ eventId, guestId }) => ({
        url: `/events/${eventId}/guests/${guestId}/check-in`,
        method: 'DELETE',
        headers: getHideErrorMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.GUESTS],
    }),
    getGuestInvitation: builder.query<
      GuestData,
      { eventId: string; filterData: FilterData; uuid: string }
    >({
      query: ({ eventId, filterData, uuid }) => ({
        url: `/events/${eventId}/guests/${uuid}`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.GUESTS],
    }),
    reserveSeats: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/invites/reserveSeats`,
        method: 'POST',
        body,
        headers: getHideSuccessMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.GUESTS],
    }),
  }),
});

export const {
  useGetGuestsQuery,
  useGetGuestsFromEventQuery,
  useGetGuestInvitationQuery,
  useGenerateInvitationsFromCSVFileMutation,
  useGenerateManyInvitationsMutation,
  useUpdateGuestMutation,
  useDownloadZipLinkMutation,
  useDownloadSelectedZipLinkMutation,
  useResendInvitationMutation,
  useCancelInvitationStatusMutation,
  useCheckInGuestMutation,
  useResetCheckInGuestMutation,
  useReserveSeatsMutation,
} = guestsApi;
