import { Button, Stack, Typography } from '@mui/material';
import PasscodeGroups from './Passcodes/PasscodeGroups';
import VoucherGroups from './Vouchers/VoucherGroups';
import { KeyOutlined, Discount, LinkOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useEffect, useState } from 'react';
import { getEventDate } from '@/Utils/event';
import LinkPasscodesDialog from './Passcodes/LinkPasscodesDialog';
import { useResponsive } from '@/Hooks/useResponsive';

const Promocodes = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  function onLinkClick() {
    setDialogOpen(true);
  }

  function onLinkDialogClose() {
    setDialogOpen(false);
  }
  const { isMobile } = useResponsive();

  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <Stack spacing={2} className='pb-4'>
            {event.passcodes_enabled && (
              <>
                <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between'>
                  <Typography className='py-2 lg:py-0' variant='h5'>
                    Passcodes
                  </Typography>
                  <Stack direction='column' spacing={2}>
                    <PermissionsGate scope={SCOPES.ManagePasscode}>
                      <Button
                        variant='contained'
                        startIcon={<LinkOutlined />}
                        onClick={() => onLinkClick()}
                      >
                        Link from other events
                      </Button>

                      <Link to='passcodes/create'>
                        <Button className='w-full' variant='contained' startIcon={<KeyOutlined />}>
                          Create Passcodes
                        </Button>
                      </Link>
                    </PermissionsGate>
                  </Stack>
                </Stack>

                <PasscodeGroups event={event} />
              </>
            )}

            {event.vouchers_enabled && (
              <>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent='space-between'
                  className='mx-2 lg:space-x-0'
                >
                  <Typography className='py-2' variant='h5'>
                    Vouchers
                  </Typography>

                  <PermissionsGate scope={SCOPES.ManageVoucher}>
                    <Link to='vouchers/create'>
                      <Button className='w-full' variant='contained' startIcon={<Discount />}>
                        Create Vouchers
                      </Button>
                    </Link>
                  </PermissionsGate>
                </Stack>

                <VoucherGroups event={event} />
              </>
            )}
          </Stack>
        </div>
      )}

      {dialogOpen && <LinkPasscodesDialog open={dialogOpen} onDialogClose={onLinkDialogClose} />}
    </>
  );
};

export default Promocodes;
