import { Event } from '@/Models/events';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useGetOrdersStatusQuery } from '@/Api/ordersSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { useResponsive } from '@/Hooks/useResponsive';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface OrdersByStatusProps {
  event: Event;
}

const OrdersByStatus = ({ event }: OrdersByStatusProps) => {
  const { data: status } = useGetOrdersStatusQuery(
    {
      eventId: event.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const data = useMemo(() => {
    if (status) {
      let labels: string[] = [];
      let ordersStatus: number[] = [];
      let backgroundColors: string[] = [];
      let borderColors: string[] = [];

      status.forEach((row: { status: string; total: number }) => {
        labels.push(row.status.split('_').join(' '));
        ordersStatus.push(row.total);
        switch (row.status.split('_').join(' ')) {
          case 'completed':
            backgroundColors.push('rgba(75, 192, 192, 0.2)');
            borderColors.push('rgba(75, 192, 192, 1)');
            break;
          case 'refunded':
            backgroundColors.push('rgba(255, 137, 86, 0.2)');
            borderColors.push('rgba(255, 137, 86, 1)');
            break;
          case 'partially_refunded':
            backgroundColors.push('rgba(255, 206, 86, 0.2)');
            borderColors.push('rgba(255, 206, 86, 1)');
            break;
          case 'canceled':
            backgroundColors.push('rgba(255, 99, 132, 0.2)');
            borderColors.push('rgba(255, 99, 132, 1)');
            break;
          default:
            backgroundColors.push('rgba(54, 162, 235, 0.2)');
            borderColors.push('rgba(54, 162, 235, 1)');
            break;
        }
      });

      return {
        labels: labels,
        datasets: [
          {
            label: '# of Votes',
            data: ordersStatus,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    } else {
      return undefined;
    }
  }, [status]);
  const { isMobile } = useResponsive();
  return data ? (
    <Doughnut
      height={isMobile ? 350 : 'auto'}
      data={data}
      options={{ maintainAspectRatio: false, responsive: isMobile ? false : true }}
      style={{ width: '100%' }}
    />
  ) : (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
};

export default OrdersByStatus;
