import { useGetUserPermissionsQuery } from '@/Api/permissionsSlice';
import { Outlet } from 'react-router-dom';

const PermissionsWrapper = () => {
  const { data: permissions } = useGetUserPermissionsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  return <Outlet />;
};

export default PermissionsWrapper;
