import { useGetTicketsQuery } from '@/Api/ticketsSlice';
import { Box, Grid, Stack, Button } from '@mui/material';
import { TicketCard } from '@/Components/Molecules/Cards/TicketCard';
import InvitationTypesTable from './InvitationTypesTable';
import { Link } from 'react-router-dom';
import { Ticket } from '@/Models/tickets';
import { Typography } from '@mui/material';
import { DateFormats, formatDate } from '@/Utils/dates';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import { useResponsive } from '@/Hooks/useResponsive';
import ReOrder from './ReOrder';

const TicketsIndex = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const [reOrderDialogOpen, setReOrderDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  const { data: tickets } = useGetTicketsQuery(
    { eventId: eventId! },
    { refetchOnMountOrArgChange: true },
  );

  function openReorderDialog() {
    setReOrderDialogOpen(true);
  }
  const { isMobile } = useResponsive();
  const container = isMobile ? false : true;

  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <Stack spacing={2} sx={{ pb: 4 }}>
            <Box className='flex flex-col lg:justify-end lg:self-end'>
              <PermissionsGate scope={SCOPES.CreateTicket}>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  <Button variant='contained' onClick={openReorderDialog}>
                    Reorder tickets
                  </Button>

                  <Link to={`create`}>
                    <Button className='w-full' variant='contained'>
                      Create Ticket
                    </Button>
                  </Link>
                </Stack>
              </PermissionsGate>
            </Box>
            <Grid container={container} spacing={2}>
              {tickets &&
                tickets.map((ticket: Ticket) => (
                  <Grid item key={ticket.id} xs={3}>
                    <div className='mt-4 lg:mt-0'>
                      <TicketCard ticket={ticket} event={event} />
                    </div>
                  </Grid>
                ))}
            </Grid>
            {!!tickets?.length && <InvitationTypesTable event={event} canCreate={true} />}
          </Stack>

          {reOrderDialogOpen && (
            <ReOrder
              tickets={tickets}
              open={reOrderDialogOpen}
              onDialogClose={() => setReOrderDialogOpen(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TicketsIndex;
