import { RENEWAL_THRESHOLD } from '@/Constants/auth';
import { User } from 'oidc-client-ts';
import { FSTXLocalStorage } from './local-storage';
import { DataKey } from '@/Models/local-storage';

export function setOrganization(orgId: string): void {
  FSTXLocalStorage.setItem(DataKey.TENANT_ID, orgId);
}

export function clearOrganization(): void {
  setOrganization('');
}

export function getOrganization(): string | undefined {
  return FSTXLocalStorage.getItem(DataKey.TENANT_ID) ?? undefined;
}

export function isTokenNearExpiration(user: User) {
  return (user.expires_in || 0) < RENEWAL_THRESHOLD;
}
