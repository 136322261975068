import React, { useEffect, useMemo, useState } from 'react';
import {
  useCreateEventArtistMutation,
  useGenerateEventArtistIdMutation,
  useGetEventArtistQuery,
  useUpdateEventArtistMutation,
} from '@/Api/eventArtistsSlice';
import { useGetArtistsQuery } from '@/Api/artistsSlice';
import {
  Box,
  Button,
  Divider,
  Paper,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Radio,
  ListItemText,
} from '@mui/material';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useLoading from '@/Hooks/useLoading';
import useForm from '@/Hooks/useForm';
import { useNavigate, useParams } from 'react-router-dom';
import { HTTP_CODE } from '@/Utils/http-client';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { PreviewData } from '@/Models/preview';
import { FileContext, ResourceType } from '@/Enums/files';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';
import { string } from 'zod';
import { useGetEventQuery } from '@/Api/eventsSlice';

const CreateOrEdit = () => {
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();
  const { artistId } = useParams();
  const dispatch = useDispatch();
  const [isArtistDisabled, setIsArtistDisabled] = React.useState(false);

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
    skip: !eventId,
  });

  const allowedImageTypes = ['image/png', 'image/jpeg'];

  const [generatedEventArtistId, setGeneratedEventArtistId] = useState<string | undefined>(
    undefined,
  );
  const [generateEventArtistId] = useGenerateEventArtistIdMutation();
  const [createEventArtist] = useCreateEventArtistMutation();
  const [updateEventArtist] = useUpdateEventArtistMutation();
  const [page, setPage] = useState(0);
  const pageSize = Number.MAX_SAFE_INTEGER;

  const { data: artists } = useGetArtistsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  const { data: eventArtist } = useGetEventArtistQuery(
    {
      eventId: eventId!,
      artistId: artistId!,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !artistId,
    },
  );

  const imagePreviews: {
    image?: PreviewData;
  } = useMemo(() => {
    return {
      image: eventArtist && { type: 'image', url: eventArtist.image },
    };
  }, [eventArtist]);

  const [fileQueue, setFileQueue] = useState<{ key: string; file: File }[]>([]);

  const isCreatingEventArtist = !artistId;

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [loading, withLoading] = useLoading();
  useEffect(() => {
    if (eventArtist) {
      setData({ ...data, ...eventArtist });
    }
  }, [eventArtist]);

  const { data, setData, errors, setError, clearErrors, hasErrors } = useForm<{
    [key: string]: any;
  }>({
    event_id: eventId || '',
    artist_id: '',
    image: '',
    headliner: false,
    day: '',
  });

  async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    if (hasErrors) {
      return;
    }

    let eventArtistData = { ...data };

    let newId = generatedEventArtistId;
    if (isCreatingEventArtist) {
      if (!newId) {
        newId = await withLoading(generateEventArtistId().unwrap()).then((res) => {
          return res.id;
        });

        setGeneratedEventArtistId(newId);
      }
      eventArtistData = { ...eventArtistData, id: newId };
    }

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles(eventId, newId));
      eventArtistData = { ...eventArtistData, ...processedFiles };
    }

    if (!validatePictures(eventArtistData)) {
      return;
    }

    let promise: Promise<any>;
    if (isCreatingEventArtist) {
      const newEventArtistData = { eventId: eventId!, body: eventArtistData };
      promise = createEventArtist(newEventArtistData).unwrap();
    } else {
      promise = updateEventArtist({
        eventId: eventId!,
        artistId: artistId!,
        body: eventArtistData,
      }).unwrap();
    }

    withLoading(promise)
      .then((response) => {
        if (response?.isArtistDisabled) {
          setIsArtistDisabled(true);
        } else {
          navigate(`/events/${eventId}/artists`);
        }
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors);
        }
      });
  }

  const calculateDays = (startsAt: any, endsAt: any) => {
    const startDate = new Date(startsAt);
    const endDate = new Date(endsAt);

    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDaysDecimal = differenceInTime / (1000 * 3600 * 24);
    const differenceInDays = Math.ceil(differenceInDaysDecimal);
    const days = [];

    const currentDate = new Date(startDate);

    for (let i = 0; i < differenceInDays; i++) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  };

  const eventDays = calculateDays(event?.starts_at, event?.ends_at);
  async function processFiles(newEventArtistId?: string, newEventId?: string) {
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.EVENTARTISTS,
        artistId: artistId || newEventArtistId || '',
        eventId: eventId || newEventId || '',
      })),
    );

    const eventArtistData: { [key: string]: any } = {};
    signedUrlPayloads.forEach((p, i) => {
      eventArtistData[fileData[i].key] = p.fields.key;
      setData((prevData: FormData) => {
        return { ...prevData, [fileData[i].key]: p.fields.key };
      });
    });

    setFileQueue([]);

    return eventArtistData;
  }

  function validatePictures(data: any) {
    const imageFields = ['image'];

    const notUploadedImages = imageFields.filter((field) => !data[field]);

    notUploadedImages.forEach((field) => {
      setError(field, 'Please upload an image first!');
    });

    if (!!notUploadedImages.length) {
      return false;
    }

    return true;
  }

  function updateData(fieldName: string, value: any) {
    clearErrors(fieldName);
    setData((prevData: FormData) => ({ ...prevData, [fieldName]: value }));
  }

  function addFileInQueue(key: string, file: File) {
    clearErrors(key);
    setData(key, '');
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }

  function getTitle() {
    return `${artistId ? 'Update' : 'Create'} EventArtist`;
  }

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  return (
    <div className='py-8'>
      <div className='max-w-8xl mx-auto sm:px-6 lg:px-8'>
        <form name='createForm' onSubmit={handleSubmit}>
          <Stack spacing={4} divider={<Divider />}>
            <Stack direction={direction} spacing={2}>
              <div className='ml-2  lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the Artist
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4 }} className='w-screen'>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      select
                      id='artist_id'
                      name='Artists'
                      label='Artists'
                      error={!!errors.artist_id}
                      helperText={errors.artist_id?.[0]}
                      fullWidth
                      value={data.artist_id}
                      onChange={(e) => updateData('artist_id', e?.target.value)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: '250px',
                            },
                          },
                        },
                      }}
                      disabled={isArtistDisabled}
                    >
                      {artists &&
                        artists.data.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Artist Image'
                        allowedFileTypes={allowedImageTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('image', file)}
                        preview
                        previewData={imagePreviews.image}
                        previewProps={{ style: { width: '15rem', objectFit: 'contain' } }}
                      />

                      {errors['image'] && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>{errors['image']}</FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      select
                      id='day'
                      name='day'
                      label='Day'
                      error={!!errors.day}
                      helperText={errors.day?.[0]}
                      fullWidth
                      inputProps={{ min: 0 }}
                      value={data.day != '' ? data.day : ''}
                      onChange={(e) => updateData('day', e?.target.value)}
                      SelectProps={{
                        multiple: false, // Ensure multiple is set to false
                        renderValue: (selected: any) => selected,
                      }}
                    >
                      {eventDays.map((day, index) => (
                        <MenuItem key={index} value={index + 1}>
                          <Radio checked={data.day === index + 1} />
                          <ListItemText
                            primary={`Day ${index + 1} - ${day.toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}`}
                          />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={!!data.headliner}
                            onChange={(e) => updateData('headliner', e.target.checked)}
                            name='headliner'
                          />
                        </div>
                      }
                      label='Headliner'
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
          </Stack>

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton loading={loading}>
              <Button type='submit' variant='contained' disabled={loading} color='success'>
                Save
              </Button>
            </LoadingButton>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CreateOrEdit;
