import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_CSV_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import { useResponsive } from '@/Hooks/useResponsive';
import TemplateDownload from '@/Components/Molecules/TemplateDownload/TemplateDownload';
import environment from '@/environments/environment';
import { getFormDataFromObject } from '@/Utils/general';
import { useImportTicketCodesMutation } from '@/Api/ticketCodesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import useLoading from '@/Hooks/useLoading';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';

const CreateOrEditTicketCodes = () => {
  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ code: string; csv_file: File | undefined }>({
    defaultValues: {
      csv_file: undefined,
    },
  });

  const allowedFileTypes = ['text/csv'];
  const { isMobile } = useResponsive();
  const { ticketId, eventId } = useParams();
  const navigate = useNavigate();
  const [isLoading, withLoading] = useLoading();
  const [importTicketCodes] = useImportTicketCodesMutation();

  const onSubmit: SubmitHandler<{ code: string; csv_file: File | undefined }> = async (data) => {
    if (data.csv_file) {
      const csvData = getFormDataFromObject(data);

      const payload = {
        eventId: eventId!,
        ticketId: ticketId!,
        data: csvData,
      };

      withLoading(importTicketCodes(payload).unwrap())
        .then(() => {
          navigate(`/events/${eventId}/ticket-codes`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onFileUpload = (file: File) => {
    setValue('csv_file', file);
  };

  return (
    <div className='py-8'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
        <form name='passcodeForm' noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 ml-2 flex-shrink-0 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>Ticket Codes import</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Import CSV file which contains the tickets codes.
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4, flex: 1 }}>
                  <Stack spacing={2}>
                    <Controller
                      name='csv_file'
                      control={control}
                      render={({ field }) => (
                        <FileUpload
                          label='Attachment'
                          allowedFileTypes={allowedFileTypes}
                          maxFileSize={MAX_CSV_FILE_SIZE_IN_BYTES}
                          onSelect={onFileUpload}
                          onError={() => {
                            // Handle file upload errors
                          }}
                        />
                      )}
                    />
                    {errors.csv_file && errors.csv_file.type !== CustomZodErrorType.INPUT_FILE && (
                      <FormControl error>
                        <FormHelperText className='!ml-0'>{errors.csv_file.message}</FormHelperText>
                      </FormControl>
                    )}
                    <TemplateDownload
                      link={environment.baseUrl + '/downloads/ticket-codes-template.csv'}
                    />
                  </Stack>
                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <LoadingButton loading={isLoading}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='success'
                        disabled={isLoading}
                      >
                        Import
                      </Button>
                    </LoadingButton>
                  </Box>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CreateOrEditTicketCodes;
