import { useGetServiceStatsQuery, useGetEventServicesQuery } from '@/Api/servicesSlice';
import { useGetEventQuery } from '@/Api/eventsSlice';
import StatsCard, { StatsCardProps } from '@/Components/Molecules/Cards/StatsCard';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import { AttachMoney, Equalizer, Functions, Sync } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ServicesTable from '../Services/ServicesTable';
import EmptyContent from '../Info/EmptyContent';
import ChangeServicesDialog from './ChangeEventServicesDialog';
import { Event } from '@/Models/events';
import { useResponsive } from '@/Hooks/useResponsive';

const pageSize = 50;

const cardDetails: {
  [key: string]: Omit<StatsCardProps, 'value'>;
} = {
  totalServicesCount: {
    title: 'Total Services Count',
    Icon: Functions,
  },
  totalSumOfServices: {
    title: 'Total Net Amount',
    Icon: AttachMoney,
  },
};

const EventServices = () => {
  const [archiveType, setArchiveType] = useState('default');
  const { eventId } = useParams();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  const { data: serviceStats } = useGetServiceStatsQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: services,
    isFetching,
    isLoading,
  } = useGetEventServicesQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  function onDialogClose() {
    setDialogOpen(false);
  }
  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  const noCausesApplied = !isFetching && !services?.data?.length;
  const canShowTable = !isLoading && !!services?.data?.length;

  return (
    <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
      <Stack spacing={2} className='h-full'>
        <Stack direction={direction} spacing={2}>
          {Object.keys(serviceStats || []).map((key) => {
            return (
              <StatsCard
                key={key}
                className='col-1'
                title={cardDetails[key].title}
                value={serviceStats[key]}
                Icon={cardDetails[key].Icon}
              />
            );
          })}
        </Stack>

        <Stack spacing={2} className='pb-4 flex-1'>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h5'>Services</Typography>
            <PermissionsGate scope={SCOPES.ManageDonationCause}>
              {canShowTable && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant='contained'
                    startIcon={<Sync />}
                    onClick={() => setDialogOpen(true)}
                  >
                    Change Services
                  </Button>
                </div>
              )}
            </PermissionsGate>
          </Stack>

          {noCausesApplied && (
            <EmptyContent
              primaryText='No Services'
              secondaryText='You can add services to your event'
              button={
                <PermissionsGate scope={SCOPES.ManageDonationCause}>
                  <Button
                    variant='contained'
                    startIcon={<Sync />}
                    onClick={() => setDialogOpen(true)}
                  >
                    Add Service
                  </Button>
                </PermissionsGate>
              }
            />
          )}

          {canShowTable && (
            <div className='flex-1'>
              <ServicesTable
                services={services}
                columns={['name', 'description', 'totalQuantity', 'totalAmount', 'actions']}
                pageSize={pageSize}
                onPageChange={setPage}
                event={event as Event}
              />
            </div>
          )}
        </Stack>
      </Stack>

      {dialogOpen && services && (
        <ChangeServicesDialog
          eventId={eventId!}
          selectedServicesIds={services?.data?.map((dc) => `${dc.id}`)}
          open={dialogOpen}
          onDialogClose={onDialogClose}
        />
      )}
    </div>
  );
};

export default EventServices;
