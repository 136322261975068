import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Faq, FaqData } from '@/Models/faqs';

export const faqsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query<FaqData, FilterData<'upcoming'>>({
      query: (filterData) => ({
        url: `/public/faqs`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getFaq: builder.query<Faq, string>({
      query: (faqId) => ({
        url: `/faqs/${faqId}`,
        method: 'GET',
      }),
    }),
    createFaq: builder.mutation<Faq, any>({
      query: (body) => ({
        url: `/faqs`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    updateFaq: builder.mutation<Faq, { faqId: String; body: any }>({
      query: ({ faqId, body }) => ({
        url: `/faqs/${faqId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    deleteFaq: builder.mutation<any, string>({
      query: (faqId) => ({
        url: `/faqs/${faqId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
  }),
});

export const {
  useGetFaqsQuery,
  useGetFaqQuery,
  useCreateFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} = faqsApi;
