import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ArticleForm, articleFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { FileContext, ResourceType } from '@/Enums/files';
import {
  useCreateArticleMutation,
  useGetArticleQuery,
  useUpdateArticleMutation,
} from '@/Api/articlesSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { PreviewData } from '@/Models/preview';
import useLoading from '@/Hooks/useLoading';
import { cleanObject } from '@/Utils/general';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import ReactQuill from 'react-quill';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  useDeleteArticleTagsMutation,
  useGetArticleTagsQuery,
  useCreateArticleTagsMutation,
  useGetTagsQuery,
} from '@/Api/tagsSlice';
import { Tag } from '@/Models/tags';
import { number } from 'zod';

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
const fileKeys = ['desktop_image', 'mobile_image'] as const;
type FileKey = typeof fileKeys[number];

const CreateOrEditArticle = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();
  const [fileQueue, setFileQueue] = useState<{ key: FileKey; file: File }[]>([]);

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [createArticle] = useCreateArticleMutation();
  const [updateArticle] = useUpdateArticleMutation();

  const [createArticleTags] = useCreateArticleTagsMutation();
  const [tag, setTag] = useState<string[]>([]);
  const [deleteArticleTag] = useDeleteArticleTagsMutation();

  const { data: tags = { data: [] } } = useGetTagsQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: articleTags, refetch } = useGetArticleTagsQuery(articleId!, {
    refetchOnMountOrArgChange: true,
    skip: !articleId,
  });

  const { data: article } = useGetArticleQuery(articleId!, {
    refetchOnMountOrArgChange: false,
    skip: !articleId,
  });

  const isCreatingArticle = !articleId;

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<ArticleForm>({
    defaultValues: {
      title: '',
      slug: '',
      author: '',
      content: '',
      desktop_image: '',
      mobile_image: '',
      published: false,
      meta_description: '',
      tag: [],
      id: '',
    },
    resolver: zodResolver(articleFormSchema),
  });

  useEffect(() => {
    if (!article) {
      return;
    }

    reset({
      ...cleanObject(article),
    });
  }, [article]);

  const imagePreviews: { [key in FileKey]?: PreviewData } = useMemo(() => {
    return {
      desktop_image: article && { type: 'image', url: article.desktop_image },
      mobile_image: article && { type: 'image', url: article.mobile_image },
    };
  }, [article]);

  function getTitle() {
    return `${!!articleId ? 'Edit' : 'Create'} Article `;
  }

  function addFileInQueue(key: FileKey, file: File) {
    clearErrors(key);
    setValue(key, file);
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }

  const tagMap = new Map(tags.data.map((tag: Tag) => [tag.name, tag]));

  function getTags(tagNames: string[]): { name: string }[] {
    return tagNames.map((name) => {
      const tag = tagMap.get(name);
      return { name };
    });
  }

  async function onSubmit() {
    let articleData = { ...getValues() };

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles());
      articleData = { ...articleData, ...processedFiles };
    }

    if (!validateImages(articleData)) {
      return;
    }

    let promise: Promise<any>;
    if (isCreatingArticle) {
      promise = createArticle(articleData).unwrap();
    } else {
      promise = updateArticle({ articleId, body: articleData }).unwrap();
    }

    withLoading(promise)
      .then((article) => {
        const tagData = getTags(tag);

        const body = {
          tags: tagData,
        };

        return createArticleTags({
          articleId: article.id! || articleId!,
          body,
        }).unwrap();
      })
      .then(() => {
        navigate('/articles');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof ArticleForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  async function processFiles(): Promise<Pick<ArticleForm, FileKey>> {
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.ARTICLES,
      })),
    );

    const articleData: Pick<ArticleForm, FileKey> = { desktop_image: '', mobile_image: '' };
    signedUrlPayloads.forEach((payload, i) => {
      articleData[fileData[i].key] = payload.fields.key;
      setValue(fileData[i].key, payload.fields.key);
    });
    setFileQueue([]);

    return articleData;
  }

  function validateImages(data: ArticleForm): boolean {
    const notUploadedImages = fileKeys.filter((key) => !data[key]);

    notUploadedImages.forEach((key) => {
      setError(key, {
        type: 'manual',
        message: 'Please upload an image first',
      });
    });

    return !notUploadedImages.length;
  }

  const { isMobile } = useResponsive();

  const theme = createTheme({
    palette: {
      error: {
        main: '#FFA500',
      },
    },
  });

  useEffect(() => {
    if (articleTags?.data) {
      refetch();
      const tagNames = articleTags.data
        .filter((item) => item.tag !== undefined)
        .map((item) => item.tag.name);
      setTag(tagNames);
    }
  }, [articleTags]);

  function handleDeleteTag(tagId: string, articleId: string) {
    if (tagId) {
      deleteArticleTag({ articleId, tagId })
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.error('Error handling tag deletion:', error);
        });
    } else {
      const updatedTags = tag.filter((tagName) => tagName !== tagName);
      setTag(updatedTags);
    }
  }

  function removeTag(tagName: string) {
    setTag((prevTags) => prevTags.filter((tag) => tag !== tagName));
  }

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the cause
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='title'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Title'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='slug'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Slug'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='author'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Author'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='content'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <ReactQuill {...field} theme='snow' />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='meta_description'
                      control={control}
                      render={({ field, fieldState: { error, isDirty } }) => (
                        <ThemeProvider theme={theme}>
                          <TextField
                            {...field}
                            label='Meta Description'
                            variant='outlined'
                            fullWidth
                            error={
                              isDirty &&
                              (!!error || field.value.length < 80 || field.value.length > 160)
                            }
                            helperText={
                              error?.message ||
                              (isDirty && (field.value.length < 80 || field.value.length > 160)
                                ? `Meta description must be either empty or contain between 80 to 160 characters. You've entered ${field.value.length} characters.`
                                : `You've entered ${field.value.length} characters.`)
                            }
                          />
                        </ThemeProvider>
                      )}
                    />
                  </Grid>

                  {tags.data && (
                    <Grid item xs={12}>
                      <Controller
                        name='tag'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            multiple
                            freeSolo
                            options={tags.data.length ? tags.data.map((tag: Tag) => tag.name) : []}
                            value={tag.map((val) => {
                              const foundTag = tags.data.find((tag) => tag.name === val);
                              return foundTag ? foundTag.name : val;
                            })}
                            onChange={(event, newValue) => {
                              setTag(newValue);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                              }
                            }}
                            renderTags={(value: string[], getTagProps) =>
                              value.map((option: string, index: number) => {
                                const tag = tags.data.find((tag) => tag.name === option);
                                return (
                                  <Chip
                                    variant='outlined'
                                    label={option}
                                    {...getTagProps({ index })}
                                    onDelete={() => {
                                      if (tag && articleId) {
                                        handleDeleteTag(tag.id, articleId);
                                      } else {
                                        removeTag(option);
                                      }
                                    }}
                                  />
                                );
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Tags'
                                InputProps={{ ...params.InputProps, endAdornment: null }}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Stack>
                      <Controller
                        name='desktop_image'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <FileUpload
                            label='Desktop Image'
                            allowedFileTypes={allowedFileTypes}
                            maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                            onSelect={(file) => addFileInQueue('desktop_image', file)}
                            preview
                            previewData={imagePreviews.desktop_image}
                            helperText='Recommended size: 1440x684px'
                            onError={(message) =>
                              setError('desktop_image', {
                                message,
                                type: CustomZodErrorType.INPUT_FILE,
                              })
                            }
                          />
                        )}
                      />

                      {!!errors.desktop_image &&
                        errors.desktop_image.type !== CustomZodErrorType.INPUT_FILE && (
                          <FormControl error>
                            <FormHelperText className='!ml-0'>
                              Please upload an image first
                            </FormHelperText>
                          </FormControl>
                        )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Controller
                        name='mobile_image'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <FileUpload
                            label='Mobile Image'
                            allowedFileTypes={allowedFileTypes}
                            maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                            onSelect={(file) => addFileInQueue('mobile_image', file)}
                            preview
                            previewData={imagePreviews.mobile_image}
                            helperText='Recommended size: 390x678px'
                            onError={(message) =>
                              setError('mobile_image', {
                                message,
                                type: CustomZodErrorType.INPUT_FILE,
                              })
                            }
                          />
                        )}
                      />

                      {!!errors.mobile_image &&
                        errors.mobile_image.type !== CustomZodErrorType.INPUT_FILE && (
                          <FormControl error>
                            <FormHelperText className='!ml-0'>
                              Please upload an image first
                            </FormHelperText>
                          </FormControl>
                        )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='subtitle1' className='pb-2'>
                      Published
                    </Typography>
                    <Controller
                      name='published'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <IOSSwitch {...field} checked={field.value} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEditArticle;
