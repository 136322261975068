import React from 'react';
import { Attendee } from '@/Models/attendees';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Stack } from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import { useGetOrderAttendesQuery } from '@/Api/attendeesSlice';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import { formatDate } from '@/Utils/dates';
import environment from '@/environments/environment';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

const OrderAttendees = ({ open, onDialogClose, referenceNumber, eventId }: any) => {
  const advancedFetch = useAdvancedFetch();

  const { data: orderAttendes } = useGetOrderAttendesQuery(
    {
      eventId: eventId!,
      referenceNumber: referenceNumber!,
      // filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  function onTicketClick(barcode: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${eventId}/attendees/${barcode}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((res) => {
      handleBlobReponse(res);
    });
  }

  const columnsAttendees: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'First name',
      width: 100,
      sortable: false,
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      width: 100,
      sortable: false,
    },
    { field: 'email', headerName: 'Email', width: 230, sortable: false },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 130,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketClick(params.value);
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'arrived',
      headerName: 'Arrived',
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <div
              className='w-2 h-2 rounded-full'
              style={{
                background: row.arrived ? 'rgb(50 225 121)' : 'rgba(0, 0, 0, 0.47)',
              }}
            ></div>
            <span>{row.arrived ? 'Yes' : 'No'}</span>
          </Stack>
        );
      },
      sortable: false,
    },
    {
      field: 'arrived_at',
      width: 220,
      headerName: 'Arrived at',
      renderCell: ({ row }) => (
        <EmptyCell condition={row.arrived}>
          <div>{formatDate(row.arrived_at)}</div>
        </EmptyCell>
      ),
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      sortable: false,
    },
  ];

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogTitle>Order attendees</DialogTitle>
      <DialogContent style={{ width: '100%', height: '700px' }}>
        {orderAttendes && <DataGrid rows={orderAttendes} columns={columnsAttendees} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDialogClose(false)} color='info'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderAttendees;
