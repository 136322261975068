import { generateSignedUrl, uploadFile } from '@/Api/filesSlice';
import { GenerateSignedPostUrlPayload, SignedPutUrlPayload, SignedUrlFields } from '@/Models/files';
import imageCompression from 'browser-image-compression';
import { store } from '@/store';
import { DistributedOmit } from '@/types/general';
import { handleBlobReponse } from '@/Utils/blob';

declare type ImageCompressionOptions = Parameters<typeof imageCompression>[1];

// TODO front: error handling
export async function uploadFilesToAwsS3(
  filesData: ({ file: File } & DistributedOmit<
    GenerateSignedPostUrlPayload,
    'filename' | 'fileType'
  >)[],
): Promise<SignedPutUrlPayload[]> {
  if (!filesData?.length) {
    return [];
  }

  const signedUrlPayloads =
    (await store
      .dispatch(
        generateSignedUrl.initiate({
          payload: filesData.map((f) => ({
            filename: f.file.name,
            fileType: f.file.type,
            ...f,
          })),
        }),
      )
      .unwrap()) || [];

  if (!!signedUrlPayloads.length) {
    await Promise.all(
      signedUrlPayloads.map((p, i) => {
        const formData = new FormData();
        Object.keys(p.fields).forEach((key) => {
          formData.append(key, p.fields[key as keyof SignedUrlFields]);
        });
        formData.append('Content-Type', filesData[i].file.type);
        formData.append('file', filesData[i].file);
        return store.dispatch(uploadFile.initiate({ url: p.baseUrl, formData })).unwrap();
      }),
    );
  }

  return signedUrlPayloads;
}

const defaultCompressOptions: ImageCompressionOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
};

export async function compressImage(file: File, options?: ImageCompressionOptions) {
  return await imageCompression(file, {
    ...defaultCompressOptions,
    ...options,
  });
}

export async function downloadFile(downloadUrl: string, filename: string) {
  fetch(downloadUrl, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      handleBlobReponse(response, {
        handleMethod: 'download',
        fileName: filename,
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
