import { createSlice } from '@reduxjs/toolkit';
import { VenueEditorState } from '@festtix/venue-editor';

const initialState: VenueEditorState = {
  currentTool: 'navigate',
};

export const venueEditorSlice = createSlice({
  name: 'venueEditor',
  initialState,
  reducers: {
    setCurrentTool: (state, action) => {
      state.currentTool = action.payload;
    },
  },
});

export const { setCurrentTool } = venueEditorSlice.actions;

export const venueEditorReducer = venueEditorSlice.reducer;
