import { useGetServicesQuery } from '@/Api/servicesSlice';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { Category } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import EmptyContent from '../Info/EmptyContent';
import ServicesTable from './ServicesTable';

const pageSize = 50;

const Services = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Services'));
    dispatch(
      setHeader({
        title: 'Services',
      }),
    );
  }, []);

  const {
    data: services,
    isFetching,
    isLoading,
  } = useGetServicesQuery(
    {
      pageData: { page, pageSize },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const tableComponent = useMemo(() => {
    const noDataText = 'No services found';

    return services?.data?.length ? (
      <ServicesTable
        services={services}
        columns={['name', 'description', 'actions']}
        pageSize={pageSize}
        onPageChange={setPage}
      />
    ) : (
      <EmptyContent
        icon={<Category sx={{ color: '#cccccc', fontSize: '8rem' }} />}
        primaryText={noDataText}
        secondaryText='Create a new service to get started'
        button={
          <PermissionsGate scope={SCOPES.ManageDonationCause}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={`create`} style={{ marginLeft: '10px' }}>
                <Button variant='contained'>Create Service</Button>
              </Link>
            </div>
          </PermissionsGate>
        }
      />
    );
  }, [services, setPage]);

  if (isLoading || isFetching) return null;

  return (
    <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
      <Stack spacing={2} className='h-full'>
        <Box className='lg:self-end'>
          <PermissionsGate scope={SCOPES.ManageDonationCause}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='create' style={{ marginLeft: '10px' }}>
                <Button variant='contained'>Create Service</Button>
              </Link>
            </div>
          </PermissionsGate>
        </Box>
        <div className='grow'>{tableComponent}</div>
      </Stack>
    </div>
  );
};

export default Services;
