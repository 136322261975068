import { useForm, Controller } from 'react-hook-form';
import { SeatSectionMapForm } from './seat-section-mapper-form-helpers';
import {
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useContext, useMemo } from 'react';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';

interface SeatSectionMapperFormProps {
  onSubmit: (data: SeatSectionMapForm) => void;
}

function SeatSectionMapperForm({ onSubmit }: SeatSectionMapperFormProps) {
  const { sectionIdentifierService } = useContext(VenueEditorContext);
  const { control, handleSubmit } = useForm<SeatSectionMapForm>({
    defaultValues: {
      sectionId: '',
    },
  });

  const sectionData = sectionIdentifierService.getSectionData();
  const sectionColors = sectionIdentifierService.getSectionColors();

  const sectionIds = useMemo(() => {
    return Object.keys(sectionData);
  }, [sectionData]);

  return (
    <form name='seatCategorizationForm' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='sectionId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  label='Section'
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                >
                  {!!sectionIds
                    ? sectionIds.map((sectionId) => (
                        <MenuItem key={sectionId} value={sectionId}>
                          <ListItem component='div'>
                            <ListItemAvatar>
                              <div
                                className={`rounded-full w-8 h-8`}
                                style={{
                                  backgroundColor: sectionColors[sectionId],
                                }}
                              ></div>
                            </ListItemAvatar>
                            <ListItemText primary={sectionData[sectionId].label} />
                          </ListItem>
                        </MenuItem>
                      ))
                    : []}
                </TextField>
              )}
            />
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent='flex-end' className='mt-4'>
          <Button type='submit' variant='contained'>
            Apply
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default SeatSectionMapperForm;
