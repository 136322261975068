import {
  useCheckInGuestMutation,
  useGetGuestInvitationQuery,
  useResetCheckInGuestMutation,
} from '@/Api/guestsSlice';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import { DialogProps } from '@/Models/dialogs';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import { formatDate } from '@/Utils/dates';
import environment from '@/environments/environment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Event } from '@/Models/events';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { useDispatch } from 'react-redux';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { CheckCircleOutline, EditOutlined, MoreVert, ReplayOutlined } from '@mui/icons-material';
import { Guest } from '@/Models/guests';
import { HTTP_CODE } from '@/Utils/http-client';
import { showSnackbar } from '@/Slices/snackbarSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import Edit from './Edit';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

interface GuestProps extends DialogProps<any> {
  uuid: string | null;
}

const GroupedGuests = ({ open, onDialogClose, uuid }: GuestProps) => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);
  const [checkInDialogOpen, setCheckInDialogOpen] = useState(false);
  const [resetCheckInDialogOpen, setResetCheckInDialogOpen] = useState(false);
  const [resetCheckIn, { isLoading: loadingResetCheckIn }] = useResetCheckInGuestMutation();

  const advancedFetch = useAdvancedFetch();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    guest: Guest;
  }>(null);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const pageSize = 10;

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const { data: guestData, refetch } = useGetGuestInvitationQuery(
    {
      eventId: eventId!,
      filterData: { search: search, pageData: { page, pageSize } },
      uuid: uuid!,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [checkIn, { isLoading: loadingCheckIn }] = useCheckInGuestMutation();

  function onTicketClick(event: Event, barcode: string) {
    advancedFetch(`${environment.baseUrl}/events/${event.id}/guests/${barcode}/ticket?download=1`, {
      method: 'GET',
    }).then((response) => {
      handleBlobReponse(response);
    });
  }

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  function onPageChange(page: number) {
    setPage(page);
  }

  function showActionMenu(guest: Guest, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, guest });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  function onEditDialogClose(value: boolean) {
    setEditDialogOpen(false);

    if (!value) {
      return;
    }
  }

  const confirmCheckIn = () => {
    if (selectedGuest) {
      checkIn({
        eventId: eventId!,
        guestId: selectedGuest.id,
      })
        .unwrap()
        .then(() => {
          setCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const confirmResetCheckIn = () => {
    if (selectedGuest) {
      resetCheckIn({
        eventId: eventId!,
        guestId: selectedGuest.id,
      })
        .unwrap()
        .then(() => {
          setResetCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const columns: GridColDef[] = [
    { field: 'first_name', headerName: 'First name', sortable: false },
    { field: 'last_name', headerName: 'Last name', width: 130, sortable: false },
    { field: 'email', headerName: 'Email', width: 230, sortable: false },
    {
      field: 'invitationType',
      headerName: 'Invitation Title',
      valueGetter: ({ row }) => row.invitation_type.title,
      flex: 1,
      sortable: false,
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 130,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketClick(event!, params.value);
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'arrived',
      headerName: 'Arrived',
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <div
              className='w-2 h-2 rounded-full'
              style={{
                background: row.arrived ? 'rgb(50 225 121)' : 'rgba(0, 0, 0, 0.47)',
              }}
            ></div>
            <span>{row.arrived ? 'Yes' : 'No'}</span>
          </Stack>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'arrived_at',
      headerName: 'Arrived at',
      renderCell: ({ row }) => (
        <EmptyCell condition={row.arrived}>
          <div>{formatDate(row.arrived_at)}</div>
        </EmptyCell>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageAttendee}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenu(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  return (
    <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle>Guests</DialogTitle>
      <DialogContent>
        <div className='h-[50vh]'>
          {guestData?.data && guestData.data.length > 0 && (
            <DataGrid
              columns={columns}
              rows={guestData.data}
              pageSize={pageSize}
              paginationMode='server'
              onPageChange={onPageChange}
              rowsPerPageOptions={[pageSize]}
              rowCount={guestData.total}
              components={{ Toolbar: GridToolbar }}
              filterMode='server'
              onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 1000 },
                },
              }}
            />
          )}
        </div>

        {menuContext && (
          <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
            <MenuItem
              onClick={() => {
                setEditDialogOpen(true);
                setSelectedGuest(menuContext.guest);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setCheckInDialogOpen(true);
                setSelectedGuest(menuContext.guest);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <CheckCircleOutline />
              </ListItemIcon>
              <ListItemText>Check-in</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setResetCheckInDialogOpen(true);
                setSelectedGuest(menuContext.guest);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <ReplayOutlined color='error' />
              </ListItemIcon>
              <ListItemText>Reset check-in</ListItemText>
            </MenuItem>
          </Menu>
        )}

        {editDialogOpen && selectedGuest && event ? (
          <Edit
            eventId={event.id}
            guest={selectedGuest}
            open={editDialogOpen}
            onDialogClose={(value) => onEditDialogClose(value)}
          />
        ) : (
          ''
        )}

        {checkInDialogOpen && selectedGuest ? (
          <Dialog
            open={checkInDialogOpen}
            aria-labelledby='check-in-dialog-title'
            aria-describedby='check-in-dialog-description'
          >
            <DialogTitle id='check-in-dialog-title'>
              Are you sure you want to check-in this guest?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='check-in-dialog-description'>
                Keep in mind that this action could affect the on-site check-in process. Please be
                sure that you want to make this action.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCheckInDialogOpen(false)} color='success'>
                Close
              </Button>
              <LoadingButton loading={loadingCheckIn}>
                <Button onClick={confirmCheckIn} disabled={loadingCheckIn} color='error'>
                  Confirm Check-in
                </Button>
              </LoadingButton>
            </DialogActions>
          </Dialog>
        ) : (
          ''
        )}

        {resetCheckInDialogOpen && selectedGuest ? (
          <Dialog
            open={resetCheckInDialogOpen}
            aria-labelledby='reset-check-in-dialog-title'
            aria-describedby='reset-check-in-dialog-description'
          >
            <DialogTitle id='reset-check-in-dialog-title'>
              Are you sure you want to reset check-in for this guest?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='reset-check-in-dialog-description'>
                Keep in mind that this action could affect the on-site check-in process. Please be
                sure that you want to make this action.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setResetCheckInDialogOpen(false)} color='success'>
                Close
              </Button>
              <LoadingButton loading={loadingCheckIn}>
                <Button onClick={confirmResetCheckIn} disabled={loadingResetCheckIn} color='error'>
                  Confirm Reset Check-in
                </Button>
              </LoadingButton>
            </DialogActions>
          </Dialog>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GroupedGuests;
