import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Stack,
  Alert,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import useForm from '@/Hooks/useForm';
import { useCopyEventMutation } from '@/Api/eventsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { Event } from '@/Models/events';

type CopyEventProps = {
  eventId: string;
};

const CopyEvent = ({ open, onDialogClose, eventId }: DialogProps<CopyEventProps>) => {
  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
    skip: !eventId,
  });

  const { data, setData, errors, setError, clearErrors } = useForm({
    eventId: eventId,
    title: '',
    slug: '',
    starts_at: '',
    ends_at: '',
    copy_tickets: false,
    private_event: false,
  });

  useEffect(() => {
    if (event) {
      setData((prevData) => ({
        ...prevData,
        title: event.title,
        slug: event.slug,
        starts_at: event.starts_at,
        ends_at: event.ends_at,
      }));
    }
  }, [event]);

  const [copyEvent] = useCopyEventMutation();

  function copyEventMethod(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    copyEvent({ eventId: eventId, body: data })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors || {});
        }
      });
  }

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  const createSlug = (title: any) => {
    return title
      .slice(0, 50)
      .toLowerCase()
      .replace(/[^a-z0-9-]/gi, '-');
  };

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='eventCopy' onSubmit={copyEventMethod}>
        <DialogTitle>Copy Event</DialogTitle>
        <DialogContent className='overflow-auto'>
          <div className='w-90 py-2'>
            <Stack sx={{ width: '100%', marginBottom: '30px' }} spacing={3}>
              <Typography sx={{ fontSize: '13px' }}>
                Event setup will be copied from <b>{event?.title}</b>. This can be updated at any
                time.
              </Typography>
              <Alert severity='info'>
                This event won't be publicily available until you publish it.
              </Alert>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id='title'
                  name='title'
                  label='Event Name'
                  error={!!errors.title}
                  helperText={errors.title?.[0]}
                  fullWidth
                  value={data?.title}
                  onChange={(e) => updateData('title', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='slug'
                  name='slug'
                  label='Event Slug'
                  error={!!errors.slug}
                  helperText={
                    errors.slug?.[0] ||
                    'This slug will be used in the event URL. Maximum length is 50 characters.'
                  }
                  fullWidth
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={data?.slug}
                  onChange={(e) => updateData('slug', e?.target.value)}
                  onFocus={(e) => updateData('slug', createSlug(data.title))}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.copy_tickets}
                      onChange={(e) => updateData('copy_tickets', e.target.checked)}
                      name='copy_tickets'
                    />
                  }
                  label={<Typography variant='body1'>Copy Event Tickets</Typography>}
                />
                <Typography variant='body2' sx={{ marginLeft: '32px', marginTop: '-7px' }}>
                  Ticket dates will be updated relative to the event date
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.private_event}
                      onChange={(e) => updateData('private_event', e.target.checked)}
                      name='private_event'
                    />
                  }
                  label={<Typography variant='body1'>Private Event</Typography>}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <Button type='submit' variant='contained' color='success'>
            Copy Event
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CopyEvent;
