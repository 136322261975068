import { useContext, useEffect, useState } from 'react';
import useEffectOnce from '@/Hooks/useEffectOnce';
import { useLazyGetTicketsQuery } from '@/Api/ticketsSlice';
import {
  useLazyGetAvailableSeatsQuery,
  useLazyGetEventVenueQuery,
  useLazyGetSeatDataFromEventQuery,
  useLazyGetSectionDataFromEventQuery,
} from '@/Api/seatingSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Ticket } from '@/Models/tickets';
import { useLazyGetEventQuery } from '@/Api/eventsSlice';
import { Event } from '@/Models/events';
import { adaptSeatData, adaptSectionData } from '@/Utils/seating';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { Subscription } from 'rxjs';
import VenueViewer from '@/Components/Custom/Seats/VenueViewer';
import { useCancelTransactionMutation, useGetTransactionDataQuery } from '@/Api/transactionsSlice';
import { DataKey, LocalTransactionData } from '@/Models/local-storage';
import { FSTXLocalStorage } from '@/Utils/local-storage';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import TimeExpired from '@/Components/Custom/Checkout/TimeExpired';
import { differenceInSeconds } from 'date-fns';
import useTimer from '@/Hooks/useTimer';
import { AccessTimeOutlined } from '@mui/icons-material';
import { getCountdownFromSeconds } from '@/Utils/dates';

interface SeatsProps {}

function Seats({}: SeatsProps) {
  const [svgContent, setSvgContent] = useState<string>('');
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [event, setEvent] = useState<Event>();
  const { eventId } = useParams<{ eventId: string; venueId: string }>();
  const [availableSeats, setAvailableSeats] = useState<{ [seatId: string]: boolean }>({});
  const { venueEditorService, sectionIdentifierService, seatIdentifierService } =
    useContext(VenueEditorContext);
  const [selectedSeatIds, setSelectedSeatIds] = useState<string[]>([]);
  const [localTransactionData, setLocalTransactionData] = useState<LocalTransactionData | null>(
    null,
  );
  const navigate = useNavigate();

  const { timeLeft, isFinished: isTimerFinished, start: startTimer } = useTimer();

  const [getTicketsRequest] = useLazyGetTicketsQuery();
  const [getEventVenueDataRequest] = useLazyGetEventVenueQuery();
  const [getEventData] = useLazyGetEventQuery();
  const [getAvailableSeats] = useLazyGetAvailableSeatsQuery();
  const [getVenueSectionData] = useLazyGetSectionDataFromEventQuery();
  const [getVenueSeatData] = useLazyGetSeatDataFromEventQuery();

  useEffect(() => {
    const localTransactionData1 = FSTXLocalStorage.getItem(DataKey.TRANSACTION);
    setLocalTransactionData(localTransactionData1);
  }, []);

  const [trId, setTrId] = useState(localTransactionData?.transactionId || '');

  useEffect(() => {
    if (localTransactionData) {
      setTrId(localTransactionData.transactionId);
    }
  }, [localTransactionData, eventId]);

  const { data: transactionData } = useGetTransactionDataQuery(
    {
      eventId: eventId!,
      transactionId: trId,
      withReservedTickets: true,
      withReservedSeats: true,
    },
    { refetchOnMountOrArgChange: true, skip: !trId },
  );

  useEffect(() => {
    if (transactionData) {
      startTimerIfValid(transactionData);
    }
  }, [transactionData]);

  function startTimerIfValid(transactionData: any) {
    const initialTime = getTransactionTimeLeft(transactionData);
    if (initialTime < 0) {
      return;
    }

    startTimer(Math.max(1, initialTime - 3));
  }

  function getTransactionTimeLeft(transactionData: { expires_at_date: string | number | Date }) {
    return differenceInSeconds(new Date(transactionData.expires_at_date), new Date());
  }

  useEffectOnce(() => {
    Promise.all([
      getTicketsRequest({ eventId: eventId!, includeInvitationTypes: true }),
      getEventVenueDataRequest(eventId!),
      getEventData(eventId!),
      getAvailableSeats({ eventId: eventId!, isOrder: true }),
      getVenueSectionData(eventId!),
      getVenueSeatData({ eventId: eventId! }),
    ]).then(
      ([
        { data: tickets },
        { data: eventVenueData },
        { data: event },
        { data: availableSeats },
        { data: venueSectionData },
        { data: venueSeatData },
      ]) => {
        if (
          !tickets ||
          !eventVenueData ||
          !event ||
          !availableSeats ||
          !venueSectionData ||
          !venueSeatData
        )
          return;

        setEvent(event);
        setTickets(tickets);
        setAvailableSeats(availableSeats);

        sectionIdentifierService.loadInitialSectionData(adaptSectionData(venueSectionData));
        seatIdentifierService.loadInitialSeatData(adaptSeatData(venueSeatData));

        fetch(eventVenueData.svg)
          .then((res) => res.text())
          .then((text) => {
            setSvgContent(text);
          });
      },
    );
  });

  useEffect(() => {
    const subscriptions = new Subscription();

    subscriptions.add(
      venueEditorService.getSelectedElementsIds$().subscribe((ids) => {
        setSelectedSeatIds(ids);
      }),
    );

    return () => {
      subscriptions.unsubscribe();
    };
  }, []);

  if (!transactionData) {
    return <></>;
  }

  return (
    <>
      {!!svgContent && (
        <VenueViewer
          transactionData={transactionData}
          svgContent={svgContent}
          event={event!}
          tickets={tickets}
          availableSeats={availableSeats}
        />
      )}

      <Stack
        className='fixed lg:bottom-[30px] md:bottom-[50px] lg:left-[290px] md:left-[30px] sm:bottom-[60px] sm:left-[31px]  bg-white rounded-full flex-row justify-between items-center p-2 px-[30px] z-[999] border-2 border-black border-solid'
        direction='row'
        spacing={2}
        alignItems='center'
      >
        <AccessTimeOutlined sx={{ fontSize: '20px' }} />
        <Typography
          variant='h6'
          color='black'
          className='px-1'
          sx={{
            fontSize: '16px',
            marginLeft: 'auto',
          }}
        >
          {getCountdownFromSeconds(timeLeft)}
        </Typography>
      </Stack>

      {isTimerFinished && (
        <Dialog open={true} fullWidth>
          <DialogContent>
            <TimeExpired eventId={eventId!} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default Seats;
