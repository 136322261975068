import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { transactionStats } from '@/Models/transactions';
import { useMemo } from 'react';
import { useResponsive } from '@/Hooks/useResponsive';
import { capitalizeString } from '@/Utils/string';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface TransactionOverviewProp {
  transactions: transactionStats[];
}

const TransactionOverview = ({ transactions }: TransactionOverviewProp) => {
  const { isMobile } = useResponsive();

  const transactionData = useMemo(() => {
    let transactionData: number[] = [];

    transactions.forEach((row) => {
      if (row.status === 'order_created') {
        transactionData[0] = row.total;
      } else if (row.status === 'order_cancelled') {
        transactionData[1] = row.total;
      } else if (row.status === 'order_failed') {
        transactionData[2] = row.total;
      } else if (row.status === 'order_paid') {
        transactionData[3] = row.total;
      } else {
        transactionData[4] = row.total;
      }
    });

    return {
      labels: ['Created', 'Cancelled', 'Failed', 'Paid', 'Confirmed'],
      datasets: [
        {
          label: '# Total',
          data: transactionData,
          backgroundColor: [
            'rgba(255, 187, 153, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 26, 75, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 255, 153, 0.2)',
          ],
          borderColor: [
            'rgba(255, 187, 153, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 26, 75, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(153, 255, 153, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  }, [transactions]);

  return (
    <Pie
      data={transactionData}
      height={isMobile ? 350 : 'auto'}
      options={{ maintainAspectRatio: false, responsive: isMobile ? false : true }}
      style={{ width: '100%' }}
    />
  );
};

export default TransactionOverview;
