import { DialogProps } from '@/Models/dialogs';
import { InvitationType } from '@/Models/tickets';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useForm from '@/Hooks/useForm';
import { useGetTicketsQuery } from '@/Api/ticketsSlice';
import {
  useUpdateInvitationTypeMutation,
  useCreateInvitationTypeMutation,
} from '@/Api/invitationsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { useGetEventQuery } from '@/Api/eventsSlice';

interface Props {
  eventId: string;
  invitationType?: InvitationType;
}

const CreateOrEditInvitationTypeDialog = ({
  open,
  onDialogClose,
  eventId,
  invitationType,
}: DialogProps<Props>) => {
  const { data: event } = useGetEventQuery(eventId, { refetchOnMountOrArgChange: true });

  const { data: tickets } = useGetTicketsQuery(
    { eventId: eventId! },
    { refetchOnMountOrArgChange: true },
  );

  const [updateInvitationType] = useUpdateInvitationTypeMutation();
  const [createInvitationType] = useCreateInvitationTypeMutation();

  const { data, setData, errors, setError, clearErrors } = useForm({
    title: '',
    ticket_id: '',
    allocated: 20,
  });

  const [selectedTicketCodes, setSelectedTicketCodes] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState('');

  function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    let promise: Promise<any>;
    if (!!invitationType) {
      promise = updateInvitationType({
        eventId,
        invitationTypeId: invitationType.id,
        body: data,
      }).unwrap();
    } else {
      promise = createInvitationType({ eventId, body: data }).unwrap();
    }

    promise
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data.errors);
        }
      });
  }

  function getAction() {
    return invitationType ? 'Update' : 'Create';
  }

  function updateData(fieldName: keyof typeof data, value: any) {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  }

  function getMinAllocated() {
    return Math.max(1, invitationType?.allocated || 0);
  }

  const handleTicketSelection = (ticketId: string) => {
    const selectedTicket = tickets?.find((ticket) => ticket.id === ticketId);
    if (selectedTicket && event?.online_event) {
      setSelectedTicketCodes(selectedTicket?.ticket_codes);
      setSelectedTicketId(ticketId);
      updateData('ticket_id', ticketId);
      updateData('allocated', selectedTicket?.ticket_codes.length);
    } else {
      updateData('ticket_id', ticketId);
      updateData('allocated', 20); // Set the allocated value to a default (in this case, 20) when event is not an online event
    }
  };

  const isAllocatedWithinRange = () => {
    return data.allocated <= selectedTicketCodes.length;
  };

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='editInvitationTypeForm' onSubmit={handleSubmit}>
        <DialogTitle>{getAction()} Invitation Type</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='w-80 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id='title'
                  name='title'
                  label='Title'
                  error={!!errors.title}
                  helperText={errors.title?.[0]}
                  fullWidth
                  value={data.title}
                  onChange={(e) => updateData('title', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  select
                  id='ticket_id'
                  name='ticket_id'
                  label='Ticket type'
                  error={!!errors.ticket_id}
                  helperText={errors.ticket_id?.[0]}
                  fullWidth
                  value={data.ticket_id} // Use selectedTicketId instead of data.ticket_id
                  onChange={(e) => {
                    handleTicketSelection(e?.target.value); // Call handleTicketSelection on ticket selection
                  }}
                >
                  {tickets &&
                    tickets.map(({ id, title }) => (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='allocated'
                  name='allocated'
                  label='Allocated Tickets'
                  type='number'
                  error={!!errors.allocated}
                  helperText={errors.allocated?.[0]}
                  inputProps={{ min: getMinAllocated() }}
                  fullWidth
                  value={data.allocated}
                  onChange={(e) => updateData('allocated', +e?.target.value)}
                />
              </Grid>
            </Grid>

            {selectedTicketId && event?.online_event && (
              <Typography
                variant='caption'
                color={isAllocatedWithinRange() ? 'textPrimary' : 'error'}
              >
                {selectedTicketCodes.length > 0 ? (
                  <span>Allocated should be in the range of 0 to {selectedTicketCodes.length}</span>
                ) : (
                  <span>There are no codes available</span>
                )}
              </Typography>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <Button
            type='submit'
            variant='contained'
            color='success'
            disabled={!isAllocatedWithinRange() && event?.online_event}
          >
            {getAction()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateOrEditInvitationTypeDialog;
