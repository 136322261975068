import React from 'react';
import { Stack, TextField, Grid, Paper } from '@mui/material';
import { Controller } from 'react-hook-form';
import { PaymentGateway } from '@/Enums/payment-gateway';
import PaymentGateways from './PaymentGateway';

import { Control } from 'react-hook-form';

type RenderFieldProps = {
  control: Control<any>;
  name: string;
  label: string;
};

const RenderField = ({ control, name, label }: RenderFieldProps) => (
  <Stack>
    <Stack direction='row' spacing={4}>
      <Paper elevation={2} sx={{ p: 4, width: '63.5%', marginLeft: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  required
                  {...field}
                  id={name}
                  label={label}
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <br />
    </Stack>
  </Stack>
);

type PaymentTypeLogicProps = {
  control: Control<any>;
  paymentType: string;
};

const PaymentTypeLogic = ({ control, paymentType }: PaymentTypeLogicProps) => {
  switch (paymentType) {
    case PaymentGateway.DUMMY_GATEWAY:
      return (
        <RenderField
          control={control}
          name='informations_step.payment_type.location'
          label='Location'
        />
      );
    case PaymentGateway.PAYPAL:
    case PaymentGateway.STRIPE:
    case PaymentGateway.TEB:
    case PaymentGateway.TEB_DR:
      return (
        <RenderField
          control={control}
          name='informations_step.payment_type.transaction_id'
          label='Transaction Id'
        />
      );
    case PaymentGateway.PROCREDIT_TEST:
    case PaymentGateway.PROCREDIT_SHF:
    case PaymentGateway.PROCREDIT_DR:
      return <PaymentGateways control={control} />;
    default:
      return <></>;
  }
};

export default PaymentTypeLogic;
