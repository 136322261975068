import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

const ConfigLayout = () => {
  return (
    <div className='h-screen w-screen flex items-center justify-center'>
      <Stack className='m-auto p-4 py-16 items-center' spacing={2}>
        <img className='mb-8' alt='Festtix logo' src='/images/festtix-logo_black.svg' />

        <Outlet />
      </Stack>
    </div>
  );
};

export default ConfigLayout;
