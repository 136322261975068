import { useLinkWithPasscodeGroupMutation } from '@/Api/promocodesSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { DialogProps } from '@/Models/dialogs';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import PasscodeGroupSelector from './PasscodeGroupSelector';

const schema = z.object({
  selectedPasscodeGroup: z.object({
    eventId: z.coerce.string().min(1),
    passcodeGroupId: z.coerce.string().min(1),
  }),
});

type FormType = z.infer<typeof schema>;

type LinkPasscodesDialogProps = DialogProps & {};

const LinkPasscodesDialog = ({ open, onDialogClose }: LinkPasscodesDialogProps) => {
  const { eventId } = useParams();
  const [linkWithVoucherGroup, { isLoading }] = useLinkWithPasscodeGroupMutation();

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<FormType>({
    defaultValues: {
      selectedPasscodeGroup: {
        eventId: '',
        passcodeGroupId: '',
      },
    },
    resolver: zodResolver(schema),
  });

  function onSubmit() {
    linkWithVoucherGroup({
      eventId: eventId as string,
      passcodeGroupId: getValues('selectedPasscodeGroup.passcodeGroupId'),
    })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      });
  }

  return (
    <Dialog maxWidth='lg' open={open} onClose={() => onDialogClose(false)}>
      <form onSubmit={handleSubmit(onSubmit)} name='linkPasscodesDialog'>
        <DialogTitle>Link Passcode Groups</DialogTitle>

        <DialogContent className='mt-1 w-[46rem]'>
          <Stack spacing={1}>
            <Stack direction='row'>
              <Stack alignItems='center' className='w-1/2'>
                <Typography variant='h6'>Events</Typography>
              </Stack>

              <Stack alignItems='center' className='w-1/2'>
                <Typography variant='h6'>Passcode Groups</Typography>
              </Stack>
            </Stack>

            <Controller
              name='selectedPasscodeGroup'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <PasscodeGroupSelector {...field} />
                  {!!error && (
                    <FormControl error>
                      <FormHelperText>Please choose a passcode group to link with</FormHelperText>
                    </FormControl>
                  )}
                </>
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack direction='row' spacing={1}>
            <Button onClick={() => onDialogClose(false)}>Cancel</Button>

            <LoadingButton loading={isLoading}>
              <Button type='submit' variant='contained' disabled={isLoading} color='success'>
                Link
              </Button>
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LinkPasscodesDialog;
