import { CardItem } from '@/Components/Organisms/CardSelection/CardSelection';
import { RowError } from '@/Models/csv';
import { FileUpload as FileUploadIcon, Edit, Functions } from '@mui/icons-material';

export enum GenerationMethod {
  GENERATE = 'Generate',
  IMPORT_CSV = 'Import CSV',
  INPUT = 'Input',
}

export const codeGenerationMethods: CardItem[] = [
  {
    label: GenerationMethod.GENERATE,
    icon: (props) => <Functions {...props} />,
  },
  {
    label: GenerationMethod.IMPORT_CSV,
    icon: (props) => <FileUploadIcon {...props} />,
  },
  {
    label: GenerationMethod.INPUT,
    icon: (props) => <Edit {...props} />,
  },
];
