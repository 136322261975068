import { Ticket } from '@/Models/tickets';
import {
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { SeatCategoryForm } from './seat-categorization-form-helpers';
import { useContext } from 'react';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { formatPrice } from '@/Utils/currency';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';

interface SeatCategorizationFormProps {
  tickets: Ticket[] | undefined;
  onSubmit: (formData: SeatCategoryForm) => void;
}

function SeatCategorizationForm({ tickets, onSubmit }: SeatCategorizationFormProps) {
  const { seatIdentifierService } = useContext(VenueEditorContext);
  const { control, handleSubmit, watch } = useForm<SeatCategoryForm>({
    defaultValues: {
      ticketId: '',
      invitationTypeEnabled: false,
      invitationTypeId: '',
    },
  });
  const { eventId } = useParams<{ eventId: string }>();

  const { data: event } = useGetEventQuery(eventId!, { skip: !eventId });

  const ticketsToColorMap = seatIdentifierService.getTicketColors();

  if (!tickets) {
    return <></>;
  }

  const watchedTicketId = watch('ticketId');
  const invitationTypeEnabled = watch('invitationTypeEnabled');

  const selectedTicket = tickets?.find((ticket) => ticket.id === watchedTicketId);

  return (
    <form name='seatCategorizationForm' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='ticketId'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  label='Ticket Type'
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                >
                  {tickets.map(({ id, title, price }) => (
                    <MenuItem key={id} value={`${id}`}>
                      <ListItem
                        component='div'
                        secondaryAction={
                          <Stack justifyContent='center' className='rounded-full w-8 h-8'>
                            {formatPrice(price, event?.currency)}
                          </Stack>
                        }
                      >
                        <ListItemAvatar>
                          <div
                            className={`rounded-full w-8 h-8`}
                            style={{
                              backgroundColor: ticketsToColorMap[id],
                            }}
                          ></div>
                        </ListItemAvatar>
                        <ListItemText primary={title} />
                      </ListItem>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {selectedTicket?.invitation_types && selectedTicket?.invitation_types?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant='subtitle1' className='pr-2 inline'>
                  Reserve for invitation type
                </Typography>
                <Controller
                  name='invitationTypeEnabled'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <IOSSwitch {...field} checked={field.value} />
                  )}
                />
              </Grid>

              {invitationTypeEnabled && (
                <Grid item xs={12}>
                  <Controller
                    name='invitationTypeId'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        select
                        label='Invitation Type'
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                      >
                        {selectedTicket?.invitation_types?.map(({ id, title }) => (
                          <MenuItem key={id} value={`${id}`}>
                            <ListItem
                              component='div'
                              secondaryAction={
                                <Stack justifyContent='center' className='rounded-full'>
                                  <BeautifulChip color='success'>free</BeautifulChip>
                                </Stack>
                              }
                            >
                              <ListItemAvatar>
                                <div
                                  className={`rounded-full w-8 h-8`}
                                  style={{
                                    backgroundColor: 'gray',
                                  }}
                                ></div>
                              </ListItemAvatar>
                              <ListItemText primary={title} />
                            </ListItem>
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Stack direction='row' justifyContent='flex-end' className='mt-4'>
          <Button type='submit' variant='contained'>
            Apply
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default SeatCategorizationForm;
