import { useGetServicesQuery, useSyncEventServicesMutation } from '@/Api/servicesSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { DialogProps } from '@/Models/dialogs';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

type ChangeEventServicesDialogProps = DialogProps & {
  eventId: string;
  selectedServicesIds: string[];
};

const ChangeEventServicesDialog = ({
  eventId,
  selectedServicesIds,
  open,
  onDialogClose,
}: ChangeEventServicesDialogProps) => {
  const { control, getValues, handleSubmit } = useForm<{ services_ids: string[] }>({
    defaultValues: {
      services_ids: [...selectedServicesIds],
    },
  });

  const {
    data: services,
    isFetching,
    isLoading,
  } = useGetServicesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [syncEventServices, { isLoading: isSyncLoading }] = useSyncEventServicesMutation();

  const emptyCollection = !isLoading && !services?.data.length;
  const hasData = !isLoading && services?.data.length;

  function onSubmit() {
    syncEventServices({
      eventId,
      body: { services_ids: getValues('services_ids') },
    })
      .unwrap()
      .then(() => {
        onDialogClose(false);
      });
  }

  return (
    <Dialog maxWidth='md' open={open} onClose={() => onDialogClose(false)}>
      <form name='changeServicesForm' onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Change Services</DialogTitle>

        <DialogContent className='mt-1 w-96'>
          <>
            {isLoading && (
              <div className='flex justify-center'>
                <CircularProgress />
              </div>
            )}

            {emptyCollection && (
              <Typography>
                You need to first add Services before applying them to a specific event
              </Typography>
            )}

            {hasData && (
              <Grid container spacing={3} className='py-2'>
                <Grid item xs={12}>
                  <Controller
                    name='services_ids'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        select
                        SelectProps={{
                          multiple: true,
                          MenuProps: {
                            sx: {
                              maxHeight: 400,
                            },
                          },
                          renderValue: (selectedDCIds) => {
                            if (!services) {
                              return '';
                            }

                            return (selectedDCIds as string[])
                              .map((id) => services.data.find((dc) => dc.id == id)?.name)
                              .join(', ');
                          },
                        }}
                        label='Services'
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                      >
                        {isFetching
                          ? []
                          : services?.data.map(({ id, name }) => (
                              <MenuItem key={id} value={`${id}`}>
                                <Checkbox checked={field.value.includes(`${id}`)} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <LoadingButton loading={isSyncLoading}>
            <Button type='submit' variant='contained' disabled={isSyncLoading} color='success'>
              Save
            </Button>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeEventServicesDialog;
