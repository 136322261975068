import { Event } from '@/Models/events';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useGetOrdersMonthlyQuery } from '@/Api/ordersSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useResponsive } from '@/Hooks/useResponsive';
import { getMonthLabels } from '@/Utils/dates';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};
export interface SalesMonthlyProps {
  event: Event;
}

const SalesMonthly = ({ event }: SalesMonthlyProps) => {
  const { data: sales } = useGetOrdersMonthlyQuery(
    {
      eventId: event.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const data = useMemo(() => {
    if (sales) {
      let ordersMonthly: number[] = [];
      let attendeesMonthly: number[] = [];

      let labels = getMonthLabels(sales.latestSoldTicketDate);

      labels.forEach((day) => {
        ordersMonthly.push(sales.orders.filter((order: any) => order.date == day)[0]?.total || 0);
        attendeesMonthly.push(
          sales.attendees.filter((attendee: any) => attendee.date == day)[0]?.total || 0,
        );
      });

      return {
        labels,
        datasets: [
          {
            label: 'Orders',
            data: ordersMonthly,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Tickets',
            data: attendeesMonthly,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
    } else {
      return undefined;
    }
  }, [sales]);
  const { isMobile } = useResponsive();

  return data ? (
    <div style={{ width: 'auto', height: 'auto' }}>
      <Line
        height={isMobile ? 350 : ''}
        options={{ responsive: isMobile ? false : true }}
        data={data}
        style={{ width: '100%' }}
      />
    </div>
  ) : (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
};

export default SalesMonthly;
