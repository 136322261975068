import React, { useState, useEffect } from 'react';
import { Order } from '@/Models/orders';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  Select,
  Checkbox,
  InputLabel,
  FormControl,
} from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import useForm from '@/Hooks/useForm';
import { useUpdateTransferOrderMutation } from '@/Api/ordersSlice';
import { useGetEventsWithTicketsQuery } from '@/Api/eventsSlice';
import TicketList from './TicketList';
import { HTTP_CODE } from '@/Utils/http-client';
import { Event } from '@/Models/events';
import { useGetOrderTicketsDetailsQuery } from '@/Api/ordersSlice';

type EditOrderProps = {
  order: Order;
  eventId: string;
};

const EditTransferOrder = ({
  open,
  onDialogClose,
  order,
  eventId,
}: DialogProps<EditOrderProps>) => {
  const [page, setPage] = useState(0);
  const pageSize = 1000;
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [selectedTickets, setSelectedTickets] = useState([]) as any;
  const [currentEvent, setCurrentEvent] = useState() as any;

  const { data: events } = useGetEventsWithTicketsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  const { data, setData, errors, setError, clearErrors } = useForm({
    event_id: '',
    new_tickets: [],
  });

  useEffect(() => {
    if (order) {
      setData({ ...data, ...order });
      setCurrentEvent(order.event_id);
    }
  }, []);

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  useEffect(() => {
    if (selectedTickets.length > 0) {
      updateData('new_tickets', selectedTickets);
    }
  }, [selectedTickets]);

  const [updateOrder] = useUpdateTransferOrderMutation();

  function transferOrder(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    updateOrder({ eventId: order.event_id, orderId: order.id, body: data })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        console.log('error', error);
        onDialogClose(true);
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors || {});
        }
      });
  }

  const handleEventSelection = (event: any) => {
    setSelectedEvent(event);
  };

  const { data: orderTicketsDetails } = useGetOrderTicketsDetailsQuery({
    eventId,
    order: order.id,
  });
  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='orderEdit' onSubmit={transferOrder}>
        <DialogTitle>Transfer Order</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='w-80 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  select
                  id='event_id'
                  name='event_id'
                  label='Event'
                  error={!!errors.event_id}
                  helperText={errors.event_id?.[0]}
                  fullWidth
                  value={data.event_id}
                  onChange={(e) => {
                    updateData('event_id', e?.target.value);
                    const event = events?.data.find((event: any) => event.id === e.target.value);
                    handleEventSelection(event);
                  }}
                >
                  {events &&
                    events?.data
                      .filter(({ id }) => id !== currentEvent)
                      .map(({ id, title }) => (
                        <MenuItem key={id} value={id}>
                          {title}
                        </MenuItem>
                      ))}
                </TextField>
              </Grid>

              {selectedEvent && (
                <Grid item xs={12}>
                  <TicketList
                    event={selectedEvent}
                    tickets={selectedEvent.tickets}
                    selectedTickets={selectedTickets}
                    setSelectedTickets={setSelectedTickets}
                    orderTicketsDetails={orderTicketsDetails}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <Button
            type='submit'
            variant='contained'
            color='success'
            disabled={orderTicketsDetails?.length !== selectedTickets.length}
          >
            Transfer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditTransferOrder;
