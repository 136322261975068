import { LayoutState } from '@/Models/layout';
import { createSlice } from '@reduxjs/toolkit';

const initialState: LayoutState = {
  title: 'Home',
  header: {
    title: 'Home',
  },
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setHeader: (state, action) => {
      state.header = action.payload;
    },
  },
});

export const { setTitle, setHeader } = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
