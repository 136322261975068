import {
  useGetDonationStatsQuery,
  useGetEventDonationCausesQuery,
  useGetEventArchivedDonationCausesQuery,
} from '@/Api/donationsSlice';
import { useGetEventQuery } from '@/Api/eventsSlice';
import StatsCard, { StatsCardProps } from '@/Components/Molecules/Cards/StatsCard';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import { AttachMoney, Equalizer, Functions, Sync } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DonationCausesTable from '../DonationCauses/DonationCausesTable';
import EmptyContent from '../Info/EmptyContent';
import ChangeDonationCausesDialog from './ChangeDonationCausesDialog';
import { Event } from '@/Models/events';
import { useResponsive } from '@/Hooks/useResponsive';
import ArchivedDonationCausesTable from '../DonationCauses/ArchivedDonationCausesTable';

const pageSize = 50;

const cardDetails: {
  [key: string]: Omit<StatsCardProps, 'value'>;
} = {
  totalDonationsCount: {
    title: 'Total Donations Count',
    Icon: Functions,
  },
  totalSumOfDonations: {
    title: 'Total Money Donated',
    Icon: AttachMoney,
  },
  averageAmountDonated: {
    title: 'Average Amount Donated per Order',
    Icon: Equalizer,
  },
};

const Donations = () => {
  const [archiveType, setArchiveType] = useState('default');
  const { eventId } = useParams();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  const { data: donationStats } = useGetDonationStatsQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: donationCauses,
    isFetching,
    isLoading,
  } = useGetEventDonationCausesQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const { data: archivedDonationCauses } = useGetEventArchivedDonationCausesQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  function onDialogClose() {
    setDialogOpen(false);
  }
  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  const noCausesApplied = !isFetching && !donationCauses?.data?.length;
  const canShowTable = !isLoading && !!donationCauses?.data?.length;

  return (
    <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
      <Stack spacing={2} className='h-full'>
        <Stack direction={direction} spacing={2}>
          {Object.keys(donationStats || []).map((key) => {
            return (
              <StatsCard
                key={key}
                className='col-1'
                title={cardDetails[key].title}
                value={donationStats[key]}
                Icon={cardDetails[key].Icon}
              />
            );
          })}
        </Stack>

        <Stack spacing={2} className='pb-4 flex-1'>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h5'>Donation Causes</Typography>
            <PermissionsGate scope={SCOPES.ManageDonationCause}>
              {canShowTable && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {archiveType === 'default' ? (
                    <Button
                      onClick={() => setArchiveType('archived')}
                      variant='contained'
                      sx={{ marginRight: '20px' }}
                    >
                      Archived
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setArchiveType('default')}
                      variant='contained'
                      sx={{ marginRight: '20px' }}
                    >
                      Default
                    </Button>
                  )}
                  <Button
                    variant='contained'
                    startIcon={<Sync />}
                    onClick={() => setDialogOpen(true)}
                  >
                    Change Donation Causes
                  </Button>
                </div>
              )}
            </PermissionsGate>
          </Stack>

          {noCausesApplied && (
            <EmptyContent
              primaryText='No Donation Causes'
              secondaryText='You can add donation causes to your event'
              button={
                <PermissionsGate scope={SCOPES.ManageDonationCause}>
                  <Button
                    variant='contained'
                    startIcon={<Sync />}
                    onClick={() => setDialogOpen(true)}
                  >
                    Add Donation Causes
                  </Button>
                </PermissionsGate>
              }
            />
          )}

          {archiveType === 'default' ? (
            canShowTable && (
              <div className='flex-1'>
                <DonationCausesTable
                  donationCauses={donationCauses}
                  columns={['logo', 'name', 'description', 'moneyDonated', 'url']}
                  pageSize={pageSize}
                  onPageChange={setPage}
                  event={event as Event}
                />
              </div>
            )
          ) : (
            <div className='flex-1'>
              <ArchivedDonationCausesTable
                archivedDonationCauses={archivedDonationCauses}
                columns={['logo', 'name', 'description', 'moneyDonated', 'url']}
                pageSize={pageSize}
                onPageChange={setPage}
                event={event as Event}
              />
            </div>
          )}
        </Stack>
      </Stack>

      {dialogOpen && donationCauses && (
        <ChangeDonationCausesDialog
          eventId={eventId!}
          selectedDonationCausesIds={donationCauses?.data?.map((dc) => `${dc.id}`)}
          open={dialogOpen}
          onDialogClose={onDialogClose}
        />
      )}
    </div>
  );
};

export default Donations;
