import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { useResponsive } from '@/Hooks/useResponsive';
import { Event } from '@/Models/events';
import { Ticket } from '@/Models/tickets';
import { getSectionColorsBasedOnAvailability } from '@/Utils/seating';
import { ColorGenerator, getItemId, isSeatAvailable } from '@festtix/venue-editor';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import SelectedSeatsInfo from './SelectedSeatsInfo';
import BaseDialog from '@/Components/Molecules/Dialogs/BaseDialog';
import { DataKey, LocalTransactionData } from '@/Models/local-storage';
import { FSTXLocalStorage } from '@/Utils/local-storage';
import { useCancelTransactionMutation } from '@/Api/transactionsSlice';
import { useNavigate } from 'react-router-dom';
import { useAddSeatsMutation } from '@/Api/seatingSlice';
import Legend from '@/Components/Molecules/Legend/Legend';

interface VenueViewerProps {
  svgContent: string;
  event: Event;
  tickets: Ticket[];
  availableSeats: { [seatId: string]: boolean };
  transactionData?: any;
}

function onWheel(e: WheelEvent) {
  e.preventDefault();
}

const colorGenerator = new ColorGenerator();

function VenueViewer({
  svgContent,
  event,
  tickets,
  availableSeats,
  transactionData,
}: VenueViewerProps) {
  const { isMobile } = useResponsive();
  const [isEditorLoading, setIsEditorLoading] = useState<boolean>(false);
  const [selectedSeatIds, setSelectedSeatIds] = useState<string[]>([]);
  const {
    venueEditorService,
    sectionIdentifierService,
    seatIdentifierService,
    editorEventService,
  } = useContext(VenueEditorContext);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [localTransactionData, setLocalTransactionData] = useState<LocalTransactionData | null>(
    null,
  );
  const navigate = useNavigate();

  const [cancelTransaction] = useCancelTransactionMutation();
  function cancelCurrentTransactionIfExists() {
    if (!localTransactionData) {
      return;
    }

    cancelTransaction({ eventId: event.id, transactionId: localTransactionData.transactionId })
      .unwrap()
      .then(() => {
        FSTXLocalStorage.removeItem(DataKey.TRANSACTION);
        navigate(`/events/${event.id}/support-order`);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }

  const [addSeatsMutation] = useAddSeatsMutation();
  function addSeats() {
    if (!localTransactionData || !selectedSeatIds.length || !event) {
      return;
    }
    addSeatsMutation({
      eventId: event.id,
      transactionId: localTransactionData.transactionId,
      seat_ids: selectedSeatIds,
    })
      .unwrap()
      .then(() => {
        navigate(`/events/${event.id}/support-order/checkout`);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }

  useEffect(() => {
    venueEditorService.initialize('venue-svg-container-canvas', svgContent);
    venueEditorService.setMaximumAllowedSelectionSize(event.maximum_seats_per_order);
    venueEditorService.listenToSeatHoverEvents();
    venueEditorService.addSectionFocusFunctionality();
    venueEditorService.removeUnidentifiedSeatItems();
    venueEditorService.removeUnlabelledSeatItems(seatIdentifierService.getSeatData());

    const seats = venueEditorService.getAllSeats();
    const sections = venueEditorService.getAllSections();

    sectionIdentifierService.generateLabelsForSections(sections);
    venueEditorService.fillLabelsForSections(sectionIdentifierService.getSectionData());

    fillSeatAvailabilityData(tickets, seats);

    if (transactionData) fillDataFromTransactionData(seats);

    sectionIdentifierService.loadColorData(
      getSectionColorsBasedOnAvailability(
        seats,
        seatIdentifierService.getSeatData(),
        colorGenerator,
      ),
    );

    editorEventService.seatsStatusChanged$.next(seats);
    editorEventService.sectionsStatusChanged$.next(sections);
    editorEventService.sectionsVisibilityChanged$.next(sections);

    editorEventService.toolChanged$.next('navigateAndSelect');

    const subscriptions = new Subscription();

    subscriptions.add(
      venueEditorService.getSelectedElementsIds$().subscribe((ids) => {
        setSelectedSeatIds(ids);
      }),
    );

    return () => {
      subscriptions.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sub = venueEditorService.getIsLoading$().subscribe((isLoading) => {
      setIsEditorLoading(isLoading);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sub = editorEventService.selectedSeatCountExceeded$.subscribe(() => {
      console.log('You have exceeded the maximum number of seats you can select.');
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const canvasContainer = document.getElementById('canvas-container');

    if (!canvasContainer) return;

    canvasContainer.addEventListener('wheel', onWheel);

    return () => {
      canvasContainer.removeEventListener('wheel', onWheel);
    };
  }, []);

  useEffect(() => {
    if (!transactionData) return;
    setLocalTransactionData(FSTXLocalStorage.getItem(DataKey.TRANSACTION));
  }, []);

  function fillSeatAvailabilityData(tickets: Ticket[], seats: paper.Item[]) {
    const ticketIdToValidityMap = tickets.reduce((acc, ticket) => {
      acc[ticket.id] = !ticket.expired && !ticket.sold_out && !ticket.temporarily_queued;
      return acc;
    }, {} as { [ticketId: string]: boolean });

    const seatAvailabilityData = seats.reduce((acc, seat) => {
      const seatId = getItemId(seat);
      const ticketIdOfCurrentSeat = seatIdentifierService.getSeatData()[seatId]?.ticket_id;

      const validTicket = ticketIdToValidityMap[ticketIdOfCurrentSeat!];
      acc[seatId] = availableSeats[seatId] && validTicket;

      return acc;
    }, {} as { [seatId: string]: boolean });

    venueEditorService.fillSeatAvailabilityData(seatAvailabilityData);
  }

  function fillDataFromTransactionData(allSeats: paper.Item[]) {
    const seatIds = transactionData.transaction_details.map(
      (detail: { seat_id: any }) => detail.seat_id,
    );

    const seatAvailabilityData = seatIds.reduce(
      (acc: { [x: string]: boolean }, seatId: string | number) => {
        acc[seatId] = true;
        return acc;
      },
      {} as { [seatId: string]: boolean },
    );

    const seats = allSeats.filter((seat) => seatIds.includes(getItemId(seat)));

    venueEditorService.fillSpecificSeatsAvailabilityData(seatAvailabilityData, seats);
    venueEditorService.selectSeats(seats);
  }

  function onCancelTransactionClick() {
    if (!localTransactionData) {
      return;
    }
    setCancelDialogOpen(true);
  }

  function onCancelDialogClose(value: boolean) {
    setCancelDialogOpen(false);

    if (!value) {
      return;
    }

    cancelCurrentTransactionIfExists();
  }

  async function proceedToCheckout() {
    await addSeats();
  }

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      divider={<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />}
      className='w-full pb-16 h-[calc(100vh-250px)]'
    >
      <div
        id='canvas-container'
        className='relative w-full md:w-[70%] h-[100%] md:h-full overflow-hidden'
      >
        <canvas
          ref={canvasRef}
          id='venue-svg-container-canvas'
          className={`absolute top-0 left-0 w-full h-full touch-none ${
            isEditorLoading ? 'pointer-events-none' : ''
          }`}
          // @ts-ignore
          resize='true'
        ></canvas>

        <ButtonGroup orientation='vertical' className='absolute bottom-4 left-4'>
          <Button
            className='!p-1'
            variant='contained'
            onClick={() => editorEventService.zoomRequest$.next('in')}
          >
            <AddOutlined />
          </Button>

          <Button
            className='!p-1'
            variant='contained'
            onClick={() => editorEventService.zoomRequest$.next('out')}
          >
            <RemoveOutlined />
          </Button>
        </ButtonGroup>

        <Legend />

        {isEditorLoading ? (
          <>
            <div className='absolute top-0 left-0 w-full h-full bg-white opacity-40'></div>

            <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
              <CircularProgress />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className='w-full md:w-[30%] h-[40%] md:h-full'>
        <SelectedSeatsInfo event={event} tickets={tickets} transactionData={transactionData} />

        {transactionData && (
          <>
            <Stack direction='row' spacing={2} className='justify-end	p-8 border-t-2'>
              <Button type='button' variant='text' color='error' onClick={onCancelTransactionClick}>
                Cancel
              </Button>
              <Button type='button' variant='contained' color='primary' onClick={proceedToCheckout}>
                Confirm
              </Button>
            </Stack>

            <BaseDialog
              title='Are you sure you want to cancel this transaction?'
              open={cancelDialogOpen}
              onClose={onCancelDialogClose}
              content="You can't undo this action"
            >
              <Button variant='outlined' onClick={() => onCancelDialogClose(false)}>
                No
              </Button>

              <Button variant='contained' color='error' onClick={() => onCancelDialogClose(true)}>
                Yes
              </Button>
            </BaseDialog>
          </>
        )}
      </div>
    </Stack>
  );
}

export default VenueViewer;
