import { Navigate, Route, Routes } from 'react-router-dom';
import EventsList from './Pages/Events/Index';
import UsersList from './Pages/Users/Index';
import CreateOrEditEvent from './Pages/Events/CreateOrEdit';
import EventDetails from './Pages/Events/Event/Index';
import CreateOrEditTicket from './Pages/Tickets/CreateOrEdit';
import CreateOrEditUsers from './Pages/Users/InviteMember';
import AuthGuard from './HOC/AuthGuard';
import Account from './Pages/Users/Account';
import ChooseOrganization from './Pages/Organizations/ChooseOrganization';
import OrganizationGuard from './HOC/OrganizationGuard';
import CreateOrganization from './Pages/Organizations/CreateOrEditOrganization';
import Page404 from './Pages/Info/404';
import AdminDashboard from './Pages/Dashboard/AdminDashboard';
import Organizers from './Pages/Organizers/Organizers';
import CreateOrEditPasscodes from './Pages/Promocodes/Passcodes/CreateOrEditPasscodes';
import CreateOrEditVouchers from './Pages/Promocodes/Vouchers/CreateOrEditVouchers';
import Editor from './Pages/Editor/Editor';
import Artist from './Pages/Artists/Index';
import CreareOrEditArtist from './Pages/Artists/CreateOrEditArtist';
import TicketsIndex from './Pages/Tickets/Index';
import EventOrders from './Pages/Orders/EventOrders';
import EventAttendees from './Pages/Attendees/EventAttendees';
import SupportOrderIndex from './Pages/SupportOrder/Index';
import SuccessIndex from './Pages/SupportOrder/Success/Index';
import CheckoutIndex from './Pages/SupportOrder/Checkout/Index';
import SeatsIndex from './Pages/SupportOrder/Seats/Index';
import GuestsList from './Pages/Guests/GuestsList';
import Guests from './Pages/Invites/Guests';
import Promocodes from './Pages/Promocodes/Promocodes';
import PermissionsWrapper from './HOC/PermissionsWrapper';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import Page403 from './Pages/Info/403';
import Donations from './Pages/Donations/Donations';
import Charities from './Pages/DonationCauses/DonationCauses';
import CreateOrEditCharity from './Pages/DonationCauses/CreateOrEditDonationCause';
import ConfigLayout from './Layouts/ConfigLayout';
import MainLayout from './Layouts/MainLayout';
import { LayoutMode } from './Models/layout';
import EventArtists from './Pages/EventArtists/Index';
import CreateOrEditEventArtists from './Pages/EventArtists/CreateOrEditEventArtist';
import Articles from './Pages/Articles/Articles';
import CreateOrEditArticle from './Pages/Articles/CreateOrEdit';
import Insights from './Pages/Insights/Insights';
import CreateOrEditTrackingLink from './Pages/Insights/CreateOrEditTrackingLink';
import Organizations from './Pages/Organizers/Organizations';
import Index from './Pages/ScanGroups/Index';
import CreateOrEdit from './Pages/ScanGroups/CreateOrEdit';
import ScanGroup from './Pages/ScanGroups/ScanGroup';
import TicketCodes from './Pages/TicketCodes/TicketCodes';
import CreatOrEditTicketCodes from './Pages/TicketCodes/CreateOrEditTicketCodes';
import AllOrders from './Pages/Organizers/AllOrders';
import VenueEditor from './Pages/VenueEditor/VenueEditor';
import VenueConfigurator from './Pages/VenueEditor/VenueConfigurator';
import EventVenue from './Pages/VenueEditor/EventVenue';
import VenueEditorContextProvider from './Contexts/VenueEditorContext';
import ParkingSpaces from './Pages/ParkingSpaces/ParkingSpaces';
import CreateOrEditParkingSpaces from './Pages/ParkingSpaces/CreateOrEdit';
import EventFaqs from './Pages/Faqs/EventFaqs';
import CreateOrEditFAQ from './Pages/Faqs/CreateOrEdit';
import FeaturedArticle from './Pages/FeaturedArticles/FeaturedArticle';
import Partners from './Pages/Partners/Partners';
import CreateOrEditPartner from './Pages/Partners/CreateOrEdit';
import OrganizationEvents from './Pages/Organizers/OrganizationEvents';
import OidcCallback from './Pages/Auth/OidcCallback';
import Archived from './Pages/Events/Archived';
import Services from './Pages/Services/Services';
import CreateOrEditService from './Pages/Services/CreateOrEditService';
import EventServices from './Pages/EventServices/EventServices';
import EditEventService from './Pages/EventServices/EditEventService';

// TODO: consider dividing each "module" into its own route file
function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/oidc-callback' element={<OidcCallback />} />

        <Route element={<ConfigLayout />}>
          <Route path='/organizations/create' element={<CreateOrganization />} />

          <Route element={<AuthGuard auth={true} redirectPath='/' />}>
            <Route path='/choose-organization' element={<ChooseOrganization />} />
            <Route path='/organizations/:organizationId/edit' element={<CreateOrganization />} />
            <Route path='/organizations/create' element={<CreateOrganization />} />
          </Route>
        </Route>

        <Route element={<AuthGuard auth={true} redirectPath='/' />}>
          <Route element={<OrganizationGuard />}>
            <Route element={<PermissionsWrapper />}>
              {/* Admins routes */}
              <Route element={<MainLayout mode={LayoutMode.ADMIN} />}>
                <Route element={<PermissionsGate scope={SCOPES.ManageApplication} page />}>
                  <Route path='/admin-dashboard' element={<AdminDashboard />} />
                  <Route path='/organizations' element={<Organizations />} />
                  <Route path='/organizers' element={<Organizers />} />
                  <Route path='/all-orders' element={<AllOrders />} />
                  <Route path='/organizers/create' element={<CreateOrEditUsers />} />
                  <Route
                    path='/organizations/:organizationId/events'
                    element={<OrganizationEvents />}
                  />
                  <Route
                    path='/organizations/:organizationId/events/:eventId/edit'
                    element={<CreateOrEditEvent />}
                  />

                  <Route path='/editor' element={<Editor />} />
                  <Route path='/featured-articles' element={<FeaturedArticle />} />

                  <Route path='/articles' element={<Articles />} />
                  <Route path='/articles/create' element={<CreateOrEditArticle />} />
                  <Route path='/articles/:articleId/edit' element={<CreateOrEditArticle />} />

                  <Route path='/partners' element={<Partners />} />
                  <Route path='/partners/create' element={<CreateOrEditPartner />} />
                  <Route path='/partners/:partnerId/edit' element={<CreateOrEditPartner />} />

                  <Route path='/artists' element={<Artist />} />
                  <Route path='/artists/create' element={<CreareOrEditArtist />} />
                  <Route path='/artists/:artistId/edit' element={<CreareOrEditArtist />} />
                </Route>
              </Route>

              {/* Clients routes */}
              <Route element={<MainLayout mode={LayoutMode.CLIENT} />}>
                <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                  <Route path='/dashboard' element={<EventsList />} />
                  <Route path='/events' element={<EventsList />} />
                  <Route path='/archived-events' element={<Archived />} />

                  <Route element={<PermissionsGate scope={SCOPES.CreateEvent} page />}>
                    <Route path='/events/create' element={<CreateOrEditEvent />} />
                  </Route>

                  <Route element={<PermissionsGate scope={SCOPES.UpdateEvent} page />}>
                    <Route path='/events/:eventId/edit' element={<CreateOrEditEvent />} />
                  </Route>
                </Route>

                <Route path='/users' element={<UsersList />} />

                <Route element={<PermissionsGate scope={SCOPES.ManageOrganization} page />}>
                  <Route path='/users/create' element={<CreateOrEditUsers />} />
                  <Route path='/users/:userId/edit' element={<CreateOrEditUsers />} />
                </Route>

                <Route path='/donation-causes' element={<Charities />} />

                <Route element={<PermissionsGate scope={SCOPES.ManageDonationCause} page />}>
                  <Route path='/donation-causes/create' element={<CreateOrEditCharity />} />
                  <Route
                    path='/donation-causes/:donationCauseId/edit'
                    element={<CreateOrEditCharity />}
                  />
                </Route>

                <Route element={<PermissionsGate scope={SCOPES.ViewDonationCause} page />}>
                  <Route path='/donation-causes' element={<Charities />} />

                  <Route element={<PermissionsGate scope={SCOPES.ManageDonationCause} page />}>
                    <Route path='/donation-causes/create' element={<CreateOrEditCharity />} />
                    <Route
                      path='/donation-causes/:donationCauseId/edit'
                      element={<CreateOrEditCharity />}
                    />
                  </Route>
                </Route>

                <Route element={<PermissionsGate scope={SCOPES.ViewDonationCause} page />}>
                  <Route path='/services' element={<Services />} />

                  <Route element={<PermissionsGate scope={SCOPES.ManageDonationCause} page />}>
                    <Route path='/services/create' element={<CreateOrEditService />} />
                    <Route path='/services/:serviceId/edit' element={<CreateOrEditService />} />
                  </Route>
                </Route>

                <Route element={<PermissionsGate scope={SCOPES.ViewVoucher} page />}>
                  <Route path='/insights' element={<Insights />} />

                  <Route element={<PermissionsGate scope={SCOPES.ManageVoucher} page />}>
                    <Route path='/insights/create' element={<CreateOrEditTrackingLink />} />
                    <Route
                      path='/insights/:trackingLinkId/edit'
                      element={<CreateOrEditTrackingLink />}
                    />
                  </Route>
                </Route>

                <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                  <Route path='/scan-groups' element={<Index />} />

                  <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                    <Route path='/scan-groups/:scanGroupId' element={<ScanGroup />} />
                  </Route>

                  <Route element={<PermissionsGate scope={SCOPES.CreateEvent} page />}>
                    <Route path='/scan-groups/create' element={<CreateOrEdit />} />
                  </Route>

                  <Route element={<PermissionsGate scope={SCOPES.CreateEvent} page />}>
                    <Route path='/scan-groups/:scanGroupId/edit' element={<CreateOrEdit />} />
                  </Route>
                </Route>

                <Route element={<PermissionsGate scope={SCOPES.ViewUser} page />}>
                  <Route path='/account' element={<Account />} />
                </Route>
              </Route>

              {/* Event routes */}
              <Route element={<MainLayout mode={LayoutMode.EVENT} />}>
                {/* General */}
                <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                  <Route path='/events/:eventId' element={<EventDetails />} />
                </Route>
                {/* Tickets */}
                <Route element={<PermissionsGate scope={SCOPES.ViewTicket} page />}>
                  <Route path='/events/:eventId/tickets' element={<TicketsIndex />} />

                  <Route element={<PermissionsGate scope={SCOPES.CreateTicket} page />}>
                    <Route
                      key='add-ticket'
                      path='/events/:eventId/tickets/create'
                      element={<CreateOrEditTicket />}
                    />
                  </Route>

                  <Route element={<PermissionsGate scope={SCOPES.UpdateTicket} page />}>
                    <Route
                      key='edit-ticket'
                      path='/events/:eventId/tickets/:ticketId/edit'
                      element={<CreateOrEditTicket />}
                    />
                  </Route>
                </Route>
                {/* Orders */}
                <Route element={<PermissionsGate scope={SCOPES.ViewOrder} page />}>
                  <Route path='/events/:eventId/orders' element={<EventOrders />} />
                </Route>
                {/* EventArtist */}
                <Route element={<PermissionsGate scope={SCOPES.ViewOrder} page />}>
                  <Route path='/events/:eventId/artists' element={<EventArtists />} />
                </Route>
                <Route element={<PermissionsGate scope={SCOPES.ManageOrder} page />}>
                  <Route
                    path='/events/:eventId/artists/create'
                    element={<CreateOrEditEventArtists />}
                  />
                  <Route
                    path='/events/:eventId/artists/:artistId/edit'
                    element={<CreateOrEditEventArtists />}
                  />
                </Route>
                {/* Attendees */}
                <Route element={<PermissionsGate scope={SCOPES.ViewAttendee} page />}>
                  <Route path='/events/:eventId/attendees' element={<EventAttendees />} />
                </Route>
                {/* Guests */}
                <Route element={<PermissionsGate scope={SCOPES.ViewGuest} page />}>
                  <Route path='/events/:eventId/guests' element={<GuestsList />} />
                </Route>
                {/* Support Order */}
                <Route element={<PermissionsGate scope={SCOPES.ViewTicket} page />}>
                  <Route path='/events/:eventId/support-order' element={<SupportOrderIndex />} />
                  <Route
                    path='/events/:eventId/support-order/seats'
                    element={
                      <VenueEditorContextProvider>
                        <SeatsIndex />
                      </VenueEditorContextProvider>
                    }
                  />
                  <Route
                    path='/events/:eventId/support-order/checkout'
                    element={<CheckoutIndex />}
                  />
                  <Route path='/events/:eventId/support-order/success' element={<SuccessIndex />} />
                </Route>
                {/* Venue editor */}
                <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                  <Route path='/events/:eventId/venues' element={<EventVenue />} />
                </Route>

                <Route element={<PermissionsGate scope={SCOPES.CreateEvent} page />}>
                  <Route
                    path='/events/:eventId/venues/:venueId/editor'
                    element={
                      <VenueEditorContextProvider>
                        <VenueEditor />
                      </VenueEditorContextProvider>
                    }
                  />
                  <Route
                    path='/events/:eventId/venues/:venueId/configurator'
                    element={
                      <VenueEditorContextProvider>
                        <VenueConfigurator />
                      </VenueEditorContextProvider>
                    }
                  />
                </Route>

                {/* invitations */}
                <Route element={<PermissionsGate scope={SCOPES.ViewGuest} page />}>
                  <Route path='/events/:eventId/invitations' element={<Guests />} />
                </Route>
                {/* Parking Spaces */}
                <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                  <Route path='/events/:eventId/parking-spaces' element={<ParkingSpaces />} />
                  <Route element={<PermissionsGate scope={SCOPES.UpdateEvent} page />}>
                    <Route
                      path='/events/:eventId/parking-spaces/create'
                      element={<CreateOrEditParkingSpaces />}
                    />
                    <Route
                      path='/events/:eventId/parking-spaces/:parkingSpacesId/edit'
                      element={<CreateOrEditParkingSpaces />}
                    />
                  </Route>
                </Route>
                {/* Ticket Codes */}
                <Route element={<PermissionsGate scope={SCOPES.ViewTicket} page />}>
                  <Route path='/events/:eventId/ticket-codes' element={<TicketCodes />} />
                  <Route
                    path='/events/:eventId/ticket-codes/:ticketId/create'
                    element={<CreatOrEditTicketCodes />}
                  />
                </Route>
                {/* Promocodes */}
                <Route element={<PermissionsGate scope={SCOPES.ViewPromocode} page />}>
                  <Route path='/events/:eventId/promocodes' element={<Promocodes />} />

                  <Route element={<PermissionsGate scope={SCOPES.ManagePasscode} page />}>
                    <Route
                      path='/events/:eventId/promocodes/passcodes/create'
                      element={<CreateOrEditPasscodes />}
                    />
                    <Route
                      path='/events/:eventId/promocodes/passcodes/:passcodeGroupId/edit'
                      element={<CreateOrEditPasscodes />}
                    />
                  </Route>

                  <Route element={<PermissionsGate scope={SCOPES.ManageVoucher} page />}>
                    <Route
                      path='/events/:eventId/promocodes/vouchers/create'
                      element={<CreateOrEditVouchers />}
                    />
                    <Route
                      path='/events/:eventId/promocodes/vouchers/:voucherGroupId/edit'
                      element={<CreateOrEditVouchers />}
                    />
                  </Route>
                </Route>
                {/* Donations */}
                <Route element={<PermissionsGate scope={SCOPES.ViewDonationCause} page />}>
                  <Route path='/events/:eventId/donations' element={<Donations />} />
                </Route>
                {/* Services */}
                <Route element={<PermissionsGate scope={SCOPES.ViewDonationCause} page />}>
                  <Route path='/events/:eventId/services' element={<EventServices />} />
                </Route>
                <Route element={<PermissionsGate scope={SCOPES.ManageDonationCause} page />}>
                  <Route
                    path='/events/:eventId/services/:serviceId/edit'
                    element={<EditEventService />}
                  />
                </Route>
                {/* Event Faqs */}
                <Route element={<PermissionsGate scope={SCOPES.ViewEvent} page />}>
                  <Route path='/events/:eventId/faqs' element={<EventFaqs />} />
                  <Route path='/events/:eventId/faqs/create' element={<CreateOrEditFAQ />} />
                  <Route
                    path='/events/:eventId/faqs/:eventFaqId/edit'
                    element={<CreateOrEditFAQ />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path='/' element={<Navigate to='/dashboard' replace />} />
          </Route>
        </Route>

        <Route path='/403' element={<Page403 />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
