import { useGetPasscodesQuery } from '@/Api/promocodesSlice';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import { DialogProps } from '@/Models/dialogs';
import { Passcode, PasscodeGroup } from '@/Models/promocodes';
import { isEmpty } from '@/Utils/general';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';

interface PasscodesProps {
  passcodeGroup: PasscodeGroup;
}

const Passcodes = ({ open, onDialogClose, passcodeGroup }: DialogProps<PasscodesProps>) => {
  const [page, setPage] = useState(0);

  const pageSize = 10;

  const { data: passcodesData } = useGetPasscodesQuery(
    {
      passcodeGroupId: passcodeGroup.id,
      filterData: { pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  const columns: GridColDef<Passcode>[] = [
    { field: 'code', headerName: 'Code', sortable: false, flex: 1 },
    {
      field: 'tickets_bought',
      headerName: 'Tickets Bought',
      renderCell: ({ row: { tickets_bought } }) => (
        <EmptyCell condition={!isEmpty(tickets_bought)}>{tickets_bought}</EmptyCell>
      ),
      sortable: false,
      flex: 1,
    },
  ];

  function onPageChange(page: number) {
    setPage(page);
  }

  return (
    <Dialog fullWidth={true} maxWidth='md' open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle>Passcodes</DialogTitle>

      <DialogContent>
        <div className='h-[50vh]'>
          {passcodesData && (
            <DataGrid
              rows={passcodesData.data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[pageSize]}
              rowCount={passcodesData.total}
              paginationMode='server'
              onPageChange={onPageChange}
              hideFooterSelectedRowCount={true}
              isRowSelectable={() => false}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Passcodes;
