import { DialogProps } from '@/Models/dialogs';
import { InvitationType } from '@/Models/tickets';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  useAssignUserInvitationTypeMutation,
  useGetAssignedUserInvitationTypeQuery,
} from '@/Api/invitationsSlice';
import { useGetUsersQuery } from '@/Api/usersSlice';
import { User } from '@/Models/users';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useLoading from '@/Hooks/useLoading';

interface Props {
  eventId: string;
  invitationType?: InvitationType;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AssignUserInvitationTypeDialog = ({
  open,
  onDialogClose,
  eventId,
  invitationType,
}: DialogProps<Props>) => {
  const theme = useTheme();

  const [selectInputLabel, setSelectInputLabel] = React.useState<string[]>([]);
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([]);
  const [loading, withLoading] = useLoading();

  const { data: userData } = useGetUsersQuery({}, { refetchOnMountOrArgChange: true });
  const [assignUsersToInvitationType] = useAssignUserInvitationTypeMutation();
  const { data: assignedUsers } = useGetAssignedUserInvitationTypeQuery(
    { eventId: eventId!, invitationTypeId: invitationType!.id },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (!!assignedUsers) {
      setSelectedUserIds([...selectedUserIds, ...assignedUsers]);
    }

    if (!!assignedUsers && !!userData) {
      const filteredUsers = userData.data.filter((user) => assignedUsers.includes(user.id));
      setSelectInputLabel(filteredUsers.map((user) => user.name));
    }
  }, [assignedUsers, userData]);

  function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    if (selectedUserIds.length > 0) {
      withLoading(
        assignUsersToInvitationType({
          eventId,
          invitationTypeId: invitationType!.id,
          body: selectedUserIds,
        }).unwrap(),
      )
        .then(() => {
          onDialogClose(true);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  function getAction() {
    return invitationType ? 'Update' : 'Create';
  }

  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event: SelectChangeEvent<typeof selectInputLabel>, key: any) => {
    const itemKey = key.key.slice(2);
    if (selectedUserIds.includes(itemKey)) {
      setSelectedUserIds(selectedUserIds.filter((user) => user !== itemKey));
    } else {
      setSelectedUserIds([...selectedUserIds, itemKey]);
    }

    const {
      target: { value },
    } = event;
    setSelectInputLabel(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='editInvitationTypeForm' onSubmit={handleSubmit}>
        <DialogTitle>Assign to User Invitation Type</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='w-80 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id='title'
                  name='title'
                  fullWidth
                  defaultValue={invitationType!.title}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id='users-label'>Users</InputLabel>
                  <Select
                    labelId='users-label'
                    id='users'
                    name='users'
                    multiple
                    value={selectInputLabel}
                    onChange={handleChange}
                    input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {userData?.data &&
                      userData.data.map(({ id, name }: User) => (
                        <MenuItem
                          key={id}
                          value={name}
                          style={getStyles(name, selectInputLabel, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <LoadingButton loading={loading}>
            <Button type='submit' variant='contained' disabled={loading} color='success'>
              {getAction()}
            </Button>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssignUserInvitationTypeDialog;
