import React, { useEffect, useMemo, useState } from 'react';
import {
  useCreateEventMutation,
  useUpdateEventMutation,
  useGetEventQuery,
  useGenerateEventIdMutation,
} from '@/Api/eventsSlice';
import {
  Box,
  Button,
  FormControlLabel,
  Divider,
  Paper,
  Grid,
  MenuItem,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Tooltip,
  Popover,
  Select,
  SelectChangeEvent,
  Chip,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  InputLabel,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { getSelectOptionsFromEnum } from '@/Utils/enums';
import { PaymentGateway } from '@/Enums/payment-gateway';
import { Currency } from '@/Enums/currency';
import { startOfDay, endOfDay, add, isBefore } from 'date-fns';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { FileContext, ResourceType } from '@/Enums/files';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useLoading from '@/Hooks/useLoading';
import useForm from '@/Hooks/useForm';
import { useNavigate, useParams } from 'react-router-dom';
import { PreviewData } from '@/Models/preview';
import { HTTP_CODE } from '@/Utils/http-client';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import environment from '@/environments/environment';
import { ChromePicker } from 'react-color';
import CircleIcon from '@mui/icons-material/Circle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { useGetTicketsQuery } from '@/Api/ticketsSlice';
import { Geometry } from '@/Models/types';
import {
  useCreateEventGenresMutation,
  useGetEventGenresQuery,
  useGetGenresQuery,
  useDeleteEventGenreMutation,
} from '@/Api/genresSlice';
import { Genre } from '@/Models/genres';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';

const allowedImageTypes = ['image/png', 'image/jpeg'];

const paymentGatewayOptions = getSelectOptionsFromEnum(PaymentGateway);
const currencyOptions = getSelectOptionsFromEnum(Currency);

const CreateOrEdit = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { organizationId } = useParams();

  const dispatch = useDispatch();
  const [hideAddress, setHideAddress] = useState(false);
  const [marker, setMarker] = useState<undefined | { lat: number; lng: number }>(undefined);
  const [searchAddress, setSearchAddress] = useState('');

  const [generatedEventId, setGeneratedEventId] = useState<string | undefined>(undefined);
  const [generateEventId] = useGenerateEventIdMutation();
  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();

  const [createEventGenres] = useCreateEventGenresMutation();
  const [genre, setGenre] = useState<string[]>([]);
  const [deleteEventGenre] = useDeleteEventGenreMutation();

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
    skip: !eventId,
  });

  const { data: genres = { data: [] } } = useGetGenresQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: eventGenres, refetch } = useGetEventGenresQuery(eventId!, {
    refetchOnMountOrArgChange: true,
    skip: !eventId,
  });

  const isCreatingEvent = !eventId;

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: environment.googleMapsApiKey,
  });

  const [fileQueue, setFileQueue] = useState<{ key: string; file: File }[]>([]);

  const [anchorPrimary, setAnchorPrimary] = React.useState<HTMLButtonElement | null>(null);
  const handleClickPrimary = (event: any) => {
    setAnchorPrimary(event.currentTarget);
  };
  const handleClosePrimary = () => {
    setAnchorPrimary(null);
  };
  const openPrimary = Boolean(anchorPrimary);
  const idPrimary = openPrimary ? 'primary-popover' : undefined;

  const [anchorSecondary, setAnchorSecondary] = React.useState<HTMLButtonElement | null>(null);
  const handleClickSecondary = (event: any) => {
    setAnchorSecondary(event.currentTarget);
  };
  const handleCloseSecondary = () => {
    setAnchorSecondary(null);
  };

  const toggleHideAddress = () => {
    setHideAddress(!hideAddress);
  };

  const openSecondary = Boolean(anchorSecondary);
  const idSecondary = openSecondary ? 'primary-popover' : undefined;

  const imagePreviews: {
    logo?: PreviewData;
    cover_image_mobile?: PreviewData;
    cover_image_desktop?: PreviewData;
    event_poster_mobile?: PreviewData;
    event_poster_desktop?: PreviewData;
    ticket_header?: PreviewData;
    parking_spaces_image?: PreviewData;
  } = useMemo(() => {
    return {
      logo: event && { type: 'image', url: event.logo },
      cover_image_mobile: event && { type: 'image', url: event.cover_image_mobile },
      cover_image_desktop: event && { type: 'image', url: event.cover_image_desktop },
      event_poster_mobile: event && { type: 'image', url: event.event_poster_mobile },
      event_poster_desktop: event && { type: 'image', url: event.event_poster_desktop },
      ticket_header: event && { type: 'image', url: event.ticket_header },
      parking_spaces_image: event && { type: 'image', url: event.parking_spaces_image },
    };
  }, [event]);

  const [loading, withLoading] = useLoading();

  const { data, setData, errors, setError, clearErrors, hasErrors } = useForm<{
    starts_at: Date | null;
    ends_at: Date | null;
    [key: string]: any;
  }>({
    title: '',
    slug: '',
    description: '',
    terms: '',
    starts_at: startOfDay(add(new Date(), { days: 1 })),
    ends_at: endOfDay(add(new Date(), { days: 1 })),
    timezone: '',
    order_prefix: '',
    age_limit: 18,
    address: '',
    venue: '',
    city: '',
    zip_code: '',
    country: '',
    payment_gateway: paymentGatewayOptions[0].value,
    currency: currencyOptions[0].value,
    vat: 0,
    logo: '',
    cover_image_mobile: '',
    cover_image_desktop: '',
    event_poster_mobile: '',
    event_poster_desktop: '',
    ticket_header: '',
    primary_color: '',
    secondary_color: '',
    invitations_enabled: false,
    passcodes_enabled: false,
    vouchers_enabled: false,
    donations_enabled: false,
    round_up_donations_enabled: false,
    services_enabled: false,
    seating_enabled: false,
    parking_spaces_enabled: false,
    online_event: false,
    minimum_seats_per_order: 1,
    maximum_seats_per_order: 10,
    private: false,
    visible: false,
    latitude: 0,
    longitude: 0,
    booking_fee: 0.0,
    booking_fee_type: 'fixed',
    parking_spaces_image: '',
  });

  const createSlug = (title: string) => {
    return title
      .slice(0, 50)
      .toLowerCase()
      .replace(/[^a-z0-9-]/gi, '-');
  };

  useEffect(() => {
    if (event) {
      setMarker({ lat: +event.latitude, lng: +event.longitude });
      setData({ ...data, ...event });
    }

    if (event?.online_event) {
      getTimezonesFromEndpoint();
    }
  }, [event]);

  const eventHasStarted = event && isBefore(new Date(event.starts_at), new Date());

  const { data: tickets } = useGetTicketsQuery(
    { eventId: eventId! },
    { refetchOnMountOrArgChange: true, skip: !eventId },
  );

  const ticketsHaveStartedSelling = useMemo(() => {
    if (tickets) {
      return tickets.some((ticket) => {
        return isBefore(new Date(ticket.starts_at), new Date());
      });
    }
    return false;
  }, [tickets]);

  const startsAtMinDate = useMemo(() => {
    return eventHasStarted ? undefined : add(new Date(), { days: 1 });
  }, []);

  function getEndsAtMinDate() {
    return data.starts_at ? new Date(data.starts_at) : new Date();
  }

  const genreMap = new Map(genres.data.map((genre: Genre) => [genre.name, genre]));

  function getGenres(genreNames: string[]): { id: string; name: string }[] {
    return genreNames.map((name) => {
      const genre = genreMap.get(name);
      return genre ? { id: genre.id, name: name } : { id: '', name: name };
    });
  }
  async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    if (hasErrors) {
      return;
    }

    const cleanedTextValue = data.description.replace(/<(?:.|\n)*?>/g, '').trim();
    if (!cleanedTextValue && !isCreatingEvent) {
      setError('description', 'Description is required');
      return;
    }

    let eventData = { ...data };

    let newId = generatedEventId;
    if (isCreatingEvent) {
      if (!newId) {
        newId = await withLoading(generateEventId().unwrap()).then((res) => {
          return res.id;
        });
        setGeneratedEventId(newId);
      }
      eventData = { ...eventData, id: newId };
    } else {
      newId = eventId;
    }

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles(newId));
      eventData = { ...eventData, ...processedFiles };
    }

    let promise: Promise<any>;
    if (isCreatingEvent) {
      promise = createEvent(eventData).unwrap();
    } else {
      promise = updateEvent({ eventId: eventId!, body: eventData }).unwrap();
    }

    withLoading(promise)
      .then(() => {
        if (newId) {
          const genreData = getGenres(genre);
          return Promise.all(
            genreData.map((genre) => {
              return createEventGenres({
                eventId: newId!,
                genreId: genre.id,
                genreName: genre.name,
              }).unwrap();
            }),
          );
        }
      })
      .then(() => {
        if (!organizationId) {
          navigate('/events');
        } else {
          navigate(`/organizations/${organizationId}/events`);
        }
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors);
        }
      });
  }

  async function processFiles(newEventId?: string) {
    // TODO front: should handle case when files exceed 5 mb after compression
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.EVENTS,
        eventId: eventId || newEventId || '',
      })),
    );

    const eventData: { [key: string]: any } = {};
    signedUrlPayloads.forEach((p, i) => {
      eventData[fileData[i].key] = p.fields.key;
      setData((prevData) => {
        return { ...prevData, [fileData[i].key]: p.fields.key };
      });
    });

    setFileQueue([]);

    return eventData;
  }

  function validatePictures(data: any) {
    const imageFields = [
      'logo',
      'cover_image_mobile',
      'cover_image_desktop',
      'ticket_header',
      'parking_spaces_image',
    ];

    if (data.online_event == true) {
      imageFields.splice(3, 1);
    }

    const notUploadedImages = imageFields.filter((field) => !data[field]);

    notUploadedImages.forEach((field) => {
      setError(field, 'Please upload an image first!');
    });

    if (!!notUploadedImages.length) {
      return false;
    }

    return true;
  }

  function updateData(fieldName: string, value: any) {
    clearErrors(fieldName);
    setData((prevData) => ({ ...prevData, [fieldName]: value }));
  }

  function getTitle() {
    return `${eventId ? 'Update' : 'Create'} Event`;
  }

  function addFileInQueue(key: string, file: File) {
    clearErrors(key);
    setData(key, '');
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const fetchLocationData = async (searchAddress: string) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${searchAddress}&key=${environment.googleMapsApiKey}`,
    );
    const result = await response.json();
    setMarker(result.results[0].geometry.location);
  };

  const onMapClick = async (e: any) => {
    let newMarker = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setMarker(newMarker);

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.latLng.lat()},${e.latLng.lng()}&result_type=locality|country|postal_code&key=${
        environment.googleMapsApiKey
      }`,
    );
    const result = await response.json();

    let address = {
      country: '',
      city: '',
      zip_code: '',
    };
    for (const result_type of result.results) {
      if (result_type.types.includes('country')) {
        address.country = result_type.address_components[0].long_name;
      }
      if (
        result_type.types.includes('postal_code') &&
        !result_type.types.includes('postal_code_prefix')
      ) {
        address.zip_code = result_type.address_components[0].long_name;
      }
      if (result_type.types.includes('locality') && !result_type.types.includes('sublocality')) {
        address.city = result_type.address_components[0].long_name;
      }
    }

    setData((data) => ({ ...data, ...address, latitude: newMarker.lat, longitude: newMarker.lng }));

    setTimezoneFromLocation(newMarker.lat, newMarker.lng);
  };

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  const setTimezoneFromLocation = async (latitude: string, longitude: string) => {
    const timestamp = Math.round(new Date().getTime() / 1000);

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${environment.googleMapsApiKey}`,
    );

    const result = await response.json();

    setData((data) => ({ ...data, timezone: result.timeZoneId }));
  };

  const getTimezonesFromEndpoint = async () => {
    const response = await fetch('https://unpkg.com/moment-timezone@latest/data/meta/latest.json');
    const responseToJson = await response.json();
    const timezones = Object.values(responseToJson.countries).flatMap((country: any) =>
      country.zones.map((zone: any) => ({
        value: zone,
        name: country.name,
      })),
    );

    setData((data) => ({ ...data, timezones: timezones }));
  };

  useEffect(() => {
    if (eventGenres?.data) {
      refetch();
      const genreNames = eventGenres.data
        .filter((item) => item.genre !== undefined)
        .map((item) => item.genre.name);
      setGenre(genreNames);
    }
  }, [eventGenres]);

  function handleDeleteGenre(genreId: string, eventId: string) {
    deleteEventGenre({ eventId, genreId })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {});
  }

  return (
    <div className='py-8'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
        <form name='createForm' onSubmit={handleSubmit}>
          <Stack spacing={4} divider={<Divider />}>
            <Stack direction={direction} spacing={2}>
              <div className='ml-2  lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the event
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      id='title'
                      name='title'
                      label='Title *'
                      error={!!errors.title}
                      helperText={errors.title?.[0]}
                      fullWidth
                      value={data.title}
                      onChange={(e) => updateData('title', e?.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='slug'
                      name='slug'
                      label='Slug *'
                      disabled={ticketsHaveStartedSelling}
                      error={!!errors.slug}
                      helperText={
                        errors.slug?.[0] ||
                        'This slug will be used in the event URL. Maximum length is 50 characters.'
                      }
                      fullWidth
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={data.slug}
                      onChange={(e) => updateData('slug', e?.target.value)}
                      onFocus={(e) => updateData('slug', createSlug(data.title))}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='subtitle1' className='pb-2'>
                      Description *
                    </Typography>
                    <ReactQuill
                      theme='snow'
                      id='description'
                      value={data.description}
                      onChange={(e) => updateData('description', e)}
                      style={{ border: errors.description && '1px solid red' }}
                    />
                    {errors.description && (
                      <Typography variant='caption' color='error'>
                        {errors.description}
                      </Typography>
                    )}
                  </Grid>

                  {genres.data && (
                    <Grid className='flex flex-col space-x-3' item xs={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          freeSolo
                          options={
                            genres.data.length ? genres.data.map((genre: Genre) => genre.name) : []
                          }
                          value={genre.map((val) => {
                            const foundGenre = genres.data.find((genre) => genre.name === val);
                            return foundGenre ? foundGenre.name : val;
                          })}
                          onChange={(event, newValue) => {
                            setGenre(newValue);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                            }
                          }}
                          renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => {
                              const genre = genres.data.find((genre) => genre.name === option);
                              return (
                                <Chip
                                  variant='outlined'
                                  label={option}
                                  {...getTagProps({ index })}
                                  onDelete={() => {
                                    if (genre && eventId) {
                                      handleDeleteGenre(genre.id, eventId);
                                    }
                                  }}
                                />
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Choose Genres'
                              InputProps={{ ...params.InputProps, endAdornment: null }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      id='terms'
                      name='terms'
                      label='Terms and Conditions Page URL'
                      error={!!errors.terms}
                      helperText={
                        errors.terms?.[0] ||
                        'If left empty the default terms and conditions will be used.'
                      }
                      fullWidth
                      value={data.terms}
                      onChange={(e) => updateData('terms', e?.target.value)}
                      type='url'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id='orderPrefix'
                      name='orderPrefix'
                      label='Order Prefix *'
                      error={!!errors.order_prefix}
                      helperText={errors.order_prefix?.[0]}
                      fullWidth
                      value={data.order_prefix}
                      onChange={(e) => updateData('order_prefix', e?.target.value.toUpperCase())}
                      placeholder='e.g. SHF'
                      disabled={!!event?.order_prefix}
                      inputProps={{
                        maxLength: 3,
                        minLength: 3,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id='ageLimit'
                      name='ageLimit'
                      label='Age Limit *'
                      type='number'
                      error={!!errors.age_limit}
                      helperText={errors.age_limit?.[0]}
                      inputProps={{ min: 0, max: 200 }}
                      fullWidth
                      value={data.age_limit}
                      onChange={(e) => updateData('age_limit', +e?.target.value)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            {data?.online_event ? null : (
              <>
                <Stack direction={direction} spacing={2}>
                  <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                    <Typography variant='h6'>Address</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      Write the address where the event will be held at
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      For Online Events this is not required.
                    </Typography>
                  </div>

                  <Stack className='lg:w-2/3'>
                    <Paper elevation={2} sx={{ p: 4 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Stack direction='row' spacing={2}>
                            <TextField
                              label='Search for your venue'
                              fullWidth
                              onChange={(e) => setSearchAddress(e.target.value)}
                            />
                            <Button onClick={() => fetchLocationData(searchAddress)}>Search</Button>
                          </Stack>
                        </Grid>

                        <Grid className='h-[277px] lg:h-[523px] w-full py-2 pl-6'>
                          {isLoaded && (
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={marker || { lat: 51.50303343502122, lng: 0.003225 }}
                              zoom={15}
                              onClick={(e: any) => onMapClick(e)}
                            >
                              {marker ? (
                                <MarkerF position={{ lat: marker.lat, lng: marker.lng }} />
                              ) : (
                                <MarkerF position={{ lat: 51.50303343502122, lng: 0.003225 }} />
                              )}
                            </GoogleMap>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            required
                            id='city'
                            name='city'
                            label='City'
                            error={!!errors.city}
                            helperText={errors.city?.[0]}
                            fullWidth
                            value={data.city}
                            disabled={marker ? false : true}
                            onChange={(e) => updateData('city', e?.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            required
                            id='zipCode'
                            name='zipCode'
                            label='Zip/Postal Code'
                            error={!!errors.zip_code}
                            helperText={errors.zip_code?.[0]}
                            fullWidth
                            value={data.zip_code}
                            disabled={marker ? false : true}
                            onChange={(e) => updateData('zip_code', e?.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            id='country'
                            name='country'
                            label='Country *'
                            error={!!errors.country}
                            helperText={errors.country?.[0]}
                            fullWidth
                            value={data.country}
                            disabled={marker ? false : true}
                            onChange={(e) => updateData('country', e?.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id='venue'
                            name='venue'
                            label='Venue *'
                            error={!!errors.venue}
                            helperText={errors.venue?.[0]}
                            fullWidth
                            value={data.venue}
                            onChange={(e) => updateData('venue', e?.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Stack>
                </Stack>
              </>
            )}

            <Stack direction={direction} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>Date & Time</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Set the date and time of the event
                </Typography>
              </div>
              <Stack className=' lg:w-2/3'>
                <Paper elevation={2} sx={{ p: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <DesktopDateTimePicker
                        label='Starts at'
                        inputFormat='MM/dd/yyyy HH:mm'
                        minDate={startsAtMinDate}
                        disabled={eventHasStarted}
                        value={data.starts_at}
                        onChange={(value) => updateData('starts_at', value)}
                        renderInput={function (
                          props: TextFieldProps,
                        ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                          return (
                            <TextField
                              {...props}
                              id='startsAt'
                              name='startsAt'
                              label='Starts At'
                              error={!!errors.starts_at}
                              helperText={errors.starts_at?.[0]}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <DesktopDateTimePicker
                        label='Ends at'
                        inputFormat='MM/dd/yyyy HH:mm'
                        minDate={getEndsAtMinDate()}
                        disabled={eventHasStarted}
                        value={data.ends_at}
                        onChange={(value) => updateData('ends_at', value)}
                        renderInput={function (
                          props: TextFieldProps,
                        ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                          return (
                            <TextField
                              {...props}
                              id='startsAt'
                              name='startsAt'
                              label='Ends At'
                              error={!!errors.ends_at}
                              helperText={errors.ends_at?.[0]}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      {!data?.online_event ? (
                        <TextField
                          required
                          id='timezone'
                          name='timezone'
                          label='Timezone'
                          error={!!errors.timezone}
                          helperText={errors.timezone?.[0]}
                          fullWidth
                          value={data.timezone}
                          disabled={eventHasStarted}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      ) : (
                        <Select
                          required
                          id='timezone'
                          name='timezone'
                          label='Timezone'
                          error={!!errors.timezone}
                          fullWidth
                          value={data.timezone}
                          disabled={!data.online_event || eventHasStarted}
                          onChange={(event) => {
                            const selectedTimezone = event.target.value;
                            setData((data) => ({ ...data, timezone: selectedTimezone }));
                          }}
                        >
                          {data.timezones?.map((timezone: any, index: any) => (
                            <MenuItem key={index} value={timezone.value}>
                              {timezone.name} - {timezone.value}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
            </Stack>

            <Stack direction={direction} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>Other</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide other information such as: payment info, ticket customization, etc.
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      id='paymentGateway'
                      name='paymentGateway'
                      label='Payment Gateway *'
                      error={!!errors.payment_gateway}
                      helperText={errors.payment_gateway?.[0]}
                      fullWidth
                      value={data.payment_gateway}
                      onChange={(e) => updateData('payment_gateway', e?.target.value)}
                    >
                      {paymentGatewayOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      id='currency'
                      name='currency'
                      label='Currency *'
                      disabled={ticketsHaveStartedSelling}
                      error={!!errors.currency}
                      helperText={errors.currency?.[0]}
                      fullWidth
                      value={data.currency}
                      onChange={(e) => updateData('currency', e?.target.value)}
                    >
                      {currencyOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      id='vat'
                      name='vat'
                      label='VAT (%) *'
                      disabled={ticketsHaveStartedSelling}
                      error={!!errors.vat}
                      helperText={errors.vat?.[0]}
                      fullWidth
                      type='vat'
                      value={data.vat}
                      onChange={(e) => updateData('vat', e?.target.value)}
                    />
                  </Grid>

                  <PermissionsGate scope={SCOPES.ManageOrganizer}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id='booking_fee_type_label'>Booking Fee Type</InputLabel>
                        <Select
                          required
                          labelId='booking_fee_type_label'
                          id='booking_fee_type'
                          value={data.booking_fee_type}
                          error={!!errors.booking_fee_type}
                          label='Booking Fee Type'
                          onChange={(e) => updateData('booking_fee_type', e?.target.value)}
                        >
                          <MenuItem value='fixed'>Fixed</MenuItem>
                          <MenuItem value='percentage'>Percentage</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id='booking_fee'
                        name='booking_fee'
                        label='Booking Fee'
                        error={!!errors.booking_fee}
                        helperText={errors.booking_fee?.[0]}
                        fullWidth
                        value={data.booking_fee}
                        onChange={(e) => updateData('booking_fee', e?.target.value)}
                      />
                    </Grid>
                  </PermissionsGate>

                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Logo*'
                        allowedFileTypes={allowedImageTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('logo', file)}
                        preview
                        previewData={imagePreviews.logo}
                        previewProps={{ style: { width: '15rem', objectFit: 'contain' } }}
                        helperText='Recommended size: 120x120px'
                      />

                      {errors['logo'] && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>{errors['logo']}</FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Cover Image Mobile*'
                        allowedFileTypes={allowedImageTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('cover_image_mobile', file)}
                        preview
                        previewData={imagePreviews.cover_image_mobile}
                        helperText='Recommended size: 390x678px'
                      />

                      {errors['cover_image_mobile'] && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>
                            {errors['cover_image_mobile']}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Cover Image Desktop*'
                        allowedFileTypes={allowedImageTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('cover_image_desktop', file)}
                        preview
                        previewData={imagePreviews.cover_image_desktop}
                        helperText='Recommended size: 1440x684px'
                      />

                      {errors['cover_image_desktop'] && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>
                            {errors['cover_image_desktop']}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Event poster for mobile*'
                        allowedFileTypes={allowedImageTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('event_poster_mobile', file)}
                        preview
                        previewData={imagePreviews.event_poster_mobile}
                        helperText='Recommended size: 328x392px'
                      />

                      {errors['event_poster_mobile'] && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>
                            {errors['event_poster_mobile']}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <FileUpload
                        label='Event poster for desktop*'
                        allowedFileTypes={allowedImageTypes}
                        maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                        onSelect={(file) => addFileInQueue('event_poster_desktop', file)}
                        preview
                        previewData={imagePreviews.event_poster_desktop}
                        helperText='Recommended size: 1440x392px'
                      />

                      {errors['event_poster_desktop'] && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>
                            {errors['event_poster_desktop']}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>

                  {data?.online_event ? null : (
                    <Grid item xs={12}>
                      <Stack>
                        <FileUpload
                          label='Ticket PDF Image'
                          allowedFileTypes={allowedImageTypes}
                          maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                          onSelect={(file) => addFileInQueue('ticket_header', file)}
                          preview
                          previewData={imagePreviews.ticket_header}
                          helperText='Recommended size: 1769x883px'
                        />

                        {errors['ticket_header'] && (
                          <FormControl error>
                            <FormHelperText className='!ml-0'>
                              {errors['ticket_header']}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} className='relative'>
                    <TextField
                      id='primary_color'
                      name='primary_color'
                      label='Primary Color'
                      error={!!errors.primary_color}
                      helperText={errors.primary_color?.[0]}
                      fullWidth
                      required
                      value={data.primary_color}
                      onChange={(e) => updateData('primary_color', e?.target.value)}
                    />

                    <Tooltip
                      title='Click to use color picker'
                      aria-describedby={idPrimary}
                      onClick={handleClickPrimary}
                      sx={{ position: 'absolute', top: '2rem', right: 0 }}
                    >
                      <IconButton>
                        <CircleIcon sx={{ color: data.primary_color }} />
                      </IconButton>
                    </Tooltip>

                    <Popover
                      id={idPrimary}
                      open={openPrimary}
                      anchorEl={anchorPrimary}
                      onClose={handleClosePrimary}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <ChromePicker
                        color={data.primary_color}
                        onChange={(e) => updateData('primary_color', e.hex)}
                      />
                    </Popover>
                  </Grid>

                  <Grid item xs={12} sm={6} className='relative'>
                    <TextField
                      id='secondary_color'
                      name='secondary_color'
                      label='Secondary Color'
                      error={!!errors.secondary_color}
                      helperText={errors.secondary_color?.[0]}
                      fullWidth
                      required
                      value={data.secondary_color}
                      onChange={(e) => updateData('secondary_color', e?.target.value)}
                    />

                    <Tooltip
                      title='Click to use color picker'
                      aria-describedby={idSecondary}
                      onClick={handleClickSecondary}
                      sx={{ position: 'absolute', top: '2rem', right: 0 }}
                    >
                      <IconButton>
                        <CircleIcon sx={{ color: data.secondary_color }} />
                      </IconButton>
                    </Tooltip>

                    <Popover
                      id={idSecondary}
                      open={openSecondary}
                      anchorEl={anchorSecondary}
                      onClose={handleCloseSecondary}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <ChromePicker
                        color={data.secondary_color}
                        onChange={(e) => updateData('secondary_color', e.hex)}
                      />
                    </Popover>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.online_event}
                            onChange={(e) => {
                              updateData('online_event', e.target.checked);
                              if (e.target.checked) {
                                getTimezonesFromEndpoint();
                              }
                            }}
                            name='online_event'
                          />
                        </div>
                      }
                      label='Online Event'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.invitations_enabled}
                            onChange={(e) => updateData('invitations_enabled', e.target.checked)}
                            name='invitations_enabled'
                          />
                        </div>
                      }
                      label='Enable Invitations'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.passcodes_enabled}
                            onChange={(e) => updateData('passcodes_enabled', e.target.checked)}
                            name='passcodes_enabled'
                          />
                        </div>
                      }
                      label='Enable Passcodes'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.vouchers_enabled}
                            onChange={(e) => updateData('vouchers_enabled', e.target.checked)}
                            name='vouchers_enabled'
                          />
                        </div>
                      }
                      label='Enable Vouchers'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.donations_enabled}
                            onChange={(e) => updateData('donations_enabled', e.target.checked)}
                            name='donations_enabled'
                          />
                        </div>
                      }
                      label='Enable Donations'
                    />
                  </Grid>

                  {data.donations_enabled && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <div className='px-[9px]'>
                            <IOSSwitch
                              checked={data.round_up_donations_enabled}
                              onChange={(e) =>
                                updateData('round_up_donations_enabled', e.target.checked)
                              }
                              name='round_up_donations_enabled'
                            />
                          </div>
                        }
                        label='Enable Round Up Donations'
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.services_enabled}
                            onChange={(e) => updateData('services_enabled', e.target.checked)}
                            name='services_enabled'
                          />
                        </div>
                      }
                      label='Enable Services'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.seating_enabled}
                            onChange={(e) => updateData('seating_enabled', e.target.checked)}
                            name='seating_enabled'
                          />
                        </div>
                      }
                      label='Enable Seating'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <div className='px-[9px]'>
                          <IOSSwitch
                            checked={data.parking_spaces_enabled}
                            onChange={(e) => updateData('parking_spaces_enabled', e.target.checked)}
                            name='parking_spaces_enabled'
                          />
                        </div>
                      }
                      label='Enable Parking'
                    />
                  </Grid>
                  {data.parking_spaces_enabled && (
                    <Grid item xs={12}>
                      <Stack>
                        <FileUpload
                          label='Parking Image*'
                          allowedFileTypes={allowedImageTypes}
                          maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                          onSelect={(file) => addFileInQueue('parking_spaces_image', file)}
                          preview
                          previewData={imagePreviews.parking_spaces_image}
                          helperText='Recommended size: 1280x720px'
                        />
                        {errors['parking_spaces_image'] && (
                          <FormControl error>
                            <FormHelperText className='!ml-0'>
                              {errors['parking_spaces_image']}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </Stack>
                    </Grid>
                  )}
                  {data.seating_enabled ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          required
                          id='minimum_seats_per_order'
                          name='minimum_seats_per_order'
                          label='Minimum seats per order (inclusive)'
                          type='number'
                          error={!!errors.minimum_seats_per_order}
                          helperText={errors.minimum_seats_per_order?.[0]}
                          inputProps={{ min: 1, max: data.maximum_seats_per_order }}
                          fullWidth
                          value={data.minimum_seats_per_order}
                          onChange={(e) => updateData('minimum_seats_per_order', +e?.target.value)}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          id='maximum_seats_per_order'
                          name='maximum_seats_per_order'
                          label='Maximum seats per order (inclusive)'
                          type='number'
                          error={!!errors.maximum_seats_per_order}
                          helperText={errors.maximum_seats_per_order?.[0]}
                          inputProps={{ min: data.minimum_seats_per_order, max: 10 }}
                          fullWidth
                          value={data.maximum_seats_per_order}
                          onChange={(e) => updateData('maximum_seats_per_order', +e?.target.value)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <PermissionsGate scope={SCOPES.ManageOrganizer}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.visible}
                            onChange={(e) => updateData('visible', e.target.checked)}
                            name='visible'
                          />
                        }
                        label='Make event visible to users'
                      />
                    </Grid>
                  </PermissionsGate>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.private}
                          onChange={(e) => updateData('private', e.target.checked)}
                          name='private'
                        />
                      }
                      label='Private'
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
          </Stack>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton loading={loading}>
              <Button type='submit' variant='contained' disabled={loading} color='success'>
                Save
              </Button>
            </LoadingButton>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CreateOrEdit;
