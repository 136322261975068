import { useState } from 'react';
import { WithStepper } from '@/Models/stepper';
import {
  ConfirmationNumberOutlined,
  EventSeatOutlined,
  RemoveCircleOutline,
} from '@mui/icons-material';
import {
  Button,
  Stack,
  TextField,
  Typography,
  Grid,
  Paper,
  MenuItem,
  IconButton,
} from '@mui/material';
import { Ticket } from '@/Models/tickets';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CheckoutForm } from './form-data';
import { formatPrice, formatPriceMajor } from '@/Utils/currency';
import {
  useApplyVoucherMutation,
  useCancelTransactionMutation,
  useCaptureOrderMutation,
  useRemoveVoucherMutation,
} from '@/Api/transactionsSlice';
import BaseDialog from '@/Components/Molecules/Dialogs/BaseDialog';
import { DataKey, LocalTransactionData } from '@/Models/local-storage';
import { FSTXLocalStorage } from '@/Utils/local-storage';
import { useNavigate, useParams } from 'react-router-dom';
import { ordinalSuffixOf } from '@/Utils/general';
import { getSelectOptionsFromEnum } from '@/Utils/enums';
import { PaymentGateway } from '@/Enums/payment-gateway';
import PaymentTypeLogic from './PaymentTypeLogic';
import { Event } from '@/Models/events';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { TransactionExtraData } from '@/Models/transactions';

type InformationsStepProps = WithStepper<{
  event: Event | undefined;
  ticketsById: { [key: string | number]: Partial<Ticket> } | undefined;
  costsData?: any;
  expanded: number;
  tdIndexIdToSeatDataMap?: { [key: string]: string };
  localTransactionData?: LocalTransactionData | null;
  transactionExtraData?: { hasVouchers: boolean };
  updateExtraData: (data: TransactionExtraData) => void;
  onVoucherAdd: any;
  appliedVoucher: any;
  onVoucherRemove: any;
}>;

const paymentGatewayOptionsSupportOrder = getSelectOptionsFromEnum(PaymentGateway);

const InformationsStep = ({
  event,
  expanded,
  ticketsById,
  costsData,
  tdIndexIdToSeatDataMap,
  localTransactionData,
  updateExtraData,
  onVoucherAdd,
  onVoucherRemove,
  appliedVoucher,
}: InformationsStepProps) => {
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelTransaction] = useCancelTransactionMutation();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const {
    control,
    getValues,
    trigger,
    setValue,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext<CheckoutForm>();

  const { fields, update } = useFieldArray({
    control,
    name: 'informations_step.ticket_holders',
  });

  function fillTicket(index: number) {
    update(index, { ...fields[index], ...getValues('informations_step.buyer') });
    trigger(`informations_step.ticket_holders.${index}`);
  }

  function onCancelDialogClose(value: boolean) {
    setCancelDialogOpen(false);

    if (!value) {
      return;
    }

    cancelTransaction({
      eventId: localTransactionData!.eventId!,
      transactionId: localTransactionData!.transactionId,
    })
      .unwrap()
      .then(() => {
        FSTXLocalStorage.removeItem(DataKey.TRANSACTION);
        navigate(`/events/${localTransactionData!.eventId}/support-order`);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }

  function onCancelTransactionClick() {
    if (!localTransactionData) {
      return;
    }
    setCancelDialogOpen(true);
  }

  function getStepKey(step: any): 'informations_step' {
    switch (step) {
      default:
        return 'informations_step';
    }
  }

  const [completeOrder] = useCaptureOrderMutation();

  const paymentType = watch('informations_step.payment_type.type');

  async function completeTransaction() {
    const formData = getValues();

    const transactionId = localTransactionData?.transactionId;
    if (!transactionId) {
      console.log('Transaction ID is not available.');
      return;
    }

    completeOrder({
      body: {
        informations_step: formData['informations_step'],
      },
      transactionId,
    })
      .unwrap()
      .then((data) => {
        FSTXLocalStorage.removeItem(DataKey.TRANSACTION);
        localStorage.setItem('buyer-email', formData['informations_step'].buyer.email);
        navigate(`/events/${eventId}/support-order/success`);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }

  async function proceedToNextStep(): Promise<void> {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (expanded === 0) {
      return;
    }

    const currStepKey = getStepKey(expanded);

    const stepValid = await trigger(currStepKey);

    if (!stepValid) {
      return;
    }

    if (expanded === 1) {
      await completeTransaction();
    }
  }

  function getDisplayLabelFromEnumValue(value: any): string {
    switch (value) {
      case PaymentGateway.PAYPAL:
        return 'PayPal';
      case PaymentGateway.STRIPE:
        return 'Stripe';
      case PaymentGateway.TEB:
        return 'Teb Sunny Hill';
      case PaymentGateway.TEB_DR:
        return 'Teb Digital Republic';
      case PaymentGateway.DUMMY_GATEWAY:
        return 'Cash';
      case PaymentGateway.PROCREDIT_TEST:
        return 'Procredit Test';
      case PaymentGateway.PROCREDIT_DR:
        return 'Procredit Digital Republic';
      case PaymentGateway.PROCREDIT_SHF:
        return 'Procredit Sunny Hill';
      default:
        return 'Cash';
    }
  }

  const [errorMessage, setErrorMessage] = useState('');
  const [voucher, setVoucher] = useState('');

  const [removeVoucher, { isLoading: isRemoving }] = useRemoveVoucherMutation();

  const [applyVoucher, { isLoading }] = useApplyVoucherMutation();
  const handleApplyVoucher = () => {
    if (!localTransactionData) {
      setErrorMessage('Transaction data is missing.');
      return;
    }

    applyVoucher({
      eventId: localTransactionData.eventId,
      transactionId: localTransactionData.transactionId,
      voucher,
    })
      .unwrap()
      .then((data) => {
        updateExtraData({
          hasVouchers: true,
        });
        onVoucherAdd(data);
      })
      .catch((err) => {
        const errorMessage =
          err?.data?.message || err?.data?.error || 'An error occurred while applying the voucher.';
        setErrorMessage(errorMessage);
      });
  };

  const handleRemoveVoucher = () => {
    if (!localTransactionData) {
      setErrorMessage('Transaction data is missing.');
      return;
    }

    removeVoucher({
      transactionId: localTransactionData.transactionId,
    })
      .unwrap()
      .then(() => {
        updateExtraData({
          hasVouchers: false,
        });
        onVoucherRemove();
      })
      .catch((err) => {
        const errorMessage =
          err?.data?.message || err?.data?.error || 'An error occurred while removing the voucher.';
        setErrorMessage(errorMessage);
      });
  };

  return (
    <>
      <Stack>
        <Stack direction='row' spacing={4}>
          <div className='w-1/3 flex-shrink-0'>
            <Typography variant='h6'>Buyer Informations</Typography>

            <Typography variant='body2' color='text.secondary'>
              Provide essential information about buyer
            </Typography>
          </div>

          <Paper elevation={2} sx={{ p: 4 }}>
            {/* <Grid container spacing={3} sx={{ mr: -3 }}>
             */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name='informations_step.buyer.first_name'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      required
                      {...field}
                      id='informations_step.buyer.first_name'
                      label='First Name'
                      error={!!error}
                      helperText={error?.message}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='informations_step.buyer.last_name'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      required
                      {...field}
                      id='informations_step.buyer.last_name'
                      label='Last Name'
                      error={!!error}
                      helperText={error?.message}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='informations_step.buyer.email'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      required
                      {...field}
                      id='informations_step.buyer.email'
                      label='Email'
                      error={!!error}
                      helperText={error?.message}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='informations_step.buyer.email_confirmed'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      required
                      {...field}
                      id='informations_step.buyer.email_confirmed'
                      label='Confirm Email'
                      error={!!error}
                      helperText={
                        error?.message ||
                        'Please make sure your email is correct as it is the only way to find your order'
                      }
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Stack>
      </Stack>

      <br />

      {fields && fields.length <= 2 ? (
        <Stack>
          <Stack direction='row' spacing={4}>
            <div className='w-1/3 flex-shrink-0 p-2'>
              <Typography variant='h6'>Ticket Holder Informations</Typography>

              <Typography variant='body2' color='text.secondary'>
                Provide essential information about ticket holder
              </Typography>
            </div>
            {ticketsById &&
              fields.map((field, index) => (
                <Paper elevation={2} sx={{ p: 4 }} key={field.id}>
                  <div>
                    <Typography>{ordinalSuffixOf(index + 1)} Attendee</Typography>
                    <Typography
                      className='text-xs flex justify-start items-center gap-1'
                      color='info.main'
                    >
                      <ConfirmationNumberOutlined sx={{ fontSize: '16px' }} />
                      <span>{ticketsById[field.ticket_id].title}</span>
                    </Typography>
                    {!!tdIndexIdToSeatDataMap && (
                      <Typography
                        className='text-xs flex justify-start items-center gap-1'
                        color='info.main'
                      >
                        <EventSeatOutlined sx={{ fontSize: '16px' }} />
                        <span>{tdIndexIdToSeatDataMap[index]}</span>
                      </Typography>
                    )}
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        name={`informations_step.ticket_holders.${index}.first_name`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            required
                            {...field}
                            id={`ticket_holders.${index}.first_name`}
                            label='First Name'
                            error={!!error}
                            helperText={error?.message}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name={`informations_step.ticket_holders.${index}.last_name`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            required
                            {...field}
                            id={`ticket_holders.${index}.last_name`}
                            label='Last Name'
                            error={!!error}
                            helperText={error?.message}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name={`informations_step.ticket_holders.${index}.email`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            required
                            {...field}
                            id={`ticket_holders.${index}.email`}
                            label='Email'
                            error={!!error}
                            helperText={error?.message}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    size='small'
                    color='secondary'
                    className='self-start normal-case mt-1'
                    onClick={() => fillTicket(index)}
                  >
                    Fill from basic information
                  </Button>
                </Paper>
              ))}
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant='h6'>Ticket Holder Informations</Typography>
              <Typography variant='body2' color='text.secondary'>
                Provide essential information about ticket holder
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {ticketsById &&
                fields.map((field: any, index: any) => (
                  <>
                    <Paper
                      elevation={2}
                      sx={{ p: 4, width: '96.5%', marginLeft: 'auto' }}
                      key={field.id}
                    >
                      <div>
                        <Typography>{ordinalSuffixOf(index + 1)} Attendee</Typography>
                        <Typography
                          className='text-xs flex justify-start items-center gap-1'
                          color='info.main'
                        >
                          <ConfirmationNumberOutlined sx={{ fontSize: '16px' }} />
                          <span>{ticketsById[field.ticket_id].title}</span>
                        </Typography>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Controller
                            name={`informations_step.ticket_holders.${index}.first_name`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                required
                                {...field}
                                id={`ticket_holders.${index}.first_name`}
                                label='First Name'
                                error={!!error}
                                helperText={error?.message}
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controller
                            name={`informations_step.ticket_holders.${index}.last_name`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                required
                                {...field}
                                id={`ticket_holders.${index}.last_name`}
                                label='Last Name'
                                error={!!error}
                                helperText={error?.message}
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controller
                            name={`informations_step.ticket_holders.${index}.email`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                required
                                {...field}
                                id={`ticket_holders.${index}.email`}
                                label='Email'
                                error={!!error}
                                helperText={error?.message}
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        size='small'
                        color='secondary'
                        className='self-start normal-case mt-1'
                        onClick={() => fillTicket(index)}
                      >
                        Fill from basic information
                      </Button>
                    </Paper>
                    <br />
                  </>
                ))}
            </Grid>
          </Grid>
        </Stack>
      )}
      <br />

      {appliedVoucher ? (
        <>
          <Stack>
            <Stack direction='row' spacing={4}>
              <div className='w-1/3 flex-shrink-0 p-2'>
                <Typography variant='h6'>Voucher</Typography>

                <Typography variant='body2' color='text.secondary'>
                  Remove applied voucher
                </Typography>
              </div>
              <Paper elevation={2} sx={{ p: 4, width: '96.5%', marginLeft: 'auto' }}>
                <div className='flex flex-row items-center gap-3'>
                  <Typography>Remove the applied voucher</Typography>
                  <IconButton color='error' onClick={handleRemoveVoucher} disabled={isRemoving}>
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              </Paper>
            </Stack>
          </Stack>
        </>
      ) : null}
      {!!event?.vouchers_enabled && !appliedVoucher && (
        <Stack>
          <Stack direction='row' spacing={4}>
            <div className='w-1/3 flex-shrink-0 p-2'>
              <Typography variant='h6'>Voucher</Typography>

              <Typography variant='body2' color='text.secondary'>
                Provide essential information voucher
              </Typography>
            </div>
            <Paper elevation={2} sx={{ p: 4, width: '96.5%', marginLeft: 'auto' }}>
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                sx={{ paddingY: 1, justifyContent: 'space-between' }}
              >
                <TextField
                  onChange={(e) => setVoucher(e.target.value)}
                  id='voucher'
                  name='voucher'
                  label='Enter promo code'
                  size='small'
                  variant='outlined'
                  className='bg-white rounded md:w-3/4'
                />
                <Button
                  variant='contained'
                  color='primary'
                  className='text-xs md:text-[13px] h-10 md:w-1/4'
                  onClick={() => handleApplyVoucher()}
                >
                  USE CODE
                </Button>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      )}
      <br />

      <Stack>
        <Stack direction='row' spacing={4}>
          <div className='w-1/3 flex-shrink-0'>
            <Typography variant='h6'>Payment Informations</Typography>

            <Typography variant='body2' color='text.secondary'>
              Provide essential information about payment gateway
            </Typography>
          </div>

          <Paper elevation={2} sx={{ p: 4, width: '96.5%', marginLeft: 'auto' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name='informations_step.payment_type.type'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      required
                      {...field}
                      select
                      id='informations_step.payment_type.type'
                      label='Payment Type'
                      error={!!error}
                      helperText={error?.message}
                      fullWidth
                    >
                      {paymentGatewayOptionsSupportOrder.map(({ label, value }: any) => (
                        <MenuItem key={value} value={value}>
                          {getDisplayLabelFromEnumValue(value)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
          <br />
        </Stack>
      </Stack>

      <br />

      {paymentType !== undefined ? (
        <PaymentTypeLogic paymentType={paymentType} control={control} />
      ) : (
        <></>
      )}

      <br />

      <Stack>
        <Stack direction='row'>
          <div className='w-1/3 flex-shrink-0 p-2'>
            <Typography variant='h6'>Summary</Typography>

            <Typography variant='body2' color='text.secondary'>
              Provides summary about tickets and total
            </Typography>
          </div>
          <Paper elevation={2} sx={{ p: 4, width: '64%', marginLeft: 'auto' }}>
            <Stack spacing={1}>
              {costsData.tickets.map(
                (
                  {
                    ticketTitle,
                    quantity,
                    ticketPriceBefore,
                    ticketPriceAfter,
                    discountPercentage,
                  }: any,
                  index: number,
                ) => {
                  return (
                    <Stack key={index} direction='column' spacing={2}>
                      <Stack direction='row' alignItems='center'>
                        <Typography color='secondary.main' variant='h6' className='w-6'>
                          {quantity}x
                        </Typography>
                        <Typography className='flex-1 ml-3' variant='subtitle1' color='textBold'>
                          {ticketTitle}
                        </Typography>
                        <Typography>{formatPrice(ticketPriceBefore, event?.currency)}</Typography>
                        <Typography className='pl-1'>
                          {event
                            ? event.booking_fee != 0 ?? event.booking_fee_type === 'percentage'
                              ? `+ ${event.booking_fee}% booking fee`
                              : `+ ${formatPriceMajor(
                                  event.booking_fee,
                                  event.currency,
                                )} booking fee`
                            : 'incl. fees'}
                        </Typography>
                      </Stack>
                      {appliedVoucher ? (
                        <Stack direction='row' className='text-green-600 justify-between'>
                          <Stack direction='row'>
                            <Typography>Discount</Typography>
                            <Typography className='px-2'>{discountPercentage}%</Typography>
                          </Stack>

                          <Typography>
                            -{' '}
                            {formatPrice(
                              Math.round((ticketPriceBefore * discountPercentage) / 100),
                              event?.currency,
                            )}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  );
                },
              )}
            </Stack>
            <br />
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='column' alignItems='flex-start'>
                <Typography variant='subtitle2'>Total</Typography>

                {appliedVoucher ? (
                  <Stack sx={{ flexDirection: 'row' }}>
                    <Typography className='line-through' variant='h6'>
                      {formatPrice(costsData.total, event?.currency)}
                    </Typography>
                    <Typography className='text-green-600 pl-2' variant='h6'>
                      {formatPrice(costsData.totalAfter, event?.currency)}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography variant='h6'>
                    {formatPrice(costsData.total, event?.currency)}
                  </Typography>
                )}
              </Stack>
              <Stack direction='row' spacing={2}>
                <Button
                  type='button'
                  variant='text'
                  color='error'
                  onClick={onCancelTransactionClick}
                >
                  Cancel
                </Button>
                <Button
                  type='button'
                  variant='contained'
                  color='success'
                  onClick={proceedToNextStep}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Stack>

      <BaseDialog
        title='Are you sure you want to cancel this transaction?'
        open={cancelDialogOpen}
        onClose={onCancelDialogClose}
        content="You can't undo this action"
      >
        <Button variant='outlined' onClick={() => onCancelDialogClose(false)}>
          No
        </Button>

        <Button variant='contained' color='error' onClick={() => onCancelDialogClose(true)}>
          Yes
        </Button>
      </BaseDialog>
    </>
  );
};

export default InformationsStep;
