import React from 'react';
import ListItem from '@mui/material/ListItem';

export interface StatsProps {
  label: string;
  total: number;
  arrived: number;
  remaining: number;
}

const StatsDisplay = ({ label, total, arrived, remaining }: StatsProps) => {
  const percentage = (arrived / total) * 100;

  return (
    <ListItem className='overflow-auto whiteSpace-nowrap lg:flex lg:justify-between lg:my-10 lg:text-gray-600 gap-2'>
      <div className='w-full'>
        <div className='w-full bg-rose-300 h-2 rounded relative'>
          <span
            className='absolute bg-blue-300 h-2 rounded max-w-full'
            style={{ width: `${percentage}%` }}
          ></span>
        </div>
        <div className='flex justify-between text-md mt-1'>
          <span>{label}</span>
          <span>{arrived} Arrived</span>
          <span>{remaining} Remaining</span>
        </div>
      </div>
    </ListItem>
  );
};

export default StatsDisplay;
