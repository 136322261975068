import React, { useCallback } from 'react';
import { Event } from '@/Models/events';
import { InvitationType } from '@/Models/tickets';
import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CreateOrEditInvitationTypeDialog from './CreateOrEditInvitationTypeDialog';
import { DeleteOutline, EditOutlined, MoreVert, AssignmentIndOutlined } from '@mui/icons-material';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import {
  useGetInvitationTypesQuery,
  useDeleteInvitationTypeMutation,
} from '@/Api/invitationsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import AssignUserInvitationTypeDialog from './AssignUserInvitationTypeDialog';
import { useResponsive } from '@/Hooks/useResponsive';

interface InvitationTypeProps {
  event: Event;
  canCreate?: boolean;
}

const InvitationTypesTable = ({ event, canCreate }: InvitationTypeProps) => {
  const { data: invitationTypes } = useGetInvitationTypesQuery(event.id!, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteInvitationType] = useDeleteInvitationTypeMutation();

  const [createOrEditDialogOpen, setCreateOrEditDialogOpen] = useState(false);
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedInvitationType, setSelectedInvitationType] = useState<InvitationType>();

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    invitationType: InvitationType;
  }>(null);

  function showActionMenu(invitationType: InvitationType, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, invitationType });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const handleCreateOrEditDialogClose = useCallback((value: boolean) => {
    setCreateOrEditDialogOpen(false);
    setSelectedInvitationType(undefined);

    if (!value) {
      return;
    }
  }, []);

  const handleAssignUserDialogClose = useCallback((value: boolean) => {
    setAssignUserDialogOpen(false);
    setSelectedInvitationType(undefined);

    if (!value) {
      return;
    }
  }, []);

  function onDeleteDialogClose(value: boolean) {
    setDeleteDialogOpen(false);

    if (value && selectedInvitationType) {
      deleteInvitationType({ eventId: event.id, invitationTypeId: selectedInvitationType.id });
    }

    setSelectedInvitationType(undefined);
  }

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1 },
    {
      field: 'ticket.title',
      valueGetter: ({ row }) => row.ticket.title,
      headerName: 'Ticket title',
      flex: 1,
    },
    { field: 'allocated', headerName: 'Allocated' },
    { field: 'reserved', headerName: 'Reserved' },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <IconButton onClick={(event) => showActionMenu(row, event)}>
          <MoreVert />
        </IconButton>
      ),
      sortable: false,
    },
  ];
  const { isMobile } = useResponsive();
  return (
    <>
      <Stack spacing={2}>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between'>
          <Typography variant='h6' className='self-center py-4  lg:pt-4'>
            Invitation Types
          </Typography>
          {canCreate && (
            <Box className='lg:self-end'>
              <PermissionsGate scope={SCOPES.CreateTicket}>
                <Button
                  className='w-full'
                  variant='contained'
                  onClick={() => setCreateOrEditDialogOpen(true)}
                >
                  Create Invitation Type
                </Button>
              </PermissionsGate>
            </Box>
          )}
        </Stack>

        {invitationTypes && (
          <DataGrid autoHeight={true} hideFooter rows={invitationTypes} columns={columns} />
        )}
      </Stack>

      <PermissionsGate scope={SCOPES.UpdateTicket}>
        {menuContext && (
          <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
            <MenuItem
              onClick={() => {
                setCreateOrEditDialogOpen(true);
                setSelectedInvitationType(menuContext.invitationType);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setAssignUserDialogOpen(true);
                setSelectedInvitationType(menuContext.invitationType);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <AssignmentIndOutlined />
              </ListItemIcon>
              <ListItemText>Assign To User</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setDeleteDialogOpen(true);
                setSelectedInvitationType(menuContext.invitationType);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <DeleteOutline color='error' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        )}
      </PermissionsGate>

      <PermissionsGate scope={SCOPES.UpdateTicket}>
        {createOrEditDialogOpen && (
          <CreateOrEditInvitationTypeDialog
            eventId={event.id}
            open={createOrEditDialogOpen}
            onDialogClose={(value) => handleCreateOrEditDialogClose(value)}
            invitationType={selectedInvitationType}
          />
        )}

        {assignUserDialogOpen && (
          <AssignUserInvitationTypeDialog
            eventId={event.id}
            open={assignUserDialogOpen}
            onDialogClose={(value) => handleAssignUserDialogClose(value)}
            invitationType={selectedInvitationType}
          />
        )}

        <DeleteDialog open={deleteDialogOpen} onDialogClose={onDeleteDialogClose} />
      </PermissionsGate>
    </>
  );
};

export default InvitationTypesTable;
