import React from 'react';
import { Ticket } from '@/Models/tickets';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { formatPrice } from '@/Utils/currency';
import { Event } from '@/Models/events';

export interface AllocatedTicketTypeProps {
  ticket: Ticket;
  event: Event;
}

const AllocatedTicketType = ({ ticket, event }: AllocatedTicketTypeProps) => {
  return (
    <ListItem className='overflow-auto whiteSpace-nowrap lg:flex lg:justify-between lg:my-2 lg:text-gray-600 gap-2'>
      <ListItemText
        className='overflow-auto lg:w-1/2 lg:mr-1'
        primary={ticket.title}
        style={{ marginTop: 0 }}
        secondary={
          <React.Fragment>
            <div className='flex items-center mt-1 '>
              <span
                className={`inline-block w-3 h-3 rounded-full mr-2 ${
                  ticket.visible
                    ? ticket.sold == ticket.allocated
                      ? 'bg-rose-400'
                      : 'bg-green-500'
                    : 'bg-red-500'
                }`}
              ></span>
              <Typography variant='subtitle2'>
                {ticket.visible
                  ? ticket.sold == ticket.allocated
                    ? 'sold out'
                    : 'active'
                  : 'hidden'}
              </Typography>
            </div>
          </React.Fragment>
        }
      />
      <div className='w-1/2'>
        <div className='w-full bg-gray-300 h-2 rounded relative'>
          <span
            className='absolute bg-rose-400 h-2 rounded max-w-full'
            style={{ width: `${(ticket.sold / ticket.allocated) * 100}%` }}
          ></span>
        </div>
        <div className='flex justify-between text-md mt-1'>
          <span>{ticket.sold} sold</span>
          <span>{formatPrice(ticket.sold * ticket.price, event.currency)}</span>
        </div>
        <div className='flex justify-between text-xs mt-1'>
          <span>{ticket.allocated} allocated</span>
          <span>{formatPrice(ticket.price, event.currency)} each</span>
        </div>
      </div>
    </ListItem>
  );
};

export default AllocatedTicketType;
