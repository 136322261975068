import { useGetDonationCausesQuery, useGetArchivedDonationCausesQuery } from '@/Api/donationsSlice';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { VolunteerActivism } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import EmptyContent from '../Info/EmptyContent';
import DonationCausesTable from './DonationCausesTable';
import ArchivedDonationCausesTable from './ArchivedDonationCausesTable';

const pageSize = 50;

const ToggleArchiveButton = ({
  archiveType,
  setArchiveType,
}: {
  archiveType: string;
  setArchiveType: (archiveType: string) => void;
}) => (
  <Button
    onClick={() => setArchiveType(archiveType === 'default' ? 'archived' : 'default')}
    variant='contained'
  >
    {archiveType === 'default' ? 'Archived' : 'Default'}
  </Button>
);

const DonationCauses = () => {
  const [archiveType, setArchiveType] = useState('default');
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Donation Causes'));
    dispatch(
      setHeader({
        title: 'Donation Causes',
      }),
    );
  }, []);

  const {
    data: donationCauses,
    isFetching,
    isLoading,
  } = useGetDonationCausesQuery(
    {
      pageData: { page, pageSize },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: archivedDonationCauses } = useGetArchivedDonationCausesQuery(
    {
      pageData: { page, pageSize },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const tableComponent = useMemo(() => {
    const isActive = archiveType === 'default';
    const causes = isActive ? donationCauses : archivedDonationCauses;
    const noDataText = isActive ? 'No donation causes found' : 'No archived donation causes found';

    return causes?.data?.length ? (
      isActive ? (
        <DonationCausesTable
          donationCauses={causes}
          columns={['logo', 'name', 'description', 'url', 'actions']}
          pageSize={pageSize}
          onPageChange={setPage}
        />
      ) : (
        <ArchivedDonationCausesTable
          archivedDonationCauses={causes}
          columns={['logo', 'name', 'description', 'url', 'actions']}
          pageSize={pageSize}
          onPageChange={setPage}
        />
      )
    ) : (
      <EmptyContent
        icon={<VolunteerActivism sx={{ color: '#cccccc', fontSize: '8rem' }} />}
        primaryText={noDataText}
        secondaryText='Create a new donation cause to get started'
        button={
          <PermissionsGate scope={SCOPES.ManageDonationCause}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {archiveType === 'default' ? (
                <Button onClick={() => setArchiveType('archived')} variant='contained'>
                  Archived
                </Button>
              ) : (
                <Button onClick={() => setArchiveType('default')} variant='contained'>
                  Default
                </Button>
              )}
              <Link to={`create`} style={{ marginLeft: '10px' }}>
                <Button variant='contained'>Create Donation Cause</Button>
              </Link>
            </div>
          </PermissionsGate>
        }
      />
    );
  }, [archiveType, donationCauses, archivedDonationCauses, setPage]);

  if (isLoading || isFetching) return null;

  return (
    <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
      <Stack spacing={2} className='h-full'>
        <Box className='lg:self-end'>
          <PermissionsGate scope={SCOPES.ManageDonationCause}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ToggleArchiveButton archiveType={archiveType} setArchiveType={setArchiveType} />
              <Link to='create' style={{ marginLeft: '10px' }}>
                <Button variant='contained'>Create Donation Cause</Button>
              </Link>
            </div>
          </PermissionsGate>
        </Box>
        <div className='grow'>{tableComponent}</div>
      </Stack>
    </div>
  );
};

export default DonationCauses;
