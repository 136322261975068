import { TAG_TYPE } from '@/Enums/tag-types';
import { EventService, Service, ServiceData } from '@/Models/services';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { internalApi } from './internalApi';
import { ServicePurchasesData } from '@/Models/servicePurchases';

export const servicesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<ServiceData, FilterData>({
      query: (filterData) => ({
        url: '/services',
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.SERVICES],
    }),
    getEventServices: builder.query<ServiceData, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/services`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.EVENT_SERVICES],
    }),
    getService: builder.query<Service, string>({
      query: (serviceId) => ({
        url: `/services/${serviceId}`,
        method: 'GET',
      }),
    }),
    getEventService: builder.query<EventService, { eventId: string; serviceId: string }>({
      query: ({ eventId, serviceId }) => ({
        url: `/events/${eventId}/services/${serviceId}`,
        method: 'GET',
      }),
    }),
    createService: builder.mutation({
      query: (body) => ({
        url: '/services',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.SERVICES],
    }),
    updateService: builder.mutation<any, { serviceId: string; body: any }>({
      query: ({ serviceId, body }) => ({
        url: `/services/${serviceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.SERVICES],
    }),
    updateEventService: builder.mutation<any, { eventId: string; serviceId: string; body: any }>({
      query: ({ eventId, serviceId, body }) => ({
        url: `/events/${eventId}/services/${serviceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_SERVICES],
    }),
    syncEventServices: builder.mutation<any, { eventId: string; body: { services_ids: string[] } }>(
      {
        query: ({ eventId, body }) => ({
          url: `/events/${eventId}/services`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: [TAG_TYPE.EVENT_SERVICES],
      },
    ),
    deleteService: builder.mutation<any, string>({
      query: (serviceId) => ({
        url: `/services/${serviceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.SERVICES],
    }),
    getServiceStats: builder.query<any, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/service-stats`,
        method: 'GET',
      }),
    }),
    getServicePurchases: builder.query<
      ServicePurchasesData,
      { eventId: string; uuid: string; filterData: FilterData }
    >({
      query: ({ eventId, uuid, filterData }) => ({
        url: `/events/${eventId}/service-purchases/${uuid}`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetEventServicesQuery,
  useGetServiceQuery,
  useGetEventServiceQuery,
  useCreateServiceMutation,
  useSyncEventServicesMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
  useUpdateEventServiceMutation,
  useGetServiceStatsQuery,
  useGetServicePurchasesQuery,
} = servicesApi;
