import { createApi } from '@reduxjs/toolkit/query/react';
import { createRequestHandler } from './Interceptors/helpers';
import loaderInterceptor from './Interceptors/loaderInterceptor';
import { RTKInterceptor } from './Interceptors/types';
import query from './thirdPartyApiHelpers';

const interceptors: RTKInterceptor[] = [loaderInterceptor];

const requestHandler = createRequestHandler(query, interceptors);

export const thirdPartyApi = createApi({
  reducerPath: 'thirdPartyApi',
  baseQuery: async (args, api, extraOptions) => {
    return await requestHandler(args, api, extraOptions);
  },
  endpoints: () => ({}),
});
