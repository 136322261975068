import { TAG_TYPE } from '@/Enums/tag-types';
import { getHideSuccessMessageHeader } from '@/Utils/http-client';
import { internalApi } from './internalApi';

export const featuredEventsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeaturedEvents: builder.query<any, any>({
      query: () => ({
        url: `/public/featuredEvents`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.FEATURED_EVENTS],
    }),
    postFeaturedEvents: builder.mutation<any, any>({
      query: (body) => ({
        url: `/featuredEvents`,
        method: 'POST',
        body,
        headers: getHideSuccessMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.FEATURED_EVENTS],
    }),
  }),
});

export const { useGetFeaturedEventsQuery, usePostFeaturedEventsMutation } = featuredEventsApi;
