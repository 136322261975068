const getPromiseDeduplicator = <T>() => {
  let pendingPromise: Promise<T> | null = null;

  return (promise: () => Promise<T>) => {
    if (pendingPromise) {
      return pendingPromise;
    } else {
      pendingPromise = promise();
    }

    return pendingPromise.finally(() => {
      pendingPromise = null;
    });
  };
};

export default getPromiseDeduplicator;
