import { TAG_TYPE } from '@/Enums/tag-types';
import { TrackingLink, TrackingLinkData } from '@/Models/insights';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { internalApi } from './internalApi';

export const insightsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrackingLinks: builder.query<TrackingLinkData, FilterData>({
      query: (filterData) => ({
        url: '/tracking-links',
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.TRACKING_LINKS],
    }),
    getTrackingLink: builder.query<TrackingLink, string>({
      query: (trackingLinkId) => ({
        url: `/tracking-links/${trackingLinkId}`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.TRACKING_LINKS],
    }),
    createTrackingLink: builder.mutation({
      query: (body) => ({
        url: '/tracking-links',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.TRACKING_LINKS],
    }),
    updateTrackingLink: builder.mutation<any, { trackingLinkId: string; body: any }>({
      query: ({ trackingLinkId, body }) => ({
        url: `/tracking-links/${trackingLinkId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.TRACKING_LINKS],
    }),
    deleteTrackingLink: builder.mutation<any, string>({
      query: (trackingLinkId) => ({
        url: `/tracking-links/${trackingLinkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.TRACKING_LINKS],
    }),
  }),
});

export const {
  useGetTrackingLinksQuery,
  useGetTrackingLinkQuery,
  useCreateTrackingLinkMutation,
  useDeleteTrackingLinkMutation,
  useUpdateTrackingLinkMutation,
} = insightsApi;
