import { TAG_TYPE } from '@/Enums/tag-types';
import { InvitationType } from '@/Models/tickets';
import { internalApi } from './internalApi';

export const ticketsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvitationTypes: builder.query<InvitationType[], string>({
      query: (eventId) => ({
        url: `/events/${eventId}/invitationTypes`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.INVITATIONS],
    }),
    createInvitationType: builder.mutation<InvitationType, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/invitationTypes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.INVITATIONS],
    }),
    updateInvitationType: builder.mutation<
      InvitationType,
      {
        eventId: string;
        invitationTypeId: string;
        body: any;
      }
    >({
      query: ({ eventId, invitationTypeId, body }) => ({
        url: `/events/${eventId}/invitationTypes/${invitationTypeId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.INVITATIONS],
    }),
    getAssignedUserInvitationType: builder.query<
      any,
      {
        eventId: string;
        invitationTypeId: string;
      }
    >({
      query: ({ eventId, invitationTypeId }) => ({
        url: `/events/${eventId}/invitationTypes/${invitationTypeId}/assignUsers`,
        method: 'GET',
      }),
      providesTags: (_, __, { invitationTypeId }) => [
        {
          type: TAG_TYPE.INVITATIONS,
          id: invitationTypeId,
        },
      ],
    }),
    assignUserInvitationType: builder.mutation<
      InvitationType,
      {
        eventId: string;
        invitationTypeId: string;
        body: any;
      }
    >({
      query: ({ eventId, invitationTypeId, body }) => ({
        url: `/events/${eventId}/invitationTypes/${invitationTypeId}/assignUsers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { invitationTypeId }) => [
        {
          type: TAG_TYPE.INVITATIONS,
          id: invitationTypeId,
        },
      ],
    }),
    deleteInvitationType: builder.mutation<any, { eventId: string; invitationTypeId: string }>({
      query: ({ eventId, invitationTypeId }) => ({
        url: `/events/${eventId}/invitationTypes/${invitationTypeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.INVITATIONS],
    }),
  }),
});

export const {
  useGetInvitationTypesQuery,
  useCreateInvitationTypeMutation,
  useDeleteInvitationTypeMutation,
  useUpdateInvitationTypeMutation,
  useAssignUserInvitationTypeMutation,
  useGetAssignedUserInvitationTypeQuery,
} = ticketsApi;
