import { Ticket } from '@/Models/tickets';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useMemo } from 'react';
import { useResponsive } from '@/Hooks/useResponsive';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y' as const,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
};

export interface TicketTypesProps {
  tickets: Ticket[];
}

const TicketTypes = ({ tickets }: TicketTypesProps) => {
  const { isMobile } = useResponsive();

  const data = useMemo(() => {
    let labels: string[] = [];
    let ticketTypes: number[] = [];

    tickets.forEach((row: { title: string; sold: number }) => {
      labels.push(row.title);
      ticketTypes.push(row.sold);
    });

    return {
      labels: labels,
      datasets: [
        {
          label: '# tickets sold',
          data: ticketTypes,
          backgroundColor: ['rgba(54, 162, 235, 0.2)'],
          borderColor: ['rgba(54, 162, 235, 1)'],
          borderWidth: 1,
        },
      ],
    };
  }, [tickets]);

  return (
    <Bar options={{ responsive: isMobile ? false : true }} data={data} style={{ width: '100%' }} />
  );
};

export default TicketTypes;
