import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SectionIdentifierEditorContainer from '@/Components/Custom/VenueEditor/SectionIdentifierEditorContainer';
import SeatSectionMapperEditorContainer from '@/Components/Custom/VenueEditor/SeatSectionMapperEditorContainer';
import SeatIdentifierEditorContainer from '@/Components/Custom/VenueEditor/SeatIdentifierEditorContainer';
import { setCurrentTool } from '@/Slices/venueEditorSlice';
import {
  useLazyGetEventVenueQuery,
  useLazyGetSeatDataQuery,
  useLazyGetSectionDataQuery,
  useStoreEventVenueDataMutation,
} from '@/Api/seatingSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { adaptSeatData, adaptSectionData, extractSeatLabellingData } from '@/Utils/seating';
import { Link } from 'react-router-dom';
import useLoading from '@/Hooks/useLoading';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import useEffectOnce from '@/Hooks/useEffectOnce';

const steps = ['Identify Sections', 'Map Seats to Sections', 'Identify Seats'];

function VenueEditor() {
  const dispatch = useDispatch();
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const { eventId, venueId } = useParams<{ eventId: string; venueId: string }>();
  const navigate = useNavigate();
  const [loading, withLoading] = useLoading();
  const { sectionIdentifierService, seatIdentifierService } = useContext(VenueEditorContext);

  const [getEventVenueDataRequest] = useLazyGetEventVenueQuery();
  const [getVenueSectionDataRequest] = useLazyGetSectionDataQuery();
  const [getVenueSeatDataRequest] = useLazyGetSeatDataQuery();
  const [storeEventData] = useStoreEventVenueDataMutation();

  useEffect(() => {
    dispatch(setTitle('Venue Editor'));
    dispatch(
      setHeader({
        title: 'Venue Editor',
      }),
    );
  }, []);

  useEffectOnce(() => {
    Promise.all([
      getEventVenueDataRequest(eventId!),
      getVenueSectionDataRequest(venueId!),
      getVenueSeatDataRequest({ venueId: venueId! }),
    ]).then(([{ data: eventVenueData }, { data: venueSectionData }, { data: venueSeatData }]) => {
      if (!eventVenueData || !venueSectionData || !venueSeatData) return;

      sectionIdentifierService.loadInitialSectionData(adaptSectionData(venueSectionData));
      seatIdentifierService.loadInitialSeatData(adaptSeatData(venueSeatData));

      fetch(eventVenueData.svg)
        .then((res) => res.text())
        .then((text) => {
          setSvgContent(text);
        });
    });
  });

  function goBack() {
    dispatch(setCurrentTool('navigate'));
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  }

  function goForward() {
    dispatch(setCurrentTool('navigate'));
    setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1));

    if (activeStep === steps.length - 1) {
      saveSeatIdentification();
    }
  }

  function saveSeatIdentification() {
    withLoading(
      storeEventData({
        eventId: eventId!,
        body: {
          sectionData: sectionIdentifierService.getSectionData(),
          seatData: extractSeatLabellingData(seatIdentifierService.getSeatData()),
        },
      }).then(() => {
        navigate(`/events/${eventId}/venues`);
      }),
    );
  }

  return (
    <Stack className='bg-white h-full relative'>
      {!!svgContent && (
        <>
          <Stack className='h-16 border-solid border-b-2' justifyContent='center'>
            <Stepper activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>

          {activeStep === 0 && <SectionIdentifierEditorContainer svgContent={svgContent} />}

          {activeStep === 1 && <SeatSectionMapperEditorContainer svgContent={svgContent} />}

          {activeStep === 2 && <SeatIdentifierEditorContainer svgContent={svgContent} />}

          <Stack
            className='h-16 pr-4 w-full absolute bottom-0 left-0 shadow-bottom-nav'
            justifyContent='center'
            alignItems='flex-end'
          >
            <Stack direction='row' columnGap='10px'>
              <Link to={`/events/${eventId}/venues`}>
                <Button>Cancel</Button>
              </Link>

              <Button onClick={goBack} disabled={!activeStep || loading}>
                Back
              </Button>

              <LoadingButton loading={loading}>
                <Button variant='contained' disabled={loading} color='success' onClick={goForward}>
                  {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                </Button>
              </LoadingButton>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default VenueEditor;
