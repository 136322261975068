import { FesttixEnvironment } from '@/types/general';

const environment: FesttixEnvironment = {
  baseUrl: `https://dashboard.festtix.co.uk/api`,
  ticketingUrl: 'https://ticketing.festtix.co.uk',
  googleMapsApiKey: 'AIzaSyDSFNH-7wmgsOFaRccgFJED3tspcpTjvCQ',
  oidcConfig: {
    client_id: 'ticketing-dashboard',
    authority: 'https://sso.festtix.co.uk/realms/festtix',
    redirect_uri: 'https://dashboard.festtix.co.uk/oidc-callback',
    response_type: 'code',
    scope: 'openid profile email',
    post_logout_redirect_uri: 'https://dashboard.festtix.co.uk',
    automaticSilentRenew: false,
  },
};

export default environment;
