import { useGetVoucherGroupsQuery, useUpdateRevokeStatusMutation } from '@/Api/promocodesSlice';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import environment from '@/environments/environment';
import { Event } from '@/Models/events';
import { VoucherGroup } from '@/Models/promocodes';
import { formatDate } from '@/Utils/dates';
import {
  Block,
  EditOutlined,
  FileDownload,
  MoreVert,
  PublishedWithChanges,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { isBefore } from 'date-fns';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Vouchers from './Vouchers';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

interface VoucherGroupsProps {
  event: Event;
}

const VoucherGroups = ({ event }: VoucherGroupsProps) => {
  const [selectedVoucherGroup, setSelectedVoucherGroup] = useState<VoucherGroup | undefined>(
    undefined,
  );
  const [page, setPage] = useState(0);
  const [updateRevokeStatus] = useUpdateRevokeStatusMutation();

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    voucherGroup: VoucherGroup;
  }>(null);

  const advancedFetch = useAdvancedFetch();

  function hideActionMenu() {
    setMenuContext(null);
  }

  const pageSize = 10;

  const { data: voucherGroupsData } = useGetVoucherGroupsQuery(
    {
      eventId: event.id,
      filterData: { pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  function hasExpired(expires_at: string) {
    return isBefore(new Date(expires_at), new Date());
  }

  function showActionMenu(voucherGroup: VoucherGroup, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, voucherGroup });
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
    {
      field: 'discount_percentage',
      headerName: 'Discount',
      sortable: false,
      valueFormatter: ({ value }) => value + ' %',
      flex: 1,
    },
    {
      field: 'allocated',
      headerName: 'Allocated',
      renderCell: ({ row: { allocated } }) => <div>{!!allocated ? allocated : 'Unlimited'}</div>,
      sortable: false,
      flex: 1,
    },
    { field: 'vouchers_count', headerName: 'Count', sortable: false, flex: 1 },
    {
      field: 'expires_at',
      headerName: 'Expires At',
      renderCell: ({ row: { expires_at } }) => (
        <EmptyCell condition={!!expires_at}>{formatDate(expires_at)}</EmptyCell>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row: { expires_at, revoked } }) => {
        const expired = expires_at && hasExpired(expires_at);
        return (
          <BeautifulChip color={revoked ? 'warning' : expired ? 'error' : 'success'}>
            {revoked ? 'Revoked' : expired ? 'Expired' : 'Active'}
          </BeautifulChip>
        );
      },
      sortable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            showActionMenu(row, event);
          }}
        >
          <MoreVert />
        </IconButton>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  function onPageChange(page: number) {
    setPage(page);
  }

  function onVoucherGroupClick({ row: voucherGroup }: GridRowParams<VoucherGroup>) {
    setSelectedVoucherGroup(voucherGroup);
  }

  function onRevokeClick(vg: VoucherGroup) {
    updateRevokeStatus({ eventId: event.id, voucherGroupId: vg.id, revoke: !vg.revoked })
      .unwrap()
      .then()
      .catch();
  }

  async function onExportClick(vg: VoucherGroup) {
    advancedFetch(`${environment.baseUrl}/events/${event.id}/voucher-groups/${vg.id}/export`, {
      method: 'GET',
    }).then((res) => {
      handleBlobReponse(res, {
        handleMethod: 'download',
        fileName: `vouchers-${event.title}-${vg.name}.csv`,
      });
    });
  }

  function onDialogClose() {
    setSelectedVoucherGroup(undefined);
  }

  return (
    <>
      <div className='h-[50vh]'>
        {voucherGroupsData && (
          <DataGrid
            rows={voucherGroupsData.data}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            rowCount={voucherGroupsData.total}
            onRowClick={onVoucherGroupClick}
            paginationMode='server'
            onPageChange={onPageChange}
            hideFooterSelectedRowCount={true}
            isRowSelectable={() => false}
          />
        )}
      </div>

      {!!selectedVoucherGroup && (
        <Vouchers
          open={!!selectedVoucherGroup}
          onDialogClose={onDialogClose}
          voucherGroup={selectedVoucherGroup}
        />
      )}

      {menuContext && (
        <Menu
          anchorEl={menuContext?.anchor}
          open={!!menuContext}
          onClose={hideActionMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <PermissionsGate scope={SCOPES.ManageVoucher}>
            <Link to={`vouchers/${menuContext.voucherGroup.id}/edit`}>
              <MenuItem disabled={menuContext.voucherGroup.revoked}>
                <ListItemIcon>
                  <EditOutlined />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            </Link>
          </PermissionsGate>

          <MenuItem
            onClick={() => {
              onExportClick(menuContext.voucherGroup);
              hideActionMenu();
            }}
          >
            <ListItemIcon>
              <FileDownload />
            </ListItemIcon>
            <ListItemText>Export Vouchers</ListItemText>
          </MenuItem>

          <PermissionsGate scope={SCOPES.ManageVoucher}>
            <MenuItem
              onClick={() => {
                onRevokeClick(menuContext.voucherGroup);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                {menuContext.voucherGroup.revoked ? (
                  <PublishedWithChanges color='success' />
                ) : (
                  <Block color='error' />
                )}
              </ListItemIcon>
              <ListItemText>
                {menuContext.voucherGroup.revoked ? 'Activate' : 'Revoke'}
              </ListItemText>
            </MenuItem>
          </PermissionsGate>
        </Menu>
      )}
    </>
  );
};

export default VoucherGroups;
