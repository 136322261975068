import useEffectOnce from '@/Hooks/useEffectOnce';
import { useContext, useEffect, useState } from 'react';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';

interface SvgEditorProps {
  initialize: () => void;
}

function SvgEditor({ initialize }: SvgEditorProps) {
  const { venueEditorService, editorEventService } = useContext(VenueEditorContext);
  const [isEditorLoading, setIsEditorLoading] = useState<boolean>(false);

  useEffectOnce(() => {
    initialize();
  });

  useEffect(() => {
    const sub = venueEditorService.getIsLoading$().subscribe((isLoading) => {
      setIsEditorLoading(isLoading);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <div className='relative h-full overflow-hidden'>
      <canvas
        id='venue-svg-container-canvas'
        className={`absolute top-0 left-0 w-full h-full touch-none ${
          isEditorLoading ? 'pointer-events-none' : ''
        }`}
        // @ts-ignore
        resize='true'
      ></canvas>

      <ButtonGroup orientation='vertical' className='absolute bottom-4 left-4'>
        <Button
          className='!p-1'
          variant='contained'
          onClick={() => editorEventService.zoomRequest$.next('in')}
        >
          <AddOutlined />
        </Button>
        <Button
          className='!p-1'
          variant='contained'
          onClick={() => editorEventService.zoomRequest$.next('out')}
        >
          <RemoveOutlined />
        </Button>
      </ButtonGroup>

      {isEditorLoading ? (
        <>
          <div className='absolute top-0 left-0 w-full h-full bg-white opacity-40'></div>

          <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
            <CircularProgress />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SvgEditor;
