export async function handleBlobReponse(
  responseData: Response,
  options: {
    handleMethod: 'download' | 'open' | 'openInNewTab';
    fileName?: string;
  } = {
    handleMethod: 'openInNewTab',
  },
) {
  const blob = await responseData.blob();

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;

  if (options.handleMethod === 'download') {
    link.download = options.fileName || 'download';
  } else if (options.handleMethod === 'open') {
    link.target = '_self';
  } else {
    link.target = '_blank';
  }

  link.click();
}
