import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingCount: 0,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: (state) => ({
      loadingCount: state.loadingCount + 1,
    }),
    hideLoader: (state) => ({
      loadingCount: Math.max(state.loadingCount - 1, 0),
    }),
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export const loaderReducer = loaderSlice.reducer;
