import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Attendee, AttendeeData } from '@/Models/attendees';
import { getHideErrorMessageHeader } from '@/Utils/http-client';

export const attendeesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAttendees: builder.query<AttendeeData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `/events/${eventId}/attendees`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.ATTENDEES],
    }),
    updateAttendee: builder.mutation<
      Attendee,
      {
        eventId: string;
        attendeeId: string;
        body: any;
      }
    >({
      query: ({ eventId, attendeeId, body }) => ({
        url: `/events/${eventId}/attendees/${attendeeId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ATTENDEES],
    }),
    getAttendeesStats: builder.query<any, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/attendees/stats`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.ATTENDEES],
    }),
    checkInAttendee: builder.mutation<
      Attendee,
      {
        eventId: string;
        attendeeId: string;
      }
    >({
      query: ({ eventId, attendeeId }) => ({
        url: `/events/${eventId}/attendees/${attendeeId}/check-in`,
        method: 'PUT',
        headers: getHideErrorMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.ATTENDEES],
    }),
    resetCheckInAttendee: builder.mutation<
      Attendee,
      {
        eventId: string;
        attendeeId: string;
      }
    >({
      query: ({ eventId, attendeeId }) => ({
        url: `/events/${eventId}/attendees/${attendeeId}/check-in`,
        method: 'DELETE',
        headers: getHideErrorMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.ATTENDEES],
    }),
    getOrderAttendes: builder.query<any, { eventId: string; referenceNumber: string }>({
      query: ({ eventId, referenceNumber }) => ({
        url: `/events/${eventId}/order/${referenceNumber}/attendees`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.ATTENDEES],
    }),
  }),
});

export const {
  useGetAttendeesQuery,
  useUpdateAttendeeMutation,
  useGetAttendeesStatsQuery,
  useCheckInAttendeeMutation,
  useResetCheckInAttendeeMutation,
  useGetOrderAttendesQuery,
} = attendeesApi;
