import { internalApi } from './internalApi';

export const usersApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPermissions: builder.query({
      query: () => ({
        url: `/permissions`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserPermissionsQuery } = usersApi;
