import { Typography } from '@mui/material';

interface TemplateDownloadProps {
  link: string;
}

const TemplateDownload = ({ link }: TemplateDownloadProps) => {
  return (
    <Typography variant='caption' sx={{ mt: 2 }}>
      Here you can{' '}
      <a
        href={link}
        style={{
          textDecoration: 'underline',
          color: '#0288d1',
          cursor: 'pointer',
        }}
      >
        download
      </a>{' '}
      the template
    </Typography>
  );
};

export default TemplateDownload;
