import { useDeleteTrackingLinkMutation } from '@/Api/insightsSlice';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { TrackingLink, TrackingLinkData } from '@/Models/insights';
import { Event } from '@/Models/events';
import { DeleteOutline, EditOutlined, FileCopyOutlined, MoreVert } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '@/Slices/snackbarSlice';

interface TrackingLinksProps {
  trackingLinks: TrackingLinkData;
  columns: string[];
  pageSize: number;
  onPageChange: (page: number) => void;
  event?: Event;
}

const TrackingLinks = ({
  trackingLinks,
  columns,
  pageSize,
  onPageChange,
  event,
}: TrackingLinksProps) => {
  const [selectedTrackingLink, setSelectedTrackingLink] = useState<TrackingLink>();
  const [shownColumns, setShownColumns] = useState<GridColDef<TrackingLink>[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    trackingLink: TrackingLink;
  }>(null);
  const dispatch = useDispatch();

  const [deleteTrackingLink] = useDeleteTrackingLinkMutation();

  const showActionMenu = useCallback(
    (trackingLink: TrackingLink, event: React.MouseEvent<HTMLElement>) => {
      setMenuContext({ anchor: event.currentTarget, trackingLink });
    },
    [setMenuContext],
  );

  const allColumns: GridColDef<TrackingLink>[] = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', sortable: false, width: 230 },
      {
        field: 'event',
        headerName: 'Event',
        sortable: false,
        renderCell: ({ row }) => row.event.title,
        width: 230,
      },
      { field: 'utm_source', headerName: 'UTM Source', sortable: false, width: 130 },
      { field: 'utm_medium', headerName: 'UTM Medium', sortable: false, width: 130 },
      { field: 'utm_campaign', headerName: 'UTM Campaign', sortable: false, width: 130 },
      {
        field: 'url',
        headerName: 'URL',
        renderCell: ({ row }) => (
          <Typography variant='body2' className='text-gray-500'>
            <IconButton
              onClick={(e) => {
                e.preventDefault(); // Prevent the click event from reaching the TextField
                navigator.clipboard.writeText(row.url).then(
                  () => {
                    dispatch(
                      showSnackbar({
                        message: 'The tracking link has been copied to your clipboard',
                        severity: 'success',
                      }),
                    );
                  },
                  (error) => {
                    console.log(error);
                    dispatch(
                      showSnackbar({
                        message: 'Failed to copy to clipboard',
                        severity: 'error',
                      }),
                    );
                  },
                );
              }}
              size='small'
            >
              <FileCopyOutlined fontSize='small' />
            </IconButton>
            {row.url}
          </Typography>
        ),
        sortable: false,
        width: 250,
      },
      {
        field: 'visits',
        headerName: 'Visits',
        renderCell: ({ row }) => <BeautifulChip color='info'>{row.visits.length}</BeautifulChip>,
        width: 130,
      },
      {
        field: 'purchases',
        headerName: 'Purchases',
        renderCell: ({ row }) => (
          <BeautifulChip color='success'>
            {row.visits.filter((visit) => visit.purchased).length}
          </BeautifulChip>
        ),
        width: 130,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => (
          <IconButton onClick={(trackingLink) => showActionMenu(row, trackingLink)}>
            <MoreVert />
          </IconButton>
        ),
        sortable: false,
      },
    ];
  }, [showActionMenu]);

  useEffect(() => {
    setShownColumns(allColumns.filter((column) => columns.includes(column.field)));
  }, [columns, allColumns]);

  function dispatchPageChange(page: number) {
    onPageChange(page);
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  function onDeleteDialogClose(value: boolean) {
    setDeleteDialogOpen(false);

    if (value && selectedTrackingLink) {
      deleteTrackingLink(selectedTrackingLink.id);
    }

    setSelectedTrackingLink(undefined);
  }

  return (
    <>
      <DataGrid
        rows={trackingLinks.data}
        columns={shownColumns}
        rowHeight={60}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        disableSelectionOnClick
        rowCount={trackingLinks.total}
        paginationMode='server'
        onPageChange={dispatchPageChange}
        hideFooterSelectedRowCount={true}
        isRowSelectable={() => false}
      />

      {menuContext && (
        <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
          <Link to={`${menuContext.trackingLink.id}/edit`}>
            <MenuItem
              onClick={() => {
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Link>

          <MenuItem
            onClick={() => {
              setSelectedTrackingLink(menuContext.trackingLink);
              setDeleteDialogOpen(true);
              hideActionMenu();
            }}
          >
            <ListItemIcon>
              <DeleteOutline color='error' />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog open={deleteDialogOpen} onDialogClose={onDeleteDialogClose} />
    </>
  );
};

export default TrackingLinks;
