import useEffectOnce from '@/Hooks/useEffectOnce';
import authService from '@/Services/AuthService';
import { useState } from 'react';
import RouteGuard from './RouteGuard';

interface AuthGuardProps {
  redirectPath: string;
  auth: boolean;
}

const AuthGuard = ({ auth, redirectPath }: AuthGuardProps) => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffectOnce(() => {
    authService.isAuthenticated().then((isAuthenticated) => {
      setIsAuth(isAuthenticated);

      if (!isAuthenticated && auth) {
        authService.login();
      }
    });
  });

  if (!isAuth) {
    return <></>;
  }

  return <RouteGuard isAllowed={auth === isAuth} redirectPath={redirectPath} />;
};

export default AuthGuard;
