import {
  Box,
  Button,
  MenuItem,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { PartnerForm, partnerFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import { PartnerRank } from '@/Enums/partner-rank';
import { getSelectOptionsFromEnum } from '@/Utils/enums';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { FileContext, ResourceType } from '@/Enums/files';
import ReactQuill from 'react-quill';
import {
  useGetPartnerQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
} from '@/Api/partnersSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { PreviewData } from '@/Models/preview';
import useLoading from '@/Hooks/useLoading';
import { cleanObject } from '@/Utils/general';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { number } from 'zod';

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
const partnerRankOptions = getSelectOptionsFromEnum(PartnerRank);

const fileKeys = ['image'] as const;
type FileKey = typeof fileKeys[number];

const CreateOrEditPartner = () => {
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();
  const [fileQueue, setFileQueue] = useState<{ key: FileKey; file: File }[]>([]);

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [createPartner] = useCreatePartnerMutation();
  const [updatePartner] = useUpdatePartnerMutation();

  const { data: partner } = useGetPartnerQuery(partnerId!, {
    refetchOnMountOrArgChange: false,
    skip: !partnerId,
  });

  const isCreatingPartner = !partnerId;

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<PartnerForm>({
    defaultValues: {
      name: '',
      description: '',
      image: '',
      rank: partnerRankOptions[0].value,
    },
    resolver: zodResolver(partnerFormSchema),
  });

  useEffect(() => {
    if (!partner) {
      return;
    }

    reset({
      ...cleanObject(partner),
    });
  }, [partner]);

  const imagePreview: { [key in FileKey]?: PreviewData } = useMemo(() => {
    return {
      image: partner && { type: 'image', url: partner.image },
    };
  }, [partner]);

  function getTitle() {
    return `${!!partnerId ? 'Edit' : 'Create'} Partner `;
  }

  function addFileInQueue(key: FileKey, file: File) {
    clearErrors(key);
    setValue(key, file);
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }
  async function onSubmit() {
    let partnerData = { ...getValues() };

    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles());
      partnerData = { ...partnerData, ...processedFiles };
    }

    if (!validateImages(partnerData)) {
      return;
    }

    let promise: Promise<any>;
    if (isCreatingPartner) {
      promise = createPartner(partnerData).unwrap();
    } else {
      promise = updatePartner({ partnerId, body: partnerData }).unwrap();
    }

    withLoading(promise)
      .then((partner) => {})
      .then(() => {
        navigate('/partners');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof PartnerForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  async function processFiles(): Promise<Pick<PartnerForm, FileKey>> {
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.PARTNERS,
      })),
    );

    const partnerData: Pick<PartnerForm, FileKey> = { image: '' };
    signedUrlPayloads.forEach((payload, i) => {
      partnerData[fileData[i].key] = payload.fields.key;
      setValue(fileData[i].key, payload.fields.key);
    });
    setFileQueue([]);

    return partnerData;
  }

  function validateImages(data: PartnerForm): boolean {
    const notUploadedImages = fileKeys.filter((key) => !data[key]);

    notUploadedImages.forEach((key) => {
      setError(key, {
        type: 'manual',
        message: 'Please upload an image first',
      });
    });

    return !notUploadedImages.length;
  }

  const { isMobile } = useResponsive();

  const theme = createTheme({
    palette: {
      error: {
        main: '#FFA500',
      },
    },
  });

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the cause
                </Typography>
              </div>
              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Name'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name='description'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <ReactQuill {...field} theme='snow' />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name='rank'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          select
                          label='Rank'
                          error={!!error}
                          helperText={error?.message}
                          fullWidth
                        >
                          {partnerRankOptions.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Controller
                        name='image'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <FileUpload
                            label='Image'
                            allowedFileTypes={allowedFileTypes}
                            maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                            onSelect={(file) => addFileInQueue('image', file)}
                            preview
                            previewData={imagePreview.image}
                            onError={(message) =>
                              setError('image', {
                                message,
                                type: CustomZodErrorType.INPUT_FILE,
                              })
                            }
                          />
                        )}
                      />
                      {!!errors.image && errors.image.type !== CustomZodErrorType.INPUT_FILE && (
                        <FormControl error>
                          <FormHelperText className='!ml-0'>
                            Please upload an image first
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEditPartner;
