import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Partner, PartnerData } from '@/Models/partners';

export const partnersApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query<PartnerData, FilterData<'upcoming'>>({
      query: (filterData) => ({
        url: `/partners`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getPartner: builder.query<Partner, string>({
      query: (partnerId) => ({
        url: `/partners/${partnerId}`,
        method: 'GET',
      }),
    }),
    createPartner: builder.mutation<Partner, any>({
      query: (body) => ({
        url: `/partners`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.PARTNERS],
    }),
    updatePartner: builder.mutation<Partner, { partnerId: String; body: any }>({
      query: ({ partnerId, body }) => ({
        url: `/partners/${partnerId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.PARTNERS],
    }),
    deletePartner: builder.mutation<any, string>({
      query: (partnerId) => ({
        url: `/partners/${partnerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.PARTNERS],
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnerQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
} = partnersApi;
