import { Event } from '@/Models/events';
import Grid from '@mui/material/Grid';
import { useGetEventStatsQuery } from '@/Api/eventsSlice';
import { Functions, BookOnline, Equalizer, AttachMoney, Payment } from '@mui/icons-material';
import StatsCard from '@/Components/Molecules/Cards/StatsCard';

export interface EventOverviewStatsProps {
  event: Event;
}

const EventOverviewStats = ({ event }: EventOverviewStatsProps) => {
  const { data: eventReports } = useGetEventStatsQuery(event.id, {
    refetchOnMountOrArgChange: true,
  });

  const icons = [Functions, BookOnline, Payment, Equalizer];

  return (
    <Grid container spacing={2}>
      {!eventReports
        ? ''
        : Object.entries(eventReports).map(([key, value]: [string, any], index) => {
            const Icon = icons[index];

            return (
              <Grid item xs={12} md={3} key={key}>
                <StatsCard title={key} value={value} Icon={Icon} />
              </Grid>
            );
          })}
    </Grid>
  );
};

export default EventOverviewStats;
