export enum PaymentGateway {
  PAYPAL = 'PayPal_Rest',
  TEB = 'Teb',
  TEB_DR = 'Teb_DigitalRepublic',
  PROCREDIT_TEST = 'Procredit_Test',
  PROCREDIT_SHF = 'Procredit_SunnyHill',
  PROCREDIT_DR = 'Procredit_DigitalRepublic',
  DUMMY_GATEWAY = 'Dummy_Gateway',
  STRIPE = 'Stripe',
}
