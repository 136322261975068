import { Ticket } from '@/Models/tickets';
import List from '@mui/material/List';
import AllocatedTicketType from '../../Atoms/AllocatedTicketType/AllocatedTicketType';
import { Event } from '@/Models/events';

export interface TicketTypesAllocatedProps {
  tickets: Ticket[];
  event: Event;
}

const TicketTypesAllocated = ({ tickets, event }: TicketTypesAllocatedProps) => {
  return (
    <List sx={{ padding: 0, marginTop: 4 }}>
      {tickets.map((ticket) => (
        <AllocatedTicketType key={ticket.id} ticket={ticket} event={event} />
      ))}
    </List>
  );
};

export default TicketTypesAllocated;
