import { z } from 'zod';

export const trackingLinkFormSchema = z.object({
  name: z.string().min(1),
  event_id: z.string().min(1),
  utm_source: z
    .string()
    .min(1)
    .regex(/^[a-z0-9]+(?:_[a-z0-9]+)*$/),
  utm_medium: z
    .string()
    .min(1)
    .regex(/^[a-z0-9]+(?:_[a-z0-9]+)*$/),
  utm_campaign: z
    .string()
    .min(1)
    .regex(/^[a-z0-9]+(?:_[a-z0-9]+)*$/),
  url: z.string().min(1),
});

export type TrackingLinkForm = z.infer<typeof trackingLinkFormSchema>;
