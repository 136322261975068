import { TAG_TYPE } from '@/Enums/tag-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import errorSnackbarInterceptor from './Interceptors/errorSnackbarInterceptor';
import { createRequestHandler } from './Interceptors/helpers';
import loaderInterceptor from './Interceptors/loaderInterceptor';
import sessionExpiredInterceptor from './Interceptors/sessionExpiredInterceptor';
import successSnackbarInterceptor from './Interceptors/successSnackbarInterceptor';
import { RTKInterceptor } from './Interceptors/types';
import query from './internalApiHelpers';

// sessionExpiredInterceptor must be the last one, because it retries the requests. If you don't want to leave this as the last
// interceptor, make sure you handle the retries differently
const interceptors: RTKInterceptor[] = [
  loaderInterceptor,
  errorSnackbarInterceptor,
  successSnackbarInterceptor,
  sessionExpiredInterceptor,
];

const requestHandler = createRequestHandler(query, interceptors);

export const internalApi = createApi({
  reducerPath: 'internalApi',
  baseQuery: async (args, api, extraOptions) => {
    return await requestHandler(args, api, extraOptions);
  },
  endpoints: () => ({}),
  tagTypes: [...Object.values(TAG_TYPE)],
});
