import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { UserRole } from '@/Enums/roles';
import { EditOutlined, MoreVert, EventAvailable } from '@mui/icons-material';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Edit from './Edit';
import { useGetAllOrganizationsQuery } from '@/Api/organizationsSlice';
import { Organization } from '@/Models/organizations';
import { useNavigate } from 'react-router-dom';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';

const pageSize = 20;

const Organizations = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    organization: Organization;
  }>(null);

  const { data: organizations, refetch } = useGetAllOrganizationsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    dispatch(setTitle('Organizations'));
    dispatch(setHeader({ title: 'Organizations' }));
  }, []);

  function onPageChange(page: number) {
    setPage(page);
  }

  function showActionMenu(organization: Organization, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, organization });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const onClickOpenPage = (
    $mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
    organization: Organization,
  ) => {
    $mouseEvent.preventDefault();
    navigate(`/organizations/${organization.id}/events`);
  };

  function onEditDialogClose(value: boolean) {
    setEditDialogOpen(false);

    if (value) {
      refetch();
    }

    if (!value) {
      return;
    }
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
    { field: 'description', headerName: 'Description', sortable: false, flex: 1 },
    {
      field: 'eventsCount',
      headerName: 'Events Count',
      renderCell: ({ row }) => {
        return <BeautifulChip color='success'>{row.events_count}</BeautifulChip>;
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'notApprovedCount',
      headerName: 'Not Aproved',
      renderCell: ({ row }) => {
        return <BeautifulChip color='error'>{row.notApprovedEvents}</BeautifulChip>;
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageOrganizer}>
          {row.role !== UserRole.ADMIN && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                showActionMenu(row, event);
              }}
            >
              <MoreVert />
            </IconButton>
          )}
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  return (
    <div className='py-8 h-full'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
        <div className='shadow-xl sm:rounded-lg flex-1'>
          {organizations && (
            <DataGrid
              rows={organizations.data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[pageSize]}
              rowCount={organizations.total}
              paginationMode='server'
              onPageChange={onPageChange}
            />
          )}

          {editDialogOpen && selectedOrganization ? (
            <Edit
              organization={selectedOrganization}
              open={editDialogOpen}
              onDialogClose={(value) => onEditDialogClose(value)}
            />
          ) : (
            ''
          )}

          {menuContext && (
            <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
              <MenuItem
                onClick={() => {
                  setEditDialogOpen(true);
                  setSelectedOrganization(menuContext.organization);
                  hideActionMenu();
                }}
              >
                <ListItemIcon>
                  <EditOutlined />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem
                component='div'
                onClick={($mouseEvent) => onClickOpenPage($mouseEvent, menuContext.organization)}
              >
                <ListItemIcon>
                  <EventAvailable />
                </ListItemIcon>
                <ListItemText>Events</ListItemText>
              </MenuItem>
            </Menu>
          )}
        </div>
      </div>
    </div>
  );
};

export default Organizations;
