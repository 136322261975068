import { format, formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export enum DateFormats {
  LONG_DATE = 'dd MMM, yyyy',
  LONG_DATETIME = 'dd MMM, yyyy HH:mm:ss zzz',
  MEDIUM_DATETIME = 'dd/MM/yy HH:mm:ss',
  SHORT_TIME = 'HH:mm:ss',
}

export function formatDate(
  date: Date | string,
  dateFormat: DateFormats = DateFormats.LONG_DATETIME,
  tz?: string,
) {
  if (tz) {
    const utcDate = utcToZonedTime(getDate(date), tz);
    return format(utcDate, dateFormat);
  }
  return format(getDate(date), dateFormat);
}

function getDate(date: Date | string) {
  if (date instanceof Date) {
    return date;
  }
  return new Date(date);
}

export function relativeDate(date: Date | string) {
  return formatDistance(new Date(date), Date.now(), { addSuffix: true });
}

export function formatTimeZoneShort(timezone: string | undefined) {
  const date = new Date();
  const formatter = new Intl.DateTimeFormat('en', {
    timeZone: timezone,
    timeZoneName: 'short',
  });

  const formatted = formatter.format(date);
  const offsetMatch = formatted.match(/([A-Z]+[\+-][0-9]+)/);

  if (offsetMatch && offsetMatch[1]) {
    return offsetMatch[1];
  } else {
    return timezone;
  }
}

export function getCountdownFromSeconds(seconds: number): string {
  if (!seconds || seconds < 0) {
    return '00:00';
  }

  return format(new Date(seconds * 1000), 'mm:ss');
}

export function getMonthLabels(months: number) {
  return Array.from(Array(30).keys())
    .map((i) => {
      let date = new Date(months);
      date.setDate(date.getDate() - i);

      let day = date.getDate();
      let month = date.toLocaleString('default', { month: 'long' });

      return getDayWithSuffix(day) + ' of ' + month;
    })
    .reverse();
}

function getDayWithSuffix(day: number) {
  if (day > 3 && day < 21) return day + 'th';
  switch (day % 10) {
    case 1:
      return day + 'st';
    case 2:
      return day + 'nd';
    case 3:
      return day + 'rd';
    default:
      return day + 'th';
  }
}
