export enum UserRole {
  // admin organization roles
  ADMIN = 'admin',
  CLIENT = 'client',

  // registered organization roles
  REGISTERED = 'registered',

  // other organization roles
  ORGANIZER = 'organizer',
  EDITOR = 'editor',
  SUPPORT = 'support',
  ANALYST = 'analyst',
  AFFILIATE = 'affiliate',
  SPONSOR = 'sponsor',
  SCAN = 'scan',
}
