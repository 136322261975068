import React from 'react';
import { Card } from '@mui/material';
import StatsDisplay from './StatsDisplay';
export interface AllocatedStatsProps {
  attendeesTotal: number;
  attendeesArrived: number;
  attendeesRemaining: number;
  guestsTotal: number;
  guestsArrived: number;
  guestsRemaining: number;
  totalRemaining: number;
}

const AllocatedStats = ({
  attendeesTotal,
  attendeesArrived,
  attendeesRemaining,
  guestsTotal,
  guestsArrived,
  guestsRemaining,
  totalRemaining,
}: AllocatedStatsProps) => {
  return (
    <>
      <Card sx={{ minWidth: 275, height: 310 }}>
        <StatsDisplay
          label={`${attendeesTotal} Attendees`}
          total={attendeesTotal}
          arrived={attendeesArrived}
          remaining={attendeesRemaining}
        />

        <StatsDisplay
          label={`${guestsTotal} Guests`}
          total={guestsTotal}
          arrived={guestsArrived}
          remaining={guestsRemaining}
        />

        <StatsDisplay
          label={`${attendeesTotal + guestsTotal} Total`}
          total={attendeesTotal + guestsTotal}
          arrived={attendeesArrived + guestsArrived}
          remaining={totalRemaining}
        />
      </Card>
    </>
  );
};

export default AllocatedStats;
