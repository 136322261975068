import React, { PropsWithChildren } from 'react';
import { Chip, ChipTypeMap } from '@mui/material';

const backgroundColorMap: { [key: string]: string } = {
  success: 'rgba(198, 246, 217, 0.5)',
  error: 'rgba(244, 87, 37, .12)',
  info: 'rgba(154, 225, 250, 0.38)',
  warning: 'rgba(255, 199, 0, .12)',
  default: 'rgba(0, 0, 0, 0.08)',
};

const colorMap: { [key: string]: string } = {
  success: '#1AA251',
  error: '#d32f2f',
  info: '#0288d1',
  warning: '#edb002',
  default: 'rgba(0, 0, 0, 0.47)',
};

export interface BeautifulChipProps {
  color?: ChipTypeMap['props']['color'];
}

export function BeautifulChip({ color, children }: PropsWithChildren<BeautifulChipProps>) {
  function getBackgroundColor() {
    return backgroundColorMap[color || 'default'];
  }

  function getColor() {
    return colorMap[color || 'default'];
  }

  return (
    <Chip
      color={color}
      variant='outlined'
      label={children}
      sx={{
        width: 'fit-content',
        minWidth: '80px',
        maxWidth: '200px',
        border: 'none',
        height: '24px',
        color: getColor(),
        fontWeight: 600,
        backgroundColor: getBackgroundColor(),
      }}
    />
  );
}
