import { z } from 'zod';
import { FieldPath } from 'react-hook-form';
import { cleanObject } from '@/Utils/general';
import { add, differenceInMinutes, isAfter, max } from 'date-fns';
import { GenerationMethod } from '../helpers';
import { VoucherGroup } from '@/Models/promocodes';

export const codeFormatErrorMessage = 'The code format is invalid.';

const invalidDateIssue: z.IssueData = {
  code: 'invalid_date',
  message: 'The provided date is invalid',
};

export function getSchema(voucherGroup?: VoucherGroup) {
  return z
    .object({
      name: z.string().min(1),
      discount_percentage: z.coerce.number().gte(1).lt(100),
      expires_at: z.coerce
        .date()
        .superRefine((date, context) => {
          const minDate = add(new Date(), { hours: 1 });

          if (!!voucherGroup) {
            const currentDate = new Date(voucherGroup.expires_at!);

            if (
              !differenceInMinutes(date, currentDate) ||
              isAfter(date, max([minDate, currentDate]))
            ) {
              return;
            }
          } else {
            if (isAfter(date, minDate)) {
              return;
            }
          }

          context.addIssue(invalidDateIssue);
        })
        .optional(),
      allocated: z.coerce.number().gte(1).optional(),
      tickets_eligible_for_voucher_discount: z.array(z.coerce.string()),

      generation_method: z.nativeEnum(GenerationMethod).optional(),
      codeNo: z.coerce.number().gte(1).lte(5000).optional(),
      csv_file: z.instanceof(File).optional(),
      code: z
        .string()
        .min(6)
        .max(30)
        .regex(/^[A-Z0-9\-]*$/, { message: codeFormatErrorMessage })
        .optional(),
    })
    .transform(cleanObject);
}

export type VouchersForm = z.infer<ReturnType<typeof getSchema>>;

export const codeGenerationFormKeys: { [key in GenerationMethod]: FieldPath<VouchersForm>[] } = {
  [GenerationMethod.GENERATE]: ['codeNo'],
  [GenerationMethod.IMPORT_CSV]: ['csv_file'],
  [GenerationMethod.INPUT]: ['code'],
};
