import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ArrivalData } from '@/Models/arrivals';
import { useMemo } from 'react';
import { useResponsive } from '@/Hooks/useResponsive';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface ArrivalsOverviewProps {
  arrivals: ArrivalData[];
}

const ArrivalsOverview = ({ arrivals }: ArrivalsOverviewProps) => {
  const { isMobile } = useResponsive();
  const data = useMemo(() => {
    let arrivalsData: number[] = [];

    arrivals.forEach((row) => {
      if (row.arrival == null) {
        arrivalsData[0] = row.total;
      } else {
        arrivalsData[1] = row.total;
      }
    });

    const total = arrivalsData.reduce((acc, cur) => acc + cur, 0);
    const percentages = arrivalsData.map((value) => ((value / total) * 100).toFixed(2));

    return {
      labels: ['Not Arrived', 'Arrived'],
      datasets: [
        {
          label: '# Total',
          data: arrivalsData,
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
          borderWidth: 1,
        },
      ],
      percentages,
    };
  }, [arrivals]);

  return (
    <Pie
      data={data}
      height={isMobile ? 350 : 'auto'}
      options={{
        maintainAspectRatio: false,
        responsive: isMobile ? false : true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const label = context.label || '';
                const value = context.parsed || 0;
                const percentage = data.percentages[context.dataIndex];

                return `${label}: ${value} (${percentage}%)`;
              },
            },
          },
        },
      }}
      style={{ width: '100%' }}
    />
  );
};

export default ArrivalsOverview;
