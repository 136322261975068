import { zodOptionalString } from '@/Utils/zod-utils';
import { z } from 'zod';

export const donationCauseFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  logo: z.union([z.string().min(1), z.instanceof(File)]),
  url: zodOptionalString(z.string().url()),
});

export type DonationCauseForm = z.infer<typeof donationCauseFormSchema>;
