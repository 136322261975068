import { Theme, useMediaQuery } from '@mui/material';

// TODO: extend if we need more than one breakpoint
export const useResponsive = () => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('laptop'));
  const isLargeDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  return {
    isMobile,
    isLargeDesktop,
  };
};
