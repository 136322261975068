import { Box, Typography, Modal, IconButton } from '@mui/material';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useEffect } from 'react';
import { getEventDate } from '@/Utils/event';
import { useResponsive } from '@/Hooks/useResponsive';
import { useGetTicketsWithCodesQuery } from '@/Api/ticketsSlice';
import { Ticket } from '@/Models/tickets';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { AddBox, Download } from '@mui/icons-material';
import { formatPrice } from '@/Utils/currency';
import environment from '@/environments/environment';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

const TicketCodes = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const advancedFetch = useAdvancedFetch();

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  function onTicketCodesRowClick({ row: ticketCodes }: GridRowParams<any>) {
    setDialogOpen(true);
    setSelectedTicket(ticketCodes);
  }

  const { isMobile } = useResponsive();

  const { data: tickets, refetch } = useGetTicketsWithCodesQuery(
    { eventId: eventId! },
    { refetchOnMountOrArgChange: true },
  );

  const columnsForTickets: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Ticket Title',
      flex: 2,
      renderCell: (params: any) => (
        <div style={{ cursor: 'pointer' }}>
          <Typography>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
    },
    {
      field: 'sold',
      headerName: 'Sold',
      flex: 1,
    },
    {
      field: 'ticket_codes_count',
      headerName: 'Ticket Codes',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDownloadClick(eventId!, row.id);
          }}
        >
          <Download />
        </IconButton>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  const checkTicketCodeStatus = (ticketCode: any): string => {
    if (ticketCode.is_guest) {
      const guestStatus = ticketCode.is_used ? 'Guest - used' : 'Guest - not used';
      return guestStatus;
    }

    const status = !ticketCode.is_used ? 'Not Sold' : 'Sold';
    return status;
  };

  const rowsForTickets =
    tickets?.map((ticket) => ({
      id: ticket.id,
      title: ticket.title,
      price: formatPrice(ticket.price, event?.currency),
      sold: ticket.sold,
      ticket_codes_count: ticket.ticket_codes.length,
      ticket_codes: ticket.ticket_codes,
    })) || [];

  const rowsForTicketCodes =
    selectedTicket?.ticket_codes?.map((ticketCode: any) => ({
      id: ticketCode.id,
      code: ticketCode.code,
      is_used: checkTicketCodeStatus(ticketCode),
    })) || [];

  function onLinkDialogClose() {
    setDialogOpen(false);
    setSelectedTicket(null);
  }

  const columns: GridColDef[] = [
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'is_used', headerName: 'Status', flex: 1 },
  ];

  function onDownloadClick(eventId: string, ticketId: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${eventId}/ticket-codes/${ticketId}/download?download=1`,
      {
        method: 'GET',
      },
    ).then((res) => {
      handleBlobReponse(res);
    });
  }

  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <Stack spacing={2} className='pb-4'>
            {event.online_event && (
              <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between'>
                <Typography className='py-2 lg:py-0' variant='h5'>
                  Ticket Codes
                </Typography>
              </Stack>
            )}
            <DataGrid
              rows={rowsForTickets}
              columns={columnsForTickets}
              pageSize={5}
              checkboxSelection={false}
              autoHeight
              onRowClick={onTicketCodesRowClick}
            />
          </Stack>
        </div>
      )}

      <Modal
        open={dialogOpen}
        onClose={onLinkDialogClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '80%',
            maxWidth: '800px',
            maxHeight: '80vh',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '16px',
          }}
        >
          {selectedTicket && (
            <>
              <Typography variant='h6'>
                Ticket Codes for {selectedTicket.title}
                <Link to={`/events/${eventId}/ticket-codes/${selectedTicket.id}/create`}>
                  <IconButton>
                    <AddBox />
                  </IconButton>
                </Link>
              </Typography>
              <DataGrid
                rows={rowsForTicketCodes}
                columns={columns}
                disableColumnMenu
                autoHeight
                pageSize={10}
              />
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default TicketCodes;
