import { DialogProps } from '@/Models/dialogs';
import { Event } from '@/Models/events';
import { InvitationType } from '@/Models/tickets';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import useForm from '@/Hooks/useForm';
import { useGetInvitationTypesQuery } from '@/Api/invitationsSlice';
import { useReserveSeatsMutation } from '@/Api/guestsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { Link } from 'react-router-dom';
import SeatSelection from '../../Components/Custom/Seats/SeatSelection';
import { useResponsive } from '@/Hooks/useResponsive';

interface ReserveSeatsProps {
  event: Event;
}

const steps = ['Information', 'Select Seats'];

const ReserveSeats = ({ open, onDialogClose, event }: DialogProps<ReserveSeatsProps>) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedSeatIds, setSelectedSeatIds] = useState<string[]>([]);

  const { data: invitationTypes } = useGetInvitationTypesQuery(event.id!, {
    refetchOnMountOrArgChange: true,
  });
  const [reserveSeats, { isLoading: loading }] = useReserveSeatsMutation();

  const { data, setData, errors, setError, clearErrors } = useForm({
    first_name: '',
    last_name: '',
    email: '',
    quantity: 1,
    invitation_type_id: '',
    send_email: false,
  });

  function addGuests() {
    if (selectedSeatIds.length != data.quantity) {
      setError('quantity', 'You must select the same number of seats as the quantity');
      setActiveStep(0);
      return;
    }
    reserveSeats({
      eventId: event.id,
      body: {
        ...data,
        seatIds: selectedSeatIds,
      },
    })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors || {});
          setActiveStep(0);
        }
      });
  }

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  function goBack() {
    if (activeStep === 0) {
      onDialogClose(false);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function goForward() {
    if (activeStep === steps.length - 1) {
      addGuests();
      return;
    }

    if (
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === '' ||
      data.quantity === 0
    ) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const fullScreenXl = useMediaQuery(theme.breakpoints.up('xl'));
  const { isLargeDesktop } = useResponsive();

  return (
    <Dialog
      open={open}
      onClose={() => onDialogClose(false)}
      fullWidth={isLargeDesktop ? fullScreenXl : fullScreen}
      maxWidth={'lg'}
    >
      <form name='ReserveSeats' onSubmit={addGuests}>
        <DialogTitle>Reserve Seats</DialogTitle>

        <DialogContent className='overflow-auto h-full'>
          <Stack className='h-16' justifyContent='center'>
            <Stepper activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>

          {activeStep === 0 && (
            <div className='w-full py-2 pb-16'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id='first_name'
                    name='first_name'
                    label='First Name'
                    error={!!errors.first_name}
                    helperText={errors.first_name?.[0]}
                    fullWidth
                    value={data.first_name}
                    onChange={(e) => updateData('first_name', e?.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id='last_name'
                    name='last_name'
                    label='Last Name'
                    error={!!errors.last_name}
                    helperText={errors.last_name?.[0]}
                    fullWidth
                    value={data.last_name}
                    onChange={(e) => updateData('last_name', e?.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id='email'
                    name='email'
                    label='Email'
                    error={!!errors.email}
                    helperText={errors.email?.[0]}
                    fullWidth
                    type='email'
                    value={data.email}
                    onChange={(e) => updateData('email', e?.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    select
                    id='invitation_type_id'
                    name='invitation_type_id'
                    label='Invitation type'
                    error={!!errors.invitation_type_id}
                    helperText={errors.invitation_type_id?.[0]}
                    fullWidth
                    value={data.invitation_type_id}
                    onChange={(e) => updateData('invitation_type_id', e?.target.value)}
                  >
                    {invitationTypes &&
                      invitationTypes.map(({ id, title }: InvitationType) => (
                        <MenuItem key={id} value={id}>
                          {title}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id='quantity'
                    name='quantity'
                    label='Quantity'
                    type='number'
                    error={!!errors.quantity}
                    helperText={errors.quantity}
                    inputProps={{ min: 1 }}
                    fullWidth
                    value={data.quantity}
                    onChange={(e) => updateData('quantity', +e?.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.send_email}
                        onChange={(e) => updateData('send_email', e.target.checked)}
                        name='visible'
                      />
                    }
                    label='Send email to invitee(s)'
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {activeStep === 1 && (
            <SeatSelection
              selectedSeatIds={selectedSeatIds}
              setSelectedSeatIds={setSelectedSeatIds}
            />
          )}

          <Stack
            className='h-16 pr-4 w-full absolute bottom-0 left-0 shadow-bottom-nav'
            justifyContent='center'
            alignItems='flex-end'
          >
            <Stack direction='row' columnGap='10px'>
              <Button onClick={() => onDialogClose(false)}>Cancel</Button>

              <Button onClick={goBack} disabled={!activeStep || loading}>
                Back
              </Button>

              <LoadingButton loading={loading}>
                <Button variant='contained' disabled={loading} color='success' onClick={goForward}>
                  {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                </Button>
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default ReserveSeats;
