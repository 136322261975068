import { TAG_TYPE } from '@/Enums/tag-types';
import { Ticket } from '@/Models/tickets';
import { getFilterQueryParamsFromObject } from '@/Utils/filters';
import { internalApi } from './internalApi';

export const ticketsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getTickets: builder.query<
      Ticket[],
      { eventId: string; locked?: boolean; includeInvitationTypes?: boolean }
    >({
      query: ({ eventId, ...filters }) => ({
        url: `/events/${eventId}/tickets`,
        method: 'GET',
        params: getFilterQueryParamsFromObject(filters),
      }),
      providesTags: [TAG_TYPE.TICKETS],
    }),
    getTicketsWithCodes: builder.query<Ticket[], { eventId: string; locked?: boolean }>({
      query: ({ eventId, ...filters }) => ({
        url: `/events/${eventId}/ticket-codes`,
        method: 'GET',
        params: getFilterQueryParamsFromObject(filters),
      }),
      providesTags: [TAG_TYPE.TICKETS],
    }),
    getTicket: builder.query<Ticket, { eventId: string; ticketId: string }>({
      query: ({ eventId, ticketId }) => ({
        url: `/events/${eventId}/tickets/${ticketId}`,
        method: 'GET',
      }),
    }),
    createTicket: builder.mutation<Ticket, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/tickets`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.TICKETS],
    }),
    updateTicket: builder.mutation<Ticket, { eventId: string; ticketId: string; body: any }>({
      query: ({ eventId, ticketId, body }) => ({
        url: `/events/${eventId}/tickets/${ticketId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.TICKETS],
    }),
    deleteTicket: builder.mutation<any, { eventId: string; ticketId: string }>({
      query: ({ eventId, ticketId }) => ({
        url: `/events/${eventId}/tickets/${ticketId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.TICKETS],
    }),
    reOrderTickets: builder.mutation<
      Ticket,
      { eventId: string | undefined; body: { tickets: Ticket[] } }
    >({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/re-order-tickets`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.TICKETS],
    }),
    getSupportOrderTickets: builder.query<Ticket[], { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/support-order/tickets`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.TICKETS],
    }),
  }),
});

export const {
  useGetTicketsQuery,
  useLazyGetTicketsQuery,
  useGetTicketsWithCodesQuery,
  useGetTicketQuery,
  useDeleteTicketMutation,
  useUpdateTicketMutation,
  useCreateTicketMutation,
  useReOrderTicketsMutation,
  useGetSupportOrderTicketsQuery,
} = ticketsApi;
