import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { PreviewData } from '@/Models/preview';
import useForm from '@/Hooks/useForm';

const imageFileTypes = ['image/png', 'image/jpeg'];

interface FileUploadProps {
  label: string;
  allowedFileTypes: string[];
  maxFileSize: number;

  onSelect: (file: File) => void;
  onError?: (message: string) => void;
  preview?: boolean;
  previewData?: PreviewData;
  previewProps?: { [key: string]: any };
  helperText?: string;
}

// TODO front: at the moment this component has logic for single file uploading
// TODO: this component should be refactored to use useFormContext hook
const FileUpload = ({
  label,
  allowedFileTypes,
  maxFileSize,
  onSelect,
  onError,
  preview,
  previewData: initialPreviewData,
  previewProps,
  helperText,
}: FileUploadProps) => {
  const [uId] = useState(uuid());
  const [previewData, setPreviewData] = useState<PreviewData>();

  useEffect(() => {
    if (initialPreviewData) {
      setPreviewData(initialPreviewData);
    }
  }, [initialPreviewData]);

  const { data, setData, errors, setError, clearErrors } = useForm<any>({
    file: null,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  function onFileUpload(event: ChangeEvent<HTMLInputElement>) {
    clearErrors('file');

    if (!event.target.files?.length) {
      return;
    }

    const file = event.target.files[0];

    if (!validateFile(file)) {
      deleteFile();
      return;
    }

    setData('file', file);
    onSelect(file);
    preparePreview(file);
  }

  function validateFile(file: File) {
    if (!allowedFileTypes.includes(file.type)) {
      const message = 'Selected file type is not supported!';
      setError('file', message);
      onError?.(message);
      return false;
    }

    // TODO front: humanize the size error message (5MB instead of 5000 KB)
    if (file.size > maxFileSize) {
      const message = `Maximum file size is ${maxFileSize} KB`;
      setError('file', message);
      onError?.(message);
      return false;
    }

    return true;
  }

  function deleteFile() {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  function preparePreview(file: File) {
    if (!imageFileTypes.includes(file.type)) {
      return;
    }

    if (preview) {
      setPreviewData({
        type: 'image',
        url: URL.createObjectURL(file),
      });
    }
  }

  return (
    <>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        id={uId}
        type='file'
        accept={allowedFileTypes.join(', ')}
        onChange={onFileUpload}
      />

      <Stack>
        <Typography variant='body2'>{label}</Typography>

        <Stack direction='row' spacing={1} alignItems='center'>
          <label htmlFor={uId}>
            <Button
              component='span'
              color='info'
              variant='contained'
              startIcon={<FileUploadIcon />}
            >
              Upload
            </Button>
          </label>

          {data.file ? (
            <Typography variant='body2' noWrap>
              {data.file.name}
            </Typography>
          ) : (
            <Typography variant='caption'>No file selected</Typography>
          )}
        </Stack>

        {errors['file'] && (
          <FormControl error>
            <FormHelperText className='!ml-0'>{errors['file']}</FormHelperText>
          </FormControl>
        )}

        <FormHelperText className='!ml-0'>{helperText}</FormHelperText>

        {previewData && previewData.type === 'image' && (
          <img {...previewProps} className='w-full mt-4' src={previewData.url} alt={label} />
        )}
      </Stack>
    </>
  );
};

export default FileUpload;
