import { Stack, Typography } from '@mui/material';
import SvgEditor from './SvgEditor';
import EditingPane from './EditingPane';
import { useContext, useEffect, useState } from 'react';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import { SeatLabelForm } from './seat-labeling-form-helpers';
import { editorTools } from '@/Constants/venue-tools';
import { useSelector } from 'react-redux';
import { GlobalStore } from '@/store';
import SeatLabelingForm from './SeatLabelingForm';

interface SeatIdentifierEditorContainerProps {
  svgContent: string;
}

function SeatIdentifierEditorContainer({ svgContent }: SeatIdentifierEditorContainerProps) {
  const {
    venueEditorService,
    sectionIdentifierService,
    seatIdentifierService,
    editorEventService,
  } = useContext(VenueEditorContext);
  const [selectedSeatIds, setSelectedSeatIds] = useState<string[]>([]);
  const { currentTool } = useSelector((state: GlobalStore) => state.venueEditor);

  function initialize() {
    venueEditorService.initialize('venue-svg-container-canvas', svgContent);
    venueEditorService.removeUnidentifiedSeatItems();

    const allSeats = venueEditorService.getAllSeats();
    const allSections = venueEditorService.getAllSections();

    venueEditorService.fillLabelsForSections(sectionIdentifierService.getSectionData());

    venueEditorService.createSeatLabelsForSeats(seatIdentifierService.getSeatData(), allSeats);
    editorEventService.seatsStatusChanged$.next(allSeats);
    editorEventService.sectionsStatusChanged$.next(allSections);
    editorEventService.sectionsVisibilityChanged$.next(allSections);
  }

  useEffect(() => {
    const sub = venueEditorService.getSelectedElementsIds$().subscribe((selectedSeatIds) => {
      setSelectedSeatIds(selectedSeatIds);
    });

    return () => sub.unsubscribe();
  }, []);

  function onSeatLabelFormSubmit(data: SeatLabelForm) {
    const seatItems = venueEditorService.getSelectedSeats();
    seatIdentifierService.calculateSeatIdentificationData(seatItems, data);
    venueEditorService.createSeatLabelsForSelection(seatIdentifierService.getSeatData());
    venueEditorService.clearSelection();
  }

  function getToolHelperText() {
    const tool = editorTools.find((tool) => tool.type === currentTool);

    return tool?.helperText ?? '';
  }

  return (
    <Stack direction='row' className='w-full h-[calc(100vh-186px)]'>
      <div className='w-[70%]'>
        <SvgEditor initialize={initialize} />
      </div>
      <div className='w-[30%] border-solid border-l-2'>
        <EditingPane
          allowedTools={['singleSeatSelector', 'rectangularSeatSelector', 'polygonalSeatSelector']}
        >
          {!!selectedSeatIds.length ? (
            <Stack>
              <div className='mb-4'>
                <SeatLabelingForm onSubmit={onSeatLabelFormSubmit} />
              </div>
            </Stack>
          ) : (
            <Stack className='h-full' alignItems='center' justifyContent='center'>
              <Typography variant='body1'>{getToolHelperText()}</Typography>
            </Stack>
          )}
        </EditingPane>
      </div>
    </Stack>
  );
}

export default SeatIdentifierEditorContainer;
