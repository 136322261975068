import { add, differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';

declare type TimerState = 'finished' | 'executing' | 'stopped' | 'created';

export default function useTimer(): {
  timeLeft: number;
  state: TimerState;
  start: (initialTimeInSeconds: number) => void;
  stop: () => void;
  resume: () => void;
  isFinished: boolean;
} {
  const [expiresAt, setExpiresAt] = useState<Date | undefined>(undefined) as any;
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined) as any;
  const [state, setState] = useState<TimerState>('created');

  useEffect(() => {
    let timerId = undefined as any;
    if (state === 'executing' && timeLeft !== 0) {
      timerId = setTimeout(() => {
        const newTime = getNewTime();
        setTimeLeft(newTime);

        if (newTime === 0) {
          setState('finished');
        }
      }, 1000);
    }

    return () => clearTimeout(timerId);
  }, [timeLeft, state, expiresAt]);

  function getNewTime() {
    let newTime = differenceInSeconds(expiresAt, new Date());
    return Math.max(0, newTime);
  }

  return {
    timeLeft,
    state,
    start: (initialTimeInSeconds) => {
      setExpiresAt(add(new Date(), { seconds: initialTimeInSeconds }));
      setTimeLeft(initialTimeInSeconds);
      if (initialTimeInSeconds >= 0) {
        setState('executing');
      }
    },
    stop: () => {
      if (state !== 'finished') {
        setState('stopped');
      }
    },
    resume: () => {
      if (state !== 'finished') {
        setState('executing');
      }
    },
    isFinished: state === 'finished',
  };
}
