import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { DeleteOutline, EditOutlined, MoreVert } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import {
  useDeleteParkingSpaceMutation,
  useGetParkingSpacesQuery,
  useGetParkingSpacesStatsQuery,
} from '@/Api/parkingSpacesSlice';
import { ParkingSpaces } from '@/Models/parkingSpaces';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { formatPrice } from '@/Utils/currency';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import ParkingPurchases from './ParkingPurchases';
import StatsCard, { StatsCardProps } from '@/Components/Molecules/Cards/StatsCard';
import { AttachMoney, Equalizer, Functions, Sync } from '@mui/icons-material';
import { useResponsive } from '@/Hooks/useResponsive';

const cardDetails: {
  [key: string]: Omit<StatsCardProps, 'value'>;
} = {
  totalParkingSpaces: {
    title: 'Total sales in Parking Space',
    Icon: Functions,
  },
  totalAmountParkingSpacesStats: {
    title: 'Total amout of Paring Spaces',
    Icon: AttachMoney,
  },
  averageAmountParkingSpaces: {
    title: 'Average Amount Parking Space Order',
    Icon: Equalizer,
  },
};

const Index = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [parkingSpaceToDelete, setParkingSpaceToDelete] = useState<string>();
  const [deleteParkingSpace] = useDeleteParkingSpaceMutation();

  const [parkingSpaceDialogOpen, setParkingSpaceDialogOpen] = useState(false);
  const [selectedParkingSpace, setSelectedParkingSpaceId] = useState<string | null>(null);

  const [page, setPage] = useState(0);
  const pageSize = 10;

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    parkingSpace: ParkingSpaces;
  }>(null);

  useEffect(() => {
    dispatch(setTitle('Parking Spaces'));
    dispatch(
      setHeader({
        title: 'Parking Spaces',
      }),
    );
  }, []);

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  const { data: parkingSpaces, refetch } = useGetParkingSpacesQuery(
    {
      eventId: eventId!,
      filterData: { pageData: { page, pageSize } },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !eventId,
    },
  );

  const { data: parkingSpacesReports } = useGetParkingSpacesStatsQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const onDialogClose = (value: boolean) => {
    setDialogOpen(false);
    if (value && parkingSpaceToDelete) {
      deleteParkingSpace({
        eventId: eventId!,
        parkingSpacesId: parkingSpaceToDelete!,
      });
    }
    refetch();
    setParkingSpaceToDelete(undefined);
  };

  function showActionMenu(parkingSpace: ParkingSpaces, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, parkingSpace });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleParkingSpacesDialogOpen = (parkignSpacesId: string) => {
    setSelectedParkingSpaceId(parkignSpacesId);
    setParkingSpaceDialogOpen(true);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'description',
      headerName: 'Descritption',
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row.description.replace(/<\/?[^>]+(>|$)/g, '')}</span>;
      },
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 230,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className='text-gray-600'>
            {' '}
            {params.row.event.currency && formatPrice(params.row.price, params.row.event.currency)}
          </span>
        );
      },
    },
    {
      field: 'allocated',
      headerName: 'Allocated',
      width: 130,
      valueGetter: ({ row }) => row.allocated,
      sortable: false,
    },
    {
      field: 'sold',
      headerName: 'Sold',
      width: 130,
      renderCell: (params) => {
        return <span>{params.row.sold}</span>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellClassName: 'sticky-actions-column',
      renderCell: ({ row }) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            showActionMenu(row, event);
          }}
        >
          <MoreVert />
        </IconButton>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <Stack direction={direction} className='sm:m-4 lg:w-[800px]' spacing={2}>
            {Object.keys(parkingSpacesReports || []).map((key) => {
              return (
                <StatsCard
                  key={key}
                  className='col-1'
                  title={cardDetails[key].title}
                  value={parkingSpacesReports[key]}
                  Icon={cardDetails[key].Icon}
                />
              );
            })}
          </Stack>
          <div className='mx-2 my-6 lg:flex lg:items-center lg:justify-end lg:self-end'>
            <Link to={`/events/${eventId}/parking-spaces/create`}>
              <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                Create Parking Spaces
              </Button>
            </Link>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {parkingSpaces ? (
              <DataGrid
                rows={parkingSpaces.data}
                columns={columns}
                paginationMode='server'
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                onRowClick={(params) => handleParkingSpacesDialogOpen(params.row.id)}
                onPageChange={onPageChange}
                rowCount={parkingSpaces.total}
                components={{ Toolbar: GridToolbar }}
              />
            ) : (
              ''
            )}
          </div>

          {parkingSpaceDialogOpen && selectedParkingSpace && (
            <ParkingPurchases
              open={parkingSpaceDialogOpen}
              onDialogClose={() => setParkingSpaceDialogOpen(false)}
              parking_space_id={selectedParkingSpace}
            />
          )}

          {parkingSpaces && event && (
            <Stack>
              <Stack>
                {parkingSpaces.data.map((parkingSpace) => (
                  <Stack>
                    <Stack>
                      {menuContext && (
                        <Menu
                          anchorEl={menuContext?.anchor}
                          open={!!menuContext}
                          onClose={hideActionMenu}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <MenuItem>
                            <Link
                              to={`${menuContext.parkingSpace.id}/edit`}
                              className='flex flex-row'
                            >
                              <ListItemIcon>
                                <EditOutlined />
                              </ListItemIcon>
                              <ListItemText>Edit</ListItemText>
                            </Link>
                          </MenuItem>

                          <MenuItem
                            className='flex flex-row'
                            disabled={parkingSpace.sold > 0}
                            onClick={(event) => {
                              setDialogOpen(true);
                              setParkingSpaceToDelete(parkingSpace?.id);
                              showActionMenu(parkingSpace, event);
                              setParkingSpaceToDelete(parkingSpace?.id);
                              hideActionMenu();
                            }}
                          >
                            <ListItemIcon>
                              <DeleteOutline color='error' />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                            <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />
                          </MenuItem>
                        </Menu>
                      )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </div>
      </div>
      <DeleteDialog open={dialogOpen} onDialogClose={onDialogClose} />
    </>
  );
};

export default Index;
