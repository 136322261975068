import { RTKInterceptor } from './types';
import { hideLoader, showLoader } from '@/Slices/loaderSlice';

const loaderInterceptor: RTKInterceptor = async (next, args, api, extraOptions) => {
  api.dispatch(showLoader());

  const result = await next(args, api, extraOptions);

  setTimeout(() => {
    api.dispatch(hideLoader());
  }, 200);

  return result;
};

export default loaderInterceptor;
