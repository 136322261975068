import React, { useEffect } from 'react';
import {
  useCreateArtistMutation,
  useGetArtistQuery,
  useUpdateArtistMutation,
} from '@/Api/artistsSlice';
import { Box, Button, Divider, Paper, Grid, Stack, TextField, Typography } from '@mui/material';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useLoading from '@/Hooks/useLoading';
import useForm from '@/Hooks/useForm';
import { useNavigate, useParams } from 'react-router-dom';
import { HTTP_CODE } from '@/Utils/http-client';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';

const CreateOrEdit = () => {
  const navigate = useNavigate();
  const { artistId } = useParams();
  const dispatch = useDispatch();

  const [createArtist] = useCreateArtistMutation();
  const [updateArtist] = useUpdateArtistMutation();
  const { data: artist } = useGetArtistQuery(artistId!, {
    refetchOnMountOrArgChange: true,
    skip: !artistId,
  });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  useEffect(() => {
    if (artist) {
      setData({ ...data, ...artist });
    }
  }, [artist]);

  const [loading, withLoading] = useLoading();

  const { data, setData, errors, setError, clearErrors, hasErrors } = useForm<{
    [key: string]: any;
  }>({
    name: '',
    description: '',
    spotify_url: '',
  });

  async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    if (hasErrors) {
      return;
    }

    let artistData = { ...data };

    let promise: Promise<any>;
    if (!artistId) {
      promise = createArtist(artistData).unwrap();
    } else {
      promise = updateArtist({ artistId: artistId!, body: artistData }).unwrap();
    }

    withLoading(promise)
      .then(() => {
        navigate('/artists');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors);
        }
      });
  }

  function updateData(fieldName: string, value: any) {
    clearErrors(fieldName);
    setData((prevData: FormData) => ({ ...prevData, [fieldName]: value }));
  }

  function getTitle() {
    return `${artistId ? 'Update' : 'Create'} Artist`;
  }

  const { isMobile } = useResponsive();
  const direction = isMobile ? 'column' : 'row';

  return (
    <div className='py-8'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
        <form name='createForm' onSubmit={handleSubmit}>
          <Stack spacing={4} divider={<Divider />}>
            <Stack direction={direction} spacing={2}>
              <div className='ml-2  lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the Artist
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id='name'
                      name='name'
                      label='Name'
                      error={!!errors.name}
                      helperText={errors.name?.[0]}
                      fullWidth
                      value={data.name}
                      onChange={(e) => updateData('name', e?.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='spotify_url'
                      name='spotify_url'
                      label='spotify_url'
                      error={!!errors.spotify_url}
                      helperText={
                        errors.spotify_url?.[0] ||
                        'This spotify_url will be used in the event URL. Maximum length is 50 characters.'
                      }
                      fullWidth
                      inputProps={{
                        maxLength: 50,
                      }}
                      type='url'
                      value={data.spotify_url}
                      onChange={(e) => updateData('spotify_url', e?.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='subtitle1' className='pb-2'>
                      Description*
                    </Typography>
                    <ReactQuill
                      theme='snow'
                      id='description'
                      value={data.description}
                      onChange={(e) => updateData('description', e)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
          </Stack>

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton loading={loading}>
              <Button type='submit' variant='contained' disabled={loading} color='success'>
                Save
              </Button>
            </LoadingButton>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CreateOrEdit;
