import { Divider, Grid, List, ListItem, ListItemText, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useGetScanGroupQuery, useGetScanGroupStatsOverviewQuery } from '@/Api/scanGroupsSlice';
import { styled } from '@mui/material/styles';
import { Event } from '@/Models/events';
import ArrivalsScanGroup from '@/Components/Organisms/ArrivalsScanGroup/ArrivalsScanGroup';
import StatsCard from '@/Components/Molecules/Cards/StatsCard';
import { Functions } from '@mui/icons-material';
import { useResponsive } from '@/Hooks/useResponsive';
import AllocatedStats from './AllocatedStats';

const ScanGroup = () => {
  const { scanGroupId } = useParams();
  const dispatch = useDispatch();
  const { data: scanGroup } = useGetScanGroupQuery(scanGroupId!, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, [scanGroup]);

  function getTitle() {
    return scanGroup?.name || 'Event Details';
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
    boxShadow: 'none',
  }));

  const { data: reports } = useGetScanGroupStatsOverviewQuery(
    { scanGroupId: scanGroupId as string },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { isMobile } = useResponsive();

  return (
    <>
      {scanGroup && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <Grid container={true} spacing={2}>
            <Grid item xs={12} md={6} height={330}>
              <Item>
                Events in this Scan Group
                <List sx={{ bgcolor: 'background.paper', height: '90%', overflowY: 'scroll' }}>
                  {scanGroup?.events?.map((event: Event) => (
                    <React.Fragment key={event.id}>
                      <ListItem alignItems='flex-start' className='relative'>
                        <ListItemText primary={event.title} secondary={event.slug} />
                      </ListItem>
                      <Divider component='li' />
                    </React.Fragment>
                  ))}
                </List>
              </Item>
            </Grid>

            <Grid item xs={12} md={6} height={isMobile ? 'auto' : 300}>
              {reports && (
                <List sx={{ padding: 0, marginTop: 0 }}>
                  <AllocatedStats
                    attendeesTotal={reports.attendeesTotal}
                    attendeesArrived={reports.attendeesArrived}
                    attendeesRemaining={reports.attendeesRemaining}
                    guestsTotal={reports.guestsTotal}
                    guestsArrived={reports.guestsArrived}
                    guestsRemaining={reports.guestsRemaining}
                    totalRemaining={reports.totalRemaining}
                  />
                </List>
              )}
            </Grid>
          </Grid>
          <ArrivalsScanGroup scanGroup={scanGroup} />
        </div>
      )}
    </>
  );
};

export default ScanGroup;
