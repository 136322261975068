import { Currency } from '@/Enums/currency';
import Dinero from 'dinero.js';
import { isEmpty } from './general';

// TODO: at the moment the Dinero library does not have information regarding different currencies minor units.
// A package is being developed by them that is in alpha version still. So until then, since anyways all our
// currencies are 2 decimal places, we can use the following function to convert.
export function formatPrice(
  price: string | number | Dinero.Dinero | undefined,
  currency: string | undefined,
): string {
  if (!currency) {
    return '-';
  }

  if (isEmpty(price)) {
    price = 0;
  }

  if (typeof price === 'object') {
    return price.setLocale('en-US').toFormat('$0,0.00');
  }

  return Dinero({ amount: +price, currency: currency.toUpperCase() as Currency })
    .setLocale('en-US')
    .toFormat('$0,0.00');
}

export function formatPriceMajor(price: string | number | undefined, currency: string | undefined) {
  if (!currency) {
    return '-';
  }

  if (isEmpty(price)) {
    price = 0;
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(+price);
}

const currencySymbols = {
  USD: '$',
  GBP: '£',
  EUR: '€',
};

export function currencySymbol(currency: string) {
  const symbol = currencySymbols[currency.toUpperCase() as keyof typeof currencySymbols];
  if (!symbol) {
    throw new Error(`Unknown currency ${currency}`);
  }
  return symbol;
}
