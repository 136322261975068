import React from 'react';
import BaseDialog from './BaseDialog';
import { Button } from '@mui/material';
import { ConfirmationDialogProps } from './dialogProps';

export default function ConfirmDialog({
  open,
  onDialogClose,
  cancelButtonLabel,
  confirmButtonLabel,
  onConfirm,
}: ConfirmationDialogProps) {
  return (
    <BaseDialog
      title='Are you sure you want to do this?'
      open={open}
      onClose={onDialogClose}
      content="You can't undo this action"
    >
      <Button onClick={() => onDialogClose(false)}>{cancelButtonLabel ?? 'Cancel'}</Button>

      <Button variant='contained' color='success' onClick={onConfirm}>
        {confirmButtonLabel ?? 'Confirm'}
      </Button>
    </BaseDialog>
  );
}
