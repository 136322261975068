import { AttendeeStatus } from '@/Enums/attendee-status';
import { OrderStatus } from '@/Enums/order-status';
import { TransactionStatus } from '@/Enums/transaction-status';
import { GuestStatus } from '@/Enums/guest-status';
import { ChipTypeMap } from '@mui/material';
import { capitalizeString } from './string';

export function getStatusColor(
  status: AttendeeStatus | OrderStatus | GuestStatus | TransactionStatus,
): ChipTypeMap['props']['color'] {
  switch (status) {
    case AttendeeStatus.COMPLETED:
    case TransactionStatus.CONFIRMED:
      return 'success';
    case AttendeeStatus.CANCELED:
    case TransactionStatus.CANCELED:
    case TransactionStatus.FAILED:
      return 'error';
    case AttendeeStatus.RESERVED:
    case TransactionStatus.PAID:
      return 'info';
    case AttendeeStatus.REFUNDED:
    case AttendeeStatus.PARTIALLY_REFUNDED:
    case TransactionStatus.CREATED:
      return 'warning';
    default:
      return 'default';
  }
}

export function humanizeAttendeeStatus(
  status: AttendeeStatus | OrderStatus | GuestStatus | TransactionStatus,
) {
  if (status === AttendeeStatus.PARTIALLY_REFUNDED) {
    return 'Partially refunded';
  }

  return capitalizeString(status);
}
