import { DialogProps } from '@/Models/dialogs';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetParkingPurchasesQuery } from '@/Api/parkingSpacesSlice';
import { formatPrice } from '@/Utils/currency';
import { useGetEventQuery } from '@/Api/eventsSlice';
import environment from '@/environments/environment';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

interface ParkingSpaceProps extends DialogProps<any> {
  parking_space_id: string | null;
}

const ParkingPurchases = ({ open, onDialogClose, parking_space_id }: ParkingSpaceProps) => {
  const { eventId } = useParams();
  const advancedFetch = useAdvancedFetch();

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const pageSize = 10;
  interface Event {
    id: string;
  }

  const { data: purchases } = useGetParkingPurchasesQuery(
    {
      eventId: eventId!,
      filterData: { search: search, pageData: { page, pageSize } },
      uuid: parking_space_id!,
    },
    { refetchOnMountOrArgChange: true },
  );

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  function onPageChange(page: number) {
    setPage(page);
  }

  const columns: GridColDef[] = [
    {
      field: 'order.first_name',
      headerName: 'First name',
      sortable: false,
      renderCell: ({ row }) => {
        return <span>{row.order.first_name}</span>;
      },
    },
    {
      field: 'order.last_name',
      headerName: 'Last name',
      sortable: false,
      renderCell: ({ row }) => {
        return <span>{row.order.last_name}</span>;
      },
    },
    {
      field: 'parking_space',
      headerName: 'Parking Space Name',
      width: 90,
      renderCell: ({ row }) => {
        return <span>{row.parking_space.name}</span>;
      },
    },
    {
      field: 'parking_space.event.title',
      headerName: 'Event',
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{row.parking_space.event.title}</span>;
      },
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketClick(event!, row?.barcode);
            }}
          >
            {row.barcode}
          </span>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 90,
      renderCell: (params) => {
        return (
          <span>{formatPrice(params.row.amount, params.row.parking_space.event.currency)}</span>
        );
      },
    },
  ];

  async function onTicketClick(event: Event, barcode: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${event.id}/parking-spaces/${barcode}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response);
    });
  }

  return (
    <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle>Parking Purchases</DialogTitle>
      <DialogContent>
        <div className='h-[50vh]'>
          {purchases?.data && purchases.data.length > 0 && (
            <DataGrid
              columns={columns}
              rows={purchases.data}
              pageSize={pageSize}
              paginationMode='server'
              onPageChange={onPageChange}
              rowsPerPageOptions={[pageSize]}
              rowCount={purchases.total}
              components={{ Toolbar: GridToolbar }}
              filterMode='server'
              onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 1000 },
                },
              }}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ParkingPurchases;
