import { SNACKBAR_DURATION } from '@/Constants/snackbar';
import { SnackbarData } from '@/Models/snackbar';
import { hideSnackbar } from '@/Slices/snackbarSlice';
import { Snackbar, Alert } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

export function SnackbarAlert({ snackbarData }: { snackbarData: SnackbarData }) {
  const [open, setOpen] = useState(snackbarData.open);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(snackbarData.open);
  }, [snackbarData]);

  const transitionFunction = useCallback((props: any) => <Slide {...props} direction='up' />, []);

  function handleClose() {
    dispatch(hideSnackbar());
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={transitionFunction}
      autoHideDuration={SNACKBAR_DURATION}
    >
      {open ? (
        <Alert onClose={handleClose} severity={snackbarData.severity}>
          {snackbarData.message}
        </Alert>
      ) : (
        <div></div>
      )}
    </Snackbar>
  );
}
