import { TAG_TYPE } from '@/Enums/tag-types';
import { getHideSuccessMessageHeader } from '@/Utils/http-client';
import { internalApi } from './internalApi';

export const featuredArticlesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeaturedArticles: builder.query<any, any>({
      query: () => ({
        url: `/featured-articles`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.FEATURED_ARTICLES],
    }),
    postFeaturedArticles: builder.mutation<any, any>({
      query: (body) => ({
        url: `/featured-articles`,
        method: 'POST',
        body,
        headers: getHideSuccessMessageHeader(),
      }),
      invalidatesTags: [TAG_TYPE.FEATURED_ARTICLES],
    }),
  }),
});

export const { useGetFeaturedArticlesQuery, usePostFeaturedArticlesMutation } = featuredArticlesApi;
