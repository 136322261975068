import { Event } from '@/Models/events';
import Grid from '@mui/material/Grid';
import RecentTransactions from '@/Components/Molecules/RecentTransactions/RecentTransactions';
import TransactionTab from '@/Components/Organisms/TransactionTab/TransactionTab';
import { useResponsive } from '@/Hooks/useResponsive';

export interface EventStatsProps {
  event: Event;
}

const TransactionsStats = () => {
  const { isMobile } = useResponsive();

  return (
    <Grid container={isMobile ? false : true} spacing={2}>
      <Grid item xs={12} md={4} height={500} sx={{ ...(isMobile && { marginTop: '10px' }) }}>
        <RecentTransactions />
      </Grid>
      <Grid item xs={12} sx={{ ...(isMobile && { marginTop: '10px' }) }} md={7} height={500}>
        <TransactionTab />
      </Grid>
    </Grid>
  );
};

export default TransactionsStats;
