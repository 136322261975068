import { Navigate, Outlet } from 'react-router-dom';

interface RouteGuardProps {
  isAllowed: boolean;
  redirectPath: string;
}

const RouteGuard = ({ isAllowed, redirectPath }: RouteGuardProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default RouteGuard;
