import { FolderOpen } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

interface EmptyContentProps {
  icon?: React.ReactNode;
  primaryText: string;
  secondaryText: string;
  button: React.ReactNode;
}

const EmptyContent = ({ icon, primaryText, secondaryText, button }: EmptyContentProps) => {
  return (
    <Stack className='w-full h-full' justifyContent='center' alignItems='center' spacing={2}>
      {icon || <FolderOpen sx={{ fontSize: '8rem', color: '#cccccc' }} />}

      <Stack alignItems='center'>
        <Typography>{primaryText}</Typography>

        <Typography className='text-gray-400'>{secondaryText}</Typography>
      </Stack>

      {button}
    </Stack>
  );
};

export default EmptyContent;
