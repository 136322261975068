import { Box, CircularProgress } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface LoadingButtonProps {
  loading: boolean;
}

// TODO front: maybe instead of this approach with children, enforce the usage
// of a button, and pass props to it so it can be fully customizable from the parent
// TODO front: size of circular progress should depend on button size
const LoadingButton = ({
  loading,
  children,
}: PropsWithChildren<LoadingButtonProps>) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {children}
      {loading && (
        <CircularProgress
          size={16}
          sx={{
            color: '#0288d1',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: '-8px',
            marginTop: '-8px',
          }}
        />
      )}
    </Box>
  );
};

export default LoadingButton;
