import { SelectOption } from '../Models/select';

export function getSelectOptionsFromEnum(
  enumType: Record<string, string | number>,
  withEmpty = false,
): SelectOption<string>[] {
  const options = withEmpty ? [{ label: 'None' } as SelectOption<string>] : [];
  return options.concat(
    Object.keys(enumType).map((key) => ({
      label: key,
      value: `${enumType[key]}`,
    })),
  );
}
