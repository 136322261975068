import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Account = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Account'));
    dispatch(
      setHeader({
        title: 'Account',
      }),
    );
  }, []);

  return <></>;
};

export default Account;
