import { useGetUsersQuery } from '@/Api/usersSlice';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const pageSize = 100;

// TODO: depending on the product decisions on how we manage user deletion and
// other stuff, we maybe can merge 'Organizers' page and 'Users/Index'
const Organizers = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const { data: organizerData } = useGetUsersQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    dispatch(setTitle('Users'));
    dispatch(setHeader({ title: 'Users' }));
  }, []);

  function onPageChange(page: number) {
    setPage(page);
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
    { field: 'email', headerName: 'Email', sortable: false, flex: 1 },
    {
      field: 'roles',
      headerName: 'Roles',
      renderCell: ({ row: { roles } }) => <div>{roles.join(', ')}</div>,
      sortable: false,
      flex: 1,
    }
  ];

  const inviteColumns = columns.filter((f) => f.field !== 'name');

  return (
    <div className='py-8 h-full'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
        <div className='flex items-center justify-end mb-6'>
          <Link to={`/organizers/create`}>
            <Button variant='contained'>Invite Organizer</Button>
          </Link>
        </div>

        <div className='shadow-xl sm:rounded-lg flex-1'>
          {organizerData && (
            <DataGrid
              rows={organizerData.data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[pageSize]}
              rowCount={organizerData.total}
              paginationMode='server'
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Organizers;
