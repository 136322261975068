import React, { useEffect } from 'react';
import { Guest } from '@/Models/guests';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import useForm from '@/Hooks/useForm';
import { useUpdateGuestMutation } from '@/Api/guestsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { useGetInvitationTypesQuery } from '@/Api/invitationsSlice';

type EditGuestProps = {
  guest: Guest;
  eventId: string;
};

const Edit = ({ open, onDialogClose, guest, eventId }: DialogProps<EditGuestProps>) => {
  const { data, setData, errors, setError, clearErrors } = useForm({
    first_name: guest.first_name,
    last_name: guest.last_name,
    email: guest.email,
  });

  const [updateGuest] = useUpdateGuestMutation();

  function editGuest(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    updateGuest({ eventId, guestId: guest.id, body: data })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors || {});
        }
      });
  }

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='guestEdit' onSubmit={editGuest}>
        <DialogTitle>Edit Guest</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='w-80 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id='first_name'
                  name='first_name'
                  label='First Name'
                  error={!!errors.first_name}
                  helperText={errors.first_name?.[0]}
                  fullWidth
                  value={data.first_name}
                  onChange={(e) => updateData('first_name', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='last_name'
                  name='last_name'
                  label='Last Name'
                  error={!!errors.last_name}
                  helperText={errors.last_name?.[0]}
                  fullWidth
                  value={data.last_name}
                  onChange={(e) => updateData('last_name', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='email'
                  name='email'
                  label='Email'
                  error={!!errors.email}
                  helperText={errors.email?.[0]}
                  fullWidth
                  type='email'
                  value={data.email}
                  onChange={(e) => updateData('email', e?.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <Button type='submit' variant='contained' color='success'>
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Edit;
