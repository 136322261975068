export enum TAG_TYPE {
  ATTENDEES = 'Attendees',
  ARTISTS = 'Artists',
  EVENTARTISTS = 'EventArtists',
  AUTH = 'Auth',
  EVENTS = 'Events',
  TICKETS = 'Tickets',
  INVITATIONS = 'Invitations',
  INVITATION_ASSIGNEES = 'Invitation Assignees',
  ORDER = 'Order',
  GUESTS = 'Guests',
  ORGANIZATIONS = 'Organizations',
  PASSCODE_GROUPS = 'Passcode Groups',
  PASSCODES = 'Passcodes',
  VOUCHER_GROUPS = 'Voucher Groups',
  VOUCHERS = 'Vouchers',
  USERS = 'Users',
  FILES = 'Files',
  ALL_DONATION_CAUSES = 'All Donation Causes',
  EVENT_DONATION_CAUSES = 'Event Donation Causes',
  DONATIONS = 'Donations',
  FEATURED_EVENTS = 'Featured Events',
  TRANSACTIONS = 'Transactions',
  SCAN_GROUPS = 'Scan Groups',
  GENRES = 'Genres',
  EVENT_VENUE = 'Event Venue',
  VENUE_SECTION_DATA = 'Venue Section Data',
  VENUE_SEAT_DATA = 'Venue Seat Data',
  PARKING_SPACES = 'Parking_Spaces',
  WALLET = 'Wallet',
  EVENT_FAQS = 'Event Faqs',
  TAGS = 'Tags',
  FEATURED_ARTICLES = 'Featured Articles',
  EVENT_ONLINE = 'Event Online',
  PARTNERS = 'Partners',
  SERVICES = 'Services',
  EVENT_SERVICES = 'Event Services',
  TRACKING_LINKS = 'Tracking Links',
}
