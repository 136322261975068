import { NAME_REGEX, NAME_REGEX_ERROR_MESSAGE } from '@/Constants/validation';
import * as z from 'zod';

// Create reusable field schema
const fieldSchema = (fieldName: string) =>
  z
    .string()
    .min(1)
    .regex(NAME_REGEX, { message: `${fieldName} ${NAME_REGEX_ERROR_MESSAGE}` });

// Create reusable object schema
const createSchema = (
  type: string,
  fields: {
    location?: z.ZodString;
    transaction_id?: z.ZodString;
    pan?: z.ZodString;
    brand?: z.ZodString;
    order_id?: z.ZodString;
    order_status?: z.ZodString;
    approval_code?: z.ZodString;
    trans_datetime?: z.ZodString;
  },
) => {
  const schemaFields = { type: z.literal(type), ...fields };
  return z.object(schemaFields);
};

const cashSchema = createSchema('Dummy_Gateway', { location: fieldSchema('Location') });
const paypalSchema = createSchema('PayPal_Rest', { transaction_id: fieldSchema('Transaction ID') });
const stripeSchema = createSchema('Stripe', { transaction_id: fieldSchema('Transaction ID') });
const tebSchema = createSchema('Teb', { transaction_id: fieldSchema('Transaction ID') });
const tebDrSchema = createSchema('Teb_DigitalRepublic', {
  transaction_id: fieldSchema('Transaction ID'),
});

const commonFields = {
  pan: fieldSchema('Pan'),
  brand: fieldSchema('Brand'),
  order_id: fieldSchema('Order ID'),
  order_status: fieldSchema('Order Status'),
  approval_code: fieldSchema('Approval Code'),
  trans_datetime: fieldSchema('Transaction Datetime'),
};

const procreditTestSchema = createSchema('Procredit_Test', commonFields);
const procreditSunnyhillSchema = createSchema('Procredit_SunnyHill', commonFields);
const procreditDigitalrepublicSchema = createSchema('Procredit_DigitalRepublic', commonFields);

const paymentTypeSchema = z.discriminatedUnion('type', [
  cashSchema,
  paypalSchema,
  stripeSchema,
  tebSchema,
  tebDrSchema,
  procreditTestSchema,
  procreditSunnyhillSchema,
  procreditDigitalrepublicSchema,
]);

const buyerSchema = z.object({
  first_name: fieldSchema('First Name'),
  last_name: fieldSchema('Last Name'),
  email: z.string().email(),
  email_confirmed: z.string().email(),
});

const ticketHolderSchema = z.object({
  index: z.number().min(0).optional(),
  first_name: fieldSchema('First Name'),
  last_name: fieldSchema('Last Name'),
  email: z.string().email(),
  ticket_id: z.coerce.string().min(1),
});

export const schema = z.object({
  informations_step: z
    .object({
      buyer: buyerSchema,
      ticket_holders: z.array(ticketHolderSchema),
      payment_type: paymentTypeSchema,
    })
    .refine(({ buyer }) => buyer.email === buyer.email_confirmed, {
      message: 'Emails must match',
      path: ['buyer', 'email_confirmed'],
    }),
});

export type CheckoutForm = z.infer<typeof schema>;
