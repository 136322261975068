import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Box,
  CardActionArea,
} from '@mui/material';
import { DateFormats, formatDate } from '@/Utils/dates';
import { Link } from 'react-router-dom';
import { useGetArchivedEventsQuery } from '@/Api/eventsSlice';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';

const Archived = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Archived Events'));
    dispatch(
      setHeader({
        title: 'Archived Events',
      }),
    );
  }, [dispatch]);

  const { data: events } = useGetArchivedEventsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='h-96 sm:rounded-lg grow'>
            {events && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {events.data.map((event) => (
                    <Link
                      key={event.id}
                      to={`/events/${event.id}`}
                      className='hover:scale-[1.005] transition-all'
                    >
                      <Card sx={{ display: 'flex', overflow: 'auto' }}>
                        <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
                          <Stack width='100%' divider={<Divider />}>
                            <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                              <CardMedia
                                component='img'
                                sx={{
                                  width: 120,
                                  height: 120,
                                  borderRadius: 1,
                                  objectFit: 'contain',
                                }}
                                image={event.logo}
                              />

                              <Stack flex={1} maxWidth='600px'>
                                <Typography variant='h6' noWrap>
                                  {event.title}
                                </Typography>

                                <Typography variant='caption' color='text.secondary'>
                                  {formatDate(
                                    event.starts_at,
                                    DateFormats.LONG_DATE,
                                    event.timezone,
                                  )}{' '}
                                  to{' '}
                                  {formatDate(event.ends_at, DateFormats.LONG_DATE, event.timezone)}
                                </Typography>

                                <Typography variant='caption' color='text.secondary' noWrap>
                                  {event.slug}
                                </Typography>
                              </Stack>

                              <Stack maxWidth='280px'>
                                <Typography variant='h6'>Venue</Typography>

                                {event.online_event ? (
                                  <Typography variant='caption' color='text.secondary'>
                                    This is an online event
                                  </Typography>
                                ) : (
                                  <>
                                    <Typography variant='caption' color='text.secondary' noWrap>
                                      {event.venue}
                                    </Typography>

                                    <Typography variant='caption' color='text.secondary'>
                                      {event.city}, {event.zip_code}
                                    </Typography>

                                    <Typography variant='caption' color='text.secondary'>
                                      {event.country}
                                    </Typography>
                                  </>
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        </CardActionArea>
                      </Card>
                    </Link>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={events.current_page}
                    count={events.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Archived;
