import { useGetEventsQuery } from '@/Api/eventsSlice';
import { useGetPasscodeGroupsQuery } from '@/Api/promocodesSlice';
import { Event } from '@/Models/events';
import { ButtonBase, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import React, { PropsWithChildren, RefCallback, useEffect, useState } from 'react';
import { Noop } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface SelectedItem {
  eventId: string;
  passcodeGroupId: string;
}

interface PasscodeGroupSelectorProps {
  name?: string;
  value: SelectedItem;
  ref?: RefCallback<any>;
  onChange: (value: SelectedItem) => void;
  onBlur: Noop;
}

const PasscodeGroupSelector = React.forwardRef(
  ({ value, onChange }: PropsWithChildren<PasscodeGroupSelectorProps>, ref) => {
    const { eventId } = useParams();

    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const [selectedItem, setSelectedItem] = useState<SelectedItem>({
      eventId: '',
      passcodeGroupId: '',
    });

    const { data: upcomingEvents, isFetching: isFetchingEvents } = useGetEventsQuery({
      filters: {
        upcoming: true,
      },
    });

    const { data: passcodeGroups, isFetching: isFetchingPasscodeGroups } =
      useGetPasscodeGroupsQuery(
        {
          eventId: selectedEventId!,
          filterData: {},
        },
        {
          skip: !selectedEventId,
        },
      );

    useEffect(() => {
      setSelectedItem(value);
    }, [value]);

    useEffect(() => {
      if (upcomingEvents) {
        setFilteredEvents(upcomingEvents.data.filter((event) => `${event.id}` !== eventId));
      }
    }, [upcomingEvents]);

    function onPasscodeSelect(passcodeId: string) {
      const item = {
        eventId: selectedEventId!,
        passcodeGroupId: passcodeId,
      };
      setSelectedItem(item);
      onChange(item);
    }

    return (
      <Stack
        ref={ref}
        direction='row'
        divider={<Divider orientation='vertical' />}
        className='h-96 border border-solid overflow-hidden'
        sx={{
          borderColor: 'divider',
        }}
      >
        <Stack className='w-1/2 overflow-y-scroll stylish-scrollbar' divider={<Divider />}>
          {isFetchingEvents && <CircularProgress className='m-auto' />}

          {upcomingEvents &&
            filteredEvents.map((event) => (
              <ButtonBase
                key={event.id}
                className='transition-all duration-200'
                sx={{
                  backgroundColor:
                    selectedItem.eventId === event.id
                      ? 'selectedItem.dark'
                      : selectedEventId === event.id
                      ? 'selectedItem.main'
                      : 'initial',
                }}
                onClick={() => setSelectedEventId(event.id)}
              >
                <Typography className='p-2' noWrap>
                  {event.title}
                </Typography>
              </ButtonBase>
            ))}
        </Stack>

        <Stack className='w-1/2 overflow-y-scroll stylish-scrollbar' divider={<Divider />}>
          {!selectedEventId && (
            <Typography className='p-2 !m-auto' color='textSecondary'>
              Please select an event first
            </Typography>
          )}

          {isFetchingPasscodeGroups && <CircularProgress className='m-auto' />}

          {passcodeGroups &&
            !isFetchingPasscodeGroups &&
            passcodeGroups.data.map((passcodeGroup) => (
              <ButtonBase
                key={passcodeGroup.id}
                className='transition-all duration-200'
                sx={{
                  backgroundColor:
                    selectedItem.passcodeGroupId === passcodeGroup.id
                      ? 'selectedItem.main'
                      : 'initial',
                }}
                onClick={() => onPasscodeSelect(passcodeGroup.id)}
              >
                <Typography className='p-2' noWrap>
                  {passcodeGroup.name}
                </Typography>
              </ButtonBase>
            ))}
        </Stack>
      </Stack>
    );
  },
);

export default PasscodeGroupSelector;
