import { Artist, ArtistData } from '@/Models/artists';
import { internalApi } from './internalApi';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { TAG_TYPE } from '@/Enums/tag-types';
import { getHideSuccessMessageHeader } from '@/Utils/http-client';

export const artistsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getArtists: builder.query<ArtistData, FilterData>({
      query: (filterData) => ({
        url: `/artists`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.ARTISTS],
    }),
    getArtist: builder.query<Artist, string>({
      query: (artistId) => ({
        url: `/artists/${artistId}`,
        method: 'GET',
      }),
    }),
    createArtist: builder.mutation<Artist, any>({
      query: (body) => ({
        url: `/artists`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ARTISTS],
    }),
    updateArtist: builder.mutation<Artist, { artistId: String; body: any }>({
      query: ({ artistId, body }) => ({
        url: `/artists/${artistId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ARTISTS],
    }),
    deleteArtist: builder.mutation<any, string>({
      query: (artistId) => ({
        url: `/artists/${artistId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.ARTISTS],
    }),
  }),
});

export const {
  useGetArtistsQuery,
  useGetArtistQuery,
  useCreateArtistMutation,
  useUpdateArtistMutation,
  useDeleteArtistMutation,
} = artistsApi;
