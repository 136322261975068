import React from 'react';
import { InvitationType } from '@/Models/tickets';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export interface AllocatedInvitationTypeProps {
  invitation: InvitationType;
  simple?: boolean;
}

const AllocatedInvitationType = ({ invitation, simple }: AllocatedInvitationTypeProps) => {
  return (
    <ListItem
      className='flex justify-between my-2 text-gray-600 gap-2'
      sx={{ paddingLeft: simple ? 0.5 : 2 }}
    >
      {!simple && (
        <ListItemText
          className='w-1/2 overflow-auto lg:w-1/2'
          primary={invitation.title}
          style={{ marginTop: 0 }}
          secondary={
            <React.Fragment>
              <div className='flex items-center mt-1'>
                <span className='inline-block w-3 h-3 rounded-full bg-green-500 mr-2'></span>
                <Typography variant='subtitle2'>active</Typography>
              </div>
            </React.Fragment>
          }
        />
      )}

      <div className='w-1/2'>
        <div className='w-full bg-gray-300 h-2 rounded relative'>
          <span
            className='absolute bg-rose-400 h-2 rounded max-w-full'
            style={{ width: `${(invitation.reserved / invitation.allocated) * 100}%` }}
          ></span>
        </div>
        {!simple ? (
          <>
            <div className='flex justify-between text-md mt-1'>
              <span>{invitation.reserved} sent</span>
            </div>
            <div className='flex justify-between text-xs mt-1'>
              <span>{invitation.allocated} allocated</span>
            </div>
          </>
        ) : (
          <>
            <div className='flex justify-between text-sm mt-1'>
              <span>{invitation.reserved} sent</span>
              <span>{invitation.allocated} allocated</span>
            </div>
          </>
        )}
      </div>
    </ListItem>
  );
};

export default AllocatedInvitationType;
