import { getOrganization } from '@/Utils/auth';
import RouteGuard from './RouteGuard';

const OrganizationGuard = () => {
  const hasChosenOrg = !!getOrganization();

  return <RouteGuard isAllowed={hasChosenOrg} redirectPath='choose-organization' />;
};

export default OrganizationGuard;
