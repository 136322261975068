import { createTheme } from '@mui/material';
// import myFont from './fonts';

export const theme = createTheme({
  typography: {
    fontFamily: 'Euclid Square',
  },
  palette: {
    selectedItem: {
      main: '#E8EFF9',
      dark: '#C5DCFE',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 600,
      laptop: 1024,
    },
  },
  // TODO: add colors
});
