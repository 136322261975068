import { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Stack,
  Box,
  Pagination,
  Button,
  TextField,
  IconButton,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useGetPublicEventsQuery } from '@/Api/eventsSlice';
import { useDrop } from 'react-dnd';
import { EventDragCard, FeaturedEventDragCard } from '@/Components/Molecules/Cards/EventDragCard';
import { Event } from '@/Models/events';
import { FeaturedEvents } from '@/Models/featuredEvents';
import {
  useGetFeaturedEventsQuery,
  usePostFeaturedEventsMutation,
} from '@/Api/featuredEventsSlice';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { DeleteOutline, SaveOutlined } from '@mui/icons-material';
import {
  useCreateFaqMutation,
  useDeleteFaqMutation,
  useGetFaqsQuery,
  useUpdateFaqMutation,
} from '@/Api/faqsSlice';

const usePrevious = (value: FeaturedEvents[] | undefined) => {
  const ref = useRef<FeaturedEvents[] | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

interface DropData {
  index: number;
}

interface FeaturedEventData {
  event_id: string;
  event: Event;
  order: number;
}

const Editor = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Editor'));
    dispatch(
      setHeader({
        title: 'Editor',
      }),
    );
  }, []);

  const { data: events } = useGetPublicEventsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );
  const [postFeaturedEvents] = usePostFeaturedEventsMutation();
  const { data: existingFeaturedEvents } = useGetFeaturedEventsQuery({});

  const prevExistingFeaturedEvents = usePrevious(existingFeaturedEvents);

  const [featuredEvents, setFeaturedEvents] = useState<FeaturedEvents[]>([]);
  const [allEvents, setAllEvents] = useState<any>(undefined);

  const [faqs, setFaqs] = useState<Array<{ question: string; answer: string }>>([]);
  const { data: existingFaqs, refetch } = useGetFaqsQuery({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState<any>(null);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setAllEvents(events?.data);
    const featuredEventsHaveChanged =
      prevExistingFeaturedEvents === undefined ||
      existingFeaturedEvents?.length !== prevExistingFeaturedEvents?.length ||
      existingFeaturedEvents?.some((event: any, index: any) => {
        const prevEvent = prevExistingFeaturedEvents[index];
        return event.event_id !== prevEvent.event_id || event.order !== prevEvent.order;
      });

    if (featuredEventsHaveChanged) {
      setFeaturedEvents(
        existingFeaturedEvents?.map((event: any) => ({
          event_id: event.event_id,
          event: event.event,
          order: event.order,
        })),
      );
    }

    setFaqs(
      existingFaqs?.data?.map((faq: any) => ({
        id: faq.id,
        question: faq.question,
        answer: faq.answer,
      })) || [],
    );
  }, [events, existingFeaturedEvents, existingFaqs, prevExistingFeaturedEvents, featuredEvents]);

  const addEventToFeatured = (event: Event, destinationIndex: number) => {
    const eventToAdd = allEvents.find((e: Event) => e.id === event.id);
    if (eventToAdd) {
      if (featuredEvents?.find((featuredEvent) => featuredEvent.event_id === event.id)) return;

      const newFeaturedEvent: FeaturedEventData = {
        event_id: event.id,
        event: eventToAdd,
        order: destinationIndex,
      };

      setFeaturedEvents((prev: FeaturedEvents[]) => {
        const copy = [...prev, newFeaturedEvent];
        copy.sort((a, b) => a.order - b.order);
        return copy.map((featuredEvent, i) => ({ ...featuredEvent, order: i + 1 }));
      });
    }
  };

  const removeFromFeatured = (featureEvent: FeaturedEvents) => {
    setFeaturedEvents((prev: FeaturedEvents[]) =>
      prev.filter((e: FeaturedEvents) => e.event_id !== featureEvent.event_id),
    );
  };

  const dropRef = useRef<HTMLDivElement>(null) as any;

  const [, drop] = useDrop(
    () => ({
      accept: 'event',
      drop: (item: Event, monitor) => {
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) {
          return;
        }

        const dropBoxBounds = dropRef.current.getBoundingClientRect();

        const index = Math.round((clientOffset.y - dropBoxBounds.top) / 100);
        addEventToFeatured(item, index);
      },
    }),
    [allEvents],
  );

  const [createFaq] = useCreateFaqMutation();
  const [updateFaq] = useUpdateFaqMutation();
  const [deleteFaq] = useDeleteFaqMutation();

  const addFaq = () => {
    setFaqs([...faqs, { question: '', answer: '' }]);
  };

  const handleFaqChange = (index: number, field: 'question' | 'answer', value: string) => {
    const newFaqs = [...faqs];
    newFaqs[index][field] = value;
    setFaqs(newFaqs);
  };

  const saveFaq = (faq: any) => {
    if (faq.id) {
      updateFaq({ faqId: faq.id, body: faq })
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      createFaq(faq)
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const removeFaq = (faq: any) => {
    if (faq.id) {
      deleteFaq(faq.id)
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      setFaqs(faqs.filter((f) => f !== faq));
    }
  };

  const openDeleteConfirmation = (faq: any) => {
    setSelectedFaq(faq);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirmation = () => {
    setOpenDeleteDialog(false);
    removeFaq(selectedFaq);
  };

  const handleDeleteCancellation = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className='py-8'>
      <div className='max-w-7xl sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between'>
          <Typography variant='h5'>Events</Typography>
          <div className='w-[45%] flex items-center justify-start'>
            <Typography variant='h5'>Featured Events</Typography>
          </div>
        </div>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }} className='py-4'>
          {allEvents && (
            <Stack
              className='py-4 w-[45%] items-center bg-[rgba(256,256,256,05)] rounded-md'
              spacing={2}
              sx={{ boxShadow: 2 }}
            >
              {allEvents.map((event: Event, index: number) => (
                <EventDragCard
                  key={event.id}
                  event={event}
                  index={index}
                  disabled={
                    !!featuredEvents?.find((featuredEvent) => featuredEvent.event_id === event.id)
                  }
                />
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  page={events?.current_page}
                  count={events?.last_page}
                  showFirstButton
                  showLastButton
                  onChange={(_, page) => onPageChange(page - 1)}
                />
              </Box>
            </Stack>
          )}
          <div ref={drop} className='w-[45%] flex flex-col items-center bg-white rounded-lg '>
            {featuredEvents && (
              <div className='w-full' ref={dropRef}>
                {featuredEvents?.map((featureEvent) => {
                  return (
                    <FeaturedEventDragCard
                      key={featureEvent.event_id}
                      featureEvent={featureEvent}
                      removeFromFeatured={removeFromFeatured}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Stack>
      </div>
      <div className='max-w-7xl sm:px-6 lg:px-8 flex justify-end'>
        <Button
          variant='contained'
          onClick={() => {
            postFeaturedEvents(featuredEvents)
              .unwrap()
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Save
        </Button>
      </div>

      <div className='flex flex-col max-w-7xl sm:px-6 lg:px-8 my-8'>
        <Typography variant='h5'>FAQs</Typography>
        <div className='my-4'>
          {faqs.map((faq, index) => (
            <Paper elevation={2} sx={{ p: 2, my: 2 }} key={index}>
              <Grid container spacing={3} alignItems='center'>
                <Grid item xs={12} sm={5}>
                  <TextField
                    label='Question'
                    value={faq.question}
                    onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                    className='w-full'
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    label='Answer'
                    multiline
                    value={faq.answer}
                    onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                    className='w-full'
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <IconButton component='div' onClick={() => saveFaq(faq)} className='w-auto'>
                    <SaveOutlined color='success' />
                  </IconButton>
                  <IconButton
                    component='div'
                    onClick={() => openDeleteConfirmation(faq)}
                    className='w-auto'
                  >
                    <DeleteOutline color='error' />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
        <div className='flex ml-auto gap-2'>
          <Button variant='contained' onClick={addFaq}>
            Add FAQ
          </Button>
        </div>
      </div>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancellation}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this FAQ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancellation} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Editor;
