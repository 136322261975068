import { Genre, GenreData } from '@/Models/genres';
import { internalApi } from './internalApi';
import { EventGenre, EventGenreData } from '@/Models/eventGenres';
import { TAG_TYPE } from '@/Enums/tag-types';

export const genresApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getGenres: builder.query<GenreData, any>({
      query: () => ({
        url: `/genres`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.GENRES],
    }),
    getEventGenres: builder.query<EventGenreData, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/genres`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.GENRES],
    }),
    updateEventGenres: builder.mutation<void, { eventId: string; genres: string[] }>({
      query: ({ eventId, genres }) => ({
        url: `/events/${eventId}/genres`,
        method: 'PUT',
        body: genres,
      }),
      invalidatesTags: [TAG_TYPE.GENRES],
    }),
    createEventGenres: builder.mutation<
      EventGenre,
      { eventId: string; genreId: string; genreName: string }
    >({
      query: ({ eventId, genreId, genreName }) => ({
        url: `/events/${eventId}/genres`,
        method: 'POST',
        body: {
          event_id: eventId,
          genre_id: genreId,
          name: genreName,
        },
      }),
      invalidatesTags: [TAG_TYPE.GENRES],
    }),
    deleteEventGenre: builder.mutation<void, { eventId: string; genreId: string }>({
      query: ({ eventId, genreId }) => ({
        url: `/events/${eventId}/genres/${genreId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.GENRES],
    }),
  }),
});

export const {
  useGetGenresQuery,
  useGetEventGenresQuery,
  useUpdateEventGenresMutation,
  useCreateEventGenresMutation,
  useDeleteEventGenreMutation,
} = genresApi;
