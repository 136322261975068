import { z } from 'zod';

export enum CustomZodErrorType {
  INPUT_FILE = 'inputFile',
  CSV_ERRORS = 'csvErrors',
}

const messages = {
  required: 'This field is required',
  invalid_date: 'Invalid date',
};

z.setErrorMap((issue, ctx) => {
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.expected === 'date') {
        return { message: messages.invalid_date };
      }
      break;
    case z.ZodIssueCode.too_small:
      if (issue.type === 'string' && issue.minimum === 1) {
        return { message: messages.required };
      }
      break;
  }

  return { message: ctx.defaultError };
});

export function zodOptionalString(
  zStr: z.ZodString,
): z.ZodOptional<z.ZodUnion<[z.ZodString, z.ZodString]>> {
  return z.union([zStr, z.string().length(0)]).optional();
}
