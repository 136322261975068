import { useEffect } from 'react';
import { Typography, Button, TextField, Grid, Paper } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { EventFaqForm, eventFaqFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateEventFaqsMutation,
  useGetEventFaqQuery,
  useUpdateEventFaqMutation,
} from '@/Api/eventFaqsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { cleanObject } from '@/Utils/general';
import useLoading from '@/Hooks/useLoading';
import { HTTP_CODE } from '@/Utils/http-client';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useDispatch } from 'react-redux';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import ReactQuill from 'react-quill';

const CreateOrEditFAQ = () => {
  const { eventId, eventFaqId } = useParams<{ eventId: string; eventFaqId: string }>();
  const [loading, withLoading] = useLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [createEventFaq] = useCreateEventFaqsMutation();
  const [updateEventFaq] = useUpdateEventFaqMutation();

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<EventFaqForm>({
    defaultValues: {
      event_id: eventId || '',
      question: '',
      answer: '',
    },
    resolver: zodResolver(eventFaqFormSchema),
  });

  const { data: eventFaq, error } = useGetEventFaqQuery(
    {
      eventId: eventId!,
      eventFaqId: eventFaqId!,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !eventFaqId,
    },
  );

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  useEffect(() => {
    if (!eventFaq) {
      return;
    }

    reset({
      ...cleanObject(eventFaq),
    });
  }, [eventFaq]);

  function getTitle() {
    return `${!!eventFaqId ? 'Edit' : 'Create'} Event Faq `;
  }

  async function onSubmit() {
    let faqData = { ...getValues() };
    console.log('Sending this data:', faqData);

    const promise = !!eventFaqId
      ? updateEventFaq({ eventId: eventId!, eventFaqId, body: faqData })
      : createEventFaq({ eventId: eventId!, body: faqData });

    withLoading(promise.unwrap())
      .then(() => {
        navigate(`/events/${eventId}/faqs`);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof EventFaqForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  return (
    <div className='flex flex-col max-w-7xl sm:px-6 lg:px-8 my-8'>
      <Typography variant='h5'>FAQs</Typography>
      <Paper elevation={2} sx={{ p: 2, my: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={12}>
              <Controller
                name='question'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    label='Question'
                    variant='outlined'
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' className='pb-2'>
                Answer*
              </Typography>
              <Controller
                name='answer'
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    theme='snow'
                    value={field.value}
                    onChange={(value) => setValue('answer', value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default CreateOrEditFAQ;
