import { z } from 'zod';

export const partnerFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  image: z.union([z.string().min(1), z.instanceof(File)]),
  rank: z.string(),
});

export type PartnerForm = z.infer<typeof partnerFormSchema>;
