import { useState } from 'react';

export default function useLoading(
  initialValue: boolean = false,
): [
  boolean,
  <T>(prms: Promise<T>) => Promise<T>,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const [loading, setLoading] = useState(initialValue);

  return [
    loading,
    function <T>(prms: Promise<T>) {
      setLoading(true);
      return prms.finally(() => setLoading(false));
    },
    setLoading,
  ];
}
