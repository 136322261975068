export const SCOPES = {
  // admin-level
  ManageApplication: 'manage:application',
  ManageOrganizer: 'manage:organizer',
  ManageFeaturedEvent: 'manage:featured-event',

  // users
  ViewUser: 'view:user',
  ManageUser: 'manage:user',

  // events
  ViewEvent: 'view:event',
  ViewEventReport: 'view:event-report',
  CreateEvent: 'create:event',
  UpdateEvent: 'update:event',
  DeleteEvent: 'delete:event',

  // tickets
  ViewTicket: 'view:ticket',
  CreateTicket: 'create:ticket',
  UpdateTicket: 'update:ticket',
  DeleteTicket: 'delete:ticket',

  //online tickets
  CanViewOnlineTicket: 'can-view-online-ticket',
  CanCreateOnlineTicket: 'can-create-online-ticket',
  CanUpdateOnlineTicket: 'can-update-online-ticket',
  CanDeleteOnlineTicket: 'can-delete-online-ticket',

  // attendees
  ViewAttendee: 'view:attendee',
  ManageAttendee: 'manage:attendee',

  // guests
  ViewAllGuest: 'view:all-guest',
  ViewGuest: 'view:guest',
  InviteGuest: 'invite:guest',

  // orders
  ViewOrder: 'view:order',
  ManageOrder: 'manage:order',

  // promocodes
  ViewPromocode: 'view:promocode',
  ViewVoucher: 'view:voucher',
  ManageVoucher: 'manage:voucher',
  ViewPasscode: 'view:passcode',
  ManagePasscode: 'manage:passcode',

  // donations
  ViewDonationCause: 'view:donation-cause',
  ManageDonationCause: 'manage:donation-cause',

  // organization
  ManageOrganization: 'manage:organization',
  AddOrganization: 'add:organization',

  // support order
  SupportOrder: 'create:support-order',
};
