import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Guest } from '@/Models/guests';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { DateFormats, formatDate } from '@/Utils/dates';
import { Event } from '@/Models/events';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import { CheckCircleOutline, EditOutlined, MoreVert, ReplayOutlined } from '@mui/icons-material';
import {
  useCheckInGuestMutation,
  useDownloadSelectedZipLinkMutation,
  useGetGuestsFromEventQuery,
  useResetCheckInGuestMutation,
} from '@/Api/guestsSlice';
import ExportIcon from '@mui/icons-material/SaveAlt';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import environment from '@/environments/environment';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import Edit from '../Invites/Edit';
import { HTTP_CODE } from '@/Utils/http-client';
import { showSnackbar } from '@/Slices/snackbarSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

const GuestsList = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [checkInDialogOpen, setCheckInDialogOpen] = useState(false);
  const [resetCheckInDialogOpen, setResetCheckInDialogOpen] = useState(false);

  const advancedFetch = useAdvancedFetch();

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  const [page, setPage] = useState(0);
  const [checkboxSelection, setCheckboxSelection] = React.useState(true);
  const [pageSize, setPageSize] = React.useState<number>(20);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);
  const [search, setSearch] = useState('');
  const [selectedGuestIds, setSelectedGuestIds] = useState<any[]>([]);

  const { data: guestData, refetch } = useGetGuestsFromEventQuery(
    {
      eventId: eventId!,
      filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  const [downloadZipLink] = useDownloadSelectedZipLinkMutation();

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    guest: Guest;
  }>(null);

  function onPageChange(page: number) {
    setPage(page);
  }

  const requestSearch = (input: React.SetStateAction<string>) => {
    setSearch(input);
  };

  function showActionMenu(guest: Guest, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, guest });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  function onEditDialogClose(value: boolean) {
    setEditDialogOpen(false);

    if (!value) {
      return;
    }
  }

  const columns: GridColDef[] = [
    { field: 'first_name', headerName: 'First name' },
    {
      field: 'last_name',
      headerName: 'Last name',
      width: 130,
    },
    { field: 'email', headerName: 'Email', width: 230 },
    {
      field: 'invitationType',
      headerName: 'Invitation Title',
      valueGetter: ({ row }) => row.invitation_type.title,
      flex: 1,
      sortable: false,
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 130,
      renderCell: (params) => {
        return (
          <span
            className='underline text-blue-600 cursor-pointer'
            onClick={() => {
              onTicketClick(event!, params.value);
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'arrived',
      headerName: 'Arrived',
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <div
              className='w-2 h-2 rounded-full'
              style={{
                background: row.arrived ? 'rgb(50 225 121)' : 'rgba(0, 0, 0, 0.47)',
              }}
            ></div>
            <span>{row.arrived ? 'Yes' : 'No'}</span>
          </Stack>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'arrived_at',
      headerName: 'Arrived at',
      renderCell: ({ row }) => (
        <EmptyCell condition={row.arrived}>
          <div>{formatDate(row.arrived_at)}</div>
        </EmptyCell>
      ),
      width: 220,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => {
        return (
          <BeautifulChip color={getStatusColor(row.status)}>
            {humanizeAttendeeStatus(row.status)}
          </BeautifulChip>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellClassName: 'sticky-actions-column',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManageAttendee}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenu(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  function onExportClick(event: Event) {
    advancedFetch(`${environment.baseUrl}/events/${event.id}/guests/export`, {
      method: 'GET',
    }).then((response) => {
      handleBlobReponse(response, {
        handleMethod: 'download',
        fileName: `${event.title}-guests.csv`,
      });
    });
  }

  function onTicketClick(event: Event, barcode: string) {
    advancedFetch(`${environment.baseUrl}/events/${event.id}/guests/${barcode}/ticket?download=1`, {
      method: 'GET',
    }).then((response) => {
      handleBlobReponse(response);
    });
  }

  const [checkIn, { isLoading: loadingCheckIn }] = useCheckInGuestMutation();
  const confirmCheckIn = () => {
    if (selectedGuest) {
      checkIn({
        eventId: eventId!,
        guestId: selectedGuest.id,
      })
        .unwrap()
        .then(() => {
          setCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const [resetCheckIn, { isLoading: loadingResetCheckIn }] = useResetCheckInGuestMutation();
  const confirmResetCheckIn = () => {
    if (selectedGuest) {
      resetCheckIn({
        eventId: eventId!,
        guestId: selectedGuest.id,
      })
        .unwrap()
        .then(() => {
          setResetCheckInDialogOpen(false);
          refetch();
        })
        .catch((error: any) => {
          if (error.status === HTTP_CODE.BAD_REQUEST) {
            dispatch(
              showSnackbar({
                message: error.data?.message,
                severity: 'error',
              }),
            );
          } else if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
            console.log(error.data?.errors);
          }
        });
    }
  };

  const downloadZIP = (guest: any) => {
    downloadZipLink({ eventId: event?.id!!, body: guest })
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            message:
              "The ZIP generation has started. We will notify you with email when it's ready and you can download it there",
            severity: 'info',
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownloadSelectedZip = () => {
    if (selectedGuestIds.length === 0) {
      dispatch(
        showSnackbar({
          message: 'No guests selected',
          severity: 'warning',
        }),
      );
      return;
    }

    const selectedGuests = { guest_ids: selectedGuestIds };

    downloadZIP(selectedGuests);
  };

  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <div className='h-full'>
            <div className='mx-auto h-full flex flex-col'>
              <div className=' mb-4'>
                <Typography>Options:</Typography>
                <div className='flex flex-col justify-between lg:flex lg:flex-row'>
                  <Button
                    onClick={() => setCheckboxSelection(!checkboxSelection)}
                    startIcon={<CompareArrowsIcon />}
                  >
                    Toggle checkbox selection
                  </Button>
                  <Stack direction={{ xs: 'column', lg: 'row' }} className='lg:space-x-2'>
                    <Button
                      onClick={handleDownloadSelectedZip}
                      variant='contained'
                      startIcon={<ExportIcon />}
                      sx={{ marginY: { sm: 2, lg: 0 } }}
                    >
                      Download Selected as ZIP
                    </Button>
                    <Button
                      onClick={() => {
                        onExportClick(event);
                      }}
                      variant='contained'
                      startIcon={<ExportIcon />}
                    >
                      Export All
                    </Button>
                  </Stack>
                </div>
              </div>
              <div className='sm:rounded-lg grow'>
                {guestData ? (
                  <DataGrid
                    autoHeight
                    rows={guestData.data}
                    columns={columns}
                    paginationMode='server'
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    onPageChange={onPageChange}
                    rowCount={guestData.total}
                    checkboxSelection={checkboxSelection}
                    onSelectionModelChange={(newSelection) => setSelectedGuestIds(newSelection)}
                    components={{ Toolbar: GridToolbar }}
                    filterMode='server'
                    onFilterModelChange={(event: any) => requestSearch(event.quickFilterValues[0])}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 1000 },
                      },
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            {editDialogOpen && selectedGuest ? (
              <Edit
                eventId={event.id}
                guest={selectedGuest}
                open={editDialogOpen}
                onDialogClose={(value) => onEditDialogClose(value)}
              />
            ) : (
              ''
            )}

            {checkInDialogOpen && selectedGuest ? (
              <Dialog
                open={checkInDialogOpen}
                aria-labelledby='check-in-dialog-title'
                aria-describedby='check-in-dialog-description'
              >
                <DialogTitle id='check-in-dialog-title'>
                  Are you sure you want to check-in this guest?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='check-in-dialog-description'>
                    Keep in mind that this action could affect the on-site check-in process. Please
                    be sure that you want to make this action.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setCheckInDialogOpen(false)} color='success'>
                    Close
                  </Button>
                  <LoadingButton loading={loadingCheckIn}>
                    <Button onClick={confirmCheckIn} disabled={loadingCheckIn} color='error'>
                      Confirm Check-in
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {resetCheckInDialogOpen && selectedGuest ? (
              <Dialog
                open={resetCheckInDialogOpen}
                aria-labelledby='reset-check-in-dialog-title'
                aria-describedby='reset-check-in-dialog-description'
              >
                <DialogTitle id='reset-check-in-dialog-title'>
                  Are you sure you want to reset check-in for this guest?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='reset-check-in-dialog-description'>
                    Keep in mind that this action could affect the on-site check-in process. Please
                    be sure that you want to make this action.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setResetCheckInDialogOpen(false)} color='success'>
                    Close
                  </Button>
                  <LoadingButton loading={loadingCheckIn}>
                    <Button
                      onClick={confirmResetCheckIn}
                      disabled={loadingResetCheckIn}
                      color='error'
                    >
                      Confirm Reset Check-in
                    </Button>
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )}

            {menuContext && (
              <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
                <MenuItem
                  onClick={() => {
                    setEditDialogOpen(true);
                    setSelectedGuest(menuContext.guest);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <EditOutlined />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setCheckInDialogOpen(true);
                    setSelectedGuest(menuContext.guest);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText>Check-in</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setResetCheckInDialogOpen(true);
                    setSelectedGuest(menuContext.guest);
                    hideActionMenu();
                  }}
                >
                  <ListItemIcon>
                    <ReplayOutlined color='error' />
                  </ListItemIcon>
                  <ListItemText>Reset check-in</ListItemText>
                </MenuItem>
              </Menu>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GuestsList;
