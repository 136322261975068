import { useGetTicketsQuery, useGetSupportOrderTicketsQuery } from '@/Api/ticketsSlice';
import { Box, Grid, Stack, Button, Slide, Paper } from '@mui/material';
import { AffiliateTicketCard } from '@/Components/Molecules/Cards/AffiliateTicketCard';
import { Link } from 'react-router-dom';
import { Ticket } from '@/Models/tickets';
import { Typography } from '@mui/material';
import { DateFormats, formatDate } from '@/Utils/dates';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useLoading from '@/Hooks/useLoading';
import { EventSeatOutlined, ShoppingCartCheckout } from '@mui/icons-material';
import { FSTXLocalStorage } from '@/Utils/local-storage';
import { useNavigate, useLocation } from 'react-router-dom';
import { DataKey, LocalTransactionData } from '@/Models/local-storage';
import Dinero from 'dinero.js';
import {
  useCreateTransactionMutation,
  useCancelTransactionMutation,
} from '@/Api/transactionsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { useResponsive } from '@/Hooks/useResponsive';
import { formatPrice } from '@/Utils/currency';

const SupportOrderIndex = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const { data: event } = useGetEventQuery(eventId!, {
    refetchOnMountOrArgChange: true,
  });
  const [ticketOrders, setTicketOrders] = useState<{ [key: string]: number }>({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, withLoading] = useLoading();
  const [filteredTicketOrders, setFilteredTicketOrders] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  const { data: tickets } = useGetSupportOrderTicketsQuery(
    { eventId: eventId! },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (tickets) {
      setTicketOrders(
        tickets.reduce((acc, val) => {
          return { ...acc, [val.id]: 0 };
        }, {}),
      );
    }
  }, [tickets]);

  const canCheckout = Object.values(ticketOrders).some((v) => !!v);

  function handleBookingFee(event: any, ticket: any, acc: any) {
    if (event.booking_fee !== 0) {
      if (event.booking_fee_type === 'percentage') {
        const bookingFeePercentage = ((event.booking_fee / 100) * ticket.price) / 100;
        const twoDigitsAfterPoint = Number(bookingFeePercentage.toFixed(2));
        const bookingFee = twoDigitsAfterPoint * 100;
        return bookingFee;
      }

      return event.booking_fee * 100;
    }

    return 0;
  }

  useEffect(() => {
    if (!tickets || !event) return;

    let total = Object.entries(ticketOrders).reduce((acc, [ticketId, quantity]) => {
      const ticket = tickets.find((ticket) => ticket.id == ticketId);
      const bookingFee = handleBookingFee(event, ticket, acc);
      const totalTicketPrice = ticket ? ticket.price + bookingFee : 0;

      return acc.add(
        Dinero({
          amount: totalTicketPrice,
          currency: event.currency,
        }).multiply(quantity),
      );
    }, Dinero({ amount: 0, currency: event.currency }));
    setTotalPrice(total.getAmount());
  }, [ticketOrders, tickets, event]);

  useEffect(() => {
    const newTicketOrders = {} as any;
    if (ticketOrders) {
      Object.keys(ticketOrders).forEach((key) => {
        const value = ticketOrders[key];
        if (value > 0) {
          newTicketOrders[key] = value;
        }
      });
    }
    setFilteredTicketOrders(newTicketOrders);
  }, [ticketOrders]);

  const [createNewTransaction] = useCreateTransactionMutation();
  const navigate = useNavigate();

  const createOrder = () => {
    cancelCurrentTransactionIfExists();

    if (event?.seating_enabled) {
      createTransaction();
    } else {
      let ticketData = Object.entries(ticketOrders)
        .filter(([_, quantity]) => !!quantity)
        .reduce((acc, [ticketId, quantity]) => {
          return { ...acc, [ticketId]: quantity };
        }, {});

      if (ticketData) {
        createTransaction(ticketData);
      }
    }
  };

  const createTransaction = (ticketData?: any) => {
    createNewTransaction({ eventId: eventId!, body: { tickets: ticketData } })
      .unwrap()
      .then((data) => {
        FSTXLocalStorage.setItem(DataKey.TRANSACTION, {
          eventId: eventId!,
          transactionId: data.transaction_id,
          expiresAt: data.expires_at,
          totalPrice,
          tickets: event?.seating_enabled ? {} : filteredTicketOrders,
        });

        if (event?.seating_enabled) {
          navigate(`/events/${eventId}/support-order/seats`);
        } else {
          navigate(`/events/${eventId}/support-order/checkout`);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const [cancelTransaction] = useCancelTransactionMutation();

  const cancelCurrentTransactionIfExists = () => {
    const currentTransaction = FSTXLocalStorage.getItem(DataKey.TRANSACTION);

    if (!currentTransaction) {
      return;
    }

    cancelTransaction({ eventId: eventId!, transactionId: currentTransaction.transactionId })
      .unwrap()
      .then(() => {
        FSTXLocalStorage.removeItem(DataKey.TRANSACTION);
        navigate(`/events/${eventId}/support-order/checkout`);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const { isMobile } = useResponsive();
  const container = isMobile ? false : true;

  if (!event) return <></>;

  return (
    <>
      {event.seating_enabled ? (
        <div className='max-w-7xl mx-auto p-16' style={{ width: '100%', height: '100%' }}>
          <LoadingButton loading={loading}>
            <Button
              variant='contained'
              disabled={loading}
              className='p-4 flex-row font-mono'
              onClick={createOrder}
            >
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                justifyContent='flex-end'
                sx={{ flexGrow: 1, marginRight: '1rem' }}
              >
                <EventSeatOutlined />
                <Typography sx={{ marginRight: '2rem' }}>Select Seats</Typography>
              </Stack>
            </Button>
          </LoadingButton>
        </div>
      ) : (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <Stack spacing={2} sx={{ pb: 4, display: 'flex', flexDirection: 'row' }}>
            <Grid container={container} spacing={4} style={{ marginLeft: '-1rem', flex: '1' }}>
              {!!tickets?.length &&
                tickets.map((ticket: Ticket) => (
                  <Grid item key={ticket.id} xs={4}>
                    <div className='mt-4 lg:mt-0'>
                      <AffiliateTicketCard
                        ticket={ticket}
                        event={event}
                        currentTicketOrders={ticketOrders[ticket.id]}
                        onReservationChange={(quantity) => {
                          setTicketOrders({
                            ...ticketOrders,
                            [ticket.id]: quantity,
                          });
                        }}
                        onPurchaseClick={() => {
                          setTicketOrders({
                            [ticket.id]: ticket.group_ticket_size,
                          });
                        }}
                      />
                    </div>
                  </Grid>
                ))}

              {!tickets?.length && (
                <Stack className='mt-24 ml-24' alignItems='center'>
                  <Typography variant='h6' color='black' className='uppercase tracking-[6px]'>
                    Coming soon
                  </Typography>
                </Stack>
              )}

              <Slide direction='up' in={canCheckout} mountOnEnter unmountOnExit>
                <Paper className='h-[88px] w-full flex shadow-inner items-center justify-center z-[999] rounded-none fixed lg:bottom-0 bottom-[70px] left-0'>
                  <LoadingButton loading={loading}>
                    <Button
                      variant='contained'
                      disabled={!canCheckout || loading}
                      className='w-full p-4 flex-row font-mono'
                      onClick={createOrder}
                    >
                      <Stack
                        direction='row'
                        spacing={1}
                        alignItems='center'
                        justifyContent='flex-end'
                        sx={{ flexGrow: 1, marginRight: '1rem' }}
                      >
                        <ShoppingCartCheckout />
                        <Typography sx={{ marginRight: '2rem' }}>Checkout</Typography>
                      </Stack>
                      <Typography sx={{ paddingLeft: '1rem' }}>{`${formatPrice(
                        totalPrice,
                        event?.currency,
                      )}`}</Typography>
                    </Button>
                  </LoadingButton>
                </Paper>
              </Slide>
            </Grid>
          </Stack>
        </div>
      )}
    </>
  );
};

export default SupportOrderIndex;
