import { QueryResult } from '@/Models/redux';
import { showSnackbar } from '@/Slices/snackbarSlice';
import { FSTX_HEADER, getSuccessMessageBasedOnMethod } from '@/Utils/http-client';
import { RTKInterceptor } from './types';

const successSnackbarInterceptor: RTKInterceptor = async (next, args, api, extraOptions) => {
  const result = await next(args, api, extraOptions);

  if (!!result.error) {
    return result;
  }

  if (shouldHideSuccessMessage(result)) {
    return result;
  }

  const request = result.meta?.request;
  if (!!request && request.method.toLowerCase() !== 'get') {
    api.dispatch(
      showSnackbar({
        message: getSuccessMessageBasedOnMethod(request.method),
        severity: 'success',
      }),
    );
  }

  return result;
};

function shouldHideSuccessMessage(response: QueryResult) {
  return (
    response.meta?.response?.headers.get(FSTX_HEADER.HIDE_SUCCESS_MESSAGES) ||
    response.meta?.response?.headers.get(FSTX_HEADER.HIDE_MESSAGES)
  );
}

export default successSnackbarInterceptor;
