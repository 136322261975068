import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Box,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGetPartnersQuery, useDeletePartnerMutation } from '@/Api/partnersSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';

const Index = () => {
  const navigate = useNavigate();

  const [partnerToDelete, setPartnerToDelete] = useState<string>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Partners'));
    dispatch(
      setHeader({
        title: 'Partners',
      }),
    );
  }, []);

  const { data: partners, refetch } = useGetPartnersQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  const [deletePartner] = useDeletePartnerMutation();
  const [selectedPartner, setSelectedPartner] = useState<any>(null);

  const openDeleteConfirmation = (partner: any) => {
    setSelectedPartner(partner);
    setPartnerToDelete(partner.id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirmation = () => {
    setOpenDeleteDialog(false);
    if (partnerToDelete) {
      deletePartner(partnerToDelete).then(() => {
        refetch();
      });
    }
  };

  const handleDeleteCancellation = () => {
    setOpenDeleteDialog(false);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditClick = (
    $mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
    partnerId: string,
  ) => {
    $mouseEvent.preventDefault();
    navigate(`/partners/${partnerId}/edit`);
  };
  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <Link to={`/partners/create`}>
              <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                Create Partner
              </Button>
            </Link>
          </div>
          <div className='h-96 sm:rounded-lg grow'>
            {/* TODO front: add empty state when there are no results */}
            {partners && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {partners?.data?.map((partner) => (
                    <Card sx={{ display: 'flex', overflow: 'auto' }}>
                      <CardActionArea
                        sx={{ display: 'flex', justifyContent: 'unset', cursor: 'default' }}
                      >
                        <Stack width='100%' divider={<Divider />}>
                          <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                            <CardMedia
                              component='img'
                              sx={{
                                width: 120,
                                height: 120,
                                borderRadius: 1,
                                objectFit: 'contain',
                              }}
                              image={partner.image}
                            />
                            <Stack flex={1} maxWidth='600px'>
                              <Typography variant='h6' noWrap>
                                {partner.name}
                              </Typography>
                              <Typography>
                                {partner.description
                                  ? partner.description.replace(/<\/?[^>]+(>|$)/g, '')
                                  : ''}
                              </Typography>
                            </Stack>
                            <BeautifulChip>{partner.rank}</BeautifulChip>
                            <Box className='flex flex-row justify-center !ml-auto'>
                              <IconButton
                                sx={{ margin: 'auto 0' }}
                                component='div'
                                onClick={($mouseEvent) => onEditClick($mouseEvent, partner.id)}
                              >
                                <EditOutlined />
                              </IconButton>
                              <IconButton
                                component='div'
                                onClick={() => openDeleteConfirmation(partner)}
                                sx={{ margin: 'auto 0' }}
                              >
                                <DeleteOutline color='error' />
                              </IconButton>
                            </Box>
                          </Stack>
                        </Stack>
                      </CardActionArea>
                    </Card>
                  ))}
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={partners.current_page}
                    count={partners.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancellation}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Partner?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancellation} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
