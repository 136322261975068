import EmptyContent from '@/Components/Organisms/Util/EmptyContent';
import { AccessTime, Event } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

// TODO: add option to recreate transaction with same data if possible
interface TimeExpiredProps {
  eventId: string;
}

const TimeExpired = ({ eventId }: TimeExpiredProps) => {
  return (
    <EmptyContent
      icon={<AccessTime className='w-12 h-12' />}
      primaryText='Session expired'
      secondaryText='This transaction has expired. Please try again.'
      button={
        <Link to={`/events/${eventId}/support-order`}>
          <Button variant='contained' startIcon={<Event />}>
            Go back
          </Button>
        </Link>
      }
    />
  );
};

export default TimeExpired;
