import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import React, { useContext, useEffect, useState } from 'react';
import { Typography, Divider, Stack, IconButton } from '@mui/material';
import { Event } from '@/Models/events';
import { Ticket } from '@/Models/tickets';
import { groupBy } from '@/Utils/general';
import { theme } from '@/Utils/theme';
import { formatPrice } from '@/Utils/currency';
import { RemoveCircleOutline } from '@mui/icons-material';
import { buildSeatDescription } from '@/Utils/seating';

interface SelectedSeatsInfoProps {
  event: Event;
  tickets: Ticket[];
  transactionData?: any;
}

interface SeatGroupsData {
  ticketsTotal: number;
  seatGroups: {
    ticket: Ticket;
    groupPrice: number;
    seats: {
      id: string;
      section_label?: string;
      group_label?: string;
      seat_number?: number;
    }[];
  }[];
}

function SelectedSeatsInfo({ event, tickets, transactionData }: SelectedSeatsInfoProps) {
  const { venueEditorService, sectionIdentifierService, seatIdentifierService } =
    useContext(VenueEditorContext);
  const [selectedSeatIds, setSelectedSeatIds] = useState<string[]>([]);
  const [seatGroupsData, setSeatGroupsData] = useState<SeatGroupsData | null>(null);

  useEffect(() => {
    const sub = venueEditorService.getSelectedElementsIds$().subscribe((ids) => {
      setSelectedSeatIds(ids);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!selectedSeatIds.length) {
      setSeatGroupsData(null);
      return;
    }

    const seatGroupsData: SeatGroupsData = {
      seatGroups: [],
      ticketsTotal: 0,
    };

    const selectedSeatsLabellingData = selectedSeatIds.map((id) => {
      const seatsLabellingData = seatIdentifierService.getSeatData();
      return {
        id,
        ...seatsLabellingData[id],
      };
    });

    const sectionLabellingData = sectionIdentifierService.getSectionData();

    const groupedSeats = groupBy(selectedSeatsLabellingData, (seat) => seat.ticket_id!);

    Object.keys(groupedSeats).forEach((ticketId) => {
      const ticket = tickets.find((t) => t.id === ticketId);

      if (!ticket) {
        return;
      }

      const seats = groupedSeats[ticketId].map((seat) => ({
        id: seat.id,
        section_label: sectionLabellingData[seat.section_id!].label,
        group_label: seat.group_label,
        seat_number: seat.seat_number,
      }));

      seatGroupsData.seatGroups.push({
        ticket,
        groupPrice: ticket.price * groupedSeats[ticketId].length,
        seats,
      });
    });

    const ticketsTotal = seatGroupsData.seatGroups.reduce(
      (acc, seatGroup) => acc + seatGroup.groupPrice,
      0,
    );

    seatGroupsData.ticketsTotal = ticketsTotal;

    setSeatGroupsData(seatGroupsData);
  }, [selectedSeatIds]);

  function removeSeat(seatId: string) {
    venueEditorService.removeSeat(seatId);
  }

  return (
    <div className='py-4 lg:p-4 h-full overflow-auto'>
      <Stack className='pb-4' alignItems='center'>
        <Typography variant='h6'>Selected Seats Information</Typography>
      </Stack>

      <Divider />

      {!selectedSeatIds.length && (
        <Stack alignItems='center' justifyContent='center'>
          <Typography variant='body1' className='pt-4'>
            No seats selected
          </Typography>
        </Stack>
      )}

      {!!selectedSeatIds.length && seatGroupsData && (
        <Stack spacing={2} className='pt-4'>
          <Stack spacing={1} divider={<Divider />}>
            {seatGroupsData.seatGroups.map(({ ticket, groupPrice, seats }) => {
              return (
                <Stack key={ticket.id} direction='column' spacing={0}>
                  <Stack direction='row'>
                    <Typography color='secondary.main' variant='body1' className='w-6'>
                      {seats.length}x
                    </Typography>

                    {transactionData ? (
                      <>
                        <Typography className='flex-1 ml-4' variant='body1'>
                          {ticket.title}
                        </Typography>

                        <Typography>{formatPrice(groupPrice, event.currency)}</Typography>
                      </>
                    ) : (
                      <Typography className='flex-1 ml-4' variant='body1'>
                        {ticket.title} [Invitation]
                      </Typography>
                    )}
                  </Stack>

                  <Stack spacing={0}>
                    {seats.map(({ id, section_label, group_label, seat_number }) => {
                      const seatDesc = buildSeatDescription(
                        section_label,
                        group_label,
                        seat_number,
                      );

                      return (
                        <Stack
                          key={id}
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Typography variant='subtitle1' color='info.main'>
                            {seatDesc}
                          </Typography>

                          <IconButton size='small' color='error' onClick={() => removeSeat(id)}>
                            <RemoveCircleOutline />
                          </IconButton>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>

          {transactionData && (
            <>
              <div
                className='border-t-2'
                style={{
                  borderColor: theme.palette.secondary.main,
                  borderTopStyle: 'dashed',
                }}
              ></div>

              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                className='max-lg:pb-8'
              >
                <Typography variant='h6'>Tickets total</Typography>
                <Stack>
                  <Typography variant='h6' className='py-2'>
                    {formatPrice(seatGroupsData.ticketsTotal, event.currency)}
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </div>
  );
}

export default SelectedSeatsInfo;
