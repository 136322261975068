import {
  DashboardOutlined,
  GroupOutlined,
  BookOnline,
  ListAltOutlined,
  Event,
  PersonOutline,
  ConfirmationNumber,
  VolunteerActivism,
  HandshakeOutlined,
  PeopleAltOutlined,
  Article,
  Insights,
  LibraryMusicOutlined,
  LibraryMusic,
  CorporateFareOutlined,
  PaidOutlined,
  QrCodeScannerOutlined,
  Stadium,
  EventOutlined,
  CategoryOutlined,
} from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { SCOPES } from './permission-map';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PaidIcon from '@mui/icons-material/Paid';
import WalletIcon from '@mui/icons-material/Wallet';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import QuizIcon from '@mui/icons-material/Quiz';
import AssignmentIcon from '@mui/icons-material/Assignment';

export interface SideBarItem {
  title: string;
  route: string;
  icon: JSX.Element;
  permission?: string;
}

export const adminNavItems: SideBarItem[] = [
  {
    icon: <DashboardOutlined />,
    title: 'Dashboard',
    route: 'admin-dashboard',
    permission: SCOPES.ManageApplication,
  },
  {
    title: 'Organizers',
    icon: <GroupOutlined />,
    route: 'organizers',
    permission: SCOPES.ManageOrganizer,
  },
  {
    title: 'Organizations',
    icon: <CorporateFareOutlined />,
    route: 'organizations',
    permission: SCOPES.ManageOrganizer,
  },
  {
    title: 'Artists',
    icon: <LibraryMusicOutlined />,
    route: 'Artists',
    permission: SCOPES.ManageOrganization,
  },
  {
    title: 'Editor',
    icon: <Event />,
    route: 'editor',
    permission: SCOPES.ManageFeaturedEvent,
  },
  {
    title: 'Articles',
    icon: <Article />,
    route: 'articles',
    permission: SCOPES.ManageApplication,
  },
  {
    title: 'Partners',
    icon: <HandshakeOutlined />,
    route: 'partners',
    permission: SCOPES.ManageApplication,
  },
  {
    title: 'Featured Articles',
    icon: <AssignmentIcon />,
    route: 'featured-articles',
    permission: SCOPES.ManageApplication,
  },
  {
    title: 'All Orders',
    icon: <AssessmentIcon />,
    route: 'all-orders',
    permission: SCOPES.ManageApplication,
  },
];

export const sidebarNavItems: SideBarItem[] = [
  {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    route: 'events',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'Archived Events',
    icon: <EventOutlined />,
    route: 'archived-events',
    permission: SCOPES.CreateEvent,
  },
  {
    title: 'Users',
    icon: <GroupOutlined />,
    route: 'users',
    permission: SCOPES.ViewUser,
  },
  {
    title: 'Fundraising',
    icon: <VolunteerActivism />,
    route: 'donation-causes',
    permission: SCOPES.ViewDonationCause,
  },
  {
    title: 'Services',
    icon: <CategoryOutlined />,
    route: 'services',
    permission: SCOPES.UpdateEvent,
  },
  {
    title: 'Insights',
    icon: <Insights />,
    route: 'insights',
    permission: SCOPES.UpdateEvent,
  },
  {
    title: 'Scan Groups',
    icon: <QrCodeScannerOutlined />,
    route: 'scan-groups',
    permission: SCOPES.ManageOrganization,
  },
];

export const eventNavItems: SideBarItem[] = [
  {
    title: 'General',
    icon: <Event />,
    route: '',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'Tickets',
    icon: <BookOnline />,
    route: 'tickets',
    permission: SCOPES.UpdateTicket,
  },
  {
    title: 'Orders',
    icon: <ListAltOutlined />,
    route: 'orders',
    permission: SCOPES.ViewOrder,
  },
  {
    title: 'Attendees',
    icon: <PersonOutline />,
    route: 'attendees',
    permission: SCOPES.ViewAttendee,
  },
  {
    title: 'Guests',
    icon: <PeopleAltOutlined />,
    route: 'guests',
    permission: SCOPES.ViewGuest,
  },
  {
    title: 'Artists',
    icon: <LibraryMusic />,
    route: 'artists',
    permission: SCOPES.UpdateEvent,
  },
  {
    title: 'FAQ',
    icon: <QuizIcon />,
    route: 'faqs',
    permission: SCOPES.UpdateEvent,
  },
];

export const cashlessNavItems: SideBarItem[] = [
  {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    route: '',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'Transactions',
    icon: <PaidIcon />,
    route: 'transactions',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'Wallets',
    icon: <WalletIcon />,
    route: 'wallets',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'TopUp',
    icon: <AddLocationIcon />,
    route: 'topup',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'Bracelets',
    icon: <PanToolIcon />,
    route: 'bracelets',
    permission: SCOPES.ViewEvent,
  },
  {
    title: 'Refunds',
    icon: <RestartAltIcon />,
    route: 'refunds',
    permission: SCOPES.ViewEvent,
  },
];

export const invitationItem: SideBarItem = {
  title: 'Invitations',
  icon: <GroupOutlined />,
  route: 'invitations',
  permission: SCOPES.ViewGuest,
};

export const promocodesItem: SideBarItem = {
  title: 'Promocodes',
  icon: <ConfirmationNumber />,
  route: 'promocodes',
  permission: SCOPES.ManagePasscode,
};

export const ticketCodes: SideBarItem = {
  title: 'Ticket Codes',
  icon: <ConfirmationNumber />,
  route: 'ticket-codes',
  permission: SCOPES.ManagePasscode,
};

export const supportOrders: SideBarItem = {
  title: 'Support Orders',
  icon: <HandshakeOutlined />,
  route: 'support-order',
  permission: SCOPES.SupportOrder,
};

export const donationsItem: SideBarItem = {
  title: 'Donations',
  icon: <VolunteerActivism />,
  route: 'donations',
  permission: SCOPES.ViewDonationCause,
};

export const servicesItem: SideBarItem = {
  title: 'Services',
  icon: <CategoryOutlined />,
  route: 'services',
  permission: SCOPES.UpdateEvent,
};

export const parkingSpacesItem: SideBarItem = {
  title: 'Parking Spaces',
  icon: <LocalParkingIcon />,
  route: 'parking-spaces',
  permission: SCOPES.UpdateEvent,
};

export const posItem: SideBarItem = {
  title: 'POS',
  icon: <PointOfSaleIcon />,
  route: 'pos',
  permission: SCOPES.ManageOrganization,
};

export const venueItem: SideBarItem = {
  title: 'Venue',
  icon: <Stadium />,
  route: 'venues',
  permission: SCOPES.ViewEvent,
};
