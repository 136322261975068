import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ScanGroupForm, scanGroupFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect } from 'react';
import {
  useCreateScanGroupMutation,
  useGetScanGroupQuery,
  useUpdateScanGroupMutation,
} from '@/Api/scanGroupsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import useLoading from '@/Hooks/useLoading';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { useGetEventsQuery } from '@/Api/eventsSlice';
import { Event } from '@/Models/events';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';

const CreateOrEdit = () => {
  const { scanGroupId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [createScanGroup] = useCreateScanGroupMutation();
  const [updateScanGroup] = useUpdateScanGroupMutation();

  const { data: scanGroup } = useGetScanGroupQuery(scanGroupId!, {
    refetchOnMountOrArgChange: false,
    skip: !scanGroupId,
  });

  const { data: events } = useGetEventsQuery(
    { pageData: { page: 0, pageSize: 1000 } },
    { refetchOnMountOrArgChange: true },
  );

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<ScanGroupForm>({
    defaultValues: {
      name: '',
      events: [],
      offline: false,
      visible: false,
    },
    resolver: zodResolver(scanGroupFormSchema),
  });

  useEffect(() => {
    if (!scanGroup) {
      return;
    }

    setValue('name', scanGroup.name);
    setValue('offline', scanGroup.offline);
    setValue('visible', scanGroup.visible);
    setValue(
      'events',
      scanGroup.events.map((event: any) => event.id),
    );
  }, [scanGroup]);

  function getTitle() {
    return `${!!scanGroupId ? 'Edit' : 'Create'} Scan Group`;
  }

  async function onSubmit() {
    let scanGroupData = { ...getValues() };

    const promise = !!scanGroupId
      ? updateScanGroup({ scanGroupId, body: scanGroupData })
      : createScanGroup(scanGroupData);

    withLoading(promise.unwrap())
      .then(() => {
        navigate('/scan-groups');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof ScanGroupForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  const { isMobile } = useResponsive();

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the scan group
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='Name'
                          variant='outlined'
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='events'
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl fullWidth variant='outlined' error={!!error}>
                          <InputLabel id='events-label'>Events</InputLabel>
                          <Select
                            labelId='events-label'
                            id='events'
                            multiple
                            value={value}
                            onChange={onChange}
                            label='Events'
                            input={<OutlinedInput label='Events' />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      events?.data.find((event: Event) => event.id === value)?.title
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {events &&
                              events.data.map((event) => (
                                <MenuItem key={event.id} value={event.id}>
                                  {event.title}
                                </MenuItem>
                              ))}
                          </Select>
                          {!!error && (
                            <Typography variant='caption' color='error.main'>
                              {error?.message}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='subtitle1' className='pb-2 inline pr-4'>
                      Offline Mode
                    </Typography>
                    <Controller
                      name='offline'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <IOSSwitch {...field} checked={field.value} />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='subtitle1' className='pb-2 inline pr-4'>
                      Enable Scanning
                    </Typography>
                    <Controller
                      name='visible'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <IOSSwitch {...field} checked={field.value} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEdit;
