import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { SeatSectionLabelForm, seatSectionLabelFormSchema } from './seat-section-labeling-form';

interface SeatSectionLabelingFormProps {
  onSubmit: (data: SeatSectionLabelForm) => void;
}

function SeatSectionLabelingForm({ onSubmit }: SeatSectionLabelingFormProps) {
  const { control, reset, handleSubmit } = useForm<SeatSectionLabelForm>({
    defaultValues: {
      sectionLabel: '',
    },
    resolver: zodResolver(seatSectionLabelFormSchema),
  });

  function onFormSubmit(data: SeatSectionLabelForm) {
    reset();
    onSubmit(data);
  }

  return (
    <form name='seatLabelForm' onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='sectionLabel'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  required
                  label='Section Label'
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent='flex-end' className='mt-4'>
          <Button type='submit' variant='contained'>
            Apply
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default SeatSectionLabelingForm;
