import App from '@/App';
import { SnackbarAlert } from '@/Components/Molecules/Snackbars/SnackbarAlert';
import { type GlobalStore } from '@/store';
import { Box, LinearProgress, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createBrowserRouter } from 'react-router-dom';
import { theme } from './Utils/theme';

// The browser router was created in this way, in contrast to using <BrowserRouter>,
// because this allows routing outside React components
export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Wrapper />
        </LocalizationProvider>
      </ThemeProvider>
    ),
  },
]);

function Wrapper() {
  const snackbarData = useSelector((state: GlobalStore) => state.snackbar);
  const isLoading = useSelector((state: GlobalStore) => !!state.loader.loadingCount);
  const { title } = useSelector((state: GlobalStore) => state.layout);

  useEffect(() => {
    document.title = `${title} | Festtix`;
  }, [title]);

  return (
    <>
      {isLoading && (
        <Box className='fixed top-0 left-0 w-full z-[1500]'>
          <LinearProgress />
        </Box>
      )}
      <App />

      {snackbarData && <SnackbarAlert snackbarData={snackbarData} />}
    </>
  );
}
