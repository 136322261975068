import { Card, CardActionArea, Stack, Typography, Divider, Button } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { Ticket } from '@/Models/tickets';
import React, { useEffect, useState, useRef } from 'react';
export const TicketDragCard = ({
  ticket,
  tickets,
  onTicketDrop,
  index,
}: {
  ticket: Ticket;
  tickets: Ticket[];
  onTicketDrop: Function;
  index: number;
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ticket',
    item: { id: ticket.id, title: ticket.title },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

   const ref = useRef<HTMLDivElement>(null);

   const [, drop] = useDrop(
     () => ({
       accept: 'ticket',
       drop: (item: Ticket) => onTicketDrop(item, index),
     }),
     [index, onTicketDrop],
   );
 
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
       e.preventDefault();

       const targetElement = ref.current;
       const rect = targetElement?.getBoundingClientRect();
       const offsetY = e.clientY - rect?.top!;
       const threshold = 30;

       if (offsetY < threshold) {
         targetElement?.scrollBy(0, -threshold + offsetY);
       } else if (offsetY > rect?.height! - threshold) {
         targetElement?.scrollBy(0, offsetY - (rect?.height! - threshold));
       }
  };


  return (
    <Card
      ref={drag}
      className={`flex w-[100%] ${isDragging ? 'opacity-50' : ''}`}
      onDragOver={handleDragOver}
    >
      <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }} ref={drop}>
        <Stack divider={<Divider />} spacing={5} className='p-2' ref={ref}>
          <Typography variant='h6' sx={{fontSize: '15px'}}>{ticket.title}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
