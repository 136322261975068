import { Ticket } from '@/Models/tickets';
import {
  Card,
  CardMedia,
  ChipTypeMap,
  CardContent,
  Typography,
  Stack,
  CardActions,
  IconButton,
} from '@mui/material';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { EditOutlined, Lock, AddBox } from '@mui/icons-material';
import { DateFormats, formatDate } from '@/Utils/dates';
import { Link } from 'react-router-dom';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { formatPrice } from '@/Utils/currency';
import { Event } from '@/Models/events';

export interface TicketCardProps {
  ticket: Ticket;
  event: Event;
}

export const TicketCard = ({ ticket, event }: TicketCardProps) => {
  // TODO front: add extra logic
  function getStatusColor(ticket: Ticket): ChipTypeMap['props']['color'] {
    if (ticket.visible) {
      return 'success';
    } else {
      return 'default';
    }
  }

  // TODO front: add extra logic
  function getStatusText(ticket: Ticket): string {
    if (ticket.visible) {
      return 'Public';
    } else {
      return 'Hidden';
    }
  }

  return (
    <>
      <Card sx={{ height: '410px' }}>
        <CardMedia component='img' sx={{ height: '200px' }} image={ticket.ticket_image} />

        <CardContent>
          <Stack>
            <Typography variant='h6' noWrap>
              {ticket.title}
            </Typography>

            <Typography variant='h4'>
              {event.currency && formatPrice(ticket.price, event.currency)}
            </Typography>

            <Typography variant='body2' color='text.secondary' gutterBottom>
              {formatDate(ticket.starts_at, DateFormats.LONG_DATE)} to{' '}
              {formatDate(ticket.ends_at, DateFormats.LONG_DATE)}
            </Typography>

            <Stack spacing={1} direction='row'>
              <BeautifulChip color={getStatusColor(ticket)}>{getStatusText(ticket)}</BeautifulChip>

              {ticket.locked && <Lock color='disabled' />}
            </Stack>
          </Stack>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
          <PermissionsGate scope={SCOPES.UpdateTicket}>
            <Link to={`/events/${ticket.event_id}/tickets/${ticket.id}/edit`}>
              <IconButton>
                <EditOutlined />
              </IconButton>
            </Link>
          </PermissionsGate>
          {event?.online_event ? (
            <PermissionsGate scope={SCOPES.UpdateTicket}>
              <Link to={`/events/${ticket.event_id}/ticket-codes/${ticket.id}/create`}>
                <IconButton>
                  <AddBox />
                </IconButton>
              </Link>
            </PermissionsGate>
          ) : null}
        </CardActions>
      </Card>
    </>
  );
};
