import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  ListItemText,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { add, endOfDay, isBefore, isWithinInterval, startOfDay } from 'date-fns';
import { DesktopDateTimePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import useLoading from '@/Hooks/useLoading';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { FileContext, ResourceType } from '@/Enums/files';
import useForm from '@/Hooks/useForm';
import { useNavigate, useParams } from 'react-router-dom';
import { PreviewData } from '@/Models/preview';
import {
  useGetTicketQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
} from '@/Api/ticketsSlice';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { currencySymbol } from '@/Utils/currency';
import { useResponsive } from '@/Hooks/useResponsive';
import { formatTimeZoneShort } from '@/Utils/dates';
import Dinero from 'dinero.js';

const allowedImageTypes = ['image/png', 'image/jpeg'];

const CreateOrEdit = () => {
  const { eventId, ticketId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });
  const { data: ticket } = useGetTicketQuery(
    { eventId: eventId!, ticketId: ticketId! },
    { refetchOnMountOrArgChange: true, skip: !ticketId },
  );

  const [createTicket] = useCreateTicketMutation();
  const [updateTicket] = useUpdateTicketMutation();

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, [event]);

  const imagePreview: PreviewData | undefined = useMemo(() => {
    return ticket ? { type: 'image', url: ticket.ticket_image } : undefined;
  }, [ticket]);

  const [fileQueue, setFileQueue] = useState<{ key: string; file: File }[]>([]);

  const [loading, withLoading] = useLoading();

  const [changeType, setChangeType] = useState() as any;

  const { data, setData, errors, setError, clearErrors, hasErrors } = useForm<{
    starts_at: Date | null;
    ends_at: Date | null;
    change_names_expires_at: Date | null;
    [key: string]: any;
  }>({
    title: '',
    description: '',
    price: 10,
    starts_at: startOfDay(add(new Date(), { days: 1 })),
    ends_at: endOfDay(add(new Date(), { days: 1 })),
    change_names_expires_at: null,
    minimum_tickets_per_order: 1,
    maximum_tickets_per_order: 1,
    allocated: 100,
    ticket_image: '',
    disclaimer: '',
    ticket_codes: [],
    show_names: false,
    day_ticket: false,
    group_ticket: false,
    group_ticket_size: 2,
    days: '',
    change_names: false,
    visible: false,
    locked: false,
    change_names_type: changeType || 'automatic_change',
  });

  useEffect(() => {
    if (ticket) {
      if (data.change_names_type == null) {
        setChangeType('automatic_change');
      } else {
        setChangeType('manual_change');
      }
    }
  }, [data]);

  useEffect(() => {
    if (!!ticket && !!event) {
      setData({
        ...data,
        ...ticket,
        price: Dinero({ amount: ticket.price, currency: event.currency }).toUnit(),
        days: ticket.days ? ticket.days.split('-').map(Number) : [],
      });
    }
  }, [ticket, event]);

  useEffect(() => {
    if (data.group_ticket) {
      updateData('maximum_tickets_per_order', data.group_ticket_size);
    }
  }, [data.group_ticket, data.group_ticket_size]);

  function getTitle() {
    return `${ticketId ? 'Edit' : 'Create'} Ticket`;
  }

  function updateData(fieldName: string, value: any) {
    clearErrors(fieldName);
    setData((prevData) => ({ ...prevData, [fieldName]: value }));
    if (fieldName === 'change_names_type' && value === 'automatic_change') {
      setData((prevData) => ({ ...prevData, ['change_names_expires_at']: null }));
    }
    if (fieldName === 'days') {
      const sortedValue = [...value].sort((a, b) => a - b);
      setData((prevData) => ({ ...prevData, ['days']: sortedValue }));
    }
  }

  async function handleSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();

    if (hasErrors) {
      return;
    }

    let ticketData = { ...data };
    if (!!fileQueue.length) {
      const processedFiles = await withLoading(processFiles());
      ticketData = { ...ticketData, ...processedFiles };
    }

    if (!validatePictures(ticketData)) {
      return;
    }

    let promise: Promise<any>;
    if (ticket) {
      promise = updateTicket({
        eventId: ticket.event_id,
        ticketId: ticket.id,
        body: ticketData,
      }).unwrap();
    } else {
      promise = createTicket({ eventId: eventId!, body: ticketData }).unwrap();
    }

    withLoading(promise)
      .then((ticket) => {
        navigate(`/events/${eventId}/tickets`);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors);
        }
      });
  }

  async function processFiles() {
    // TODO front: should handle case when files exceed 5 mb after compression
    const compressedFiles = await Promise.all(fileQueue.map(({ file }) => compressImage(file)));
    const fileData = fileQueue.map((data, i) => ({
      ...data,
      file: compressedFiles[i],
    }));

    const signedUrlPayloads = await uploadFilesToAwsS3(
      fileData.map((d) => ({
        ...d,
        resourceType: ResourceType.IMAGE,
        context: FileContext.TICKETS,
        eventId: eventId!,
      })),
    );

    const ticketData: { [key: string]: any } = {};
    signedUrlPayloads.forEach((p, i) => {
      ticketData[fileData[i].key] = p.fields.key;
      setData(fileData[i].key, p.fields.key);
    });

    setFileQueue([]);

    return ticketData;
  }

  function validatePictures(data: any) {
    if (!data['ticket_image']) {
      setError('ticket_image', 'Please upload an image first!');
      return false;
    }

    return true;
  }

  const ticketHasStartedSelling = ticket && isBefore(new Date(ticket.starts_at), new Date());

  const startsAtMinDate = useMemo(() => {
    return ticketHasStartedSelling ? undefined : add(new Date(), { minutes: 30 });
  }, [ticket]);

  const endsAtMinDate = useMemo(() => {
    if (ticketHasStartedSelling) {
      return undefined;
    }

    return data.starts_at ? add(new Date(data.starts_at), { minutes: 30 }) : new Date();
  }, [ticket, data.starts_at]);

  function getMaxSellingDate() {
    return event ? new Date(event.ends_at) : undefined;
  }

  function getTicketMaxSellingDate(data: any) {
    return ticket ? new Date(ticket.ends_at) : data;
  }

  function getCustomizedDayComponent(
    day: Date,
    _: Date[],
    DayComponentProps: PickersDayProps<Date>,
  ) {
    const isEventDay = event
      ? isWithinInterval(day, {
          start: new Date(event.starts_at),
          end: new Date(event.ends_at),
        })
      : true;

    return (
      <Badge key={day.toString()} color='success' invisible={!isEventDay} variant='dot'>
        <PickersDay {...DayComponentProps} />
      </Badge>
    );
  }

  function addFileInQueue(key: string, file: File) {
    clearErrors(key);
    setData(key, '');
    const files = fileQueue.filter((f) => f.key !== key);
    files.push({ key, file });
    setFileQueue(files);
  }
  const { isMobile } = useResponsive();

  const calculateDays = (startsAt: any, endsAt: any) => {
    const startDate = new Date(startsAt);
    const endDate = new Date(endsAt);

    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDaysDecimal = differenceInTime / (1000 * 3600 * 24);
    const differenceInDays = Math.ceil(differenceInDaysDecimal);
    const days = [];

    const currentDate = new Date(startDate);

    for (let i = 0; i < differenceInDays; i++) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  };

  const eventDays = calculateDays(event?.starts_at, event?.ends_at);

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form name='createForm' onSubmit={handleSubmit}>
            <Stack spacing={4} divider={<Divider />}>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 ml-2 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>General</Typography>

                  <Typography variant='body2' color='text.secondary'>
                    Provide essential information about the ticket
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id='title'
                        name='title'
                        label='Title'
                        error={!!errors.title}
                        helperText={errors.title?.[0]}
                        fullWidth
                        value={data.title}
                        onChange={(e) => updateData('title', e?.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id='description'
                        name='description'
                        label='Description'
                        error={!!errors.description}
                        helperText={errors.description?.[0]}
                        multiline
                        rows={3}
                        fullWidth
                        value={data.description}
                        onChange={(e) => updateData('description', e?.target.value)}
                      />
                    </Grid>

                    <Grid item sm={6}>
                      <DesktopDateTimePicker
                        label='Starts at'
                        inputFormat='MM/dd/yyyy HH:mm'
                        minDateTime={startsAtMinDate}
                        maxDate={getMaxSellingDate()}
                        disabled={ticketHasStartedSelling}
                        value={data.starts_at}
                        onChange={(value) => updateData('starts_at', value)}
                        renderDay={getCustomizedDayComponent}
                        renderInput={function (
                          props: TextFieldProps,
                        ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                          return (
                            <TextField
                              {...props}
                              required
                              id='startsAt'
                              name='startsAt'
                              label={`Starts at (${formatTimeZoneShort(event?.timezone)})`}
                              error={!!errors.starts_at}
                              helperText={errors.starts_at?.[0]}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item sm={6}>
                      <DesktopDateTimePicker
                        label='Ends at'
                        inputFormat='MM/dd/yyyy HH:mm'
                        minDateTime={endsAtMinDate}
                        maxDate={getMaxSellingDate()}
                        disabled={ticketHasStartedSelling}
                        value={data.ends_at}
                        onChange={(value) => updateData('ends_at', value)}
                        renderDay={getCustomizedDayComponent}
                        renderInput={function (
                          props: TextFieldProps,
                        ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                          return (
                            <TextField
                              {...props}
                              required
                              id='endsAt'
                              name='endsAt'
                              label={`Ends at (${formatTimeZoneShort(event?.timezone)})`}
                              error={!!errors.ends_at}
                              helperText={errors.ends_at?.[0]}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id='price'
                        name='price'
                        label='Price'
                        disabled={ticketHasStartedSelling}
                        type='number'
                        error={!!errors.price}
                        helperText={errors.price?.[0]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              {event && currencySymbol(event.currency)}
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ min: 0, step: 0.0001 }}
                        fullWidth
                        value={data.price}
                        onChange={(e) => updateData('price', +e?.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>

              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 ml-2 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>Purchasing</Typography>

                  <Typography variant='body2' color='text.secondary'>
                    Configure information on how the users are going to purchase the ticket
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        id='minimum_tickets_per_order'
                        name='minimum_tickets_per_order'
                        label='Minimum tickets per order (inclusive)'
                        type='number'
                        error={!!errors.minimum_tickets_per_order}
                        helperText={errors.minimum_tickets_per_order?.[0]}
                        inputProps={{ min: 1 }}
                        fullWidth
                        value={data.minimum_tickets_per_order}
                        onChange={(e) => updateData('minimum_tickets_per_order', +e?.target.value)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        required
                        id='maximum_tickets_per_order'
                        name='maximum_tickets_per_order'
                        label='Maximum tickets per order (inclusive)'
                        type='number'
                        error={!!errors.maximum_tickets_per_order}
                        helperText={errors.maximum_tickets_per_order?.[0]}
                        inputProps={{ min: 1 }}
                        fullWidth
                        value={data.maximum_tickets_per_order}
                        onChange={(e) => updateData('maximum_tickets_per_order', +e?.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id='allocated'
                        name='allocated'
                        label='Allocated Tickets'
                        type='number'
                        error={!!errors.allocated}
                        helperText={errors.allocated?.[0]}
                        inputProps={{ min: 1 }}
                        fullWidth
                        value={data.allocated}
                        onChange={(e) => updateData('allocated', +e?.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>

              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <div className='w-3/3 ml-2 lg:w-1/3 lg:flex-shrink-0'>
                  <Typography variant='h6'>Other</Typography>

                  <Typography variant='body2' color='text.secondary'>
                    Provide other customization information
                  </Typography>
                </div>

                <Paper elevation={2} sx={{ p: 4, width: '100%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack>
                        <FileUpload
                          label='Ticket image'
                          allowedFileTypes={allowedImageTypes}
                          maxFileSize={MAX_IMAGE_FILE_SIZE_IN_BYTES}
                          onSelect={(file) => addFileInQueue('ticket_image', file)}
                          preview
                          previewData={!!ticket ? imagePreview : undefined}
                          helperText='Recommended size: 570x213px'
                        />

                        {errors['ticket_image'] && (
                          <FormControl error>
                            <FormHelperText className='!ml-0'>
                              {errors['ticket_image']}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='subtitle1' className='pb-2'>
                        Ticket Disclaimer
                      </Typography>
                      <ReactQuill
                        theme='snow'
                        id='disclaimer'
                        value={data.disclaimer}
                        onChange={(e) => updateData('disclaimer', e)}
                        style={{ border: errors.disclaimer && '1px solid red' }}
                      />
                      {errors.disclaimer && (
                        <Typography variant='caption' color='error'>
                          {errors.disclaimer}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.show_names}
                            onChange={(e) => updateData('show_names', e.target.checked)}
                            name='show_names'
                          />
                        }
                        label='Show the client name on the ticket'
                      />
                    </Grid>

                    {data?.show_names && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.change_names}
                              onChange={(e) => updateData('change_names', e.target.checked)}
                              name='change_names'
                            />
                          }
                          label='Let the client to change name on the ticket'
                          sx={{
                            marginLeft: '20px',
                            marginTop: '-30px',
                            marginBottom: '-10px',
                            '& .MuiSvgIcon-root': { fontSize: 20 },
                          }}
                        />
                      </Grid>
                    )}

                    {data.change_names && (
                      <>
                        <Grid item xs={12}>
                          <RadioGroup
                            aria-labelledby='change-type'
                            name='change'
                            value={data.change_names_type}
                            onChange={(e) => updateData('change_names_type', e?.target.value)}
                          >
                            <FormControlLabel
                              value='automatic_change'
                              control={<Radio />}
                              label='Automatic Change'
                              sx={{
                                marginLeft: '50px',
                                marginTop: '-20px',
                                marginBottom: '-10px',
                                '& .MuiSvgIcon-root': { fontSize: 20 },
                              }}
                            />
                            <FormControlLabel
                              value='manual_change'
                              control={<Radio />}
                              label='Manual Change'
                              sx={{
                                marginLeft: '50px',
                                marginTop: '10px',
                                '& .MuiSvgIcon-root': { fontSize: 20 },
                              }}
                            />
                          </RadioGroup>
                        </Grid>
                      </>
                    )}

                    {data?.change_names_type === 'manual_change' && (
                      <>
                        <Grid item sm={6}>
                          <DesktopDateTimePicker
                            label='Expire Date'
                            inputFormat='MM/dd/yyyy HH:mm'
                            minDateTime={startsAtMinDate}
                            maxDate={getTicketMaxSellingDate(data.ends_at)}
                            value={data.change_names_expires_at}
                            onChange={(value) => updateData('change_names_expires_at', value)}
                            renderDay={getCustomizedDayComponent}
                            renderInput={function (
                              props: TextFieldProps,
                            ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
                              return (
                                <TextField
                                  {...props}
                                  required
                                  id='expireManual'
                                  name='expireManual'
                                  label={`Expire Date (${formatTimeZoneShort(event?.timezone)})`}
                                  error={!!errors.change_names_expires_at}
                                  helperText={errors.change_names_expires_at?.[0]}
                                  sx={{
                                    width: '88%',
                                    marginLeft: '80px',
                                    marginTop: '-10px',
                                    marginBottom: '-5px',
                                  }}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.day_ticket}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                updateData('days', '');
                              }
                              updateData('day_ticket', e.target.checked);
                            }}
                            name='day_ticket'
                          />
                        }
                        label='Day Ticket'
                      />
                    </Grid>

                    {data?.day_ticket && (
                      <Grid item xs={12}>
                        <TextField
                          required
                          select
                          id='days'
                          name='days'
                          label='Days'
                          error={!!errors.days}
                          helperText={errors.days?.[0]}
                          fullWidth
                          value={data.days != '' ? data.days : []}
                          onChange={(e) => updateData('days', e?.target.value)}
                          SelectProps={{
                            multiple: true,
                            renderValue: (selected: any) => (selected || []).join('-'),
                          }}
                        >
                          {eventDays.map((day, index) => (
                            <MenuItem key={index} value={index + 1}>
                              <Checkbox checked={(data.days || []).includes(index + 1)} />
                              <ListItemText
                                primary={`Day ${index + 1} - ${day.toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })}`}
                              />
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.group_ticket}
                            onChange={(e) => updateData('group_ticket', e.target.checked)}
                            name='group_ticket'
                          />
                        }
                        label='Group Ticket'
                      />
                    </Grid>

                    {data?.group_ticket && (
                      <Grid item xs={12}>
                        <TextField
                          required
                          id='group_ticket_size'
                          name='group_ticket_size'
                          label='Group Ticket Size'
                          type='number'
                          error={!!errors.group_ticket_size}
                          helperText={errors.group_ticket_size?.[0]}
                          inputProps={{ min: 2, max: 10 }}
                          fullWidth
                          value={data.group_ticket_size}
                          onChange={(e) => updateData('group_ticket_size', +e?.target.value)}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.visible}
                            onChange={(e) => updateData('visible', e.target.checked)}
                            name='visible'
                            disabled={
                              event?.online_event &&
                              (!ticketId || data?.ticket_codes.length < data?.allocated)
                            }
                          />
                        }
                        label='Make ticket visible to users'
                      />
                      {event?.online_event && data.ticket_codes.length < data.allocated ? (
                        <Typography variant='body2' color='text.secondary'>
                          To activate this checkbox you should import ticket codes as are in
                          allocated tickets.
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.locked}
                            onChange={(e) => updateData('locked', e.target.checked)}
                            name='locked'
                          />
                        }
                        label='Ticket is locked for the general public. It can only be bought by using a passcode'
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
            </Stack>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton loading={loading}>
                <Button type='submit' disabled={loading} variant='contained' color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrEdit;
