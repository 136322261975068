import { DialogProps } from '@/Models/dialogs';
import { Event } from '@/Models/events';
import { InvitationType } from '@/Models/tickets';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useForm from '@/Hooks/useForm';
import { useGetInvitationTypesQuery } from '@/Api/invitationsSlice';
import { useGenerateManyInvitationsMutation } from '@/Api/guestsSlice';
import { HTTP_CODE } from '@/Utils/http-client';

interface InviteFormProps {
  event: Event;
}

const InviteForm = ({ open, onDialogClose, event }: DialogProps<InviteFormProps>) => {
  const { data: invitationTypes } = useGetInvitationTypesQuery(event.id!, {
    refetchOnMountOrArgChange: true,
  });
  const [quantityLimit, setQuantityLimit] = useState(false);
  const [generateManyInvitations, { isLoading: loading }] = useGenerateManyInvitationsMutation();

  const { data, setData, errors, setError, clearErrors } = useForm({
    first_name: '',
    last_name: '',
    email: '',
    notes: '',
    quantity: 1,
    invitation_type_id: '',
    send_email: false,
  });

  function addGuests(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    generateManyInvitations({ eventId: event.id, body: data })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors || {});
        }
      });
  }

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  useEffect(() => {
    setQuantityLimit(data.quantity >= 100);
  }, [data.quantity]);

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='inviteForm' onSubmit={addGuests}>
        <DialogTitle>Add invitations</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='w-80 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id='first_name'
                  name='first_name'
                  label='First Name'
                  error={!!errors.first_name}
                  helperText={errors.first_name?.[0]}
                  fullWidth
                  value={data.first_name}
                  onChange={(e) => updateData('first_name', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='last_name'
                  name='last_name'
                  label='Last Name'
                  error={!!errors.last_name}
                  helperText={errors.last_name?.[0]}
                  fullWidth
                  value={data.last_name}
                  onChange={(e) => updateData('last_name', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='email'
                  name='email'
                  label='Email'
                  error={!!errors.email}
                  helperText={errors.email?.[0]}
                  fullWidth
                  type='email'
                  value={data.email}
                  onChange={(e) => updateData('email', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='notes'
                  name='notes'
                  label='Notes'
                  error={!!errors.notes}
                  helperText={errors.notes?.[0]}
                  fullWidth
                  type='notes'
                  value={data.notes}
                  onChange={(e) => updateData('notes', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  select
                  id='invitation_type_id'
                  name='invitation_type_id'
                  label='Invitation type'
                  error={!!errors.invitation_type_id}
                  helperText={errors.invitation_type_id?.[0]}
                  fullWidth
                  value={data.invitation_type_id}
                  onChange={(e) => updateData('invitation_type_id', e?.target.value)}
                >
                  {invitationTypes &&
                    invitationTypes.map(({ id, title }: InvitationType) => (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='quantity'
                  name='quantity'
                  label='Quantity'
                  type='number'
                  error={!!errors.quantity}
                  helperText={errors.quantity?.[0]}
                  inputProps={{ min: 1 }}
                  fullWidth
                  value={data.quantity}
                  onChange={(e) => updateData('quantity', +e?.target.value)}
                />
              </Grid>

              {/* TODO front: add invitation type */}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={quantityLimit}
                      checked={data.send_email}
                      onChange={(e) => updateData('send_email', e.target.checked)}
                      name='visible'
                    />
                  }
                  label='Send email to invitee(s)'
                />
              </Grid>
              <Grid item xs={12}>
                {quantityLimit && (
                  <Typography variant='body2' color='secondary'>
                    If you create more than 100 invites, please download the PDFs as ZIP because the
                    emails won&apos;t be sent out
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>
          <LoadingButton loading={loading}>
            <Button type='submit' variant='contained' disabled={loading} color='success'>
              Add
            </Button>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InviteForm;
