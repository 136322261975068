import { Ticket } from '@/Models/tickets';
import { Event } from '@/Models/events';
import {
  Card,
  CardMedia,
  ChipTypeMap,
  CardContent,
  Typography,
  Stack,
  CardActions,
  IconButton,
  Button,
} from '@mui/material';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { EditOutlined, Lock } from '@mui/icons-material';
import { DateFormats, formatDate } from '@/Utils/dates';
import { Link } from 'react-router-dom';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import NumberPicker from '../FormFields/NumberPicker';
import { formatPrice, formatPriceMajor } from '@/Utils/currency';

export interface TicketCardProps {
  ticket: Ticket;
  event: Event;
  currentTicketOrders: number;
  onReservationChange: (value: number) => void;
  onPurchaseClick: () => void;
}

export const AffiliateTicketCard = ({
  ticket,
  event,
  currentTicketOrders,
  onReservationChange,
  onPurchaseClick,
}: TicketCardProps) => {
  function getStatusColor(ticket: Ticket): ChipTypeMap['props']['color'] {
    if (ticket.visible) {
      return 'success';
    } else {
      return 'default';
    }
  }

  function getStatusText(ticket: Ticket): string {
    if (ticket.visible) {
      return 'Public';
    } else {
      return 'Hidden';
    }
  }

  return (
    <>
      <Card sx={{ height: '410px' }}>
        <CardMedia component='img' sx={{ height: '200px' }} image={ticket.ticket_image} />

        <CardContent>
          <Stack>
            <Typography variant='h6' noWrap>
              {ticket.title}
            </Typography>

            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='h4'>{formatPrice(ticket.price, event.currency)}</Typography>
              <Typography variant='subtitle1' color='info.main' className='px-2 text-xs font-mono'>
                {event.booking_fee != 0
                  ? event.booking_fee_type === 'percentage'
                    ? `+ ${event.booking_fee}% booking fee`
                    : `+ ${formatPriceMajor(event.booking_fee, event.currency)} booking fee`
                  : 'incl. fees'}
              </Typography>
            </Stack>

            <Typography variant='body2' color='text.secondary' gutterBottom>
              {formatDate(ticket.starts_at, DateFormats.LONG_DATE)} to{' '}
              {formatDate(ticket.ends_at, DateFormats.LONG_DATE)}
            </Typography>

            {ticket.sold_out ? (
              <Stack spacing={1} direction='row'>
                <BeautifulChip color='error'>Sold out</BeautifulChip>

                {ticket.locked && <Lock color='disabled' />}
              </Stack>
            ) : ticket.temporarily_queued ? (
              <Stack spacing={1} direction='row'>
                <BeautifulChip color='error'>The last tickets are in transaction</BeautifulChip>

                {ticket.locked && <Lock color='disabled' />}
              </Stack>
            ) : (
              <Stack spacing={1} direction='row'>
                <BeautifulChip color={getStatusColor(ticket)}>
                  {getStatusText(ticket)}
                </BeautifulChip>

                {ticket.locked && <Lock color='disabled' />}
              </Stack>
            )}

            {!ticket.visible ||
            new Date().getTime() < new Date(ticket.starts_at).getTime() ||
            new Date().getTime() > new Date(ticket.ends_at).getTime() ? (
              <></>
            ) : ticket.group_ticket ? (
              <Stack className='w-full flex items-end'>
                <Button variant='contained' className='w-28' onClick={onPurchaseClick}>
                  Purchase
                </Button>
              </Stack>
            ) : (
              <NumberPicker
                value={currentTicketOrders}
                minValue={ticket.minimum_tickets_per_order}
                maxValue={ticket.maximum_tickets_per_order}
                incrementDisabled={ticket.sold_out || ticket.temporarily_queued}
                onChange={onReservationChange}
              />
            )}
          </Stack>
        </CardContent>

        <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}></CardActions>
      </Card>
    </>
  );
};
