import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import useForm from '@/Hooks/useForm';
import { HTTP_CODE } from '@/Utils/http-client';
import { useUpdateOrganizationSettingsMutation } from '@/Api/organizationsSlice';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';
import { Organization } from '@/Models/organizations';

type EditOrderProps = {
  organization: Organization;
};

const Edit = ({ open, onDialogClose, organization }: DialogProps<EditOrderProps>) => {
  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    business_no: '',
    vat_no: '',
    address: '',
    city: '',
    zip_code: '',
    country: '',
    contact: '',
  });

  const { data, setData, errors, setError, clearErrors } = useForm({
    cashless: organization.settings?.cashless || false,
    company_details: companyDetails,
  });

  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  function editOrder(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    const updatedData = {
      ...data,
      company_details: JSON.stringify(companyDetails),
    };

    updateOrganizationSettings({
      organizationSettingsId: organization?.settings?.id,
      body: updatedData,
    })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          setError(error.data?.errors || {});
        }
      });
  }

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  function updateCompanyDetails(field: keyof typeof companyDetails, value: string) {
    setCompanyDetails({
      ...companyDetails,
      [field]: value,
    });
  }

  useEffect(() => {
    if (organization.settings?.company_details) {
      setCompanyDetails(JSON.parse(organization.settings.company_details));
    }
  }, [organization]);

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='orderEdit' onSubmit={editOrder}>
        <DialogTitle>Organization Settings</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='w-90 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>{organization?.name}</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='name'
                  name='name'
                  label='Company Name'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.name}
                  onChange={(e) => updateCompanyDetails('name', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='business_no'
                  name='business_no'
                  label='Business No'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.business_no}
                  onChange={(e) => updateCompanyDetails('business_no', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='vat_no'
                  name='vat_no'
                  label='VAT No'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.vat_no}
                  onChange={(e) => updateCompanyDetails('vat_no', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='address'
                  name='address'
                  label='Address'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.address}
                  onChange={(e) => updateCompanyDetails('address', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='city'
                  name='city'
                  label='City'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.city}
                  onChange={(e) => updateCompanyDetails('city', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='zip_code'
                  name='zip_code'
                  label='Zip Code'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.zip_code}
                  onChange={(e) => updateCompanyDetails('zip_code', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='country'
                  name='country'
                  label='Country'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.country}
                  onChange={(e) => updateCompanyDetails('country', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id='contact'
                  name='contact'
                  label='Contact'
                  error={!!errors.company_details}
                  helperText={errors.company_details?.[0]}
                  fullWidth
                  value={companyDetails.contact}
                  onChange={(e) => updateCompanyDetails('contact', e?.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <div className='px-[9px]'>
                      <IOSSwitch
                        checked={data.cashless}
                        onChange={(e) => updateData('cashless', e.target.checked)}
                        name='cashless'
                      />
                    </div>
                  }
                  label='Enable Cashless Features'
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>

          <Button type='submit' variant='contained' color='primary'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Edit;
