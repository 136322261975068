import { internalApi } from './internalApi';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { TAG_TYPE } from '@/Enums/tag-types';
import { ParkingSpaces, ParkingSpacesData } from '@/Models/parkingSpaces';
import { ParkingPurchasesData } from '@/Models/parkingPurchases';

export const parkingSpacesApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getParkingSpaces: builder.query<ParkingSpacesData, { eventId: string; filterData: FilterData }>(
      {
        query: ({ eventId, filterData }) => ({
          url: `/events/${eventId}/parking-spaces`,
          method: 'GET',
          params: { ...getFilterQueryParams(filterData) },
        }),
      },
    ),
    getParkingSpace: builder.query<ParkingSpaces, { eventId: string; parkingSpacesId: string }>({
      query: ({ eventId, parkingSpacesId }) => ({
        url: `/events/${eventId}/parking-spaces/${parkingSpacesId}`,
        method: 'GET',
      }),
    }),
    getParkingPurchases: builder.query<
      ParkingPurchasesData,
      { eventId: string; uuid: string; filterData: FilterData }
    >({
      query: ({ eventId, uuid, filterData }) => ({
        url: `/events/${eventId}/parking-purchases/${uuid}`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
    }),
    getParkingSpacesStats: builder.query<any, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/parking-stats`,
        method: 'GET',
      }),
    }),
    createParkingSpace: builder.mutation<ParkingSpaces, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/parking-spaces`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.PARKING_SPACES],
    }),
    updateParkingSpace: builder.mutation<
      ParkingSpaces,
      { eventId: string; parkingSpacesId: string; body: any }
    >({
      query: ({ eventId, parkingSpacesId, body }) => ({
        url: `/events/${eventId}/parking-spaces/${parkingSpacesId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.PARKING_SPACES],
    }),
    deleteParkingSpace: builder.mutation<any, { eventId: string; parkingSpacesId: string }>({
      query: ({ eventId, parkingSpacesId }) => ({
        url: `/events/${eventId}/parking-spaces/${parkingSpacesId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.PARKING_SPACES],
    }),
  }),
});

export const {
  useGetParkingSpaceQuery,
  useGetParkingSpacesQuery,
  useGetParkingPurchasesQuery,
  useGetParkingSpacesStatsQuery,
  useDeleteParkingSpaceMutation,
  useCreateParkingSpaceMutation,
  useUpdateParkingSpaceMutation,
} = parkingSpacesApi;
