import _ from 'lodash';

export function isEmpty(value: unknown): value is null | undefined {
  return _.isNil(value);
}

export function cleanObject<T extends Record<string | number, any>>(obj: T): T {
  return _.omitBy(obj, _.isNil) as typeof obj;
}

export function getFormDataFromObject<T extends Record<string | number, any>>(obj: T): FormData {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    let value = obj[key];

    if (Array.isArray(value)) {
      value.forEach((el) => {
        formData.append(`${key}[]`, el);
      });
      return;
    }

    if (value instanceof Date) {
      value = value.toISOString();
    } else if (typeof value === 'number') {
      value = `${value}`;
    }

    formData.append(key, value);
  });

  return formData;
}

export function createMapFromArray<T>(
  elements: T[],
  accessor: (el: T) => string | number,
): { [key: string | number]: T } {
  return elements.reduce((acc, el) => {
    return {
      ...acc,
      [accessor(el)]: el,
    };
  }, {});
}

export function ordinalSuffixOf(i: any) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function groupBy<T>(
  elements: T[],
  accessor: (el: T) => string | number,
): { [key: string]: T[] } {
  return elements.reduce<{ [key: string]: T[] }>((acc, el) => {
    const key = accessor(el).toString();

    return {
      ...acc,
      [key]: (acc[key] || []).concat(el),
    };
  }, {});
}
