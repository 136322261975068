import UserAvatar from '@/Components/Molecules/Avatars/UserAvatar';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Person, Groups, Logout } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMe } from '@/Hooks/useQueryFns';
import { ArrowBack } from '@mui/icons-material/';
import { useDispatch } from 'react-redux';
import { LogoutAction } from '@/Constants/actions';
import { LayoutState } from '@/Models/layout';
import authService from '@/Services/AuthService';
export interface TopNavigationProps {
  renderContent?: any;
  header?: LayoutState['header'];
}

export default function TopNavigation({ renderContent, header }: TopNavigationProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data: me } = useMe();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onLogoutClick() {
    authService.logout().then(() => {
      dispatch(LogoutAction());
    });
  }

  return (
    <>
      <nav className='sticky top-0 bg-gray-50 z-10 pl-10 shadow-md py-1'>
        <div className='px-4 sm:px-6 lg:px-8'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              {Object.values(params).length ? null : (
                <IconButton style={{ color: 'black' }}>
                  <ArrowBack style={{ fontSize: '20px' }} onClick={() => navigate(-1)} />
                </IconButton>
              )}
              <header className='flex flex-row justify-center'>
                <div className='flex flex-col gap-x-4'>
                  <Typography variant='h6'>{header?.title}</Typography>

                  {header?.subtitle && (
                    <Typography variant='body2' color='text.secondary'>
                      {header?.subtitle}
                    </Typography>
                  )}
                </div>
              </header>
            </Stack>

            <Stack direction='row' alignItems='center' className='self-start'>
              {me && (
                <Stack>
                  <Typography variant='body1'>{me.name}</Typography>
                </Stack>
              )}

              <Tooltip title='Account settings'>
                <IconButton size='small' onClick={handleClick}>
                  <UserAvatar user={me} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </div>
      </nav>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose} onClick={handleClose}>
        <Link to='/account'>
          <MenuItem>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            Account
          </MenuItem>
        </Link>

        <Divider />

        <Link to='/choose-organization'>
          <MenuItem>
            <ListItemIcon>
              <Groups />
            </ListItemIcon>
            Change Organization
          </MenuItem>
        </Link>

        <MenuItem onClick={() => onLogoutClick()}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
