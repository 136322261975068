import { useMemo } from 'react';
import { Order } from '@/Models/orders';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from '@mui/material';
import { DialogProps } from '@/Models/dialogs';
import { useGetTransactionDetailsQuery, useGetOrderDetailsQuery } from '@/Api/ordersSlice';
import { Event } from '@/Models/events';
import { formatPrice } from '@/Utils/currency';

type TransactionDetailsOrderProps = {
  order: Order;
  event: Event;
};

const TransactionDetails = ({
  open,
  onDialogClose,
  order,
  event,
}: DialogProps<TransactionDetailsOrderProps>) => {
  const { data: transaction } = useGetTransactionDetailsQuery(
    { eventId: event.id, transactionId: order.transaction_id! },
    { refetchOnMountOrArgChange: true },
  );

  const { data: orderDetails } = useGetOrderDetailsQuery(
    { eventId: event.id, orderId: order.id },
    { refetchOnMountOrArgChange: true },
  );

  const paymentGatewayData = useMemo(() => {
    if (!orderDetails?.payment_gateway_data) return {};

    return JSON.parse(orderDetails.payment_gateway_data);
  }, [orderDetails]);

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <DialogTitle>Transaction Details</DialogTitle>

      <DialogContent>
        {transaction && (
          <List>
            <ListItem disablePadding>
              <span className='mr-2 text-gray-500'>ID: </span>
              {transaction.transaction_id}
            </ListItem>
            <ListItem disablePadding>
              <span className='mr-2 text-gray-500'>Payment Gateway: </span>
              {transaction.payment_gateway}
            </ListItem>
            <ListItem disablePadding>
              <span className='mr-2 text-gray-500'>Payment Gateway ID: </span>
              {transaction.payment_gateway_transaction_id}
            </ListItem>
            <ListItem disablePadding>
              <span className='mr-2 text-gray-500'>Status: </span>
              {transaction.status}
            </ListItem>
            <ListItem disablePadding>
              <span className='mr-2 text-gray-500'>Booking Fee: </span>
              {formatPrice(transaction.booking_fee, event.currency)}
            </ListItem>
          </List>
        )}
        {paymentGatewayData && (
          <List>
            {Object.entries(paymentGatewayData).map(([key, value]) => (
              <ListItem disablePadding key={key}>
                <span className='mr-2 text-gray-500 capitalize'>{key} </span>
                {value as string}
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onDialogClose(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionDetails;
