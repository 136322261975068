import React, { PropsWithChildren } from 'react';

interface EmptyCellProps {
  condition: boolean;
}

const EmptyCell = ({ condition, children }: PropsWithChildren<EmptyCellProps>) => {
  return condition ? <>{children}</> : <div style={{ color: '#aeacac' }}>-</div>;
};

export default EmptyCell;
