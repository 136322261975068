import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { User, UserData, UserInviteData } from '@/Models/users';

export const usersApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => ({ url: '/me', method: 'GET' }),
      providesTags: [{ type: TAG_TYPE.USERS, id: 'me' }],
    }),
    getUsers: builder.query<UserData, FilterData>({
      query: (filterData) => ({
        url: `/users`,
        method: 'GET',
        params: { ...getFilterQueryParams(filterData) },
      }),
      providesTags: [TAG_TYPE.USERS],
    }),
    getUser: builder.query({
      query: (userId: String) => ({
        url: `/users/${userId}`,
        method: 'GET',
      }),
    }),
    getUserRoles: builder.query<{ id: string; name: string; path: string }[], string>({
      query: (userId: string) => ({
        url: `/users/${userId}/roles`,
        method: 'GET',
      }),
    }),
    createUser: builder.mutation<User, any>({
      query: (body) => ({
        url: `/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.USERS],
    }),
    updateUser: builder.mutation<User, { userId: string; body: any }>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.USERS],
    }),
    deleteUser: builder.mutation<any, string>({
      query: (userId: string) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.USERS],
    }),
    getAddOrganizationPermission: builder.query<any, void>({
      query: () => ({ url: '/can-add-organization', method: 'GET' }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useGetUserRolesQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetAddOrganizationPermissionQuery,
} = usersApi;
