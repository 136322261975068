import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { User } from '@/Models/users';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { MoreVert, EditOutlined, DeleteOutline, Send } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useGetUsersQuery } from '@/Api/usersSlice';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useMe } from '@/Hooks/useQueryFns';
import ConfirmDialog from '@/Components/Molecules/Dialogs/ConfirmDialog';
import {
  useRemoveMemberMutation,
  useSendTemporaryPasswordMutation,
} from '@/Api/organizationsSlice';

const Index = () => {
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<null | User>();
  const dispatch = useDispatch();

  const { data: me } = useMe();

  useEffect(() => {
    dispatch(setTitle('Users'));
    dispatch(
      setHeader({
        title: 'Users',
      }),
    );
  }, []);

  const [page, setPage] = useState(0);

  const pageSize = 100;

  const { data: userData } = useGetUsersQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );

  const [sendTemporaryPassword] = useSendTemporaryPasswordMutation();
  const [removeMember] = useRemoveMemberMutation();

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    user: User;
  }>(null);

  function onPasswordDialogClose(value: boolean) {
    setPasswordDialogOpen(false);
    if (value && currentUser) {
      sendTemporaryPassword(currentUser.id);
    }
    setCurrentUser(undefined);
  }

  function onDeleteDialogClose(value: boolean) {
    setRemoveDialogOpen(false);
    if (value && currentUser) {
      removeMember(currentUser.id);
    }
    setCurrentUser(undefined);
  }

  function onPageChange(page: number) {
    setPage(page);
  }

  function showActionMenu(user: User, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, user });
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
    { field: 'email', headerName: 'Email', sortable: false, flex: 1 },
    {
      field: 'roles',
      headerName: 'Roles',
      renderCell: ({ row: { roles } }) => <div>{roles.join(', ')}</div>,
      sortable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => {
        if (me?.sub === row.id) return <></>;

        return (
          <IconButton onClick={(event) => showActionMenu(row, event)}>
            <MoreVert />
          </IconButton>
        );
      },
      sortable: false,
    },
  ];

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <Link to={`/users/create`}>
              <Button className='w-full' variant='contained'>
                Invite user
              </Button>
            </Link>
          </div>

          <div className='shadow-xl sm:rounded-lg flex-1'>
            {userData && (
              <DataGrid
                rows={userData.data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[pageSize]}
                rowCount={userData.total}
                paginationMode='server'
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </div>

      <DeleteDialog
        open={removeDialogOpen}
        onDialogClose={onDeleteDialogClose}
        query='Are you sure you want to remove this user from your organization?'
        confirmButtonLabel='Remove'
      />

      <ConfirmDialog
        open={passwordDialogOpen}
        onDialogClose={onPasswordDialogClose}
        confirmButtonLabel='Send'
      />

      {menuContext && (
        <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
          <Link to={`/users/${menuContext.user.id}/edit`}>
            <MenuItem>
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Link>

          {menuContext.user.new_account && (
            <MenuItem
              onClick={() => {
                setPasswordDialogOpen(true);
                setCurrentUser(menuContext.user);
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <Send color='info' />
              </ListItemIcon>
              <ListItemText>Send Temporary Password</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setRemoveDialogOpen(true);
              setCurrentUser(menuContext.user);
              hideActionMenu();
            }}
          >
            <ListItemIcon>
              <DeleteOutline color='error' />
            </ListItemIcon>
            <ListItemText>Remove</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default Index;
