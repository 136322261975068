import { Event } from '@/Models/events';
import Grid from '@mui/material/Grid';
import RecentOrders from '@/Components/Molecules/RecentOrders/RecentOrders';
import SalesReportTabs from '@/Components/Organisms/SalesReportTabs/SalesReportTabs';
import ArrivalsTabs from '@/Components/Organisms/ArrivalsTabs/ArrivalsTabs';
import TicketTypesReportTabs from '@/Components/Organisms/TicketTypesReportTabs/TicketTypesReportTabs';
import { useResponsive } from '@/Hooks/useResponsive';
export interface EventStatsProps {
  event: Event;
}

const EventStats = ({ event }: EventStatsProps) => {
  const { isMobile } = useResponsive();

  return (
    <Grid container={isMobile ? false : true} spacing={2}>
      <Grid item xs={12} md={4} height={500}>
        <RecentOrders event={event} />
      </Grid>
      <Grid item xs={12} sx={{ ...(isMobile && { marginTop: '10px' }) }} md={8} height={500}>
        <SalesReportTabs event={event} />
      </Grid>
      <Grid item xs={12} sx={{ ...(isMobile && { marginTop: '10px' }) }} md={7} height={500}>
        <ArrivalsTabs event={event} />
      </Grid>
      <Grid item xs={12} sx={{ ...(isMobile && { marginTop: '10px' }) }} md={5} height={500}>
        <TicketTypesReportTabs event={event} />
      </Grid>
    </Grid>
  );
};

export default EventStats;
