import environment from '@/environments/environment';
import { getHeaders } from '@/Utils/http';
import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

const query = fetchBaseQuery({
  baseUrl: environment.baseUrl,
  credentials: 'include',
  prepareHeaders,
});

async function prepareHeaders(headers: Headers, api: Partial<BaseQueryApi>) {
  return getHeaders(headers, api.endpoint);
}

export default query;
