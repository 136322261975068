import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { EventFaq, EventFaqData } from '@/Models/eventFaqs';

export const faqsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventFaqs: builder.query<EventFaqData, { eventId: string; filterData: FilterData }>({
      query: ({ eventId, filterData }) => ({
        url: `events/${eventId}/faqs`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENT_FAQS],
    }),
    getEventFaq: builder.query<EventFaq, { eventId: string; eventFaqId: string }>({
      query: ({ eventId, eventFaqId }) => ({
        url: `events/${eventId}/faqs/${eventFaqId}`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.EVENT_FAQS],
    }),
    createEventFaqs: builder.mutation<EventFaq, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `events/${eventId}/faqs`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_FAQS],
    }),
    updateEventFaq: builder.mutation<EventFaq, { eventId: string; eventFaqId: String; body: any }>({
      query: ({ eventId, eventFaqId, body }) => ({
        url: `events/${eventId}/faqs/${eventFaqId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENT_FAQS],
    }),
    deleteEventFaq: builder.mutation<any, { eventId: string; eventFaqId: string }>({
      query: ({ eventId, eventFaqId }) => ({
        url: `events/${eventId}/faqs/${eventFaqId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.EVENT_FAQS],
    }),
  }),
});

export const {
  useGetEventFaqsQuery,
  useGetEventFaqQuery,
  useUpdateEventFaqMutation,
  useCreateEventFaqsMutation,
  useDeleteEventFaqMutation,
} = faqsApi;
