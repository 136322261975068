import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyContent from './EmptyContent';
import { HomeOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setTitle } from '@/Slices/layoutSlice';

const Page403 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Forbidden'));
  }, []);

  return (
    <div className='h-screen w-screen'>
      <EmptyContent
        icon={
          <Typography variant='h1' fontWeight='bold'>
            403
          </Typography>
        }
        primaryText='Page Forbidden'
        secondaryText='You are not allowed to access the page you requested'
        button={
          <Link to='/dashboard'>
            <Button variant='contained' startIcon={<HomeOutlined />}>
              Go back Home
            </Button>
          </Link>
        }
      />
    </div>
  );
};

export default Page403;
