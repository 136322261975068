import React, { useState } from 'react';
import { Event } from '@/Models/events';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useGetOrdersQuery } from '@/Api/ordersSlice';
import { Order } from '@/Models/orders';
import { relativeDate } from '@/Utils/dates';
import environment from '@/environments/environment';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import { getStatusColor, humanizeAttendeeStatus } from '@/Utils/attendees';
import { formatPrice } from '@/Utils/currency';
import { currencySymbol } from '@/Utils/currency';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

export interface RecentOrdersProps {
  event: Event;
}

const RecentOrders = ({ event }: RecentOrdersProps) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState('');

  const advancedFetch = useAdvancedFetch();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
    boxShadow: 'none',
  }));

  const { data: orderData } = useGetOrdersQuery(
    {
      eventId: event.id,
      filterData: { search: search, pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  async function onTicketClick(event: Event, referenceNo: string) {
    advancedFetch(
      `${environment.baseUrl}/events/${event.id}/orders/${referenceNo}/ticket?download=1`,
      {
        method: 'GET',
      },
    ).then((response) => {
      handleBlobReponse(response);
    });
  }
  return (
    <Item>
      Recent Orders
      <List sx={{ bgcolor: 'background.paper', height: '90%', overflowY: 'scroll' }}>
        {orderData?.data?.map((order: Order) => (
          <React.Fragment key={order.referenceNo}>
            <ListItem alignItems='flex-start' className='relative'>
              <ListItemText
                primary={order.first_name + ' ' + order.last_name}
                secondary={
                  <React.Fragment>
                    <Typography variant='subtitle2'>{order.email}</Typography>
                    <div className='my-2'>
                      <span
                        className='text-blue-600 cursor-pointer'
                        onClick={() => {
                          onTicketClick(event!, order.referenceNo);
                        }}
                      >
                        #{order.referenceNo}
                      </span>
                    </div>
                    <div className='gap-2 lg:mb-2 lg:flex lg:gap-2'>
                      <BeautifulChip>
                        {event && formatPrice(order.discounted, event.currency)}
                      </BeautifulChip>
                      <BeautifulChip color={getStatusColor(order.status)}>
                        {humanizeAttendeeStatus(order.status)}
                      </BeautifulChip>
                    </div>
                    <div>
                      <Typography variant='subtitle2' className='text-right right-4 bottom-6'>
                        {relativeDate(order.created_at)}
                      </Typography>
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider component='li' />
          </React.Fragment>
        ))}
      </List>
    </Item>
  );
};

export default RecentOrders;
