import React from 'react';
import SidebarMenu from './SidebarMenu';
import { cashlessNavItems } from '../../../Constants/sidebar-nav-items';
const EventsSidebar = ({ pathname, items, toggleDrawer }: any) => {
  const eventMenuItems = [
    {
      sectionTitle: 'Events',
      sectionLink: '/events',
      subsectionTitle: 'Event',
      subsectionItems: items,
    },
  ];

  return <SidebarMenu pathname={pathname} menuItems={eventMenuItems} toggleDrawer={toggleDrawer} />;
};

export { EventsSidebar };
