import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import { MAX_CSV_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { DialogProps } from '@/Models/dialogs';
import { Event } from '@/Models/events';
import { InvitationType } from '@/Models/tickets';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import useForm from '@/Hooks/useForm';
import environment from '@/environments/environment';
import TemplateDownload from '@/Components/Molecules/TemplateDownload/TemplateDownload';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import { RowError } from '@/Models/csv';
import { useGetInvitationTypesQuery } from '@/Api/invitationsSlice';
import { useGenerateInvitationsFromCSVFileMutation } from '@/Api/guestsSlice';
import { HTTP_CODE } from '@/Utils/http-client';

const allowedFileTypes = ['text/csv'];

interface InviteCSVProps {
  event: Event;
}

const InviteCSV = ({ open, onDialogClose, event }: DialogProps<InviteCSVProps>) => {
  const { data: invitationTypes } = useGetInvitationTypesQuery(event.id!, {
    refetchOnMountOrArgChange: true,
  });
  const [generateInvitationsFromCSVFile, { isLoading: loading }] =
    useGenerateInvitationsFromCSVFileMutation();

  const { data, setData, errors, setError, clearErrors, hasErrors } = useForm<any>({
    invitation_type_id: '',
    file: null,
    send_email: false,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  function updateData(fieldName: keyof typeof data, value: any) {
    clearErrors(fieldName);
    setData(fieldName, value);
  }

  function onFileUpload(file: File) {
    clearErrors('file', 'inputFile', 'csvErrors');
    setData('file', file);
  }

  function importFromCSV(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!data.file) {
      setError('file', 'Please upload a file first!');
      return;
    }

    if (hasErrors) {
      return;
    }

    const formData = new FormData();
    formData.append('send_email', data.send_email);
    formData.append('guestListFile', data.file);
    formData.append('invitation_type_id', data.invitation_type_id);

    generateInvitationsFromCSVFile({ eventId: event.id, csvFile: formData })
      .unwrap()
      .then(() => {
        onDialogClose(true);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          if (!!error.data?.errors[CustomZodErrorType.CSV_ERRORS]) {
            setError(
              'csvErrors',
              getInvalidRowMessage(error.data?.errors[CustomZodErrorType.CSV_ERRORS]),
            );
          } else {
            setError(error.data?.errors || {});
          }
        }
      });
  }

  function getInvalidRowMessage(
    invalidRowsData: RowError<'firstName' | 'lastName' | 'email' | 'notes'>[],
  ) {
    return 'The csv file contains invalid data';
  }

  return (
    <Dialog open={open} onClose={() => onDialogClose(false)}>
      <form name='importForm' onSubmit={importFromCSV}>
        <DialogTitle>Import from CSV</DialogTitle>

        <DialogContent className='overflow-auto'>
          <div className='h-96 w-80 py-2'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  select
                  id='invitation_type_id'
                  name='invitation_type_id'
                  label='Invitation type'
                  error={!!errors.invitation_type_id}
                  helperText={errors.invitation_type_id?.[0]}
                  fullWidth
                  value={data.invitation_type_id}
                  onChange={(e) => updateData('invitation_type_id', e?.target.value)}
                >
                  {invitationTypes &&
                    invitationTypes.map(({ id, title }: InvitationType) => (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Stack>
                  <FileUpload
                    label='Attachment'
                    allowedFileTypes={allowedFileTypes}
                    maxFileSize={MAX_CSV_FILE_SIZE_IN_BYTES}
                    onSelect={onFileUpload}
                    onError={(message) => setError('inputFile', message)}
                  />

                  {/* TODO front: visualize these errors better */}
                  {errors['csvErrors'] && (
                    <FormControl error>
                      <FormHelperText className='!ml-0'>
                        {JSON.stringify(errors['csvErrors'])}
                      </FormHelperText>
                    </FormControl>
                  )}

                  <TemplateDownload
                    link={environment.baseUrl + '/downloads/invites-template.csv'}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='body1'>Helper text (to be filled...)</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.send_email}
                      onChange={(e) => updateData('send_email', e.target.checked)}
                      name='visible'
                    />
                  }
                  label='Send email to invitee(s)'
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onDialogClose(false)}>Cancel</Button>
          <LoadingButton loading={loading}>
            <Button type='submit' variant='contained' disabled={loading} color='success'>
              Import
            </Button>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InviteCSV;
