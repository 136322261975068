import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PermissionsGate from '@/HOC/PermissionsGate';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const SidebarMenu = ({ pathname, menuItems, toggleDrawer }: any) => {
  const location = useLocation();
  const isEventsSection = location.pathname.startsWith('/events');

  let basePath = location.pathname;

  if (isEventsSection) {
    const eventPathParts = location.pathname.split('/');
    const eventIdIndex = eventPathParts.indexOf('events') + 1;
    const eventId = eventPathParts[eventIdIndex];
    basePath = `/events/${eventId}`;
  } else {
    basePath = basePath.split('/').slice(0, -1).join('/');
  }

  return (
    <List sx={{ width: '100%', pt: 10 }} component='nav'>
      {menuItems.map((section: any) => (
        <>
          <Link onClick={toggleDrawer(false)} to={section.sectionLink}>
            <ListItemButton
              selected={pathname.startsWith(section.sectionLink)}
              sx={{ color: isEventsSection ? 'rgba(255,255,255,.8)' : 'white' }}
            >
              <ListItemIcon sx={{ color: isEventsSection ? 'inherit' : 'white' }}>
                <ArrowBackIosNewIcon />
              </ListItemIcon>
              <ListItemText primary={section.sectionTitle} />
            </ListItemButton>
          </Link>
          <Divider />
          <Box
            sx={{
              bgcolor: 'rgba(71, 98, 130, 0.2)',
              pb: 2,
            }}
            className='overflow-y-auto max-h-[calc(95vh-16rem)]'
          >
            <ListItemButton
              alignItems='flex-start'
              sx={{
                px: 2.5,
                pt: 2.5,
                pb: 0,
              }}
            >
              <ListItemText
                primary={section.subsectionTitle}
                primaryTypographyProps={{
                  fontSize: 16,
                  fontWeight: 'medium',
                  color: 'white',
                  mb: 2,
                }}
                sx={{ my: 0 }}
              />
            </ListItemButton>
            {section.subsectionItems.map((item: any) => (
              <PermissionsGate key={item.title} scope={item.permission}>
                <Link onClick={toggleDrawer(false)} to={`${basePath}/${item.route}`}>
                  <ListItemButton
                    key={item.title}
                    sx={{
                      minHeight: 32,
                      color: isEventsSection ? 'rgba(255,255,255,.8)' : 'white',
                    }}
                    selected={location.pathname.endsWith(`${basePath}/${item.route}`)}
                  >
                    <ListItemIcon sx={{ color: isEventsSection ? 'inherit' : 'white' }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    />
                  </ListItemButton>
                </Link>
              </PermissionsGate>
            ))}
          </Box>
        </>
      ))}
    </List>
  );
};

export default SidebarMenu;
