import { TAG_TYPE } from '@/Enums/tag-types';
import { Organization, OrganizationData } from '@/Models/organizations';
import { getHidesMessagesHeader } from '@/Utils/http-client';
import { internalApi } from './internalApi';
import { FilterData } from '@/Models/filtering';
import { getFilterQueryParams } from '@/Utils/filters';
import { EventData } from '@/Models/events';

export const organizationsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<Organization, string>({
      query: (orgId) => ({
        url: `/organizations/${orgId}`,
        method: 'GET',
      }),
      providesTags: (_, __, orgId) => [{ type: TAG_TYPE.ORGANIZATIONS, id: orgId }],
    }),
    getMyOrganizations: builder.query<Organization[], void>({
      query: () => ({
        url: `/my-organizations`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: [TAG_TYPE.ORGANIZATIONS],
    }),
    createOrganization: builder.mutation<Organization, any>({
      query: (body) => ({
        url: `/organizations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.ORGANIZATIONS],
    }),
    updateOrganization: builder.mutation<Organization, { organizationId: string; body: any }>({
      query: ({ organizationId, body }) => ({
        url: `/organizations/${organizationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { organizationId }) => [
        { type: TAG_TYPE.ORGANIZATIONS, id: organizationId },
      ],
    }),
    getOrganizationRoles: builder.query<{ id: string; name: string; path: string }[], string>({
      query: (orgId: string) => ({
        url: `/organizations/${orgId}/roles`,
        method: 'GET',
      }),
    }),
    inviteUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `/organizations/invitation`,
        method: 'POST',
        body,
      }),
    }),
    updateMember: builder.mutation<any, any>({
      query: ({ userId, data }) => ({
        url: `/organizations/member/${userId}`,
        method: 'PUT',
        body: data,
      }),
    }),
    sendTemporaryPassword: builder.mutation<any, any>({
      query: (userId) => ({
        url: `/organizations/member/${userId}/send-temporary-password`,
        method: 'POST',
      }),
    }),
    removeMember: builder.mutation<any, any>({
      query: (userId) => ({
        url: `/organizations/member/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.USERS],
    }),
    updateOrganizationSettings: builder.mutation<
      any,
      { organizationSettingsId: string; body: any }
    >({
      query: ({ organizationSettingsId, body }) => ({
        url: `/organizations/settings/${organizationSettingsId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { organizationSettingsId }) => [
        { type: TAG_TYPE.ORGANIZATIONS, id: organizationSettingsId },
      ],
    }),
    getAllOrganizations: builder.query<OrganizationData, FilterData>({
      query: () => ({
        url: `/organizations`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.ORGANIZATIONS],
    }),

    getOrganizationEvents: builder.query<
      EventData,
      { organizationId: string; filterData: FilterData }
    >({
      query: ({ organizationId, filterData }) => ({
        url: `organizations/${organizationId}/events`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.ORGANIZATIONS],
    }),
  }),
});

export const {
  useGetMyOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationRolesQuery,
  useInviteUserMutation,
  useUpdateMemberMutation,
  useSendTemporaryPasswordMutation,
  useRemoveMemberMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationSettingsMutation,
  useCreateOrganizationMutation,
  useGetAllOrganizationsQuery,
  useGetOrganizationEventsQuery,
} = organizationsApi;
