import {
  useLazyGetEventVenueQuery,
  useLazyGetSeatDataQuery,
  useLazyGetSectionDataQuery,
  useStoreSeatCategorizationDataMutation,
} from '@/Api/seatingSlice';
import { useLazyGetTicketsQuery } from '@/Api/ticketsSlice';
import SeatCategorizationEditorContainer from '@/Components/Custom/VenueEditor/SeatCategorizationEditorContainer';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { VenueEditorContext } from '@/Contexts/VenueEditorContext';
import useEffectOnce from '@/Hooks/useEffectOnce';
import useLoading from '@/Hooks/useLoading';
import { Ticket } from '@/Models/tickets';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { adaptSeatData, adaptSectionData, extractSeatCategorizationData } from '@/Utils/seating';
import { Button, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function VenueConfigurator() {
  const dispatch = useDispatch();
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const { eventId, venueId } = useParams<{ eventId: string; venueId: string }>();
  const navigate = useNavigate();
  const [loading, withLoading] = useLoading();
  const { sectionIdentifierService, seatIdentifierService } = useContext(VenueEditorContext);

  const [getTicketsRequest] = useLazyGetTicketsQuery();
  const [getEventVenueDataRequest] = useLazyGetEventVenueQuery();
  const [getVenueSectionDataRequest] = useLazyGetSectionDataQuery();
  const [getVenueSeatDataRequest] = useLazyGetSeatDataQuery();

  const [storeSeatCategorizationData] = useStoreSeatCategorizationDataMutation();

  useEffect(() => {
    dispatch(setTitle('Venue Configurator'));
    dispatch(
      setHeader({
        title: 'Venue Configurator',
      }),
    );
  }, []);

  useEffectOnce(() => {
    Promise.all([
      getTicketsRequest({ eventId: eventId!, includeInvitationTypes: true }),
      getEventVenueDataRequest(eventId!),
      getVenueSectionDataRequest(venueId!),
      getVenueSeatDataRequest({
        venueId: venueId!,
        fields: ['id', 'group_label', 'seat_number', 'ticket_id', 'invitation_type_id'],
      }),
    ]).then(
      ([
        { data: tickets },
        { data: eventVenueData },
        { data: venueSectionData },
        { data: venueSeatData },
      ]) => {
        if (!tickets || !eventVenueData || !venueSectionData || !venueSeatData) return;

        setTickets(tickets);

        sectionIdentifierService.loadInitialSectionData(adaptSectionData(venueSectionData));
        seatIdentifierService.loadInitialSeatData(adaptSeatData(venueSeatData));

        fetch(eventVenueData.svg)
          .then((res) => res.text())
          .then((text) => {
            setSvgContent(text);
          });
      },
    );
  });

  function saveData() {
    withLoading(
      storeSeatCategorizationData({
        venueId: venueId!,
        body: extractSeatCategorizationData(seatIdentifierService.getSeatData()),
      }).then(() => {
        navigate(`/events/${eventId}/venues`);
      }),
    );
  }

  return (
    <Stack className='bg-white h-full relative'>
      {!!svgContent && (
        <>
          <SeatCategorizationEditorContainer svgContent={svgContent} tickets={tickets} />

          <Stack
            className='h-16 pr-4 w-full absolute bottom-0 left-0 shadow-bottom-nav'
            justifyContent='center'
            alignItems='flex-end'
          >
            <Stack direction='row' columnGap='10px'>
              <Link to={`/events/${eventId}/venues`}>
                <Button disabled={loading}>Cancel</Button>
              </Link>

              <LoadingButton loading={loading}>
                <Button variant='contained' disabled={loading} color='success' onClick={saveData}>
                  {'Save'}
                </Button>
              </LoadingButton>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default VenueConfigurator;
