import React, { PropsWithChildren } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@mui/material';

export interface BaseDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  title: string;
  content: React.ReactNode;
}

export default function BaseDialog({
  open,
  onClose,
  title,
  content,
  children,
}: PropsWithChildren<BaseDialogProps>) {
  return (
    <Dialog onClose={() => onClose(false)} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>

      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
}
