import React from 'react';
import { Event } from '@/Models/events';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrivalsOverview from '@/Components/Molecules/ArrivalsOverview/ArrivalsOverview';
import ArrivalsDaily from '@/Components/Molecules/ArrivalsDaily/ArrivalsDaily';
import ArrivalsHourly from '@/Components/Molecules/ArrivalsHourly/ArrivalsHourly';
import CircularProgress from '@mui/material/CircularProgress';
import { useResponsive } from '@/Hooks/useResponsive';

export interface ArrivalsScanGroupTabsProps {
  stats: any;
  model: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ArrivalsScanGroupTabs = ({ stats, model }: ArrivalsScanGroupTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const loading = (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
  const { isMobile } = useResponsive();

  return (
    <Box sx={{ width: '100%', backgroundColor: '#fff', p: 2, height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className='scrollable auto-scroll-buttons primary-indicator-color lg:primary-text-color'>
          <Typography variant='body1' component='div' className='capitalize text-gray-500 pb-4'>
            {model} Arrival
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            {...(isMobile && {
              variant: 'scrollable',
              scrollButtons: 'auto',
              indicatorColor: 'primary',
              textColor: 'primary',
            })}
          >
            <Tab label='Overview' {...a11yProps(0)} />
            <Tab label='Daily' {...a11yProps(1)} />
            <Tab label='Hourly' {...a11yProps(2)} />
          </Tabs>
        </div>
      </Box>
      {!stats ? (
        loading
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <ArrivalsOverview
              arrivals={model == 'attendees' ? stats.attendeesArrival : stats.guestsArrival}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ArrivalsDaily
              arrivals={model == 'attendees' ? stats.attendeesDaily : stats.guestsDaily}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ArrivalsHourly
              arrivals={model == 'attendees' ? stats.attendeesHourly : stats.guestsHourly}
            />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default ArrivalsScanGroupTabs;
