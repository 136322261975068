import { ArrivalData } from '@/Models/arrivals';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';
import { useResponsive } from '@/Hooks/useResponsive';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          const total = context.dataset.data.reduce((acc: any, cur: any) => acc + cur, 0);
          const percentage = ((value / total) * 100).toFixed(2);

          return `${label}: ${value} (${percentage}%)`;
        },
      },
    },
  },
};

export interface ArrivalsHourlyProps {
  arrivals: ArrivalData[];
}

const ArrivalsHourly = ({ arrivals }: ArrivalsHourlyProps) => {
  const { isMobile } = useResponsive();

  const data = useMemo(() => {
    let labels: string[] = [];
    let arrivalsData: number[] = [];

    arrivals.forEach((row: { arrival: string; total: number }) => {
      if (row.arrival != null) {
        labels.push(row.arrival);
        arrivalsData.push(row.total);
      }
    });

    return {
      labels,
      datasets: [
        {
          label: 'Arrivals Hourly',
          data: arrivalsData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };
  }, [arrivals]);

  return (
    <Line
      options={{
        responsive: isMobile ? false : true,
        plugins: {
          ...options.plugins,
        },
      }}
      data={data}
      style={{ width: '100%' }}
    />
  );
};

export default ArrivalsHourly;
