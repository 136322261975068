import React, { useEffect, useState } from 'react';
import { useGetOrganizationEventsQuery } from '@/Api/organizationsSlice';
import { Organization } from '@/Models/organizations';
import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  Divider,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { useResponsive } from '@/Hooks/useResponsive';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTitle, setHeader } from '@/Slices/layoutSlice';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';

const OrganizationEvents = () => {
  const navigate = useNavigate();
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    organization: Organization;
  }>(null);

  const [page, setPage] = useState(0);
  const pageSize = 10;
  const { organizationId } = useParams();

  const { data: organizationEvents } = useGetOrganizationEventsQuery(
    { organizationId: organizationId!, filterData: { pageData: { page, pageSize } } },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  useEffect(() => {
    dispatch(setTitle('Organization Events'));
    dispatch(setHeader({ title: 'Organization Events' }));
  }, []);

  const dispatch = useDispatch();
  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditClick = (
    $mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
    organizationId: string,
    eventId: string,
  ) => {
    $mouseEvent.preventDefault();
    navigate(`/organizations/${organizationId}/events/${eventId}/edit`);
  };

  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2 lg:flex lg:items-center lg:justify-end lg:self-end mb-6'></div>
          <div className='h-96 sm:rounded-lg grow'>
            {organizationEvents && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {organizationEvents.data.map((organizationEvent) => (
                    <Card sx={{ display: 'flex', overflow: 'auto' }} key={organizationEvent.id}>
                      <CardActionArea
                        sx={{ display: 'flex', justifyContent: 'unset', cursor: 'default' }}
                      >
                        <Stack width='100%' divider={<Divider />}>
                          <Stack sx={{ p: 4 }} spacing={2}>
                            <Stack direction='row' className='justify-between relative' flex={1}>
                              <Typography variant='h6' noWrap>
                                {organizationEvent?.title}
                              </Typography>
                              <div className='lg:absolute top-2 right-16'>
                                <BeautifulChip
                                  color={organizationEvent?.visible ? 'success' : 'error'}
                                >
                                  {organizationEvent?.visible ? 'Approved' : 'Not Approved'}
                                </BeautifulChip>
                              </div>
                              <Box>
                                <PermissionsGate scope={SCOPES.ManageOrganization}>
                                  <IconButton
                                    component='div'
                                    onClick={($mouseEvent) =>
                                      onEditClick(
                                        $mouseEvent,
                                        organizationId!,
                                        organizationEvent.id,
                                      )
                                    }
                                    sx={{ '&:hover': { backgroundColor: 'rgba(0,0,255,0.1)' } }}
                                  >
                                    <EditOutlined color='primary' />
                                  </IconButton>
                                </PermissionsGate>
                              </Box>
                            </Stack>
                          </Stack>
                        </Stack>
                      </CardActionArea>
                    </Card>
                  ))}
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={organizationEvents.current_page}
                    count={organizationEvents.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationEvents;
