import {
  useDeleteDonationCauseMutation,
  useArchiveDonationCauseMutation,
} from '@/Api/donationsSlice';
import { BeautifulChip } from '@/Components/Atoms/BeautifulChip/BeautifulChip';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { DonationCause, DonationCauseData } from '@/Models/donations';
import { Event } from '@/Models/events';
import { formatPrice } from '@/Utils/currency';
import { DeleteOutline, EditOutlined, MoreVert, UnarchiveOutlined } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface DonationCausesTableProps {
  donationCauses: DonationCauseData;
  columns: string[];
  pageSize: number;
  onPageChange: (page: number) => void;
  event?: Event;
}

const DonationCausesTable = ({
  donationCauses,
  columns,
  pageSize,
  onPageChange,
  event,
}: DonationCausesTableProps) => {
  const [selectedDonationCause, setSelectedDonationCause] = useState<DonationCause>();
  const [shownColumns, setShownColumns] = useState<GridColDef<DonationCause>[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    donationCause: DonationCause;
  }>(null);

  const [deleteDonationCause] = useDeleteDonationCauseMutation();
  const [archiveDonationCause] = useArchiveDonationCauseMutation();

  const showActionMenu = useCallback(
    (donationCause: DonationCause, event: React.MouseEvent<HTMLElement>) => {
      setMenuContext({ anchor: event.currentTarget, donationCause });
    },
    [setMenuContext],
  );

  const allColumns: GridColDef<DonationCause>[] = useMemo(() => {
    return [
      {
        field: 'logo',
        headerName: 'Logo',
        renderCell: ({ row }) => (
          <img className='w-16 h-16 my-2 object-contain' src={row.logo} alt='Donation cause logo' />
        ),
        sortable: false,
      },
      { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
      { field: 'description', headerName: 'Description', sortable: false, flex: 2 },
      {
        field: 'moneyDonated',
        headerName: 'Money donated',
        sortable: false,
        renderCell: ({ row }) => (
          <BeautifulChip color='success'>
            {event && formatPrice(row.moneyDonated, event.currency)}
          </BeautifulChip>
        ),
        flex: 1,
      },
      {
        field: 'url',
        headerName: 'Url',
        sortable: false,
        renderCell: ({ row }) => (
          <a target='_blank' href={row.url} className='underline text-blue-600 cursor-pointer'>
            <Typography>{row.url}</Typography>
          </a>
        ),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => (
          <IconButton onClick={(donationCause) => showActionMenu(row, donationCause)}>
            <MoreVert />
          </IconButton>
        ),
        sortable: false,
      },
    ];
  }, [showActionMenu]);

  useEffect(() => {
    setShownColumns(allColumns.filter((column) => columns.includes(column.field)));
  }, [columns, allColumns]);

  function dispatchPageChange(page: number) {
    onPageChange(page);
  }

  function hideActionMenu() {
    setMenuContext(null);
  }

  function onDeleteDialogClose(value: boolean) {
    setDeleteDialogOpen(false);

    if (value && selectedDonationCause) {
      deleteDonationCause(selectedDonationCause.id);
    }

    setSelectedDonationCause(undefined);
  }

  function archiveDOnationCause(donationCause: any) {
    archiveDonationCause(donationCause.id);
  }

  return (
    <>
      <DataGrid
        rows={donationCauses.data}
        columns={shownColumns}
        rowHeight={80}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        disableSelectionOnClick
        rowCount={donationCauses.total}
        paginationMode='server'
        onPageChange={dispatchPageChange}
        hideFooterSelectedRowCount={true}
        isRowSelectable={() => false}
      />

      {menuContext && (
        <Menu anchorEl={menuContext?.anchor} open={!!menuContext} onClose={hideActionMenu}>
          <Link to={`${menuContext.donationCause.id}/edit`}>
            <MenuItem
              onClick={() => {
                hideActionMenu();
              }}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Link>

          <MenuItem
            onClick={() => {
              setSelectedDonationCause(menuContext.donationCause);
              setDeleteDialogOpen(true);
              hideActionMenu();
            }}
          >
            <ListItemIcon>
              <DeleteOutline color='error' />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setSelectedDonationCause(menuContext.donationCause);
              archiveDOnationCause(menuContext.donationCause);
              hideActionMenu();
            }}
          >
            <ListItemIcon>
              <UnarchiveOutlined color='info' />
            </ListItemIcon>
            <ListItemText>Archive</ListItemText>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog open={deleteDialogOpen} onDialogClose={onDeleteDialogClose} />
    </>
  );
};

export default DonationCausesTable;
