export enum ResourceType {
  IMAGE = 'images',
}

export enum FileContext {
  EVENTS = 'events',
  EVENTARTISTS = 'eventArtists',
  TICKETS = 'tickets',
  ORGANIZATIONS = 'organizations',
  DONATIONS = 'donations',
  ARTICLES = 'articles',
  PARTNERS = 'partners',
  PARKINGSPACES = 'parkingSpaces',
  SERVICES = 'services',
}
