import { z } from 'zod';

export const parkingSpacesFormSchema = z.object({
  event_id: z.string().min(1),
  name: z.string().min(1),
  description: z.string().min(1),
  image: z.union([z.string().min(1), z.instanceof(File)]),
  price: z.coerce.number().min(0),
  allocated: z.coerce.number().min(0),
});

export type ParkingSpacesForm = z.infer<typeof parkingSpacesFormSchema>;
