import { useGetDonationCausesQuery } from '@/Api/donationsSlice';
import { SCOPES } from '@/Constants/permission-map';
import PermissionsGate from '@/HOC/PermissionsGate';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { VolunteerActivism } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import EmptyContent from '../Info/EmptyContent';
import { useGetTrackingLinksQuery } from '@/Api/insightsSlice';
import TrackingLinks from './TrackingLinks';

const pageSize = 50;

const Insights = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Tracking Links'));
    dispatch(
      setHeader({
        title: 'Tracking Links',
      }),
    );
  }, []);

  const {
    data: trackingLinks,
    isFetching,
    isLoading,
  } = useGetTrackingLinksQuery(
    {
      pageData: { page, pageSize },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <>
      {!isFetching && !trackingLinks?.data?.length && (
        <EmptyContent
          icon={<VolunteerActivism sx={{ color: '#cccccc', fontSize: '8rem' }} />}
          primaryText='No tracking links found'
          secondaryText='Create a new tracking link to get started'
          button={
            <PermissionsGate scope={SCOPES.ManageDonationCause}>
              <Link to={`create`}>
                <Button variant='contained'>Create Tracking Link</Button>
              </Link>
            </PermissionsGate>
          }
        />
      )}

      {!isLoading && !!trackingLinks?.data?.length && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <Stack spacing={2} className='h-full'>
            <Box className='lg:self-end'>
              <PermissionsGate scope={SCOPES.ManageDonationCause}>
                <Link to={`create`}>
                  <Button className='w-full' variant='contained'>
                    Create Tracking Link
                  </Button>
                </Link>
              </PermissionsGate>
            </Box>

            <div className='grow'>
              <TrackingLinks
                trackingLinks={trackingLinks}
                columns={[
                  'name',
                  'event',
                  'utm_source',
                  'utm_medium',
                  'utm_campaign',
                  'url',
                  'visits',
                  'purchases',
                  'actions',
                ]}
                pageSize={pageSize}
                onPageChange={setPage}
              />
            </div>
          </Stack>
        </div>
      )}
    </>
  );
};

export default Insights;
