import { QueryResult } from '@/Models/redux';
import authService from '@/Services/AuthService';
import { HTTP_CODE } from '@/Utils/http-client';
import getPromiseDeduplicator from '@/Utils/promise';
import { User } from 'oidc-client-ts';
import { RTKInterceptor } from './types';

const deduplicateTokenRenewalRequest = getPromiseDeduplicator<User | null>();

const sessionExpiredInterceptor: RTKInterceptor = async (next, args, api, extraOptions) => {
  const result = await next(args, api, extraOptions);

  if (!result.error) {
    return result;
  }

  if (sessionHasExpired(result)) {
    const renewResult = await deduplicateTokenRenewalRequest(() => authService.renewToken());

    if (!renewResult) {
      authService.logout();
      return result;
    }

    if (renewResult) {
      return await next(args, api, extraOptions);
    }
  }

  return result;
};

function sessionHasExpired(exception: QueryResult) {
  return exception.meta?.response?.status === HTTP_CODE.UNAUTHORIZED;
}

export default sessionExpiredInterceptor;
