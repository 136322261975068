import { z } from 'zod';
import { CardItem } from '@/Components/Organisms/CardSelection/CardSelection';
import { FieldPath } from 'react-hook-form';
import { codeGenerationMethods, GenerationMethod } from '../helpers';
import { add, differenceInMinutes, isAfter, max } from 'date-fns';
import { cleanObject } from '@/Utils/general';
import { PasscodeGroup } from '@/Models/promocodes';

const invalidDateIssue: z.IssueData = {
  code: 'invalid_date',
  message: 'The provided date is invalid',
};

export function getSchema(passcodeGroup?: PasscodeGroup) {
  return z
    .object({
      name: z.string().min(1),
      expires_at: z.coerce.date().superRefine((date, context) => {
        const minDate = add(new Date(), { hours: 1 });

        if (!!passcodeGroup) {
          const currentDate = new Date(passcodeGroup.expires_at);

          if (
            !differenceInMinutes(date, currentDate) ||
            isAfter(date, max([minDate, currentDate]))
          ) {
            return;
          }
        } else {
          if (isAfter(date, minDate)) {
            return;
          }
        }

        context.addIssue(invalidDateIssue);
      }),
      allocated_tickets: z.coerce.number().gte(1),
      unlocked_tickets: z.array(z.coerce.string()),

      generation_method: z.nativeEnum(GenerationMethod).optional(),
      codeNo: z.coerce.number().gte(1).lte(5000).optional(),
      csv_file: z.instanceof(File).optional(),
    })
    .transform(cleanObject);
}

export type PasscodesForm = z.infer<ReturnType<typeof getSchema>>;

export const passcodeGenerationMethods: CardItem[] = codeGenerationMethods.filter(
  (m) => m.label !== GenerationMethod.INPUT,
);

export const codeGenerationFormKeys: { [key in GenerationMethod]?: FieldPath<PasscodesForm>[] } = {
  [GenerationMethod.GENERATE]: ['codeNo'],
  [GenerationMethod.IMPORT_CSV]: ['csv_file'],
};
