import { Event } from '@/Models/events';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useState } from 'react';
import {
  EditOutlined,
  FileDownload,
  Info as InfoIcon,
  LinkOff,
  LinkOutlined,
  Mail,
  MoreVert,
} from '@mui/icons-material';
import EmptyCell from '@/Components/Molecules/EmptyCell/EmptyCell';
import { formatDate } from '@/Utils/dates';
import { PasscodeGroup } from '@/Models/promocodes';
import Passcodes from './Passcodes';
import { useGetPasscodeGroupsQuery, useUnlinkPasscodeGroupMutation } from '@/Api/promocodesSlice';
import { Link } from 'react-router-dom';
import environment from '@/environments/environment';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import useAdvancedFetch from '@/Hooks/useAdvancedFetch';
import { handleBlobReponse } from '@/Utils/blob';

interface PasscodeGroupsProps {
  event: Event;
}

const PasscodeGroups = ({ event }: PasscodeGroupsProps) => {
  const [selectedPasscodeGroup, setSelectedPasscodeGroup] = useState<PasscodeGroup | undefined>(
    undefined,
  );
  const advancedFetch = useAdvancedFetch();
  const [page, setPage] = useState(0);

  const [menuContext, setMenuContext] = useState<null | {
    anchor: HTMLElement;
    passcodeGroup: PasscodeGroup;
  }>(null);

  function hideActionMenu() {
    setMenuContext(null);
  }

  const pageSize = 10;

  const { data: passcodeGroupsData } = useGetPasscodeGroupsQuery(
    {
      eventId: event.id,
      filterData: { pageData: { page, pageSize } },
    },
    { refetchOnMountOrArgChange: true },
  );

  const [unlinkPasscodeGroup] = useUnlinkPasscodeGroupMutation();

  function showActionMenu(passcodeGroup: PasscodeGroup, event: React.MouseEvent<HTMLElement>) {
    setMenuContext({ anchor: event.currentTarget, passcodeGroup });
  }

  function onUnlinkClick(passcodeGroup: PasscodeGroup) {
    unlinkPasscodeGroup({ eventId: event.id, passcodeGroupId: passcodeGroup.id });
    hideActionMenu();
  }

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: '',
      sortable: false,
      width: 50,
      renderCell: ({ row }) => {
        if (!row.event_count || row.event_count < 2) {
          return <></>;
        }

        return (
          <Tooltip title='This passcode group is linked to other events'>
            <LinkOutlined color='disabled' />
          </Tooltip>
        );
      },
    },
    { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
    {
      field: 'allocated_tickets',
      headerName: 'Allocated Tickets',
      renderHeader: () => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <span>Allocated Tickets</span>
          <Tooltip title='The number of tickets that the clients can buy by using this passcode'>
            <InfoIcon className='!text-[1rem]' />
          </Tooltip>
        </Stack>
      ),
      renderCell: ({ row: { allocated_tickets } }) => (
        <div>{!!allocated_tickets ? allocated_tickets : 'Unlimited'}</div>
      ),
      sortable: false,
      flex: 1,
    },
    { field: 'passcodes_count', headerName: 'Count', sortable: false, flex: 1 },
    {
      field: 'expires_at',
      headerName: 'Expires At',
      renderCell: ({ row: { expires_at } }) => (
        <EmptyCell condition={!!expires_at}>{formatDate(expires_at)}</EmptyCell>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <PermissionsGate scope={SCOPES.ManagePasscode}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              showActionMenu(row, event);
            }}
          >
            <MoreVert />
          </IconButton>
        </PermissionsGate>
      ),
      disableExport: true,
      sortable: false,
    },
  ];

  function onPageChange(page: number) {
    setPage(page);
  }

  function onPasscodeGroupClick({ row: passcodeGroup }: GridRowParams<PasscodeGroup>) {
    setSelectedPasscodeGroup(passcodeGroup);
  }

  async function onExportClick(pg: PasscodeGroup) {
    advancedFetch(`${environment.baseUrl}/events/${event.id}/passcode-groups/${pg.id}/export`, {
      method: 'GET',
    }).then((res) => {
      handleBlobReponse(res, {
        handleMethod: 'download',
        fileName: `passcodes-${event.title}-${pg.name}.csv`,
      });
    });

    hideActionMenu();
  }

  function onDialogClose() {
    setSelectedPasscodeGroup(undefined);
  }

  return (
    <>
      <div className='h-[50vh]'>
        {passcodeGroupsData && (
          <DataGrid
            rows={passcodeGroupsData.data}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            rowCount={passcodeGroupsData.total}
            onRowClick={onPasscodeGroupClick}
            paginationMode='server'
            onPageChange={onPageChange}
            hideFooterSelectedRowCount={true}
            isRowSelectable={() => false}
          />
        )}
      </div>

      {!!selectedPasscodeGroup && (
        <Passcodes
          open={!!selectedPasscodeGroup}
          onDialogClose={onDialogClose}
          passcodeGroup={selectedPasscodeGroup}
        />
      )}

      {menuContext && (
        <Menu
          anchorEl={menuContext?.anchor}
          open={!!menuContext}
          onClose={hideActionMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <PermissionsGate scope={SCOPES.ManagePasscode}>
            <Link to={`passcodes/${menuContext.passcodeGroup.id}/edit`}>
              <MenuItem>
                <ListItemIcon>
                  <EditOutlined />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            </Link>
          </PermissionsGate>

          {menuContext.passcodeGroup.event_count && menuContext.passcodeGroup.event_count > 1 && (
            <MenuItem
              onClick={() => {
                onUnlinkClick(menuContext.passcodeGroup);
              }}
            >
              <ListItemIcon>
                <LinkOff color='error' />
              </ListItemIcon>
              <ListItemText>Unlink</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              onExportClick(menuContext.passcodeGroup);
            }}
          >
            <ListItemIcon>
              <FileDownload />
            </ListItemIcon>
            <ListItemText>Export Passcodes</ListItemText>
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              hideActionMenu();
            }}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText>Send Emails</ListItemText>
          </MenuItem> */}
        </Menu>
      )}
    </>
  );
};

export default PasscodeGroups;
