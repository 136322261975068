import React from 'react';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import PermissionsGate from '@/HOC/PermissionsGate';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const SidebarMenuList = ({ pathname, items, toggleDrawer, isMobile }: any) => {
  return (
    <List
      sx={{ width: '100%', pt: 10 }}
      component='nav'
      className={!isMobile ? 'flex flex-col h-full' : ''}
    >
      {items.map((item: any) => (
        <PermissionsGate scope={item.permission}>
          <Link onClick={toggleDrawer(false)} to={`/${item.route}`}>
            <ListItemButton
              selected={pathname.startsWith(`/${item.route}`)}
              sx={{ color: 'rgba(255,255,255,.8)' }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </Link>
        </PermissionsGate>
      ))}
    </List>
  );
};

export default SidebarMenuList;
