import { Typography } from '@mui/material';
import { DateFormats, formatDate } from '@/Utils/dates';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '@/Api/eventsSlice';
import EventDashboard from './EventDashboard';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { getEventDate } from '@/Utils/event';

const Index = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
        subtitle: getEventDate(event),
      }),
    );
  }, [event]);

  function getTitle() {
    return event?.title || 'Event Details';
  }

  return (
    <>
      {event && (
        <div className='max-w-7xl mx-auto p-4' style={{ width: '100%', height: '100%' }}>
          <EventDashboard event={event} />
        </div>
      )}
    </>
  );
};

export default Index;
