import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Box,
  CardActionArea,
} from '@mui/material';
import { DateFormats, formatDate } from '@/Utils/dates';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGetArticlesQuery, useDeleteArticleMutation } from '@/Api/articlesSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';

const Index = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState<string>();

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Articles'));
    dispatch(
      setHeader({
        title: 'Articles',
      }),
    );
  }, []);

  const { data: articles } = useGetArticlesQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );
  const [deleteArticle] = useDeleteArticleMutation();

  const onDialogClose = (value: boolean) => {
    setOpen(false);
    if (value && articleToDelete) {
      deleteArticle(articleToDelete);
    }
    setArticleToDelete(undefined);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditClick = (
    $mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
    articleId: string,
  ) => {
    $mouseEvent.preventDefault();
    navigate(`/articles/${articleId}/edit`);
  };
  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <Link to={`/articles/create`}>
              <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                Create Article
              </Button>
            </Link>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {/* TODO front: add empty state when there are no results */}
            {articles && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {articles.data.map((article) => (
                    <Card sx={{ display: 'flex', overflow: 'auto' }}>
                      <CardActionArea
                        sx={{ display: 'flex', justifyContent: 'unset', cursor: 'default' }}
                      >
                        <Stack width='100%' divider={<Divider />}>
                          <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                            <CardMedia
                              component='img'
                              sx={{
                                width: 120,
                                height: 120,
                                borderRadius: 1,
                                objectFit: 'contain',
                              }}
                              image={article.desktop_image}
                            />
                            <Stack flex={1} maxWidth='600px'>
                              <Typography variant='h6' noWrap>
                                {article.title}
                              </Typography>

                              <Typography variant='caption' color='text.secondary'>
                                {formatDate(article.created_at, DateFormats.LONG_DATE)}
                              </Typography>

                              <Typography variant='caption' color='text.secondary' noWrap>
                                {article.slug}
                              </Typography>
                            </Stack>

                            <Box className='flex flex-row justify-center !ml-auto'>
                              <IconButton
                                sx={{ margin: 'auto 0' }}
                                component='div'
                                onClick={($mouseEvent) => onEditClick($mouseEvent, article.id)}
                              >
                                <EditOutlined />
                              </IconButton>
                            </Box>
                          </Stack>
                        </Stack>
                      </CardActionArea>
                    </Card>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={articles.current_page}
                    count={articles.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>

      <DeleteDialog open={open} onDialogClose={onDialogClose} />
    </>
  );
};

export default Index;
