import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { internalApi } from './Api/internalApi';
import { thirdPartyApi } from './Api/thirdPartyApi';
import {
  SetOrganizationAction,
  ClearOrganizationAction,
  LoginAction,
  LogoutAction,
} from './Constants/actions';
import { layoutReducer } from './Slices/layoutSlice';
import { loaderReducer } from './Slices/loaderSlice';
import { snackbarReducer } from './Slices/snackbarSlice';
import { clearOrganization, setOrganization } from './Utils/auth';
import { venueEditorReducer } from './Slices/venueEditorSlice';

const appReducer = combineReducers({
  layout: layoutReducer,
  loader: loaderReducer,
  snackbar: snackbarReducer,
  venueEditor: venueEditorReducer,
  [internalApi.reducerPath]: internalApi.reducer,
  [thirdPartyApi.reducerPath]: thirdPartyApi.reducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  switch (action.type) {
    case SetOrganizationAction.type:
      setOrganization(action.payload);
      return appReducer(undefined, action);
    case ClearOrganizationAction.type:
      clearOrganization();
      return appReducer(undefined, action);
    case LoginAction.type:
    case LogoutAction.type:
      clearOrganization();
      return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(internalApi.middleware, thirdPartyApi.middleware),
});

setupListeners(store.dispatch);

export type GlobalStore = ReturnType<typeof store.getState>;
