import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Pagination,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Box,
  CardActionArea,
} from '@mui/material';
import { DateFormats, formatDate } from '@/Utils/dates';
import DeleteDialog from '@/Components/Molecules/Dialogs/DeleteDialog';
import { EditOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGetEventsQuery, useDeleteEventMutation } from '@/Api/eventsSlice';
import PermissionsGate from '@/HOC/PermissionsGate';
import { SCOPES } from '@/Constants/permission-map';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import CopyEvent from './CopyEvent';

const Index = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<string>();
  const [copyEventDialogOpen, setCopyEVentDialogOpen] = useState(false);
  const [copiedEvent, setCopiedEvent] = useState() as any;

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    dispatch(setTitle('Upcoming Events'));
    dispatch(
      setHeader({
        title: 'Upcoming Events',
      }),
    );
  }, [dispatch]);

  const { data: events } = useGetEventsQuery(
    { pageData: { page, pageSize } },
    { refetchOnMountOrArgChange: true },
  );
  const [deleteEvent] = useDeleteEventMutation();

  const onDialogClose = (value: boolean) => {
    setOpen(false);
    if (value && eventToDelete) {
      deleteEvent(eventToDelete);
    }
    setEventToDelete(undefined);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditClick = ($event: React.MouseEvent<HTMLDivElement, MouseEvent>, eventId: string) => {
    $event.preventDefault();
    navigate(`/events/${eventId}/edit`);
  };

  const onCopyEventClick = (
    $event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    eventId: string,
  ) => {
    $event.preventDefault();
    setCopyEVentDialogOpen(true);
    setCopiedEvent(eventId);
  };

  function onCopyEventDialogClose(value: boolean) {
    setCopyEVentDialogOpen(false);

    if (!value) {
      return;
    }
  }

  const responsive = useResponsive();
  const isMobile = responsive.isMobile;
  const direction = isMobile ? 'column' : 'row';

  return (
    <>
      <div className='py-8 h-full'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 h-full flex flex-col'>
          <div className='mx-2  lg:flex lg:items-center lg:justify-end lg:self-end  mb-6'>
            <PermissionsGate className='' scope={SCOPES.CreateEvent}>
              <Link to={`/events/create`}>
                <Button className='w-full lg:justify-end lg:self-end' variant='contained'>
                  Create Event
                </Button>
              </Link>
            </PermissionsGate>
          </div>

          <div className='h-96 sm:rounded-lg grow'>
            {/* TODO front: add empty state when there are no results */}
            {events && (
              <Stack spacing={4} paddingBottom={4}>
                <Stack spacing={2}>
                  {events.data.map((event) => (
                    <Link
                      key={event.id}
                      to={`/events/${event.id}`}
                      className='hover:scale-[1.005] transition-all'
                    >
                      <Card sx={{ display: 'flex', overflow: 'auto' }}>
                        <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
                          <Stack width='100%' divider={<Divider />}>
                            <Stack sx={{ p: 4 }} direction={direction} spacing={4}>
                              <CardMedia
                                component='img'
                                sx={{
                                  width: 120,
                                  height: 120,
                                  borderRadius: 1,
                                  objectFit: 'contain',
                                }}
                                image={event.logo}
                              />

                              <Stack flex={1} maxWidth='600px'>
                                <Typography variant='h6' noWrap>
                                  {event.title}
                                </Typography>

                                <Typography variant='caption' color='text.secondary'>
                                  {formatDate(
                                    event.starts_at,
                                    DateFormats.LONG_DATE,
                                    event.timezone,
                                  )}{' '}
                                  to{' '}
                                  {formatDate(event.ends_at, DateFormats.LONG_DATE, event.timezone)}
                                </Typography>

                                <Typography variant='caption' color='text.secondary' noWrap>
                                  {event.slug}
                                </Typography>
                              </Stack>

                              <Stack maxWidth='280px'>
                                <Typography variant='h6'>Venue</Typography>

                                {event.online_event ? (
                                  <Typography variant='caption' color='text.secondary'>
                                    This is an online event
                                  </Typography>
                                ) : (
                                  <>
                                    <Typography variant='caption' color='text.secondary' noWrap>
                                      {event.venue}
                                    </Typography>

                                    <Typography variant='caption' color='text.secondary'>
                                      {event.city}, {event.zip_code}
                                    </Typography>

                                    <Typography variant='caption' color='text.secondary'>
                                      {event.country}
                                    </Typography>
                                  </>
                                )}
                              </Stack>

                              <Box className='flex flex-row justify-center !ml-auto'>
                                <PermissionsGate scope={SCOPES.UpdateEvent}>
                                  <IconButton
                                    sx={{ margin: 'auto 0' }}
                                    component='div'
                                    onClick={($event) => onEditClick($event, event.id)}
                                  >
                                    <EditOutlined />
                                  </IconButton>
                                </PermissionsGate>

                                <PermissionsGate scope={SCOPES.CreateEvent}>
                                  <IconButton
                                    sx={{ margin: 'auto 0' }}
                                    component='div'
                                    onClick={($event) => onCopyEventClick($event, event.id)}
                                  >
                                    <ContentCopyOutlined color='info' />
                                  </IconButton>
                                </PermissionsGate>
                              </Box>
                            </Stack>
                          </Stack>
                        </CardActionArea>
                      </Card>
                    </Link>
                  ))}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={events.current_page}
                    count={events.last_page}
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => onPageChange(page - 1)}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>

      {copyEventDialogOpen && (
        <CopyEvent
          eventId={copiedEvent}
          open={copyEventDialogOpen}
          onDialogClose={(value: any) => onCopyEventDialogClose(value)}
        />
      )}
      <DeleteDialog open={open} onDialogClose={onDialogClose} />
    </>
  );
};

export default Index;
