import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  AddOutlined,
  ConfirmationNumberOutlined,
  EditOutlined,
  NotListedLocation,
  NotListedLocationOutlined,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import EmptyContent from '../Info/EmptyContent';
import { Organization } from '@/Models/organizations';
import { OrganizationType } from '@/Enums/organization-type';
import { useGetMyOrganizationsQuery } from '@/Api/organizationsSlice';
import { useDispatch } from 'react-redux';
import { SetOrganizationAction } from '@/Constants/actions';
import { setTitle } from '@/Slices/layoutSlice';
import { useGetAddOrganizationPermissionQuery } from '@/Api/usersSlice';
import { useMe } from '@/Hooks/useQueryFns';
import environment from '@/environments/environment';

const ChooseOrganization = () => {
  const dispatch = useDispatch();
  const { data: organizations } = useGetMyOrganizationsQuery();
  const { data: me } = useMe();
  const { data: addOrgPermission } = useGetAddOrganizationPermissionQuery();
  useEffect(() => {
    dispatch(setTitle('Choose Organization'));
  }, []);

  const navigate = useNavigate();

  function chooseOrg(org: Organization) {
    dispatch(SetOrganizationAction(org.id));
    // TODO: find a better solution for this
    if (org.type === OrganizationType.ADMINISTRATION) {
      navigate('/admin-dashboard');
    } else {
      navigate('/dashboard');
    }
  }

  function editOrg(orgId: string) {
    navigate(`/organizations/${orgId}/edit`);
  }

  if (!organizations || !addOrgPermission) {
    return <></>;
  }

  if (!!organizations && !organizations.length && !addOrgPermission.canAddOrganization) {
    return (
      <EmptyContent
        icon={<NotListedLocationOutlined className='!text-8xl' />}
        primaryText='Lost?'
        secondaryText='Did you mean to go to the ticketing page?'
        button={
          <a href={environment.ticketingUrl}>
            <Button variant='contained' startIcon={<ConfirmationNumberOutlined />}>
              Festtix Ticketing
            </Button>
          </a>
        }
      />
    );
  }

  return (
    <>
      {(!organizations || !!organizations.length) && (
        <>
          <Paper className='p-8 m-2 lg:p-8 lg:w-[30rem]'>
            <Stack spacing={2}>
              <Typography variant='h5' textAlign='center'>
                Choose organization
              </Typography>

              {organizations && (
                <List className='max-h-[35vh] overflow-auto stylish-scrollbar'>
                  {organizations.map((org: Organization, index: any) => (
                    <React.Fragment key={org.id}>
                      <ListItem
                        button
                        onClick={() => chooseOrg(org)}
                        secondaryAction={
                          me?.sub === org.user_id && (
                            <IconButton
                              edge='end'
                              onClick={($ev) => {
                                $ev.stopPropagation();
                                editOrg(org.id);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          )
                        }
                      >
                        {/* Organization picture */}
                        <img
                          alt='Organization Image'
                          src={org.logo}
                          className='object-contain mr-4'
                          width={40}
                          height={40}
                        />

                        <ListItemText
                          primary={org.name}
                          secondary={
                            org.type === OrganizationType.ADMINISTRATION ? 'Administration' : null
                          }
                        />
                      </ListItem>

                      {index !== organizations.length - 1 && <Divider variant='inset' />}
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Stack>
          </Paper>

          {addOrgPermission?.canAddOrganization && (
            <>
              <Typography variant='body1'>OR</Typography>

              <Link to='/organizations/create'>
                <Button variant='contained' startIcon={<AddOutlined />}>
                  Create new organization
                </Button>
              </Link>
            </>
          )}
        </>
      )}

      {organizations && !organizations?.length && addOrgPermission?.canAddOrganization && (
        <EmptyContent
          icon={<img className='w-24 h-24' src='/images/group.svg' alt='Group icon' />}
          primaryText='No organizations'
          secondaryText='Get started by creating a new organization'
          button={
            <Link to='/organizations/create'>
              <Button variant='contained' startIcon={<AddOutlined />}>
                Create new organization
              </Button>
            </Link>
          }
        />
      )}
    </>
  );
};

export default ChooseOrganization;
