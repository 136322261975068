import { Tag, TagData } from '@/Models/tags';
import { internalApi } from './internalApi';
import { ArticleTag, ArticleTagData } from '@/Models/articleTags';
import { TAG_TYPE } from '@/Enums/tag-types';
export const tagsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<TagData, any>({
      query: () => ({
        url: `/public/tags`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.TAGS],
    }),
    getArticleTags: builder.query<ArticleTagData, string>({
      query: (articleId) => ({
        url: `/articles/${articleId}/tags`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPE.TAGS],
    }),
    updateArticleTags: builder.mutation<void, { articleId: string; tags: string[] }>({
      query: ({ articleId, tags }) => ({
        url: `/articles/${articleId}/tags`,
        method: 'PUT',
        body: tags,
      }),
      invalidatesTags: [TAG_TYPE.TAGS],
    }),
    createArticleTags: builder.mutation<
      ArticleTag,
      {
        articleId: string;
        body: { tags: { name: string }[] };
      }
    >({
      query: ({ articleId, body }) => ({
        url: `/articles/${articleId}/tags`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.TAGS],
    }),
    deleteArticleTags: builder.mutation<void, { articleId: string; tagId: string }>({
      query: ({ articleId, tagId }) => ({
        url: `/articles/${articleId}/tags/${tagId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.TAGS],
    }),
  }),
});
export const {
  useGetTagsQuery,
  useGetArticleTagsQuery,
  useUpdateArticleTagsMutation,
  useCreateArticleTagsMutation,
  useDeleteArticleTagsMutation,
} = tagsApi;
