import authService from '@/Services/AuthService';
import Cookies from 'js-cookie';
import { getOrganization } from './auth';
import { FSTX_HEADER } from './http-client';

export async function getHeaders(headers: Headers = new Headers(), url?: string): Promise<Headers> {
  headers.set('X-Requested-With', 'XMLHttpRequest');
  headers.set('X-XSRF-TOKEN', Cookies.get('XSRF-TOKEN') || '');
  headers.set('Accept', 'application/json, text/plain, */*');

  if (!url?.startsWith('public/')) {
    try {
      const token = await authService.getToken();
      headers.set('Authorization', `Bearer ${token}`);
    } catch (e) {
      authService.login();
      throw e;
    }
  }

  const org = getOrganization();
  if (org) {
    headers.set(FSTX_HEADER.TENANT_ID, org);
  }

  return headers;
}
