import { z } from 'zod';

export const scanGroupFormSchema = z.object({
  name: z.string().min(1),
  events: z.array(z.string().min(1)),
  offline: z.boolean(),
  visible: z.boolean(),
});

export type ScanGroupForm = z.infer<typeof scanGroupFormSchema>;
