import { AddOutlined, FormatShapes, HighlightAlt } from '@mui/icons-material';
import { ToolType } from '@festtix/venue-editor';

export const editorTools: {
  tooltip: string;
  icon: JSX.Element;
  type: ToolType;
  helperText: string;
  keyboardKey?: string;
}[] = [
  {
    tooltip: 'Single section selector',
    icon: <AddOutlined />,
    type: 'singleSectionSelector',
    helperText: 'Click on a section to select it',
    keyboardKey: 'q',
  },
  {
    tooltip: 'Single seat selector',
    icon: <AddOutlined />,
    type: 'singleSeatSelector',
    helperText: 'Click on seats to select them',
    keyboardKey: 'w',
  },
  {
    tooltip: 'Rectangular seat selector',
    icon: <HighlightAlt />,
    type: 'rectangularSeatSelector',
    helperText: 'Click and drag to select multiple seats',
    keyboardKey: 'e',
  },
  {
    tooltip: 'Polygonal seat selector',
    icon: <FormatShapes />,
    type: 'polygonalSeatSelector',
    helperText: 'Click to draw a polygon to select multiple seats',
    keyboardKey: 'r',
  },
];
