import { useEffect, useState } from 'react';
import { Stack, Typography, Dialog, DialogContent } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { Ticket } from '@/Models/tickets';
import { CheckoutForm, schema } from '@/Components/Custom/Checkout/form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import { createMapFromArray } from '@/Utils/general';
import InformationsStep from '@/Components/Custom/Checkout/InformationsStep';
import { DataKey, LocalTransactionData } from '@/Models/local-storage';
import { FSTXLocalStorage } from '@/Utils/local-storage';
import { GroupedTicketData, TransactionData, TransactionExtraData } from '@/Models/transactions';
import { useGetAppliedVoucherQuery, useGetTransactionDataQuery } from '@/Api/transactionsSlice';
import { CostData } from '@/Components/Custom/Checkout/models';
import { useParams } from 'react-router-dom';
import { AccessTimeOutlined } from '@mui/icons-material';
import { getCountdownFromSeconds } from '@/Utils/dates';
import useTimer from '@/Hooks/useTimer';
import { differenceInSeconds } from 'date-fns';
import TimeExpired from '@/Components/Custom/Checkout/TimeExpired';
import { useGetEventQuery } from '@/Api/eventsSlice';
import { buildSeatDescription } from '@/Utils/seating';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Dinero from 'dinero.js';

type CheckoutStep = 0 | 1;

const Checkout = () => {
  const [expanded, setExpanded] = useState<CheckoutStep>(1);
  const { eventId } = useParams();

  const [ticketsById, setTicketsById] = useState<
    { [ticketId: string]: Partial<Ticket> } | undefined
  >(undefined);

  const [transactionExtraData, setTransactionExtraData] = useState<TransactionExtraData | null>(
    null,
  );

  const [appliedVoucher, setAppliedVoucher] = useState<any>(undefined);

  const {
    timeLeft,
    isFinished: isTimerFinished,
    start: startTimer,
    stop: stopTimer,
    resume: resumeTimer,
  } = useTimer();

  const [localTransactionData, setLocalTransactionData] = useState<LocalTransactionData | null>(
    null,
  );
  const [tdIndexIdToSeatDataMap, setTdIndexIdToSeatDataMap] = useState<any>({});

  const [costsData, setCostsData] = useState<CostData>({
    tickets: [],
    bookingFee: 0.0,
    total: 0.0,
    voucher: '',
    totalQuantity: 0,
  });

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  const methods = useForm<CheckoutForm>({
    mode: 'onBlur',
    defaultValues: {
      informations_step: {
        buyer: {
          first_name: '',
          last_name: '',
          email: '',
          email_confirmed: '',
        },
        ticket_holders: [],
        payment_type: {
          type: 'Dummy_Gateway',
          location: '',
        },
      },
    },
    resolver: zodResolver(schema),
  });

  const {
    control,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid },
    trigger,
    watch,
  } = methods;

  function onExpandToggleClick(step: CheckoutStep) {
    setExpanded((prev) => (prev === step ? 0 : step));
  }

  useEffect(() => {
    setLocalTransactionData(FSTXLocalStorage.getItem(DataKey.TRANSACTION));
    setTransactionExtraData(FSTXLocalStorage.getItem(DataKey.TRANSACTION_EXTRA));
  }, [
    FSTXLocalStorage.getItem(DataKey.TRANSACTION),
    FSTXLocalStorage.getItem(DataKey.TRANSACTION_EXTRA),
  ]);

  const { data: transactionData } = useGetTransactionDataQuery(
    {
      eventId: eventId!,
      transactionId: localTransactionData?.transactionId!,
      withReservedTickets: true,
      withReservedSeats: true,
    },
    { refetchOnMountOrArgChange: true, skip: !localTransactionData },
  );

  useEffect(() => {
    if (transactionData) {
      fillDataFromTransactionData(transactionData);
      calculateCostStats();
      startTimerIfValid(transactionData);
    }
  }, [transactionData]);

  const shouldFetch = !!localTransactionData && !!transactionExtraData?.hasVouchers;

  const { data: appliedVoucherData, error } = useGetAppliedVoucherQuery(
    shouldFetch
      ? {
          eventId: localTransactionData.eventId,
          transactionId: localTransactionData?.transactionId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  function fillDataFromTransactionData(transactionData: any) {
    const { transaction_details, reserved_tickets, reserved_seats } = transactionData;

    setValue(
      'informations_step.ticket_holders',
      transaction_details.map((td: any, index: number) => ({
        index: index,
        ticket_id: td.ticket_id,
        email: '',
        first_name: '',
        last_name: '',
      })),
    );

    setTicketsById(createMapFromArray(reserved_tickets, (t: any) => t.id));
    setTdIndexIdToSeatDataMap(buildSeatDescriptionMap(transaction_details, reserved_seats));
  }

  function buildSeatDescriptionMap(transactionDetails: any[], seats: any[]) {
    if (!seats || !seats.length) return false;

    return transactionDetails.reduce((acc, curr) => {
      const seatData = seats.find((seat) => seat.id === curr.seat_id);
      const {
        section: { label },
        group_label,
        seat_number,
      } = seatData || {};
      acc[curr.index] = buildSeatDescription(label, group_label, seat_number);
      return acc;
    }, {});
  }

  function calculateCostStats() {
    setCostsData((prevData: any) => {
      const { transaction_details, booking_fee } = transactionData;

      let totalQuantity = 0;

      const tickets = Object.entries(groupTickets(transactionData)).map((item) => {
        const [ticketId, { ticketTitle, quantity, ticketPrice }] = item;
        totalQuantity += quantity;
        return {
          ticketTitle: ticketTitle,
          quantity: quantity,
          ticketPriceBefore: ticketPrice,
          ticketPriceAfter: ticketPrice,
          ticketId,
          discountPercentage: 0,
        };
      });

      if (appliedVoucher) {
        tickets.forEach((ticket) => {
          if (
            appliedVoucher?.tickets_eligible_for_voucher_discount?.some((voucher: any) => {
              return voucher.id === ticket.ticketId;
            })
          ) {
            ticket.discountPercentage = appliedVoucher.discount_percentage;
            ticket.ticketPriceAfter = Dinero({
              amount: ticket.ticketPriceBefore,
              currency: event?.currency,
            })
              .multiply(100 - appliedVoucher.discount_percentage)
              .divide(100)
              .getAmount();
          }
        });
      }

      const ticketsPrice = getTicketsPrice(transaction_details);
      const total = ticketsPrice + booking_fee;

      let totalAfter = 0;

      if (appliedVoucher) {
        totalAfter = tickets?.reduce((acc, td) => {
          return acc + td.ticketPriceAfter;
        }, +booking_fee);
      }

      return {
        ...prevData,
        bookingFee: booking_fee,
        total,
        totalAfter,
        totalQuantity,
        tickets,
        discountPercentage: appliedVoucher?.discount_percentage || 0,
      };
    });
  }

  function groupTickets(inputTransactionData: TransactionData): GroupedTicketData {
    const { transaction_details, reserved_tickets } = inputTransactionData;

    const groupedTickets: GroupedTicketData = transaction_details.reduce((acc, curr) => {
      let currTicketPrice = 0;
      let currQuantity = 0;
      if (acc[curr.ticket_id]) {
        currTicketPrice = acc[curr.ticket_id].ticketPrice;
        currQuantity = acc[curr.ticket_id].quantity;
      }

      const ticketTitle = reserved_tickets.find((t) => t.id === curr.ticket_id)?.title;

      acc[curr.ticket_id] = {
        ticketPrice: currTicketPrice + curr.ticket_price,
        quantity: currQuantity + 1,
        ticketTitle,
      };

      return acc;
    }, {} as GroupedTicketData);

    return groupedTickets;
  }

  function getTicketsPrice(transaction_details: any) {
    return (
      transaction_details?.reduce((acc: any, td: any) => {
        return acc + td.ticket_price;
      }, 0) || 0
    );
  }

  function startTimerIfValid(transactionData: any) {
    const initialTime = getTransactionTimeLeft(transactionData);
    if (initialTime < 0) {
      return;
    }

    startTimer(Math.max(1, initialTime - 3));
  }

  function getTransactionTimeLeft(transactionData: any) {
    return differenceInSeconds(new Date(transactionData.expires_at_date), new Date());
  }

  function updateTransactionExtraData(data: TransactionExtraData) {
    const newExtraData = { ...(transactionExtraData || {}), ...data };
    FSTXLocalStorage.setItem(DataKey.TRANSACTION_EXTRA, newExtraData);
    setTransactionExtraData(newExtraData);
  }

  useEffect(() => {
    if (appliedVoucherData) {
      setAppliedVoucher(appliedVoucherData);
    }
  }, [appliedVoucherData, error]);

  useEffect(() => {
    if (transactionData) {
      calculateCostStats();
    }
  }, [transactionData, appliedVoucher]);

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <FormProvider {...methods}>
            <form noValidate>
              <InformationsStep
                event={event}
                isExpanded={expanded === 1}
                expanded={expanded}
                onExpandClick={() => onExpandToggleClick(1)}
                ticketsById={ticketsById}
                costsData={costsData}
                tdIndexIdToSeatDataMap={tdIndexIdToSeatDataMap}
                localTransactionData={localTransactionData}
                updateExtraData={updateTransactionExtraData}
                onVoucherAdd={(data: any) => setAppliedVoucher(data)}
                onVoucherRemove={() => setAppliedVoucher(undefined)}
                appliedVoucher={appliedVoucher}
              />
              <br />
            </form>
          </FormProvider>
        </div>
      </div>

      <Stack
        className='fixed lg:bottom-[30px] md:bottom-[50px] lg:left-[290px] md:left-[30px] sm:bottom-[60px] sm:left-[31px]  bg-white rounded-full flex-row justify-between items-center p-2 px-[30px] z-[999] border-2 border-black border-solid'
        direction='row'
        spacing={2}
        alignItems='center'
      >
        <AccessTimeOutlined sx={{ fontSize: '20px' }} />
        <Typography
          variant='h6'
          color='black'
          className='px-1'
          sx={{
            fontSize: '16px',
            marginLeft: 'auto',
          }}
        >
          {getCountdownFromSeconds(timeLeft)}
        </Typography>
      </Stack>

      {isTimerFinished && (
        <Dialog open={true} fullWidth>
          <DialogContent>
            <TimeExpired eventId={eventId!} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Checkout;
