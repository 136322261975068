import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { EventServiceForm, eventServiceFormSchema } from './form-data';
import { zodResolver } from '@hookform/resolvers/zod';
import FileUpload from '@/Components/Molecules/FileUpload/FileUpload';
import { MAX_IMAGE_FILE_SIZE_IN_BYTES } from '@/Constants/forms';
import { CustomZodErrorType } from '@/Utils/zod-utils';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import { compressImage, uploadFilesToAwsS3 } from '@/Utils/files';
import { FileContext, ResourceType } from '@/Enums/files';
import {
  useCreateServiceMutation,
  useGetEventServiceQuery,
  useGetServiceQuery,
  useUpdateEventServiceMutation,
  useUpdateServiceMutation,
} from '@/Api/servicesSlice';
import { HTTP_CODE } from '@/Utils/http-client';
import { PreviewData } from '@/Models/preview';
import useLoading from '@/Hooks/useLoading';
import { cleanObject } from '@/Utils/general';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import IOSSwitch from '@/Components/Atoms/Switches/IOSSwitch';
import { currencySymbol } from '@/Utils/currency';
import { useGetEventQuery } from '@/Api/eventsSlice';
import Dinero from 'dinero.js';

const EditEventService = () => {
  const { eventId, serviceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, withLoading] = useLoading();

  const { data: event } = useGetEventQuery(eventId!, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const [updateEventService] = useUpdateEventServiceMutation();

  const { data: service } = useGetEventServiceQuery(
    { eventId: eventId!, serviceId: serviceId! },
    {
      refetchOnMountOrArgChange: false,
      skip: !serviceId,
    },
  );

  const {
    setValue,
    getValues,
    control,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<EventServiceForm>({
    defaultValues: {
      price: 0,
      status: service?.status === 'active',
    },
    resolver: zodResolver(eventServiceFormSchema),
  });

  useEffect(() => {
    if (!!service && !!event) {
      reset({
        ...cleanObject(service),
        status: service.status === 'active',
        price: service.price
          ? Dinero({ amount: service.price, currency: event.currency }).toUnit()
          : 0,
      });
    }
  }, [service, reset]);

  function getTitle() {
    return `${!!serviceId ? 'Edit' : 'Create'} Event Service`;
  }

  async function onSubmit() {
    let serviceData = { ...getValues() };

    const promise = updateEventService({
      eventId: eventId!,
      serviceId: serviceId!,
      body: serviceData,
    });

    withLoading(promise.unwrap())
      .then(() => {
        navigate(`/events/${eventId}/services`);
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};

          Object.keys(errors).forEach((key) => {
            setError(key as keyof EventServiceForm, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  }

  const { isMobile } = useResponsive();

  return (
    <>
      <div className='py-8'>
        <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the service
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: 1, minWidth: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='price'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          type='number'
                          {...field}
                          required
                          label='Price'
                          variant='outlined'
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                {event && currencySymbol(event.currency)}
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ min: 0, step: 0.0001 }}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='status'
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl component='fieldset' variant='standard' error={!!error}>
                          <Typography variant='subtitle1' className='pb-2'>
                            Activate Service
                          </Typography>
                          <IOSSwitch checked={value} onChange={(e) => onChange(e.target.checked)} />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Stack>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' disabled={loading} color='success'>
                  Save
                </Button>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditEventService;
