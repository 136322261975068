import { useEffect } from 'react';
import { UserRole } from '@/Enums/roles';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  ListItemAvatar,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetUserQuery, useGetUserRolesQuery } from '@/Api/usersSlice';
import {
  useGetOrganizationRolesQuery,
  useInviteUserMutation,
  useUpdateMemberMutation,
} from '@/Api/organizationsSlice';
import LoadingButton from '@/Components/Molecules/Buttons/LoadingButton';
import { HTTP_CODE } from '@/Utils/http-client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { roleExplain } from '@/Constants/roleExplain';
import { useDispatch } from 'react-redux';
import { setHeader, setTitle } from '@/Slices/layoutSlice';
import { useResponsive } from '@/Hooks/useResponsive';
import { getOrganization } from '@/Utils/auth';
import { InviteMemberForm, inviteMemberFormSchema } from './inviteMemberHelpers';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

// TODO: convert choosing user role from a 'select' component, to a radio
// button mode with descriptions of each role
const InviteMember = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { pathname } = useLocation();
  const creatingOrganizer = pathname.startsWith('/organizers');

  useEffect(() => {
    dispatch(setTitle(getTitle()));
    dispatch(
      setHeader({
        title: getTitle(),
      }),
    );
  }, []);

  const { data: userToEdit } = useGetUserQuery(userId!, {
    refetchOnMountOrArgChange: true,
    skip: !userId,
  });

  const { data: userRoles } = useGetUserRolesQuery(userId!, {
    refetchOnMountOrArgChange: true,
    skip: !userId || creatingOrganizer,
  });

  const { data: organizationRoles } = useGetOrganizationRolesQuery(getOrganization()!);

  const { setValue, getValues, control, setError, handleSubmit } = useForm<InviteMemberForm>({
    defaultValues: {
      email: '',
      roleIds: [],
    },
    resolver: zodResolver(inviteMemberFormSchema),
  });

  const [inviteUser, { isLoading: loading }] = useInviteUserMutation();
  const [updateMember, { isLoading: loadingUpdate }] = useUpdateMemberMutation();

  // TODO front: when you spread the userToEdit, it fills in other attributes
  // that are not specified on the form. We don't want that
  useEffect(() => {
    if (userToEdit) {
      setValue('email', userToEdit.email);
    }
  }, [userToEdit]);

  useEffect(() => {
    if (!creatingOrganizer) {
      return;
    }

    const clientRoleId = organizationRoles?.find(({ name }) => name === UserRole.CLIENT)?.id;

    if (!clientRoleId) {
      return;
    }

    setValue('roleIds', getValues('roleIds').concat(clientRoleId));
  }, [organizationRoles]);

  useEffect(() => {
    if (!userRoles) {
      return;
    }

    setValue(
      'roleIds',
      userRoles.map(({ id }) => id),
    );
  }, [userRoles]);

  const onSubmit: SubmitHandler<InviteMemberForm> = async (formData) => {
    const data = getValues();

    const promise = userToEdit ? updateMember({ userId: userId!, data }) : inviteUser(data);

    promise
      .unwrap()
      .then(() => {
        navigate(creatingOrganizer ? '/organizers' : '/users');
      })
      .catch((error) => {
        if (error.status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
          const errors = error.data?.errors || {};
          Object.keys(errors).forEach((key) => {
            setError(key as keyof typeof formData, {
              message: errors[key]?.[0],
              type: 'validate',
            });
          });
        }
      });
  };

  function getTitle() {
    return `${userId ? 'Update' : 'Create'} ${creatingOrganizer ? 'Organizer' : 'User'}`;
  }

  const { isMobile } = useResponsive();

  return (
    <div className='py-8'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
        <form name='createForm' onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} divider={<Divider />}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <div className='ml-2 lg:w-1/3 flex-shrink-0'>
                <Typography variant='h6'>General</Typography>
                <Typography variant='body2' color='text.secondary'>
                  Provide essential information about the user
                </Typography>
              </div>

              <Paper elevation={2} sx={{ p: 4, flex: '1' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name='email'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          id='email'
                          name='email'
                          label='Email'
                          disabled={!!userToEdit}
                          error={!!error}
                          helperText={error?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  {!creatingOrganizer && (
                    <Grid item xs={12}>
                      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <FormControl required>
                          <FormLabel id='role-label'>User Role</FormLabel>

                          {(organizationRoles || []).map(({ id, name }) => (
                            <ListItem key={id}>
                              <ListItemAvatar>
                                <Controller
                                  key={id}
                                  name='roleIds'
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <Checkbox
                                      {...field}
                                      onChange={(_, checked) => {
                                        if (checked) {
                                          field.onChange([...field.value, id]);
                                        } else {
                                          field.onChange(
                                            field.value.filter((value) => value !== id),
                                          );
                                        }
                                      }}
                                      checked={field.value.includes(id)}
                                    />
                                  )}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={name.toUpperCase()}
                                secondary={
                                  !!name
                                    ? roleExplain[name as keyof typeof roleExplain] || ''
                                    : null
                                }
                              />
                            </ListItem>
                          ))}
                        </FormControl>
                      </List>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Stack>
          </Stack>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            {userToEdit ? (
              <LoadingButton loading={loadingUpdate}>
                <Button type='submit' variant='contained' color='success' disabled={loadingUpdate}>
                  Update
                </Button>
              </LoadingButton>
            ) : (
              <LoadingButton loading={loading}>
                <Button type='submit' variant='contained' color='success' disabled={loading}>
                  Save
                </Button>
              </LoadingButton>
            )}
          </Box>
        </form>
      </div>
    </div>
  );
};

export default InviteMember;
