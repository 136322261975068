import { Card, CardActionArea, Stack, Typography, Divider, Button } from '@mui/material';
import { useDrag } from 'react-dnd';
import { Event } from '@/Models/events';
import { FeaturedEvents } from '@/Models/featuredEvents';

export const EventDragCard = ({
  event,
  disabled,
  index,
}: {
  event: Event;
  disabled: boolean;
  index: number;
}) => {
  const [, drag] = useDrag(() => ({
    type: 'event',
    item: { id: event.id, title: event.title, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Card
      ref={drag}
      className='flex w-[90%]'
      style={
        disabled
          ? {
              pointerEvents: 'none',
              opacity: 0.5,
            }
          : {}
      }
    >
      <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
        <Stack divider={<Divider />} spacing={4} className='p-4'>
          <Typography variant='h6'>{event.title}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export const FeaturedEventDragCard = ({
  featureEvent,
  removeFromFeatured,
}: {
  featureEvent: FeaturedEvents;
  removeFromFeatured: any;
}) => {
  return (
    <Card className='flex w-[90%] mt-3 ml-6'>
      <CardActionArea sx={{ display: 'flex', justifyContent: 'unset' }}>
        <Stack
          className='p-4 w-full'
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>{featureEvent.event.title}</Typography>
          <Button className='m-0' onClick={() => removeFromFeatured(featureEvent)}>
            remove
          </Button>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
