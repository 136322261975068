import { getFilterQueryParams } from '@/Utils/filters';
import { FilterData } from '@/Models/filtering';
import { TAG_TYPE } from '@/Enums/tag-types';
import { internalApi } from './internalApi';
import { Event, EventData } from '@/Models/events';
import { getHideSuccessMessageHeader } from '@/Utils/http-client';

export const eventsApi = internalApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<EventData, FilterData<'upcoming'>>({
      query: (filterData) => ({
        url: `/events`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getArchivedEvents: builder.query<EventData, FilterData<'archived'>>({
      query: (filterData) => ({
        url: `/archived-events`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getEventsWithTickets: builder.query<EventData, FilterData<'upcoming'>>({
      query: (filterData) => ({
        url: `/events-with-tickets`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getPublicEvents: builder.query<EventData, FilterData>({
      query: (filterData) => ({
        url: `/public/events`,
        method: 'GET',
        params: getFilterQueryParams(filterData),
      }),
      providesTags: [TAG_TYPE.EVENTS],
    }),
    getEvent: builder.query<Event, string>({
      query: (eventId) => ({
        url: `/events/${eventId}`,
        method: 'GET',
      }),
    }),
    generateEventId: builder.mutation<{ id: string }, void>({
      query: () => ({
        url: `/events/generate-id`,
        method: 'POST',
        headers: getHideSuccessMessageHeader(),
      }),
    }),
    createEvent: builder.mutation<Event, any>({
      query: (body) => ({
        url: `/events`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    updateEvent: builder.mutation<Event, { eventId: String; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    deleteEvent: builder.mutation<any, string>({
      query: (eventId) => ({
        url: `/events/${eventId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
    getEventStats: builder.query<any, string>({
      query: (eventId) => ({
        url: `/events/${eventId}/stats`,
        method: 'GET',
      }),
    }),
    copyEvent: builder.mutation<any, { eventId: string; body: any }>({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/copy-event`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG_TYPE.EVENTS],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetArchivedEventsQuery,
  useGetEventsWithTicketsQuery,
  useGetPublicEventsQuery,
  useGetEventQuery,
  useLazyGetEventQuery,
  useGenerateEventIdMutation,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useGetEventStatsQuery,
  useCopyEventMutation,
} = eventsApi;
