import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { NextHandler, RTKInterceptor } from './types';

const createRequestExecutor = (query: ReturnType<typeof fetchBaseQuery>): RTKInterceptor => {
  return async (_, args, api, extraOptions) => {
    return await query(args, api, extraOptions);
  };
};

const createRequestHandler = (
  query: ReturnType<typeof fetchBaseQuery>,
  interceptors: RTKInterceptor[],
) => {
  const handler = interceptors
    .concat(createRequestExecutor(query))
    .reduceRight((next, interceptor) => {
      return async (args, api, extraOptions) => {
        return await interceptor(next, args, api, extraOptions);
      };
    }, null as unknown as NextHandler);

  return handler;
};

export { createRequestHandler };
