import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStore } from '@/store';
import { setCurrentTool } from '@/Slices/venueEditorSlice';
import { ToolType } from '@festtix/venue-editor';
import { editorTools } from '@/Constants/venue-tools';

interface EditingPaneProps {
  allowedTools: ToolType[];
}

function EditingPane({ allowedTools, children }: PropsWithChildren<EditingPaneProps>) {
  const { currentTool } = useSelector((state: GlobalStore) => state.venueEditor);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentTool('navigate'));
  }, []);

  return (
    <Stack className='w-full h-full' divider={<Divider />}>
      <Toolbar tools={allowedTools} />

      <Stack className='p-4 h-[calc(100%-3rem)]'>
        {currentTool === 'navigate' ? (
          <Stack className='h-full' alignItems='center' justifyContent='center'>
            <Typography variant='body1'>Please select a tool first.</Typography>
          </Stack>
        ) : (
          children
        )}
      </Stack>

      <Stack className='p-4 h-full'>
        <Typography variant='subtitle2'>
          Activate Canvas Tools using the keyboard keys below:
        </Typography>
        <List sx={{ p: 0 }}>
          {editorTools.map((tool) => (
            <ListItem key={tool.type} sx={{ p: 0 }}>
              <ListItemButton>
                <ListItemIcon className='uppercase'>{tool.keyboardKey}</ListItemIcon>
                <ListItemText primary={tool.tooltip} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Stack>
  );
}

export default EditingPane;
