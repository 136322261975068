import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Stack, SvgIconTypeMap, Typography } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

export interface CardItemIconCustomProps {
  isSelected: boolean;
}

export interface CardItem {
  label: string;
  icon: (props: DefaultComponentProps<SvgIconTypeMap>, isSelected: boolean) => JSX.Element;
}

export interface CardSelectionProps {
  items: CardItem[];
  onSelectionChange?: (item: CardItem | undefined) => void;
  value?: CardItem;
}

const CardSelection = ({ items, onSelectionChange, value }: CardSelectionProps) => {
  const [selectedMethod, setSelectedMethod] = useState<CardItem | undefined>(value);

  useEffect(() => {
    if (selectedMethod !== value) {
      setSelectedMethod(value);
    }
  }, [value]);

  useEffect(() => {
    if (!!onSelectionChange) {
      onSelectionChange(selectedMethod);
    }
  }, [selectedMethod]);

  function isSelected(item: CardItem, selected: CardItem | undefined) {
    return !!selected && item.label === selected.label;
  }

  function getItemClassNames(item: CardItem) {
    const isActive = isSelected(item, selectedMethod);

    return classNames('p-4 w-40 border-[3px] rounded cursor-pointer transition-all', {
      'border-gray-200  hover:bg-gray-100': !isActive,
      'border-[#bae4ff] bg-[#e6f5ff] hover:bg-[#dbedfa]': isActive,
    });
  }

  function getIconClassNames(item: CardItem) {
    const isActive = isSelected(item, selectedMethod);

    return classNames({
      'text-[#727486]': !isActive,
      'text-[#6fb2df]': isActive,
    });
  }

  function selectItem(item: CardItem | undefined) {
    setSelectedMethod(selectedMethod === item ? undefined : item);
  }

  return (
    <Stack direction='row' spacing={2}>
      {items &&
        items.map((item) => (
          <Stack
            key={item.label}
            alignItems='center'
            className={getItemClassNames(item)}
            onClick={() => selectItem(item)}
          >
            {item.icon(
              {
                className: '!text-[4rem] ' + getIconClassNames(item),
              },
              selectedMethod === item,
            )}

            <Typography variant='body1' className='select-none'>
              {item.label}
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
};

export default CardSelection;
